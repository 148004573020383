import { PhosphorIcons } from 'components/Icons/Phosphor';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type IconKeywordsRecord = Record<keyof typeof iconKeywordCategoryIconMap, Array<string>>;

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const iconKeywordCategoryIconMap = {
    homepage: PhosphorIcons.HouseLine.Fill,
    services: PhosphorIcons.GearFine.Fill,
    portfolio: PhosphorIcons.Briefcase.Fill,
    about: PhosphorIcons.UsersThree.Fill,
    faq: PhosphorIcons.SealQuestion.Fill,
    testimonials: PhosphorIcons.ChatCircle.Fill,
    contact: PhosphorIcons.Phone.Fill
} as const;

const iconKeywords: IconKeywordsRecord = {
    homepage: ['home', 'house', 'index', 'welcome', 'dashboard', 'landing'],
    services: ['services', 'tools', 'features', 'solutions', 'offerings', 'capabilities'],
    portfolio: ['portfolio', 'gallery', 'projects', 'work', 'case studies', 'examples', 'showcase'],
    about: ['about', 'team', 'company', 'us', 'story', 'mission', 'who we are'],
    faq: ['faq', 'questions', 'help', 'info', 'knowledge base', 'troubleshooting'],
    testimonials: ['testimonials', 'reviews', 'feedback', 'comments', 'clients', 'experiences', 'opinions'],
    contact: ['contact', 'phone', 'email', 'reach', 'get in touch', 'support', 'inquiry']
} as const;

const iconKeywordsEntries = Object.entries(iconKeywords) as [keyof typeof iconKeywordCategoryIconMap, Array<string>][];

export function findCategoryIcon(input?: string | null) {
    if (!input) return PhosphorIcons.File.Fill; // No input provided
    const inputLowerCase = input.toLowerCase(); // Split input into words

    for (const [category, keywords] of iconKeywordsEntries) {
        const hasKeywordInInput = keywords.some((keyword) => inputLowerCase.includes(keyword));
        if (hasKeywordInInput) {
            // Check if any word matches
            return iconKeywordCategoryIconMap[category];
        }
    }
    return PhosphorIcons.File.Fill; // No match found
}
