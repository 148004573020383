import { useNXSelectContext } from 'components/Form/NXSelect/_NXSelect/consts';
import './_NXSelectOption.scss';
import classNames from 'classnames';
import type { NXSelectNamespace } from 'components/Form/NXSelect/_NXSelect/types';
import { useLayoutEffect } from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    value: string | number;
    children: React.ReactNode;
    className?: string;
    onSelect?: (option: NXSelectNamespace.OptionValue) => void;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _NXSelectOption: React.FC<Props> = ({ value, children, className, onSelect }) => {
    /***** HOOKS *****/
    const { setIsListOpen, selectedOption, setSelectedOption, value: _value, onChange } = useNXSelectContext();

    const isSelected = value === selectedOption?.value;
    const option = { value, label: children };

    /***** EFFECTS *****/
    useLayoutEffect(() => {
        if (_value === value) {
            setSelectedOption(option);
        }
    }, [_value, value]);

    /***** RENDER *****/
    return (
        <button
            type="button"
            className={classNames('NXSelectOption', className, {
                'NXSelectOption--isSelected': isSelected
            })}
            onClick={() => {
                setIsListOpen(false);
                onChange(option);
                onSelect?.(option);
            }}
        >
            {children}
        </button>
    );
};
