import React from 'react';
import { useController } from 'react-hook-form';
import { useEffect } from 'react';
import { ColourPicker } from 'components/Form/ColourPicker';
import { webpageColourThemeFormFieldsEnum } from 'containers/katana/containers/contentEditorModules/webpageColourTheme/consts';
import { requiredFieldValidation } from 'utilities/methods/form';
import { formFieldHexCodeParser } from 'components/Form/ColourPicker/formFieldHexCodeParser';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    name: string;
};

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const { COLOUR } = webpageColourThemeFormFieldsEnum;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _ColourPickerHookFormDefault: React.FC<Props> = ({ name }) => {
    /***** HOOKS *****/
    const { field: customColorField } = useController({ name });
    const { onChange: customColourOnChange, value: customColourValue } = customColorField;

    const { field: colorField } = useController({ name: COLOUR });
    const { onChange: colourOnChange, value: colourValue } = colorField;

    const customValidations = [requiredFieldValidation, formFieldHexCodeParser];
    const nonCustomValidations = [] as [];
    const colourPickerInputValidations = colourValue === 'custom' ? customValidations : nonCustomValidations;

    function _onChange(val: string | null) {
        if (val === '#NaNNaNNaN') {
            // this is to handle a special case for HexColorPicker (external library)
            customColourOnChange(null);
        } else if (val) {
            colourOnChange('custom');
            customColourOnChange(val);
        } else {
            customColourOnChange(null);
        }
    }

    useEffect(() => {
        // This effect regulates that the interactions with the colorField (ColorSelector) always clear the value of customColorField (ColourPicker)
        if (colourValue && colourValue !== 'custom') {
            customColourOnChange(null);
        }
    }, [colourValue]);

    /***** RENDER *****/
    return <ColourPicker value={customColourValue ?? ''} onChange={_onChange} validations={colourPickerInputValidations} />;
};
