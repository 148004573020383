import { useMutation } from '@tanstack/react-query';
import { useOrderingFormContext } from 'containers/orderingForm/contexts/useOrderingFormContext';
import { API } from 'utilities/api/services';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';
import { NXQuery } from 'utilities/query';
import { UserPreferences } from 'utilities/UserPreferences';
/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type MutationFn = {
    itemId: string;
    cartToken: string;
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * When a cart item is removed, we need to check if it was the domain being used for VIPsites, and if so, set that to another domain in the cart. This needs to happen in every possible place that an item can be removed from the cart.
 */
function useSetAlternativeVipsitesDomain() {
    const { cartToken } = useOrderingFormContext();
    const [vipSitesDomain, setVipSitesDomain] = UserPreferences.useLocalStorage('CART_VIP_SITES_DOMAIN_NAME');
    const { data: cart_data } = NXQuery.orderingForm.cart.cartId.getCart.useQuery(cartToken);

    function handleSetAlternativeVipsitesDomain(itemUuid: string) {
        const itemBeingRemoved = cart_data?.cart?.items?.find((cartItem) => cartItem.uuid === itemUuid);

        // If the domain being removed is the domain being used for VIPsites, set it to the next highest domain in the cart.
        if (vipSitesDomain === itemBeingRemoved.name) {
            const nextHighestDomainInCart = cart_data?.cart?.items?.find(
                (item) => item.order_type === 'register' && item.name !== itemBeingRemoved.name
            );

            if (nextHighestDomainInCart) {
                setVipSitesDomain(nextHighestDomainInCart.name);
            }
        }
    }

    return { handleSetAlternativeVipsitesDomain };
}

function _useMutation() {
    const { handleSetAlternativeVipsitesDomain } = useSetAlternativeVipsitesDomain();

    return useMutation({
        mutationFn: ({ itemId, cartToken }: MutationFn) => API.cart.DELETE.removeItems(cartToken, itemId),
        mutationKey: createBaseQueryKey(['removeCartItem']),
        onSuccess: (_, { cartToken, itemId }) => {
            NXQuery.orderingForm.cart.cartId.getCart.invalidateQuery(cartToken);

            handleSetAlternativeVipsitesDomain(itemId);
        }
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const removeItems = Object.freeze({
    useMutation: _useMutation
});
