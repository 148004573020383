import { useNavigate, useRouter } from '@tanstack/react-router';
import { OutlineButton } from 'components/Buttons/OutlineButton';
import { logoutApp } from 'components/Header/action';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import RequestLoader from 'components/Loaders/Request';
import Avatar from 'components/Placeholders/Avatar';
import SolidTag from 'components/Tags/SolidTag';
import { pushNotification } from 'components/Toast/functions';
import Text from 'components/Utils/Text';
import { useSelectAccountMutation } from 'containers/dashboard/modules/accounts/useSelectAccount';
import { noop } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useClickAway } from 'utilities/hooks/useClickAway';
import { NXQuery } from 'utilities/query';
import './_accounts.scss';
import { useLoggedInAccountData } from './methods';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const backgrounds = [
    'linear-gradient(90deg, #0a9fe5, #10c5ef)',
    'linear-gradient(90deg, #5956d7, #c544fb)',
    'linear-gradient(90deg, #0dd41a, #86fc6f)',
    'linear-gradient(90deg, #c32888, #a01e54)',
    'linear-gradient(90deg, #f4ad26, #ca3c78)',
    'linear-gradient(90deg, #0575e6, #0054a8)',
    'linear-gradient(90deg, #f6d365, #fda085)',
    'linear-gradient(90deg, #84fab0, #8fd3f4)'
];

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const Accounts = () => {
    /***** STATE *****/
    const [showAccountsDropdown, setShowAccountsDropdown] = useState(false);
    const [accountError, setAccountError] = useState(false);

    /***** HOOKS *****/
    const navigate = useNavigate();
    const router = useRouter();
    const accountRef = useRef<HTMLButtonElement>(null);
    useClickAway([accountRef], () => setShowAccountsDropdown(false));

    /***** QUERIES *****/
    const { isSuccess: isAccountListSuccess, data: login_account_list_data } = NXQuery.auth.accountList.useSuspenseQuery();
    const { isSuccess: isCheckTokenSuccess } = NXQuery.auth.login.checkToken.useSuspenseQuery();
    const { mutateAsync: handleSelectAccountAsync, mutate: handleSelectAccount } = useSelectAccountMutation();

    const loggedInAccount = useLoggedInAccountData((account) => {
        if (!account) return null;
        return {
            id: account.id,
            role: account.attributes.role,
            firstname: account.attributes.firstname,
            lastname: account.attributes.lastname,
            company: account.attributes.company,
            email: account.attributes.email,
            accountNumber: account.attributes.account_number
        };
    });

    /***** FUNCTIONS *****/
    function toggleAccountsDropdown() {
        setShowAccountsDropdown(!showAccountsDropdown);
    }

    /***** EFFECTS *****/
    useEffect(() => {
        if (isAccountListSuccess && isCheckTokenSuccess && !loggedInAccount) {
            pushNotification({
                status: 401,
                details: 'Looks like we encountered an Account issue. Please try again later or contact our support team.'
            });
            setAccountError(true);
        }
    }, [isAccountListSuccess, isCheckTokenSuccess]);

    /***** RENDER HELPERS *****/

    const renderDropdownContents = () => {
        if (!loggedInAccount) return null;

        return (
            <div className="accounts__container">
                <div className="accounts__indicator">
                    {loggedInAccount.role === 'account holder' ? 'You are logged in as' : 'You are currently managing services for'}
                </div>

                <div className="accounts__login">
                    <Avatar
                        fullname={`${loggedInAccount.firstname} ${loggedInAccount.lastname}`}
                        size={65}
                        fontSize={22}
                        background={backgrounds[0]}
                    />

                    {loggedInAccount.company ? <div className="accounts__login--company">{loggedInAccount.company}</div> : ''}

                    <div className={`accounts__login--name ${loggedInAccount.company ? '' : 'individual'}`}>
                        {`${loggedInAccount.firstname} ${loggedInAccount.lastname}`}
                    </div>

                    {!loggedInAccount.company ? <div className="accounts__login--email">{loggedInAccount.email}</div> : ''}

                    <div className="accounts__login--number">#{loggedInAccount.accountNumber}</div>

                    {loggedInAccount.role === 'account holder' ? (
                        <OutlineButton
                            type="onClick"
                            className="accounts__login--profile"
                            color="primary"
                            onClick={(e) => {
                                e.preventDefault();
                                toggleAccountsDropdown();
                                navigate({ to: '/account/general', hash: 'overview' });
                            }}
                        >
                            My Profile
                        </OutlineButton>
                    ) : (
                        ''
                    )}
                </div>

                <div className="accounts__users">
                    {login_account_list_data
                        ?.filter((account) => account.id !== loggedInAccount?.id)
                        .map(({ id, attributes: { firstname, lastname, company, role } }, index) => (
                            <button
                                type="button"
                                key={id}
                                className="accounts__user"
                                onClick={() => {
                                    // must navigate first, otherwise the userData will get updated
                                    // and user redirected before the onSuccess callback would run to navigate away
                                    navigate({ to: '/dashboard' });
                                    handleSelectAccount(id);
                                }}
                            >
                                <div className="accounts__user--section">
                                    <Avatar
                                        fullname={`${firstname} ${lastname}`}
                                        size={39}
                                        fontSize={14}
                                        background={backgrounds[(index + 1) % backgrounds.length]}
                                    />
                                    <div className={`accounts__info ${company ? 'justify' : ''} ${role !== 'account holder' ? 'full' : ''}`}>
                                        <div className="accounts__info--company">{company}</div>
                                        <div className="accounts__info--name">{`${firstname} ${lastname}`}</div>
                                    </div>
                                    {role === 'account holder' && <SolidTag>Your account</SolidTag>}
                                </div>
                                {role === 'account holder' && (
                                    <OutlineButton
                                        className="accounts__user--profile"
                                        type="onClick"
                                        onClick={(e) => {
                                            e.stopPropagation();

                                            // Must use the promise to ensure the navigation happens on success. callbacks on the
                                            // mutation function are not run when the component unmounts which happens due to
                                            // the app mounting state of the application.
                                            handleSelectAccountAsync(id)
                                                .then(() => navigate({ to: '/account/general', hash: 'overview' }))
                                                .catch(noop);
                                        }}
                                    >
                                        My Profile
                                    </OutlineButton>
                                )}
                            </button>
                        ))}
                </div>

                <button className="accounts__logout" type="button" onClick={() => logoutApp(router)}>
                    <i className="icon icon-power" />
                    Logout
                </button>
            </div>
        );
    };

    const renderLoaderOrError = () => {
        if (accountError) {
            return (
                <div>
                    <Text lead--1 medium warn>
                        Account Error
                    </Text>
                    <Text warn size--s>
                        Something went wrong
                    </Text>
                </div>
            );
        }
        return <RequestLoader />;
    };

    /***** RENDER *****/
    return (
        <>
            <Avatar
                fullname={loggedInAccount ? `${loggedInAccount.firstname} ${loggedInAccount.lastname}` : '  '}
                size={33}
                fontSize={12}
                background={backgrounds[0]}
            />
            <button
                type="button"
                ref={accountRef}
                className="accounts__dropdown"
                onClick={toggleAccountsDropdown}
                onKeyDown={(e) => {
                    // Open menu with spacebar
                    if (e.key === ' ' && !showAccountsDropdown) {
                        e.preventDefault();
                        toggleAccountsDropdown();
                    }
                    // Close menu with escape key
                    if (['Escape', 'Esc'].includes(e.key) && showAccountsDropdown) {
                        e.preventDefault();
                        toggleAccountsDropdown();
                    }
                }}
            >
                {loggedInAccount ? (
                    <>
                        <div className={`accounts__dropdown--credential ${loggedInAccount.company ? 'justify' : ''}`}>
                            <div className="accounts__dropdown--company">{loggedInAccount.company}</div>
                            <div className="accounts__dropdown--name">{`${loggedInAccount.firstname} ${loggedInAccount.lastname}`}</div>
                        </div>

                        <PhosphorIcons.Chevron state={showAccountsDropdown ? 'up' : 'down'} secondary size={16} className="HeaderLink__chevron" />

                        {showAccountsDropdown ? renderDropdownContents() : ''}
                    </>
                ) : (
                    renderLoaderOrError()
                )}
            </button>
        </>
    );
};

export default Accounts;
