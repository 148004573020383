import { DragHandle } from 'components/DragHandle';
import Grid from 'components/Grid';
import { HandleButton } from 'components/HandleButton';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { SortableItem } from 'components/SortableItem';
import Text from 'components/Utils/Text';
import { CategoryIcon } from 'containers/katana/components/pageOrganiser/CategoryIcon';
import type { NavigationLabelsNamespace } from 'containers/katana/containers/contentEditorModules/navigationLabels/types';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { SectionOrganiserChildBaseBar } from 'containers/katana/modules/presetContent/components/sectionOrganiser/sectionOrganiserChild/baseBar';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    navigationItem: NavigationLabelsNamespace.SortableEntry;
    setNavigationItems: React.Dispatch<React.SetStateAction<NavigationLabelsNamespace.SortableEntry[]>>;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const SortableNavigationItemsListEntry = ({ navigationItem, setNavigationItems }: Props) => {
    /***** STATE *****/
    const { katanaServiceId } = useKatanaParams();

    /***** QUERIES *****/
    const { data: pages_data } = katanaQuery.serviceID.pages.useQuery(katanaServiceId);
    const { data: section_definitions_data } = katanaQuery.serviceID.meta.sectionDefinitions.useQuery(katanaServiceId);

    /***** FUNCTIONS *****/
    function handleRemove(id: NavigationLabelsNamespace.SortableEntry['id']) {
        setNavigationItems((items) => items.filter((item) => item.id !== id));
    }

    /***** RENDER HELPERS *****/
    const isPage = 'path' in navigationItem;
    const pageName = (!isPage && pages_data?.find((page) => page.id === navigationItem.site_page_id)?.name) || '';
    const isHomePage = isPage && navigationItem.path === '/';

    /***** RENDER *****/
    return (
        <SortableItem id={navigationItem.id}>
            <Grid columns="auto auto 1fr auto" gap={2} justify-children--stretch inject>
                <SectionOrganiserChildBaseBar>
                    {isPage ? <CategoryIcon category={isHomePage ? 'home' : navigationItem.name} /> : <PhosphorIcons.Section size={25} />}
                    <DragHandle.DNDKit id={navigationItem.id} />
                    <Text>
                        {!isPage && (navigationItem.name || section_definitions_data?.[navigationItem.section_id]?.name)}
                        {pageName && (
                            <>
                                {' '}
                                -{' '}
                                <Text secondary semiBold>
                                    {pageName}
                                </Text>
                            </>
                        )}
                    </Text>
                    <HandleButton onClick={() => handleRemove(navigationItem.id)} color="warn">
                        <PhosphorIcons.Trash />
                    </HandleButton>
                </SectionOrganiserChildBaseBar>
            </Grid>
        </SortableItem>
    );
};
