import { SocialMediaLinksContentModule } from 'containers/katana/containers/contentEditorModules/socialMediaLinks';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import type { KatanaNamespace } from 'containers/katana/types';
import _ from 'lodash';
import { useMemo } from 'react';
import type { NXUtils } from 'utilities/NXUtils';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useNavigationDefaultFormValues(subpageRouteData: KatanaNamespace.SubPageRouteData) {
    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();

    /***** QUERIES *****/
    // Combine all necessary queries
    const queries = {
        siteInfo: katanaQuery.serviceID.getSiteInfo.useQuery(katanaServiceId),
        navigationItems: katanaQuery.serviceID.navigationItems.useQuery(katanaServiceId),
        navigation: katanaQuery.serviceID.getNavigation.useQuery(katanaServiceId),
        socials: katanaQuery.serviceID.getSocials.useQuery(katanaServiceId),
        sections: katanaQuery.serviceID.sections.useQuery(katanaServiceId),
        sectionDefinitions: katanaQuery.serviceID.meta.sectionDefinitions.useQuery(katanaServiceId),
        socialsDefinitions: katanaQuery.serviceID.meta.getSocialsDefinitions.useQuery(katanaServiceId)
    };

    // prettier-ignore
    const requiredQueryData = [
        queries.socialsDefinitions.data,
        queries.navigationItems.data,
        queries.navigation.data,
        queries.socials.data,
        queries.sections.data,
        queries.siteInfo.data
    ];

    /***** HOOK RESULTS *****/
    return useMemo(() => {
        type QueryData = NXUtils.Prettify<
            Partial<
                { navigation_items: typeof queries.navigationItems.data } & typeof queries.navigation.data & {
                        socials?: typeof queries.socials.data;
                    }
            >
        >;

        const isFetching = Object.values(queries).some((query) => query.isFetching);
        const isMissingData = requiredQueryData.some((data) => !data);

        // Using isFetching here because the query is used on other pages too so resetting the query means the data will be refetched before it gets to the navigation form page
        if (isFetching || isMissingData || !queries.socialsDefinitions.data?.length) {
            return undefined;
        }

        const queryData: QueryData = {
            ...(queries.navigationItems.data ? { navigation_items: queries.navigationItems.data } : {}),
            ...(queries.navigation.data ?? {})
        };

        if (_.values(queries.socials.data).length) {
            const mappedSocialsData = queries.socialsDefinitions.data.map((socialMediaType) =>
                queries.socials.data?.find(({ type }) => type === socialMediaType)
            );
            _.set(queryData, SocialMediaLinksContentModule.formFields.SOCIALS, mappedSocialsData);
        }

        return queryData;
    }, [...requiredQueryData, ...Object.values(queries).map((query) => !query.isFetching)]);
}
