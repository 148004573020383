/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Component, Fragment, createRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'utilities/methods/tanstack/router/withRouter';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import SetupForm from 'containers/email/forms/setup';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import Table from 'components/Table';

/*   ACTIONS
 *****************************************************/
import { purchaseMailbox, purchaseMailboxReset, resetEmailState, setupMailbox } from 'containers/email/action';
import { formatMailboxFormValues, formatMailboxPurchaseValues } from 'containers/email/methods';
import { servicesMethods } from 'containers/services/methods';
import { gaPurchaseEvent } from 'router/google';
import { cancelSearchEmail } from 'utilities/api/services';
import { truncateText } from 'utilities/methods/commonActions';
import { emailSearch, getEmailList, resetEmailSearch } from '../action';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { EmailHostingBanner } from './banners/email';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
class EmailOverview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showSetupLightbox: false,
            setupLightboxMeta: null,
            configure: {
                id: undefined
            },
            cancelLightbox: {
                open: false,
                id: undefined
            },
            showAddLightbox: false,
            showInvoiceLightbox: false,
            invoiceID: null,
            current_page: null,
            last_page: null,
            total_all_records: null,
            total_records: null,
            sortBy: null,
            orderBy: null,
            record_per_page: undefined,
            currentKeyword: null
        };

        this.openPayInvoice = this.openPayInvoice.bind(this);
        this.closePayInvoice = this.closePayInvoice.bind(this);
        this.closeLightbox = this.closeLightbox.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.closePayInvoiceSuccess = this.closePayInvoiceSuccess.bind(this);
        this.handleSetupSubmit = this.handleSetupSubmit.bind(this);
        this.togglePopup = this.togglePopup.bind(this);
        this.handleMailboxSubmit = this.handleMailboxSubmit.bind(this);
        this.showMore = this.showMore.bind(this);
        this.setSort = this.setSort.bind(this);
        this.setOrder = this.setOrder.bind(this);
        this.setShow = this.setShow.bind(this);
        this.purchaseScrollRef = createRef();
        this.monitorKeyword = this.monitorKeyword.bind(this);
        this.toggleCancelLightbox = this.toggleCancelLightbox.bind(this);
    }

    /************ SETUP SEARCH HANDLER ***********/
    monitorKeyword(keyword = null) {
        this.setState({
            currentKeyword: keyword
        });
    }
    /********************************************/

    handleSetupSubmit(values) {
        const { setupMailbox, getEmailList } = this.props;
        const { configure } = this.state;
        const { closeLightbox } = this;

        const attributes = formatMailboxFormValues(values, 'email');

        // delete fields that are only there for display
        delete attributes.billing_cycle;
        delete attributes.plan;

        const setupMailboxFinallyCallback = () => {
            closeLightbox();
            getEmailList();
        };

        setupMailbox(configure.id, attributes, {
            successCallback: setupMailboxFinallyCallback,
            errorCallback: setupMailboxFinallyCallback
        });
    }

    /************** SETUP LIGHTBOX **************/
    closeLightbox() {
        this.setState({
            showSetupLightbox: false,
            configure: {
                id: undefined
            }
        });
    }

    openLightbox(id) {
        this.setState({
            showSetupLightbox: true,
            configure: {
                id
            }
        });
    }

    toggleCancelLightbox(mailboxId) {
        this.setState({
            cancelLightbox: {
                open: !this.state.cancelLightbox.open,
                id: mailboxId || undefined
            }
        });
    }

    /************** SORTING AND ORDERING **************/
    setSort(sort) {
        const { getEmailList } = this.props;
        let params;

        this.setState(
            {
                sortBy: sort
            },
            () => {
                const { sortBy, orderBy, recordsPerPage } = this.state;

                params = {
                    sort_by: sortBy,
                    sort: orderBy,
                    record_per_page: recordsPerPage
                };
                getEmailList(params);
            }
        );
    }

    setOrder(order) {
        const { getEmailList } = this.props;
        let params;

        this.setState(
            {
                orderBy: order
            },
            () => {
                const { sortBy, orderBy, recordsPerPage } = this.state;

                params = {
                    sort_by: sortBy,
                    sort: orderBy,
                    record_per_page: recordsPerPage
                };
                getEmailList(params);
            }
        );
    }

    setShow(recordsPerPage) {
        const { getEmailList } = this.props;
        let params;

        this.setState(
            {
                recordsPerPage: recordsPerPage
            },
            () => {
                const { sortBy, orderBy, recordsPerPage } = this.state;

                params = {
                    sort_by: sortBy,
                    sort: orderBy,
                    record_per_page: recordsPerPage
                };
                getEmailList(params);
            }
        );
    }

    /************** DISPLAY SERVICES **************/

    showMore() {
        const { getEmailList, email_list_data } = this.props;
        const { current_page, last_page, sortBy, orderBy } = this.state;

        let next = current_page;
        if (current_page < last_page) {
            next++;
        }
        const params = {
            page: next,
            sort_by: sortBy,
            sort: orderBy
        };

        getEmailList(params, email_list_data);
    }

    /************** OPEN/CLOSE PAY INVOICE **************/
    openPayInvoice(id) {
        this.setState(
            {
                showInvoiceLightbox: true,
                invoiceID: id
            },
            () => {
                this.togglePopup();
            }
        );
    }

    closePayInvoice() {
        this.setState({
            showInvoiceLightbox: false
        });
    }

    closePayInvoiceSuccess() {
        const { getEmailList } = this.props;
        this.setState(
            {
                showAddLightbox: false,
                showInvoiceLightbox: false,
                invoiceID: null
            },
            () => {
                getEmailList();
            }
        );
    }

    /************** OPEN/CLOSE POPUP FORM **************/
    togglePopup() {
        const { purchaseMailboxReset } = this.props;
        const { showAddLightbox } = this.state;

        this.setState(
            {
                showAddLightbox: !showAddLightbox
            },
            () => {
                if (!showAddLightbox) {
                    purchaseMailboxReset();
                }
            }
        );
    }

    /************** SUBMIT MAILBOXES **************/

    handleMailboxSubmit(values) {
        const { purchaseMailbox } = this.props;

        purchaseMailbox(formatMailboxPurchaseValues(values));
    }

    componentDidMount() {
        const { getEmailList, resetEmailState, email_information_mailbox_data } = this.props;

        if (email_information_mailbox_data) {
            resetEmailState();
        }

        getEmailList();
    }

    componentDidUpdate(prevProps) {
        const {
            getEmailList,
            email_list_status,
            email_list_meta,
            email_list_more_status,
            email_purchase_mailbox_status,
            email_purchase_mailbox_data,
            service_cancel_status
        } = this.props;
        const { toggleCancelLightbox } = this;

        if (email_list_status === 'success' && prevProps.email_list_status === 'loading') {
            if (email_list_meta) {
                const { current_page, last_page, total_all_records, total_records } = email_list_meta;

                this.setState({
                    current_page,
                    last_page,
                    total_all_records,
                    total_records
                });
            }
        }

        if (email_list_more_status === 'success' && prevProps.email_list_more_status === 'loading') {
            if (email_list_meta) {
                const { current_page, last_page, total_all_records, total_records } = email_list_meta;

                this.setState({
                    current_page,
                    last_page,
                    total_all_records,
                    total_records
                });
            }
        }

        if (email_purchase_mailbox_status === 'success' && prevProps.email_purchase_mailbox_status === 'loading') {
            const { attributes } = email_purchase_mailbox_data;

            gaPurchaseEvent({
                invoiceId: attributes.invoice,
                item_brand: 'Axigen',
                item_category: 'email_hosting'
            });

            this.openPayInvoice(attributes.invoice);
            getEmailList();
        }

        if ((service_cancel_status === 'success' || service_cancel_status === 'error') && prevProps.service_cancel_status === 'loading') {
            toggleCancelLightbox();
        }
    }

    /**********************************************************************************************************
     *   CREATE TABLE MATRIX
     **********************************************************************************************************/

    render() {
        const {
            isExpressServiceManager,
            resetEmailState,
            email_list_more_status,
            email_list_status,
            email_list_data,
            email_search_status,
            email_search_data,
            email_purchase_mailbox_status,
            email_purchase_mailbox_data,
            email_purchase_mailbox_error,
            email_mailbox_setup_status,
            service_cancel_status,
            emailSearch,
            resetEmailSearch,
            getEmailList,
            history
        } = this.props;
        const {
            showSetupLightbox,
            showAddLightbox,
            showInvoiceLightbox,
            invoiceID,
            current_page,
            last_page,
            configure,
            cancelLightbox,
            currentKeyword
        } = this.state;
        const {
            openLightbox,
            closeLightbox,
            openPayInvoice,
            closePayInvoice,
            closePayInvoiceSuccess,
            handleMailboxSubmit,
            togglePopup,
            setShow,
            handleSetupSubmit,
            monitorKeyword,
            toggleCancelLightbox
        } = this;

        const renderButton = (domain, id, invoice) => {
            if (domain === 'unconfigured' && invoice) {
                return {
                    label: 'Pay Invoice',
                    button: 'Solid',
                    type: 'onClick',
                    className: '',
                    size: 'medium',
                    onClick: (e) => {
                        e.preventDefault();
                        openPayInvoice(invoice);
                    }
                };
            } else if (domain === 'unconfigured') {
                return [
                    {
                        label: 'Setup',
                        button: 'Solid',
                        type: 'onClick',
                        className: '',
                        size: 'medium',
                        onClick: (e) => {
                            e.preventDefault();
                            openLightbox(id);
                        }
                    },
                    {
                        label: 'Cancel',
                        button: 'Outline',
                        type: 'onClick',
                        className: '',
                        color: 'warn',
                        size: 'medium',
                        onClick: (e) => {
                            e.preventDefault();
                            toggleCancelLightbox(id);
                        }
                    }
                ];
            }

            return {
                label: 'Manage',
                type: 'onClick',
                className: '',
                size: 'medium',
                onClick: (e) => {
                    e.preventDefault();
                    resetEmailState();
                    history.push(`/my-services/email-hosting/${id}/general#mailboxes`);
                },
                list: [
                    {
                        label: 'Add Mailbox',
                        type: 'onClick',
                        onClick: (e) => {
                            e.preventDefault();
                            resetEmailState();
                            history.push(`/my-services/email-hosting/${id}/general#add-mailbox`);
                        }
                    }
                ]
            };
        };

        const renderServiceName = (domain, product, disk_space) => {
            return (
                <div className="sharedTable__column--title">
                    <div className="primary">{domain && domain !== 'unconfigured' ? truncateText(domain, 35, '...') : 'Requires Setup'}</div>
                    {domain && domain !== 'unconfigured' ? (
                        ''
                    ) : (
                        <div className="secondary">
                            {product ? product : 'Not available'}
                            {disk_space ? ` - ${disk_space}` : ''}
                        </div>
                    )}
                </div>
            );
        };

        const isLastPage = () => {
            if (current_page && last_page && current_page === last_page) {
                return true;
            }
            return false;
        };

        /*   CREATE TABLE MATRIX
         **********************************************************************************************************/
        const handleTableMatrix = (data) => {
            if (!data) {
                return false;
            }

            const emailTempMatrix = Array(data.length).fill();

            Object.keys(emailTempMatrix).forEach((value) => {
                const { id, attributes } = data[value];
                const { domain, invoice, product, total_mailbox, disk_space } = attributes;

                emailTempMatrix[value] = {
                    domain: renderServiceName(domain, product, disk_space),
                    ...(domain !== 'unconfigured' && { mailboxes: total_mailbox }),
                    actions: renderButton(domain, id, invoice)
                };
            });

            return emailTempMatrix;
        };

        /*   CREATE TABLE MATRIX
         **********************************************************************************************************/
        const emailMatrix = handleTableMatrix(servicesMethods.table.handleMatrixCondition(currentKeyword, email_list_data, email_search_data));

        const handleTableRender = () => {
            let conditionalTableProps = {
                embedded: true
            };

            if (!isExpressServiceManager) {
                conditionalTableProps = servicesMethods.table.getSharedConditionalProps({
                    title: `Manage Email Hosting`,
                    search: {
                        data: email_search_data,
                        placeholder: `Search for an email hosting service`,
                        status: email_search_status,
                        cancel: cancelSearchEmail,
                        search: emailSearch,
                        reset: resetEmailSearch,
                        keyword: monitorKeyword
                    },
                    paginationStatus: email_list_more_status,
                    setShow,
                    isLastPage,
                    matrix: emailMatrix
                });
            }

            if (isExpressServiceManager && emailMatrix && emailMatrix.length > 5) {
                conditionalTableProps = {
                    showAll: {
                        label: 'show all email hosting',
                        status: email_list_status,
                        conditions: (emailMatrix && emailMatrix.length > 5) || !isLastPage(),
                        onClick: () => history.push('/my-services/email-hosting')
                    },
                    embedded: true
                };
            }

            const getError = () => {
                if (isExpressServiceManager) return 'You have no active mailboxes on your account.';
                return currentKeyword
                    ? 'No mailboxes matched your search.'
                    : `You have no active mailboxes on your account. Why don't you purchase some below to get started!`;
            };

            return (
                <Table
                    className="service__table--email"
                    sort={{
                        function: getEmailList
                    }}
                    header={[
                        {
                            title: `Domain`,
                            sort: `name`,
                            className: `domain`
                        },
                        {
                            title: `Mailboxes`,
                            className: `mailboxes`
                        },
                        {
                            action: true
                        }
                    ]}
                    loading={servicesMethods.table.handleLoading(email_list_status, email_search_status)}
                    matrix={isExpressServiceManager ? emailMatrix && emailMatrix.slice(0, 5) : emailMatrix}
                    error={getError()}
                    stacked={true}
                    dynamicActions={true}
                    {...conditionalTableProps}
                />
            );
        };

        if (isExpressServiceManager) {
            // Return this if this component called in the Express Service Manager
            return (
                <Fragment>
                    {handleTableRender()}
                    {showSetupLightbox ? (
                        <OverlayLightbox
                            onOpen={showSetupLightbox}
                            className="setupService__lightbox"
                            onClose={closeLightbox}
                            title="Setup Mailbox"
                            loading={email_mailbox_setup_status}
                        >
                            <SetupForm onSubmit={handleSetupSubmit} id={configure.id} />
                        </OverlayLightbox>
                    ) : (
                        ''
                    )}
                    {showInvoiceLightbox ? (
                        <OverlayLightbox
                            invoiceid={invoiceID}
                            title={`Pay Invoice #` + invoiceID}
                            onOpen={showInvoiceLightbox}
                            onClose={closePayInvoice}
                            onSuccessClose={closePayInvoiceSuccess}
                        />
                    ) : (
                        ''
                    )}
                    {cancelLightbox.open && (
                        <OverlayLightbox
                            title="Cancel Service"
                            onOpen={cancelLightbox.open}
                            onClose={toggleCancelLightbox}
                            cancel={{
                                id: cancelLightbox.id,
                                service: 'emailHosting',
                                closeAction: toggleCancelLightbox,
                                fromDashboard: true
                            }}
                            loading={service_cancel_status}
                        />
                    )}
                </Fragment>
            );
        }

        return (
            <div className="ServiceOverview">
                {handleTableRender()}
                {showSetupLightbox ? (
                    <OverlayLightbox
                        onOpen={showSetupLightbox}
                        className="setupService__lightbox"
                        onClose={closeLightbox}
                        title="Setup Mailbox"
                        loading={email_mailbox_setup_status}
                    >
                        <SetupForm onSubmit={handleSetupSubmit} id={configure.id} />
                    </OverlayLightbox>
                ) : (
                    ''
                )}
                {showInvoiceLightbox ? (
                    <OverlayLightbox
                        title={'Pay Invoice #' + invoiceID}
                        invoiceid={invoiceID}
                        onOpen={showInvoiceLightbox}
                        onClose={closePayInvoice}
                        onSuccessClose={closePayInvoiceSuccess}
                    />
                ) : (
                    ''
                )}

                {cancelLightbox.open && (
                    <OverlayLightbox
                        title="Cancel Service"
                        onOpen={cancelLightbox.open}
                        onClose={toggleCancelLightbox}
                        cancel={{
                            id: cancelLightbox.id,
                            service: 'emailHosting',
                            closeAction: toggleCancelLightbox
                        }}
                        loading={service_cancel_status}
                    />
                )}

                <EmailHostingBanner handleMailboxSubmit={handleMailboxSubmit} />
            </div>
        );
    }
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default withRouter(
    connect(
        (state) => ({
            email_information_mailbox_data: state.email.email_information_mailbox_data,
            email_list_more_status: state.services.email_list_more_status,
            email_list_status: state.services.email_list_status,
            email_list_data: state.services.email_list_data,
            email_list_meta: state.services.email_list_meta,
            email_search_status: state.services.email_search_status,
            email_search_data: state.services.email_search_data,
            email_purchase_mailbox_status: state.email.email_purchase_mailbox_status,
            email_purchase_mailbox_data: state.email.email_purchase_mailbox_data,
            email_purchase_mailbox_error: state.email.email_purchase_mailbox_error,
            email_mailbox_setup_status: state.email.email_mailbox_setup_status,
            service_cancel_status: state.cancel.service_cancel_status,
            app_viewport: state.app.app_viewport
        }),
        {
            getEmailList,
            setupMailbox,
            purchaseMailboxReset,
            purchaseMailbox,
            emailSearch,
            resetEmailSearch,
            resetEmailState
        }
    )(EmailOverview)
);
