/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CheckBoxList } from 'components/Form/CheckBoxList';
import NXBox from 'components/NXBox';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import ContentEditorModuleContent from 'containers/katana/containers/ContentEditorLightbox/contentEditorModuleContent';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { NXDropZone } from 'components/Form/NXDropZone';
import { useUpdateKatanaSiteInfoMutation } from 'containers/katana/queries/useUpdateKatanaSiteInfoMutation';
import { useController } from 'react-hook-form';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
const { FAVICON } = useUpdateKatanaSiteInfoMutation.FORM_FIELD_KEYS;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _FavIconContentModule = () => {
    /***** HOOKS *****/
    const {
        field: { value: favIcon }
    } = useController({ name: FAVICON });
    const {
        field: { value: favIconOpen, onChange: setFavIconOpenState }
    } = useController({ name: 'favIconOpen' });

    /***** EFFECTS *****/
    useEffect(() => {
        if (favIcon) {
            setFavIconOpenState(true);
        }
    }, [favIcon]);

    /***** RENDER *****/
    return (
        <NXBox.TopAccordion.WithSessionState
            title="Favicon"
            description="Favicons are miniature versions of your business logo, enhancing the professional appearance of your website and making it easily recognisable in bookmarks and browser tabs."
            isOpenDefault
        >
            <NXBox.DefaultPadding>
                <ContentEditorModuleContent>
                    <CheckBoxList>
                        <CheckBoxList.Item.HookForm name="favIconOpen">Upload a custom favicon</CheckBoxList.Item.HookForm>
                    </CheckBoxList>
                    {favIconOpen ? (
                        <NXDropZone.HookForm.WithImageControl
                            name={FAVICON}
                            label="Favicon Image"
                            imageControlProps={{
                                size: 80
                            }}
                            singleFile
                        />
                    ) : (
                        ''
                    )}
                </ContentEditorModuleContent>
            </NXBox.DefaultPadding>
        </NXBox.TopAccordion.WithSessionState>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const FavIconContentModule = Object.assign(_FavIconContentModule, {
    formFields: { FAVICON }
});

export { FavIconContentModule };
