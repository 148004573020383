import { SolidButton } from 'components/Buttons/SolidButton';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import { useOrderingFormContext } from 'containers/orderingForm/contexts/useOrderingFormContext';
import 'containers/orderingForm/pages/registerPage/recommendedDomains/_recommendedDomains.scss';
import { Suggestions } from 'containers/orderingForm/pages/registerPage/recommendedDomains/suggestions';
import type { DomainSuggestions } from 'containers/orderingForm/types';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import { NXQuery } from 'utilities/query';
/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const RecommendedDomains = () => {
    /***** HOOKS *****/
    const { domainData } = useOrderingFormContext();
    //const [suggestedItems, setSuggestedItems] = useState<DomainSuggestions[] | null>(null);
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** QUERIES *****/
    const {
        data: domain_suggestion_data,
        isFetchingNextPage: isDomainSuggestionsFetchingNextPage,
        fetchNextPage,
        hasNextPage
    } = NXQuery.domain.suggestion.useInfiniteQuery(domainData);

    const suggestedItems = domain_suggestion_data?.pages
        .flatMap((page) => page.data)
        .filter((suggestion, index, self) => {
            return self.findIndex((s) => s.tld === suggestion.tld) === index;
        }) as Array<DomainSuggestions>;

    /***** RENDER *****/
    return (
        <Flex direction="column" gap={1} className="recommendedDomains__container">
            <Text size--s={isMobile} semiBold={isMobile} bold={!isMobile} secondary align--center>
                Recommended Domains
            </Text>

            <Suggestions suggestedItems={suggestedItems} />
            {suggestedItems?.length ? (
                <SolidButton
                    type="onClick"
                    onClick={() => fetchNextPage()}
                    disabled={!hasNextPage}
                    isLoading={isDomainSuggestionsFetchingNextPage}
                    className="recommendedDomains__button--showMore"
                >
                    <Text semiBold>Show more</Text>
                </SolidButton>
            ) : null}
        </Flex>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
