import { getLoggedInAccountData } from 'containers/dashboard/modules/accounts/methods';
import { useSelector } from 'react-redux';
import { capitalize, toLuxonDate } from 'utilities/methods/commonActions';
import { NXQuery } from 'utilities/query';
import { orderedTiers, tiers } from './consts';

/**
 * Get VIPrewards tier for the logged in customer and returns some formatted information for usage in components
 */
export function useVipRewards() {
    /***** HOOKS *****/
    const rewards_tiers_list_data = useSelector((state) => state.rewards.rewards_tiers_list_data);

    /***** QUERIES *****/
    const { data: login_account_list_data } = NXQuery.auth.accountList.useSuspenseQuery(void 0);
    const { data: app_check_token_data } = NXQuery.auth.login.checkToken.useSuspenseQuery(void 0, { select: ({ data }) => data.attributes });
    const loggedInAccount = getLoggedInAccountData(login_account_list_data, app_check_token_data);

    function getCustomerFrom() {
        function getLuxonDateSafe() {
            if (typeof loggedInAccount?.attributes?.customer_from !== 'string') {
                return null;
            }

            const dateWithoutTime = loggedInAccount.attributes.customer_from.split(
                loggedInAccount.attributes.customer_from.includes('T') ? 'T' : ' '
            )[0];

            const parsedDate = toLuxonDate(dateWithoutTime, 'yyyy-MM-dd');

            if (!parsedDate.isValid) {
                return null;
            }

            return parsedDate;
        }

        const luxonDate = getLuxonDateSafe();

        return {
            luxonDate,
            readable: luxonDate ? luxonDate.toFormat('dd/MM/yyyy') : ''
        };
    }

    function getCurrentTier() {
        const tier = loggedInAccount?.attributes?.reward_tier;

        if (typeof tier !== 'string') {
            return null;
        }

        const lowerCaseTier = tier.toLowerCase();

        return {
            lowercase: lowerCaseTier,
            readable: lowerCaseTier === tiers.VIP ? 'VIP' : capitalize(lowerCaseTier)
        };
    }

    /**
     * @param {NXUtils.ValueOf<typeof tiers>} tier
     */
    function isAtOrAboveTier(tier) {
        if (!loggedInAccount?.attributes?.reward_tier) {
            return false;
        }

        return orderedTiers.indexOf(loggedInAccount.attributes.reward_tier) >= orderedTiers.indexOf(tier);
    }

    return {
        /**
         * Current VIPrewards tier of the account logged in account
         */
        currentTier: getCurrentTier(),
        /**
         * Boolean representing whether the user has an account
         */
        isMember: !!loggedInAccount?.attributes?.reward_tier,
        /**
         * Account creation date of the logged in account
         */
        customerFrom: getCustomerFrom(),
        /**
         * First name of the account holder who's account the user is currently logged into
         */
        firstName: loggedInAccount?.attributes?.firstname || '',
        /**
         * List of all VIPrewards tiers and their associated benefits
         */
        rewardsTiers: rewards_tiers_list_data?.reward_tiers || null,

        /**
         * List of all promo codes related to the VIPrewards tiers
         */
        promoCodes: rewards_tiers_list_data?.promo_codes || null,

        /**
         * Returns whether the user is at or above a specific tier
         */
        isAtOrAboveTier
    };
}
