import Anchor from 'components/Anchor';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import { useOrderingFormContext } from 'containers/orderingForm/contexts/useOrderingFormContext';
import { ExclamationMark } from 'containers/orderingForm/exclamationMarkIcon';
import { IntaserveFormInput } from 'containers/orderingForm/orderingFormInputs';
import { FieldValidation } from 'containers/orderingForm/orderingFormInputs/fieldValidation';
import type { EditFormValues, Errors, Register, Watch } from 'containers/orderingForm/orderingFormInputs/types';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type EligibilityFormFieldsProps = {
    productData?: Artah.Products.POST._200['data'];
    auEligibilityParams: any;
    register: Register<EditFormValues>;
    watch: Watch<EditFormValues>;
    errors: Errors<EditFormValues>;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const EligibilityFormFields: React.FC<EligibilityFormFieldsProps> = ({ productData, auEligibilityParams, register, watch, errors }) => {
    /***** HOOKS *****/
    const { currentItem } = useOrderingFormContext();
    const hasEligibilityNumber = productData?.[0]?.attributes?.custom_fields.some((field) => field.name === 'Eligibility Number');

    /***** QUERIES *****/
    const {
        data: domain_eligibility_data,
        isError: isAuEligibilityError,
        isSuccess: isAuEligibilitySuccess,
        isLoading: isAuEligibilityLoading
    } = NXQuery.domain.domainID.auEligibility.useQuery(auEligibilityParams);

    /***** RENDER *****/
    return (
        <>
            {productData?.[0]?.attributes?.custom_fields?.length > 0 && currentItem?.order_type === 'register' && (
                <Flex direction="column" gap={4} className="editItemConfig__eligibilityForm">
                    <Text size--xm bold>
                        Domain Eligibility
                    </Text>

                    <Flex direction="column" className="editItemConfig__eligibilityFormInfoNotice">
                        <Flex align="center">
                            <ExclamationMark size={15} />
                            <Text size--xm bold>
                                Eligibility Required
                            </Text>
                        </Flex>
                        <Text size--s>
                            {`${currentItem?.product_name} domain names require eligibility in order to be registered. Please enter your eligibility information below.`}
                        </Text>
                        <Anchor
                            target="_blank"
                            href="https://www.auda.org.au/au-domain-names/au-rules-and-policies/au-domain-administration-rules-licensing-2/"
                            className="editItemConfig__eligibilityFormInfoNoticeAnchor"
                        >
                            Read more about eligibility
                        </Anchor>
                    </Flex>

                    {productData?.[0]?.attributes?.custom_fields.map((field) => {
                        if (hasEligibilityNumber && field.name !== 'Eligibility Type' && field.name !== 'Eligibility Number') return;
                        const name = field.name
                            .split(' ')
                            .map((word, index) => (index === 0 ? word.toLowerCase() : word))
                            .join('');

                        if (field.type === 'dropdown') {
                            return (
                                <IntaserveFormInput.HookForm.Select
                                    key={name}
                                    register={register}
                                    watch={watch}
                                    name={name}
                                    options={field.options}
                                    placeHolder="Please Select"
                                    label={field.name}
                                    validationErrors={errors}
                                />
                            );
                        }

                        if (field.type === 'text') {
                            return (
                                <div key={name}>
                                    <IntaserveFormInput.HookForm.Text
                                        register={register}
                                        name={name}
                                        placeholder={`Enter ${field.name}`}
                                        label={field.name}
                                        validationErrors={errors}
                                        errorBorder={name === 'eligibilityNumber' ? isAuEligibilityError : !!errors?.[name as keyof EditFormValues]}
                                        successBorder={name === 'eligibilityNumber' ? isAuEligibilitySuccess : false}
                                    />
                                    {name === 'eligibilityNumber' && (
                                        <>
                                            <FieldValidation
                                                isError={isAuEligibilityError}
                                                isSuccess={isAuEligibilitySuccess}
                                                isLoading={isAuEligibilityLoading}
                                                message={
                                                    isAuEligibilityError
                                                        ? 'Invalid eligibility number'
                                                        : isAuEligibilitySuccess
                                                            ? 'Eligibility code has been correctly validated'
                                                            : ''
                                                }
                                            />
                                            {domain_eligibility_data?.status === 'OK' && (
                                                <IntaserveFormInput.HookForm.Text register={register} label="Legal Name" name="legalName" disabled />
                                            )}
                                        </>
                                    )}
                                </div>
                            );
                        }
                    })}
                </Flex>
            )}
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
