import { useMutation } from '@tanstack/react-query';
import { useFormValuesFileUpload } from 'containers/katana/hooks/useFormValuesFileUpload';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { filterEndpointAttributeKeys } from 'containers/katana/queries/methods/filterEndpointAttributeKeys';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import type { KatanaNamespace } from 'containers/katana/types';
import { has, keys } from 'lodash';
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions/handleDefaultErrorNotification';
import { handleDefaultSuccessNotification } from 'utilities/methods/commonActions/handleDefaultSuccessNotification';

type Params = Parameters<typeof KATANA_API.katana.service_id.info.POST>[0];

/**********************************************************************************************************
 *   Katana Update site info
 **********************************************************************************************************/
/**
 * Gets the site info
 * @param  options - mutation options
 */
export function useUpdateKatanaSiteInfoMutation(
    serviceID: KatanaNamespace.ServiceId,
    options = {},
    fileUploadOptions: { context?: KATANA_API.katana.service_id.uploads.POST.Params['formData']['context'] } = {}
) {
    /***** HOOKS *****/
    const { page } = useKatanaParams();
    const processAndUploadFiles = useFormValuesFileUpload(serviceID, fileUploadOptions);

    /***** FUNCTIONS *****/
    async function handleMutationAsync(values: Params['attributes']) {
        const filteredAttributes = filterEndpointAttributeKeys(values, useUpdateKatanaSiteInfoMutation.acceptableKeys);

        const fileRecords = await processAndUploadFiles(filteredAttributes);

        if (has(values, 'color') || has(values, 'style')) {
            const cancelQueries = [
                katanaQuery.serviceID.service.cancelQueries(serviceID),
                katanaQuery.serviceID.getSiteInfo.cancelQueries(serviceID),
                katanaQuery.serviceID.getNavigation.cancelQueries(serviceID),
            ];
            await Promise.all(cancelQueries);
        }

        if (values?.color) {
            katanaQuery.serviceID.service.optimistic(serviceID, 'data.site.color', values.color);
            katanaQuery.serviceID.getSiteInfo.optimistic(serviceID, 'data.color', values.color);
            katanaQuery.serviceID.getNavigation.optimistic(serviceID, 'data.style.color', values.color);
        }

        if (values?.style) {
            katanaQuery.serviceID.getSiteInfo.optimistic.merge(serviceID, 'data.style', values.style);
            katanaQuery.serviceID.service.optimistic.merge(serviceID, 'data.site.style', values.style);
        }

        const updatedAttributes = {
            ...filteredAttributes,
            ...fileRecords,
        };

        if (!keys(updatedAttributes).length) {
            return Promise.resolve();
        }
        return await KATANA_API.katana.service_id.info.POST({ serviceID, attributes: updatedAttributes });
    }

    function handleMutation(values: Params['attributes']) {
        return handleMutationAsync(values);
    }

    /***** HOOK RESULTS *****/
    return useMutation({
        mutationFn: handleMutation,
        onSuccess: page === 'setup' ? undefined : handleDefaultSuccessNotification,
        onError: handleDefaultErrorNotification,
        onSettled: () => {
            katanaQuery.serviceID.service.invalidateQueries(serviceID);
        },
        ...options,
    });
}

useUpdateKatanaSiteInfoMutation.FORM_FIELD_KEYS = {
    TITLE: 'title',
    DESCRIPTION: 'description',
    IS_ACTIVE: 'is_active',
    COLOR: 'color',
    FAVICON: 'favicon',
    STYLE: 'style',
    FONTS: 'style.fonts',
} as const;
useUpdateKatanaSiteInfoMutation.acceptableKeys = Object.values(useUpdateKatanaSiteInfoMutation.FORM_FIELD_KEYS);
