/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_FormItemInnerPrependText.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    children: React.ReactNode;
};
/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _FormItemInnerPrependedText: React.FC<Props> = ({ children }) =>
    children ? (
        <Flex align="center" justify="end" className="FormItemInnerPrependedText">
            <Text bold italic size--xs secondary>
                {children}
            </Text>
        </Flex>
    ) : null;
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
