import { createRoute, redirect } from '@tanstack/react-router';
import { createKatanaServiceId } from 'containers/katana/methods';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { MyServicesRoute } from 'containers/services';
import type { GetBeforeLoadContextFromRouteId } from 'router/types';
import { routerMiddleware } from 'router/utils/middleware';
import { lazyFN } from 'utilities/methods/tanstack/router/lazyFn';

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const VIPSitesRoute = createRoute({
    getParentRoute: () => MyServicesRoute,
    path: 'vip-sites/$katanaServiceId',
    async loader({ params }) {
        // we're not awaiting this because we don't want to block the route from loading the content editor lightbox
        katanaQuery.serviceID.service.prefetchQuery(createKatanaServiceId(params.katanaServiceId));
    },
    beforeLoad(opts) {
        routerMiddleware.business({ path: '/my-services/vip-sites' }, opts);
        routerMiddleware.authentication('user', opts);
        routerMiddleware.business(this, opts);
        routerMiddleware.authentication('user', opts);
        redirectDirectMatch(opts);
    }
}).lazy(lazyFN(() => import('./vip-sites.$katanaServiceId.lazy'), 'LazyVIPSitesRoute'));

/**********************************************************************************************************
 *   ROUTE HELPER FUNCTIONS
 **********************************************************************************************************/
type BeforeLoadContext = GetBeforeLoadContextFromRouteId<'/my-services/vip-sites/$katanaServiceId'>;

function redirectDirectMatch({ buildLocation, location, params }: BeforeLoadContext) {
    const builtLocation = buildLocation({
        to: '/my-services/vip-sites/$katanaServiceId',
        params
    });

    if (builtLocation === location) {
        throw redirect({
            to: '/my-services/vip-sites/$katanaServiceId/overview',
            hash: 'basic-details',
            params
        });
    }
}
