import RequestLoader from 'components/Loaders/Request';
import { KatanaHookFormHandler } from 'containers/katana/components/HookFormHandler';
import { navigationZodSchema } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/navigationFormHandler/consts';
import { useHandleNavigationSubmit } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/navigationFormHandler/useHandleNavigationSubmit';
import { useNavigationDefaultFormValues } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/navigationFormHandler/useNavigationDefaultFormValues';
import type { KatanaNamespace } from 'containers/katana/types';
import React from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type NavigationFormHandler = React.FC<{
    subpageRouteData: KatanaNamespace.SubPageRouteData;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const NavigationFormHandler: NavigationFormHandler = ({ subpageRouteData }) => {
    /***** HOOKS *****/
    const defaultValues = useNavigationDefaultFormValues(subpageRouteData);
    const handleNavigationSubmit = useHandleNavigationSubmit(subpageRouteData);

    /***** RENDER *****/
    if (!defaultValues) {
        return <RequestLoader message="Loading Navigation Details..." />;
    }

    return (
        <KatanaHookFormHandler defaultValues={defaultValues} zodSchema={navigationZodSchema} handleOnSubmit={handleNavigationSubmit}>
            {subpageRouteData.modules}
        </KatanaHookFormHandler>
    );
};
