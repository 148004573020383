/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getDataFromData } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { SECTION_DEFINITION_PROPERTY_TYPES } from 'containers/katana/components/contentDefinitionForm/consts';
import _ from 'lodash';

/**
 * Get files from redux values object
 */
export function getFilesFromValues(values) {
    if (['string', 'number'].includes(typeof values)) {
        return values;
    }

    const files = {};

    function handleKeyValueFile(key, value) {
        if (!(value instanceof File)) {
            return;
        }
        files[key] = value;
    }

    const valueEntries = Object.entries(values);

    valueEntries.forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach((_value, i) => {
                if (!(key in files)) {
                    files[key] = [];
                }

                if (_value instanceof File) {
                    files[key][i] = _value;
                    return;
                }

                const recursiveFiles = getFilesFromValues(_value);
                console.groupEnd();

                files[key][i] = recursiveFiles;
            });
        } else {
            handleKeyValueFile(key, value);
        }
    });

    return files;
}

/**
 * @type {FileReferenceUploadTrackerRecord}
 */
const fileReferenceTrackerRecord = {
    files: [],
    tracker: []
};

/**
 * Recursively uploads files to the server using the provided asyncFileUploadMutation function.
 * @param {Array<File> | File} files - An array of File objects to upload.
 * @param {Function} asyncFileUploadMutation - An async function that takes a FormData object and returns a Promise.
 * @param {{
 *  context?: import('utilities/api/katana').KATANA_API.katana.service_id.uploads.POST.Params['formData']['context']
 * }} options
 * @returns {Promise<Array<Artah.Katana.ID.Uploads.POST._200['data']>>} - A Promise that resolves with an array of results from each file upload.
 */
export async function iterativelyUploadFiles(files, asyncFileUploadMutation, options = {}) {
    const isFileArray = Array.isArray(files);
    const file = isFileArray ? files.pop() : files;

    const fileFormData = new FormData();

    async function getFileUploadResult() {
        if (fileReferenceTrackerRecord.files.includes(file)) {
            const foundFile = fileReferenceTrackerRecord.tracker.find((fileReference) => fileReference.file === file);
            return foundFile.uploadResult;
        }
        fileFormData.append('file', file);
        fileFormData.append('name', file.name);
        if (options.context) {
            fileFormData.append('context', options.context);
        }

        const uploadResult = getDataFromData(await asyncFileUploadMutation(fileFormData));

        fileReferenceTrackerRecord.files.push(file);
        fileReferenceTrackerRecord.tracker.push({
            file,
            uploadResult
        });

        return uploadResult;
    }

    const fileUploadResult = await getFileUploadResult();

    /**
     * If it's not an array, just return the result
     */
    if (!isFileArray) {
        return fileUploadResult;
    }

    /**
     * If it's an array and there are no more files, return the result
     */
    if (!files.length) {
        return [fileUploadResult];
    }

    /**
     * If it's an array and there are more files, recursively call this function again
     */
    const nextFileUploadResult = await iterativelyUploadFiles(files, asyncFileUploadMutation, options);

    return [fileUploadResult, ...nextFileUploadResult];
}

/**
 * Loops over the entries in the files object and and runs the iterativelyUploadFiles function on each entry.
 * @param {Record<string, Array<File> | File> | {}} files - An object of key value pairs where the key is the name of the file and the value is an array of File objects.
 * @param {Function} mutateUploadFile - An async function that takes a FormData object and returns a Promise.
 * @param {{
 *  context?: import('utilities/api/katana').KATANA_API.katana.service_id.uploads.POST.Params['formData']['context']
 * }} options
 */
export async function processValueFileObject(files, mutateUploadFile, options = {}) {
    if (['string', 'number'].includes(typeof files)) {
        return files;
    }

    const fileUrls = {};

    async function handleImageFileUpload(object, key, value) {
        if (value instanceof File) {
            object[key] = await iterativelyUploadFiles(value, mutateUploadFile, options);
            return true;
        }

        return false;
    }

    for (const [key, value] of Object.entries(files)) {
        if (await handleImageFileUpload(fileUrls, key, value)) {
            continue;
        }

        if (Array.isArray(value)) {
            fileUrls[key] = [];
            for (let i = 0; i < value.length; i++) {
                const _value = value[i];

                const isFile = await handleImageFileUpload(fileUrls[key], i, value);

                if (!isFile) {
                    fileUrls[key][i] = await processValueFileObject(_value, mutateUploadFile, options);
                }
            }
        }
    }

    if (!Object.keys(fileUrls).length) {
        return files;
    }

    return fileUrls;
}

/**
 * This function takes an array of KatanaProperty objects and returns an object with the default values for each property.
 * It's recursive, so it will also return the default values for any nested properties.
 * The recursive values are returned with dot notation where each nested property is separated by a dot i.e. another object
 * @param {import('containers/katana/types').KatanaNamespace.SectionDefinitions.PropertiesModified[]} properties
 * @returns {object}
 */
export function getDefaultValuesFromProperties(properties = []) {
    const defaultValues = {};
    properties.forEach((property) => {
        const { key, defaultValue, type } = property;

        if (type === SECTION_DEFINITION_PROPERTY_TYPES.REPEATED) {
            return;
        }

        if ('properties' in property) {
            const propertiesResult = getDefaultValuesFromProperties(property.properties);
            // We need to clone the propertiesResult so that we're not bleeding definition data between properties
            defaultValues[key] = _.clone(propertiesResult);
            return;
        }

        // We need to clone the defaultValue so that we're not bleeding definition data between properties
        defaultValues[key] = _.clone(defaultValue);
    });

    return defaultValues;
}
