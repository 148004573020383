export type PrimitiveKey<T extends string = string> = `P_${T}`;
export type SemanticKey<T extends string = string> = `S_${T}`;
export type ComponentKey<T extends string = string> = `C_${T}`;

export type DefaultPrimitiveTokens = {
    'P_color_primary': string;
    'P_color_primary_hover': string;
    'P_color_secondary': string;
    'P_color_secondary_hover': string;
    'P_color_tertiary': string;
    'P_color_tertiary_hover': string;
    'P_color_confirm': string;
    'P_color_confirm_hover': string;
    'P_color_info': string;
    'P_color_info_hover': string;
    'P_color_notice': string;
    'P_color_notice_hover': string;
    'P_color_warn': string;
    'P_color_warn_hover': string;
    'P_color_white': string;
    'P_color_black': string;
    'P_color_grey': string;

    'P_border-radius_default': string;
};

type SemanticValue = keyof DefaultPrimitiveTokens;
export type DefaultSemanticTokens = {
    'S_color_background_primary_base': SemanticValue;
    'S_color_background_primary_hover': SemanticValue;
    'S_color_background_secondary_base': SemanticValue;
    'S_color_background_secondary_hover': SemanticValue;
    'S_color_background_confirm_base': SemanticValue;
    'S_color_background_confirm_hover': SemanticValue;
    'S_color_background_info_base': SemanticValue;
    'S_color_background_info_hover': SemanticValue;
    'S_color_background_notice_base': SemanticValue;
    'S_color_background_notice_hover': SemanticValue;
    'S_color_background_warn_base': SemanticValue;
    'S_color_background_warn_hover': SemanticValue;
    'S_color_background_default_base': SemanticValue;
    'S_color_background_inverse_base': SemanticValue;
    'S_color_background_grey_base': SemanticValue;

    'S_color_text_primary_base': SemanticValue;
    'S_color_text_primary_hover': SemanticValue;
    'S_color_text_secondary_base': SemanticValue;
    'S_color_text_secondary_hover': SemanticValue;
    'S_color_text_confirm_base': SemanticValue;
    'S_color_text_confirm_hover': SemanticValue;
    'S_color_text_info_base': SemanticValue;
    'S_color_text_info_hover': SemanticValue;
    'S_color_text_notice_base': SemanticValue;
    'S_color_text_notice_hover': SemanticValue;
    'S_color_text_warn_base': SemanticValue;
    'S_color_text_warn_hover': SemanticValue;
    'S_color_text_default_base': SemanticValue;
    'S_color_text_inverse_base': SemanticValue;
    'S_color_text_grey_base': SemanticValue;

    'S_color_icon_primary_base': SemanticValue;
    'S_color_icon_secondary_base': SemanticValue;
    'S_color_icon_confirm_base': SemanticValue;
    'S_color_icon_info_base': SemanticValue;
    'S_color_icon_notice_base': SemanticValue;
    'S_color_icon_warn_base': SemanticValue;
    'S_color_icon_default_base': SemanticValue;
    'S_color_icon_inverse_base': SemanticValue;
    'S_color_icon_grey_base': SemanticValue;

    'S_color_border_primary_base': SemanticValue;
    'S_color_border_secondary_base': SemanticValue;
    'S_color_border_confirm_base': SemanticValue;
    'S_color_border_info_base': SemanticValue;
    'S_color_border_notice_base': SemanticValue;
    'S_color_border_warn_base': SemanticValue;
    'S_color_border_default_base': SemanticValue;
    'S_color_border_grey_base': SemanticValue;

    'S_border-radius_default': SemanticValue;
};

/**********************************************************************************************************
 *   VENTRA TOKEN TYPES
 **********************************************************************************************************/
/**
 * - Ventra primitive token set must include all the default primitive tokens at a minimum, and any other optional token starting with `P_`.
 */
export interface VentraPrimitiveTokens extends DefaultPrimitiveTokens {
    [key: PrimitiveKey]: string;
}

/**
 * - Ventra semantic token sets must include all the default semantic tokens at a minimum, and any other optional token starting with `S_`.
 * - All token values must be one of the Ventra primitive token keys
 */
export type VentraSemanticTokens<TVentraPrimitiveTokens> = Record<keyof DefaultSemanticTokens, keyof TVentraPrimitiveTokens> & {
    [key: SemanticKey]: keyof TVentraPrimitiveTokens;
};

/**
 * - Ventra component token sets must be an object with string keys, where each value is a nested object with any key starting with `C_`.
 * - All nested values must be one of the Ventra primitive or semantic token keys
 */
export type VentraComponentTokens<TVentraPrimitiveTokens, TVentraSemanticTokens> = {
    [outerKey: string]: {
        [innerKey: ComponentKey]: keyof TVentraPrimitiveTokens | keyof TVentraSemanticTokens;
    };
};

/**********************************************************************************************************
 *   ALTERNATE BRAND TOKEN TYPES
 **********************************************************************************************************/
/**
 * - Alternate brand primitive token sets must include all the default primitive tokens at a minimum, and any other optional token starting with `P_`.
 */
export interface AlternateBrandPrimitiveTokens extends DefaultPrimitiveTokens {
    [key: PrimitiveKey]: string;
}

/**
 * - Alternate brand semantic token sets must include all the Ventra semantic token keys, and no more
 * - All token values must be one of the primitive token keys for that brand
 */
export type AlternateBrandSemanticTokens<
    TVentraSemanticTokens extends Record<keyof DefaultSemanticTokens, any>,
    TAlternateBrandPrimitiveTokens
> = Record<keyof TVentraSemanticTokens, keyof TAlternateBrandPrimitiveTokens>;

/**
 * - Alternate brand component token sets must be an object with all the keys of the Ventra component token set and no more, where each value is a nested object with all the keys of that same nested object in the Ventra component token set.
 * - All nested values must be one of the primitive or semantic token keys for that brand
 */
export type AlternateBrandComponentTokens<TVentraComponentTokens, TAlternateBrandPrimitiveTokens, TAlternateBrandSemanticTokens> = {
    [K in keyof TVentraComponentTokens]: Record<
        keyof TVentraComponentTokens[K],
        keyof TAlternateBrandPrimitiveTokens | keyof TAlternateBrandSemanticTokens
    >;
};

/**********************************************************************************************************
 *   BRAND TOKEN UTILITY START
 **********************************************************************************************************/
type BrandIdentifiers = "INT" | "VIP"
type BrandSpecificComponentToken<TBrand extends BrandIdentifiers, TKey extends string> = `${TBrand}_${TKey}__${string}`;
/**
 * - Alternate brand token sets must be an object with all the keys of the Ventra component token set and no more, where each value is a nested object with all the keys of that same nested object in the Ventra component token set.
 * - All nested values must be one of the primitive values for that brand
 */
export type AlternateBrandBrandToken<TBrand extends BrandIdentifiers, TKeys extends string> = {
    [K in TKeys]: {
        [P in BrandSpecificComponentToken<TBrand, K>]: string
    }
}

type CreateBrandTokens = <
    TBrand extends BrandIdentifiers,
    TKeys extends string
>(brand: TBrand, tokens: AlternateBrandBrandToken<TBrand, TKeys>) => AlternateBrandBrandToken<TBrand, TKeys>;

/**
 * Utility function for creating brand tokens. This will enforce the desired naming conventions. Note that this utility
 * is required so that it can infer the available keys based on the object - otherwise typescript cannot narrow
 * the possible keys from `string` and will give you no intellisense if using the type directly.
 */
export const createBrandTokens: CreateBrandTokens = (_, tokens) => tokens;
