import {
    REWARDS_SIGNUP_ERROR,
    REWARDS_SIGNUP_REQUEST,
    REWARDS_SIGNUP_SUCCESS,
    REWARDS_TIERS_LIST_ERROR,
    REWARDS_TIERS_LIST_REQUEST,
    REWARDS_TIERS_LIST_SUCCESS
} from './actionTypes';

function vipRewardsReducer(state = {}, action) {
    switch (action.type) {
        /**********************************************************************************************************
         *   TIERS LIST
         **********************************************************************************************************/
        case REWARDS_TIERS_LIST_REQUEST:
            return {
                ...state,
                rewards_tiers_list_status: 'loading'
            };
        case REWARDS_TIERS_LIST_SUCCESS:
            return {
                ...state,
                rewards_tiers_list_status: 'success',
                rewards_tiers_list_data: action.rewards_tiers_list_data
            };
        case REWARDS_TIERS_LIST_ERROR:
            return {
                ...state,
                rewards_tiers_list_status: 'error'
            };
        /**********************************************************************************************************
         *   SIGNUP
         **********************************************************************************************************/
        case REWARDS_SIGNUP_REQUEST:
            return {
                ...state,
                rewards_signup_status: 'loading'
            };

        case REWARDS_SIGNUP_SUCCESS:
            return {
                ...state,
                rewards_signup_status: 'success'
            };

        case REWARDS_SIGNUP_ERROR:
            return {
                ...state,
                rewards_signup_status: 'error'
            };

        default:
            return state;
    }
}

export default vipRewardsReducer;
