import { queryOptions, useQuery } from '@tanstack/react-query';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import type { KatanaNamespace } from 'containers/katana/types';
import { queryClient } from 'store/queryClient';
import { KATANA_API } from 'utilities/api/katana';
import { MINUTE } from 'utilities/consts';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/

function createQueryKey(serviceID: KatanaNamespace.ServiceId, socialID?: number) {
    return katanaQueryKeys.katana.service.ID.user.socials.ID({ serviceID, socialID });
}

function createQueryOptions(serviceID: KatanaNamespace.ServiceId, socialID: number) {
    return queryOptions({
        queryKey: createQueryKey(serviceID, socialID),
        queryFn: () => KATANA_API.katana.service_id.user.socials.$socialID.GET(serviceID, socialID),
        staleTime: MINUTE * 2.5,
        enabled: Boolean(serviceID && socialID),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        },
    });
}

function invalidateQueries(serviceID: KatanaNamespace.ServiceId, socialID?: number) {
    return queryClient.invalidateQueries({ queryKey: createQueryKey(serviceID, socialID) });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useQuery(serviceID: KatanaNamespace.ServiceId, socialID: number) {
    // This causes a type issue if you don't pass options
    return useQuery(createQueryOptions(serviceID, socialID));
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const getSocialAccount = Object.freeze({
    useQuery: _useQuery,
    invalidateQueries,
});
