import classNames from 'classnames';
import React from 'react';
import './_HandleButton.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    children: React.ReactNode;
    fullHeight?: boolean;
    color?: 'primary' | 'warn';
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    intrinsicProps?: Omit<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, 'onClick'>;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _HandleButton: React.FC<Props> = ({ fullHeight, children, color = 'primary', onClick, intrinsicProps }) => (
    <button
        type="button"
        className={classNames('HandleButton HoverBlocker', {
            'HandleButton--fullHeight': fullHeight,
            [`HandleButton--color-${color}`]: color
        })}
        onClick={onClick}
        {...intrinsicProps}
    >
        {children}
    </button>
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const HandleButton = Object.assign(_HandleButton, {
    width: 31
});
export { HandleButton };
