import { useMutation } from '@tanstack/react-query';
import { newPageTrackerData } from 'containers/katana/components/pageOrganiser/newPageTracker';
import { createKatanaPageID } from 'containers/katana/methods';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import { service } from 'containers/katana/queries/serviceID/service';
import type { KatanaNamespace } from 'containers/katana/types';
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions/handleDefaultErrorNotification';
import { handleDefaultSuccessNotification } from 'utilities/methods/commonActions/handleDefaultSuccessNotification';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Params = Parameters<typeof KATANA_API.katana.site.service_id.pages.POST>[0];

function createMutationKey(serviceID: KatanaNamespace.ServiceId) {
    return katanaQueryKeys.katana.service.ID.pages.create(serviceID);
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation(serviceID: KatanaNamespace.ServiceId) {
    function handleMutation(attributes: Params['attributes']) {
        return KATANA_API.katana.site.service_id.pages.POST({ serviceID, attributes });
    }

    return useMutation({
        mutationKey: createMutationKey(serviceID),
        mutationFn: handleMutation,
        onSuccess: (e) => {
            if (e.status === 200) {
                newPageTrackerData.addNewID(createKatanaPageID(e.data.id));
            }
            handleDefaultSuccessNotification(e);
        },
        onError: handleDefaultErrorNotification,
        onSettled: () => {
            service.invalidateQueries(serviceID);
        },
    });
}

export const createPage = Object.freeze({
    useMutation: _useMutation,
});
