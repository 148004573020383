import { _TableHeader } from './_TableHeader';
import { SortableTitle } from './sortableTitle';
import Title from './Title';

/***** EXPORTS *****/
const _Header = Object.assign(_TableHeader, {
    Title,
    SortableTitle
});

export default _Header;
