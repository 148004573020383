import { useDynamicFormActions } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useDynamicFormActions';
import { useGetDynamicFormFieldValue } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useGetDynamicFormFieldValue';
import { useTargetKeyToPropertyPath } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useTargetKeyToPropertyPath';
import { useAccordionTitleValueTargetCaveat } from 'containers/katana/formFields/caveats/useAccordionTitleValueTargetCaveat';
import { useRemoveEntryAndReassignFirstCaveat } from 'containers/katana/formFields/caveats/useRemoveEntryAndReassignFirstCaveat';
import { useSingularNameCaveat } from 'containers/katana/formFields/caveats/useSingularNameCaveat';
import type { OptionsIconNamespace } from 'containers/katana/formFields/repeated/OptionsIcon/types';
import { SORTABLE_ID_KEY } from 'containers/katana/formFields/repeated/SortableRepeatedWrapper/consts';
import type { KatanaNamespace } from 'containers/katana/types';
import _ from 'lodash';
import type { FieldArrayFieldsProps } from 'redux-form';
import { deepOmit } from 'utilities/methods/recursion/deepOmit/deepOmit';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    member: string;
    index: number;
    fields: FieldArrayFieldsProps<unknown>;
    property: KatanaNamespace.SectionDefinitions.PropertiesModified;
};

function hasName(nameChecking: string, names: string[], copyIndex: number) {
    for (const name of names) {
        if (name === `${nameChecking} Copy ${copyIndex}`) {
            return true;
        }
    }
    return false;
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export const useActionHandler = ({ index, fields, property, member }: Props) => {
    /***** HOOKS *****/
    const { dynamicChange } = useDynamicFormActions();
    const onRemoveServiceTargetKeyCopy = useRemoveEntryAndReassignFirstCaveat(property);

    const targetPropertyAccessor = useTargetKeyToPropertyPath(property?.key, onRemoveServiceTargetKeyCopy);
    const targetPropertyValue = useGetDynamicFormFieldValue(targetPropertyAccessor);
    const singularName = useSingularNameCaveat(property);
    const accordionTitleValueTargetKey = useAccordionTitleValueTargetCaveat(property);

    // function handleUpdateUnsplashDownloadLinks() {
    //     const memberNodeQuantity = member.split('.').length;
    //     const memberLastNode = member.split('.')[memberNodeQuantity - 1];
    //     const keyOfParentField = member.replace(`.${memberLastNode}`, '');
    //     console.log('memberNodeQuantity, memberLastNode,keyOfParentField', memberNodeQuantity, memberLastNode, keyOfParentField);
    //     const downloadLinks = Object.entries(sectionDefinitionUnsplashDownloadLinks.state);
    //     const filteredLinks = downloadLinks.filter(([key, value]) => {
    //         return key.includes(keyOfParentField);
    //     });

    //     const existingLinks = downloadLinks.filter(([key, value]) => {
    //         return !key.includes(keyOfParentField);
    //     });

    //     if (filteredLinks.length > 0) {
    //         const updatedLinkOrder = filteredLinks.map(([key, value]) => {
    //             const indexKey = key.replace(keyOfParentField, '');
    //             const indexDigit = indexKey.match(/[\d]/);
    //             if (indexDigit) {
    //                 const indexDigitMatch = Number(indexDigit[0]);
    //                 if (indexDigitMatch > index) {
    //                     const updatedLastNode = memberLastNode.replace(/[\d]/, indexDigitMatch - 1);
    //                     const updatedKey = `${keyOfParentField}.${updatedLastNode}.image`;
    //                     return [updatedKey, value] as const;
    //                 } else if (indexDigitMatch < index) {
    //                     return [key, value] as const;
    //                 } else {
    //                     return [];
    //                 }
    //             } else {
    //                 return [];
    //             }
    //         });
    //         const updatedLinkOrderWithoutEmptyArray = updatedLinkOrder.filter((element) => element.length !== 0);
    //         const mergedLinks = [...existingLinks, ...updatedLinkOrderWithoutEmptyArray];
    //         sectionDefinitionUnsplashDownloadLinks.setState(() => Object.fromEntries(mergedLinks));
    //     }
    // }

    /***** FUNCTIONS *****/
    // TODO: Need to implement a Hook Form version of this, not sure how this will need to be done until we convert the form to hook form
    function removeService() {
        if (!onRemoveServiceTargetKeyCopy) {
            fields.remove(index);
            return;
        }

        const fieldValue = fields.get(index);

        if (!_.isPlainObject(fieldValue)) {
            console.error(`fieldValue is not a plain object, therefore we can't check for the targetPropertyValue: "${targetPropertyValue}"`);
            fields.remove(index);
            return;
        }
        const valuesFromRemovingField = _.entries(fieldValue as object);

        const entryInFields = valuesFromRemovingField.find(([key, value]) => {
            return _.isEqual(value, targetPropertyValue);
        });

        if (!entryInFields) {
            console.error(`targetPropertyValue: "${targetPropertyValue}" cannot be found in fieldValue: "${fieldValue}"`);
            fields.remove(index);
            return;
        }

        const newEntryIndex = index === 0 ? 1 : 0;
        const firstFieldEntry = fields.get(newEntryIndex) as Record<string, unknown> | null | string | Array<any>;

        if (firstFieldEntry && typeof firstFieldEntry === 'object' && !Array.isArray(firstFieldEntry)) {
            const [entryInFieldKey] = entryInFields;
            const firstFieldKeyValue = firstFieldEntry[entryInFieldKey];

            dynamicChange(targetPropertyAccessor, firstFieldKeyValue);
        }

        fields.remove(index);
    }

    function handleUpdatedAccordionTitle(values: object | unknown) {
        if (!accordionTitleValueTargetKey) return values;

        const fieldValues = fields.getAll();
        const names = [];

        for (const fieldValue of fieldValues) {
            names.push(fieldValue[accordionTitleValueTargetKey]);
        }

        const currentTitleValue = _.get(values, accordionTitleValueTargetKey) as string | undefined;

        if (!currentTitleValue) {
            _.set(values as object, accordionTitleValueTargetKey, singularName);
        } else if (currentTitleValue.match(/\sCopy\s\d+$/)) {
            const copyWithDigit = currentTitleValue.match(/\sCopy\s\d+$/)?.[0];
            const currentTitleValueWithoutCopy = currentTitleValue.split(copyWithDigit)[0];
            for (let i = 1; i <= names.length + 1; i++) {
                if (!hasName(currentTitleValueWithoutCopy, names, i)) {
                    _.set(values as object, accordionTitleValueTargetKey, `${currentTitleValueWithoutCopy} Copy ${i}`);
                    break;
                }
            }
        } else {
            // it will cover the cases for singularName + digit, like 'Testimonial 1', and the cases for singularName, like 'Testimonial'
            for (let i = 1; i <= names.length; i++) {
                if (!hasName(currentTitleValue, names, i)) {
                    _.set(values as object, accordionTitleValueTargetKey, `${currentTitleValue} Copy ${i}`);
                    break;
                }
            }
        }

        return values;
    }

    function handleOmitSortable(values: object | unknown) {
        if (_.isPlainObject(values)) {
            return deepOmit(values as object, [SORTABLE_ID_KEY]);
        }

        return values;
    }

    function getDuplicateValue() {
        const fieldValues = _.cloneDeep(fields.get(index)) as object | unknown;
        const fieldValuesUpdateFlow = _.flow([handleUpdatedAccordionTitle, handleOmitSortable]);
        return fieldValuesUpdateFlow(fieldValues);
    }

    function duplicateEntry() {
        const duplicateValue = getDuplicateValue();
        fields.splice(index + 1, 0, duplicateValue);
    }

    function handleAction(action: OptionsIconNamespace.Action) {
        switch (action) {
            case 'delete':
                removeService();
                break;
            case 'duplicate':
                duplicateEntry();
                break;
            default:
                break;
        }
    }

    /***** HOOK RESULTS *****/
    return handleAction;
};
