/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';
import isURL from 'validator/lib/isURL';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

export function formFieldUrlRuleParser(validation: KatanaNamespace.SectionDefinitions.ValidationsWithTypedNames) {
    const protocols = _.isArray(validation.value) ? validation.value : ['http', 'https', 'ftp'];
    return (value: string) => {
        if (typeof value !== 'string') {
            return 'Invalid URL';
        }
        const isUrlValid = isURL(value, { require_protocol: true, ...(protocols?.length ? { protocols } : {}) });
        if (isUrlValid) {
            return undefined;
        }
        const hasProtocol = protocols.some((protocol) => value.startsWith(`${protocol}://`));
        if (!hasProtocol) {
            const updatedProtocols = protocols.map((protocol) => `${protocol}://`);
            const lastProtocol = updatedProtocols.pop();

            const protocolMessage = updatedProtocols.length > 1 ? `${updatedProtocols.join(', ')} or ${lastProtocol}` : lastProtocol;

            return `Invalid URL, must include ${protocolMessage}`;
        }
        return 'Invalid URL';
    };
}
