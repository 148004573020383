/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { SolidButton } from 'components/Buttons/SolidButton';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { RewardCard } from 'containers/vipRewards/modules/rewardCard/new';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import helloFreshImage from 'assets/images/promo/helloFreshNZ/helloFreshNZ.jpg';
import '../_rewardCard.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const HelloFreshNZOfferCard = () => (
    /***** RENDER *****/
    <RewardCard>
        <RewardCard.Image tag="SPECIAL OFFER">
            <div className="rewardCard__bg rewardCard__bg--overflowHidden">
                <img src={helloFreshImage} alt="Hello fresh box and vegetables sitting on a table" width={278} />
            </div>
        </RewardCard.Image>
        <RewardCard.Body
            title="Get up to $200 OFF"
            description="Flexible, no commitments - pause or skip when you need to. Offer available for new and past HelloFresh customers. T&Cs apply."
        >
            <SolidButton
                color="primary"
                type="anchor"
                target="_blank"
                href="https://analytics.nexigen.digital/redirect?mtm_source=ccp&mtm_campaign=hello-fresh-nz&mtm_medium=viprewards&siteId=8&redirect=https://www.hellofresh.co.nz/pages/simplydelicious?c=VENTRAIP200"
            >
                Claim Now
            </SolidButton>
        </RewardCard.Body>
    </RewardCard>
);
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
