/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _NXDropZoneReduxFormDefault as NXDropZoneReduxFormDefault } from 'components/Form/NXDropZone/wrappers/reduxForm/Default';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_NXDropZoneWithNamedAttachments.scss';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { NXDropZoneNamespace } from 'components/Form/NXDropZone/types';

/**********************************************************************************************************
 *  COMPONENT START
 **********************************************************************************************************/
export const _NXDropZoneReduxFormWithNamedAttachments: React.FC<NXDropZoneNamespace.ReduxForm.Default.Props> = ({ className, ...props }) => {
    /***** RENDER HELPERS *****/
    const renderer = useCallback(({ file, removeFile }: { file: File | File[]; removeFile: (index?: number) => void }) => {
        return (
            <Padding y={2}>
                <Text uppercase size--s>
                    attachments
                </Text>
                {Array.isArray(file) &&
                    file.map((item, index) => (
                        <Text medium secondary size--s key={index}>
                            {item.name} <Text italic> ({Math.ceil(item.size / 1000)}kb)</Text>{' '}
                            <Anchor onClick={() => removeFile(index)} color="warn" className="NXDropZoneWithNamedAttachments__remove">
                                <Text span size--xs medium className="icon icon-blog-close">
                                    {' '}
                                </Text>
                            </Anchor>
                        </Text>
                    ))}
            </Padding>
        );
    }, []);

    const message = useMemo(() => {
        return (
            <Text size--s info secondary>
                Click here to attach a file. <Text secondary>Or drag and drop it into this box</Text>
            </Text>
        );
    }, []);

    /***** RENDER *****/
    return (
        <NXDropZoneReduxFormDefault
            className={classNames('NXDropZoneWithNamedAttachments', className)}
            message={message}
            renderer={renderer}
            {...props}
        />
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
