import { useMutation } from '@tanstack/react-query';
import { service } from 'containers/katana/queries/serviceID/service';
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions/handleDefaultErrorNotification';
import { handleDefaultSuccessNotification } from 'utilities/methods/commonActions/handleDefaultSuccessNotification';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Params = Parameters<typeof KATANA_API.katana.site.service_id.pages.page_id.sections.POST>[0];
type MainParams = Omit<Params, 'attributes'>;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation(params: MainParams) {
    function handleMutation(attributes: Params['attributes']) {
        return KATANA_API.katana.site.service_id.pages.page_id.sections.POST({ ...params, attributes });
    }

    return useMutation({
        mutationFn: handleMutation,
        onSuccess: handleDefaultSuccessNotification,
        onError: handleDefaultErrorNotification,

        onSettled: () => {
            service.invalidateQueries(params.serviceID);
        }
    });
}

export const createPageSection = Object.freeze({
    useMutation: _useMutation
});
