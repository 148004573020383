import classNames from 'classnames';
import Title from 'components/NXTable/Header/Title';
import 'components/NXTable/Header/_NXTableHeader.scss';
import { useNXTableContext } from 'components/NXTable/NXTableContext';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Heading bar for the table
 */
export const _TableHeader: NXTable.Header.THeader = ({ children, className, gap = 2, columns, headings }) => {
    /***** HOOKS *****/
    const nxTableContext = useNXTableContext();
    const finalColumns = nxTableContext?.columns || columns;

    /***** RENDER HELPERS *****/
    const headerClassNames = classNames('NXTableHeader', 'NXTableHeader__alignItems--left', className);

    const rowStyle = {
        gridTemplateColumns: finalColumns,
        gap: Number(gap) >= 0 ? `calc(5px * ${gap})` : ''
    };

    /***** RENDER *****/
    return (
        <div className={headerClassNames} style={rowStyle}>
            {headings ? headings.map(([key, title]) => <Title key={key}>{title}</Title>) : children}
        </div>
    );
};
