/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useContext } from 'react';
import { useTanstackStore } from 'utilities/hooks/tanstack-store/useTanstackStore';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { SolidButton } from 'components/Buttons/SolidButton';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { PictureInPictureContext } from 'components/PictureInPicture/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @param {{
 *      text?:string,
 * }} props
 */
function _PictureInPictureShowButton({ text = 'Show' }) {
    /***** HOOKS *****/
    const { stateStoreData } = useContext(PictureInPictureContext);
    const [pictureState, setPictureState] = useTanstackStore(stateStoreData.store);

    /***** RENDER *****/
    return (
        <Padding x={2} inject>
            <SolidButton
                width="auto"
                color="secondary"
                type="onClick"
                className="PictureInPictureShowButton"
                onClick={() => {
                    if (pictureState === 'minimised') {
                        // Commit to storage
                        stateStoreData.commit?.('visible');
                    }

                    setPictureState('visible');
                }}
            >
                <PhosphorIcons.ArrowLineLeft />
                &nbsp;&nbsp;
                <Text size--xss semiBold lead--1>
                    {text}
                </Text>
            </SolidButton>
        </Padding>
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { _PictureInPictureShowButton };
