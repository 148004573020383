import { useMutation } from '@tanstack/react-query';
import { useNXQuery } from 'components/NXQueryProvider';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import type { KatanaNamespace } from 'containers/katana/types';
import { KATANA_API } from 'utilities/api/katana';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useRegisterSiteMutation(serviceID: KatanaNamespace.ServiceId) {
    /***** HOOKS *****/
    const NXQuery = useNXQuery();

    /***** FUNCTIONS *****/
    function mutationFn(attributes: Partial<KATANA_API.katana.service_id.register.POST.Attributes>) {
        return KATANA_API.katana.service_id.register.POST({ serviceID, attributes });
    }

    /***** HOOK RESULTS *****/
    return useMutation({
        mutationFn,
        onSuccess: () => {
            katanaQuery.serviceID.service.invalidateQueries(serviceID);

            NXQuery.auth.login.checkToken.invalidate();
            NXQuery.auth.login.checkToken.optimistic(void 0, 'data.attributes.is_user_detail_completed', true);
        }
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
