/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { checkIsSelectHosting } from 'config/containers/promotions/methods';
import { resetHostingState } from 'containers/hosting/state/accountActions';
import store from 'store/store';
import { activePromotion, getIncludedObjBasedOnType } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   SERVICES
 **********************************************************************************************************/
export const servicesMethods = {
    table: {
        /**
         * Returns the searchData if a keyword is present
         * else returns the listData if it exists
         * else returns false
         *
         * @template {Array<any>} T
         * @template {Array<any>} G
         * @param {string} keyword
         * @param {T} listData
         * @param {G} searchData
         */
        handleMatrixCondition(keyword, listData, searchData) {
            if (keyword) return searchData?.length > 0 ? searchData : false;
            if (listData?.length > 0) return listData;
            return false;
        },

        handleLoading(listStatus, searchStatus) {
            if (listStatus === 'loading' || searchStatus === 'loading') return 'loading';
            if (listStatus === 'error' || searchStatus === 'error') return 'error';
            return 'success';
        },

        /**
         * Handles Promo Actions button, checks if the specific service has any promo buttons that need to be added
         * @param {Object} serviceData: - Single service data object
         * @param {Object[]|ReactNode[]} actions - Default actions to be displayed
         * @param {'domains'|'webHosting'|'vps'} category - The category this is being called under
         * @param {Object} history - History object from the react router
         * @param {boolean} isExpressServiceManager - If the user is in the express service manager
         * @param {string} [rewardTier] - The reward tier of the user
         *
         * @returns {Object[]|import("react").ReactNode[]}
         */
        handlePromoActionsButtons({ serviceData, actions, category, history, isExpressServiceManager, rewardTier }) {
            // We don't want to show the promo buttons in the express service manager
            if (isExpressServiceManager) return actions;

            const beforeActions = [];
            const afterActions = [];

            // If it's not a promo period OR the user is not a vipRewards member just return the normal actions
            if (!activePromotion('cyberMondayVipRewards2024') || !rewardTier) {
                return actions;
            }

            /**
             * START INSERT PROMO BUTTONS - push them to "beforeActions" or "afterActions"
             */

            /***** CYBER MONDAY 2024 START *****/
            // Hosting buttons
            if (serviceData.type === 'web-hosting') {
                const invoice = getIncludedObjBasedOnType(serviceData?.included, 'invoice');

                if (!invoice) {
                    const renewButton = {
                        label: 'Renew',
                        className: 'cyberMondayRenewButton',
                        size: 'medium',
                        type: 'onClick',
                        onClick: () => {
                            resetHostingState(history, `/my-services/hosting/${serviceData.id}/account?open=true&type=renew#renew`)(store.dispatch);
                        }
                    };

                    if (checkIsSelectHosting(serviceData)) {
                        beforeActions.push(renewButton);
                    }
                }
            }
            /***** CYBER MONDAY 2024 END *****/

            /**
             * END INSERT PROMO BUTTONS
             */

            const newButtons = [...beforeActions, ...actions, ...afterActions];

            for (let i = 0; i < newButtons.length; i++) {
                // Give all buttons an extra className of "PromotionButton" so that we can make them smaller than usual if required during promo periods
                const promoClassName = 'PromotionButton';

                if (newButtons[i].className) newButtons[i].className = `${newButtons[i].className} ${promoClassName}`;
                else newButtons[i].className = promoClassName;
            }

            return newButtons;
        },

        getSharedConditionalProps({
            title,
            paginationStatus,
            search: { data, placeholder, status, cancel, search, reset, keyword },
            setShow,
            isLastPage,
            matrix
        }) {
            return {
                title: title,
                search: {
                    slim: true,
                    render: {
                        status: status,
                        placeholder: placeholder
                    },
                    functions: {
                        cancel: cancel,
                        search: search,
                        reset: reset
                    },
                    helpers: {
                        keyword: keyword
                    }
                },
                headingActions: [
                    {
                        type: `select`,
                        label: 'Show',
                        className: `headingActions__show`,
                        loading: paginationStatus === 'loading',
                        options: [
                            {
                                label: 'Show 10',
                                onClick: () => {
                                    setShow(10);
                                }
                            },
                            {
                                label: 'Show 20',
                                onClick: () => {
                                    setShow(20);
                                }
                            },
                            {
                                label: 'Show 50',
                                onClick: () => {
                                    setShow(50);
                                }
                            },
                            {
                                label: 'Show All',
                                onClick: () => {
                                    setShow(999);
                                }
                            }
                        ],
                        onClick: () => {
                            setShow(20);
                        }
                    }
                ],
                showAll: {
                    status: paginationStatus,
                    conditions: !isLastPage() && !data && matrix && matrix.length > 0,
                    onClick: (e) => {
                        e.preventDefault();
                        setShow(999);
                    }
                }
            };
        }
    },

    upgrade_plan_paths: {
        canUpgrade: (planUpgradeData) => {
            return planUpgradeData?.length && planUpgradeData.some((plan) => plan?.attributes?.path_type === 'upgrade');
        }
    }
};
