import { Tooltip } from '@mui/material';
import { SolidButton } from 'components/Buttons/SolidButton';
import Grid from 'components/Grid';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import type { NavigationLabelsNamespace } from 'containers/katana/containers/contentEditorModules/navigationLabels/types';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { SectionOrganiserChildBaseBar } from 'containers/katana/modules/presetContent/components/sectionOrganiser/sectionOrganiserChild/baseBar';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import type { KatanaNamespace } from 'containers/katana/types';
import React, { useState } from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    section: KatanaNamespace.Section;
    onAdd: (page: NavigationLabelsNamespace.SortableEntry) => void;
    navigationItems: NavigationLabelsNamespace.SortableEntry[];
};
/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const NavigationItemsSection: React.FC<Props> = ({ section, onAdd, navigationItems }) => {
    /***** STATE *****/
    const [canShowTooltip, setCanShowTooltip] = useState(true);

    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();

    /***** QUERIES *****/
    const { data: section_definitions_data } = katanaQuery.serviceID.meta.sectionDefinitions.useQuery(katanaServiceId);

    /***** RENDER HELPERS *****/
    const isDisabled = navigationItems.some((item) => item.id === section.id);
    const tooltipTitle = isDisabled && canShowTooltip ? 'This section is already included in your navigation' : '';

    /***** RENDER *****/
    return (
        <Grid columns="auto 1fr auto" alignItems--center inject>
            <SectionOrganiserChildBaseBar className="NavigationItemsPage">
                <Padding left={1} right={2}>
                    <PhosphorIcons.Section size={25} />
                </Padding>

                <Text bold black>
                    {section.name || section_definitions_data?.[section.section_id]?.name}
                </Text>

                <Tooltip title={tooltipTitle} placement="top" arrow disableInteractive>
                    <div
                        onMouseLeave={() => setCanShowTooltip(true)}
                        onMouseEnter={() => {
                            if (!isDisabled) {
                                setCanShowTooltip(false);
                            }
                        }}
                    >
                        <SolidButton
                            type="onClick"
                            disabled={isDisabled}
                            onClick={() => {
                                onAdd(section);
                            }}
                            size="small"
                        >
                            <Padding left={2} right={3}>
                                Add to menu
                            </Padding>
                        </SolidButton>
                    </div>
                </Tooltip>
            </SectionOrganiserChildBaseBar>
        </Grid>
    );
};
