import { useSiteDataPreviewValues } from 'containers/katana/containers/ContentEditorLightbox/hooks/useSiteDataPreviewValues';
import { useKatanaNextPage } from 'containers/katana/hooks/useKatanaNextPage';
import { useModifiedInitialValues } from 'containers/katana/hooks/useModifiedInitialValues';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import React, { useState } from 'react';
import ContentEditorRenderModules from '../contentEditorRenderModules';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type LayoutContentFormHandler = React.FC<{
    subpageRouteData: SubpageRouteData;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const LayoutContentFormHandler: LayoutContentFormHandler = ({ subpageRouteData }) => {
    /***** STATE *****/
    const [isSubmitting, setIsSubmitting] = useState(false);

    /***** HOOKS *****/
    const { performRouting } = useKatanaNextPage(subpageRouteData);
    const { katanaServiceId, subpage } = useKatanaParams();
    const { modules } = subpageRouteData;
    const { submitSiteData } = useSiteDataPreviewValues();

    /***** QUERIES *****/
    const { data: get_katana_section_definitions_data } = katanaQuery.serviceID.meta.sectionDefinitions.useQuery(katanaServiceId);

    const { mutateAsync: mutateAsyncCreateKatanaTheme } = katanaQuery.serviceID.createTheme.useMutation(katanaServiceId);

    const newInitialValues = useModifiedInitialValues(get_katana_section_definitions_data, modules, isSubmitting);

    /***** FUNCTIONS *****/
    const handleSubmit = async (_values: object) => {
        setIsSubmitting(true);
        const promises = [mutateAsyncCreateKatanaTheme(_values)];
        submitSiteData(promises);

        const { restore } = katanaQuery.serviceID.service.optimistic.concat(katanaServiceId, 'data.attributes.launch_checklist', [
            'layout-and-content-completed'
        ]);

        return Promise.all(promises)
            .then(() => {
                performRouting();
            })
            .catch((e) => {
                restore();
                return e;
            })
            .finally(() => {
                setIsSubmitting(false);
                katanaQuery.serviceID.service.invalidateQueries(katanaServiceId);
            });
    };

    /***** RENDER *****/
    return (
        <ContentEditorRenderModules subpageRouteData={subpageRouteData} subpage={subpage} onSubmit={handleSubmit} initialValues={newInitialValues} />
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { LayoutContentFormHandler };
