import { Tooltip } from '@mui/material';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import type { BrowserBox } from 'components/Lightboxes/BrowserBox';
import { useShouldRenderOldPhoneViewport } from 'components/Lightboxes/BrowserBox/special/useShouldRenderOldPhoneViewport';
import { ViewPortControlActive } from 'components/Lightboxes/BrowserBox/viewPortControlActive';
import RequestLoader from 'components/Loaders/Request';
import { Flex } from 'components/Utils/Flex';
import React from 'react';
import './_ViewPortControlButton.scss';

const OldPhoneViewportControl = React.lazy(() =>
    import('components/Lightboxes/BrowserBox/special/OldPhoneViewportControl.lazy').then((res) => ({
        default: res.OldPhoneViewportControlLazy,
    }))
);

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const BrowserBoxViewPortControls: React.FC<BrowserBox.ViewportState> = ({ viewportMode, setViewportMode }) => (
    <Flex className="BrowserBox__viewportControls" gap={2}>
        <Tooltip title="Desktop" arrow placement="top">
            <button
                className="ViewPortControlButton"
                type="button"
                onClick={(e) => {
                    e.stopPropagation();
                    setViewportMode('desktop');
                }}
            >
                <PhosphorIcons.Desktop.DuoTone gray={viewportMode !== 'desktop'} size={32} />
                <ViewPortControlActive isActive={viewportMode === 'desktop'} />
            </button>
        </Tooltip>
        <Tooltip title="Tablet" arrow placement="top">
            <button
                className="ViewPortControlButton"
                type="button"
                onClick={(e) => {
                    e.stopPropagation();
                    setViewportMode('tablet');
                }}
            >
                <PhosphorIcons.DeviceTablet.DuoTone gray={viewportMode !== 'tablet'} size={32} />
                <ViewPortControlActive isActive={viewportMode === 'tablet'} />
            </button>
        </Tooltip>
        <Tooltip title="Mobile" arrow placement="top">
            <button
                className="ViewPortControlButton"
                type="button"
                onClick={(e) => {
                    e.stopPropagation();
                    setViewportMode('mobile');
                }}
            >
                <PhosphorIcons.DeviceMobile.DuoTone gray={viewportMode !== 'mobile'} size={32} />
                <ViewPortControlActive isActive={viewportMode === 'mobile'} />
            </button>
        </Tooltip>
        {useShouldRenderOldPhoneViewport() && (
            <RequestLoader.Suspense>
                <OldPhoneViewportControl viewportMode={viewportMode} setViewportMode={setViewportMode} />
            </RequestLoader.Suspense>
        )}
    </Flex>
);
