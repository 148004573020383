/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import _ from 'lodash';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { usePostNavigationMutation } from 'containers/katana/queries/usePostNavigationMutation';
import { useUpdateKatanaSiteBusinessInfoMutation } from 'containers/katana/queries/useUpdateKatanaSiteBusinessInfoMutation';
import { useUpdateKatanaSiteInfoMutation } from 'containers/katana/queries/useUpdateKatanaSiteInfoMutation';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { pushNotification } from 'components/Toast/functions';
import { useSiteDataPreviewValues } from 'containers/katana/containers/ContentEditorLightbox/hooks/useSiteDataPreviewValues';
import { useKatanaNextPage } from 'containers/katana/hooks/useKatanaNextPage';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const { LOGO } = useUpdateKatanaSiteBusinessInfoMutation.FORM_FIELD_KEYS;
const { STYLE, FONTS } = useUpdateKatanaSiteInfoMutation.FORM_FIELD_KEYS;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useHandleBasicDetailsSubmit(subpageRouteData: KatanaNamespace.SubPageRouteData) {
    /***** HOOKS *****/
    const { katanaServiceId, page, subpage, childPage } = useKatanaParams();
    const { submitSiteData } = useSiteDataPreviewValues();
    const { performRouting } = useKatanaNextPage(subpageRouteData);

    /***** QUERIES *****/
    const { mutateAsync: mutateUpdateKatanaSiteBusinessInfo } = useUpdateKatanaSiteBusinessInfoMutation(katanaServiceId, { onSuccess: _.noop });
    const { mutateAsync: mutateUpdateKatanaSiteInfo } = useUpdateKatanaSiteInfoMutation(katanaServiceId, { onSuccess: _.noop });
    const { mutateAsync: mutatePostKatanaNavigation } = usePostNavigationMutation(katanaServiceId);

    const { data: get_katana_site_navigation_data } = katanaQuery.serviceID.getNavigation.useQuery(katanaServiceId);

    /***** HOOK RESULTS *****/
    return async function handleSubmit(values: { [x: string]: any }) {
        const clonedValues = _.cloneDeep(values);
        if (clonedValues?.logo_choice === LOGO) {
            _.set(clonedValues[FONTS], 'brand', null);
        }
        if (clonedValues?.logo_choice === STYLE) {
            clonedValues[LOGO] = null;
        }

        const processedNavigationValues = _.cloneDeep(get_katana_site_navigation_data);
        if (clonedValues?.style?.logo_scale) {
            _.set(processedNavigationValues, 'style.logo_scale', clonedValues?.style?.logo_scale);
            _.unset(clonedValues, 'style.logo_scale');
        }

        const promises = [mutateUpdateKatanaSiteBusinessInfo(clonedValues), mutateUpdateKatanaSiteInfo(clonedValues)];

        // Only send the logo scale navigation update if we're not in the setup phase. During setup, this is causing a validation error because the navigation doesn't exist yet. BE will fix this later
        if (page !== 'setup') {
            promises.push(mutatePostKatanaNavigation(processedNavigationValues));
        }

        submitSiteData(promises);

        const { restore } = katanaQuery.serviceID.service.optimistic.concat(katanaServiceId, 'data.attributes.launch_checklist', [
            'webpage-info-completed'
        ]);

        return Promise.all(promises)
            .then((e) => {
                if (page !== 'setup') {
                    pushNotification({ status: 200, message: 'Successfully updated site details.' });
                }
                performRouting();
                return e;
            })
            .catch((e) => {
                restore();
                return e;
            })
            .finally(() => {
                katanaQuery.serviceID.service.invalidateQueries(katanaServiceId);
            });
    };
}
