/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useEffect, useId } from 'react';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/

/**
 * Handles registering of data for used in a wider context
 *
 * @template {Record<string, any>} T
 * @param {Object} props - An object containing the registry and setRegistry functions
 * @param {React.Dispatch<React.SetStateAction<T>>} props.setRegistry - A function to update the registered data
 */
function useRegistryData({ setRegistry }) {
    /***** STATE *****/
    const id = useId();

    /***** FUNCTIONS *****/
    /**
     * Updates the registry with the provided data
     * @param {import("utilities/NXUtils").NXUtils.ValueOf<T>} data - The data to be registered
     */
    function updateRegistry(data) {
        setRegistry((registry) => ({ ...registry, [id]: data }));
    }

    /***** EFFECTS *****/

    useEffect(() => {
        return () => {
            /**
             * Unregister Actions when it unmounts
             */
            setRegistry((registry) => {
                if (id in registry) {
                    delete registry[id];
                }
                return registry;
            });
        };
    }, []);

    /***** HOOK RESULTS *****/
    return { updateRegistry };
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export default useRegistryData;
