import type { contactInformationZodSchema } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/contactInformationFormHandler/consts';
import type { KatanaNamespace } from 'containers/katana/types';
import { nxAxios } from 'store/nxAxios';
import invariant from 'tiny-invariant';
import type { PartialDeep, Simplify } from 'type-fest';
import { urlQueryBuilder } from 'utilities/methods/commonActions';
import type { z } from 'zod';

function payload<TAttributes extends object>(attributes: TAttributes) {
    return {
        type: 'katana',
        attributes,
    };
}

export namespace KATANA_API {
    export namespace katana {
        export namespace GET {
            export type Filters = {
                page: number;
                sort: string;
                record_per_page?: number;
            };
        }

        export namespace domains {
            export namespace GET {
                export type Filters = {
                    page: number;
                    sort: string;
                };
            }
        }

        export namespace service_id {
            export namespace enable {
                export namespace POST {
                    export type Params = {
                        serviceID: KatanaNamespace.ServiceId;
                        attributes?: {
                            finalize?: boolean;
                        };
                    };
                }
            }

            export namespace business {
                export namespace POST {
                    export type Params = {
                        serviceID: KatanaNamespace.ServiceId;
                        attributes: Simplify<
                            Partial<
                                {
                                    name?: string;
                                    tag_line?: string;
                                    logo?: Artah.Katana.ID.Uploads.POST._200['data'] | string | null;
                                    business_categories?: string[];
                                } & z.infer<typeof contactInformationZodSchema>
                            >
                        >;
                    };
                }
            }

            export namespace socials {
                export namespace POST {
                    export type Params = {
                        serviceID: KatanaNamespace.ServiceId;
                        attributes: KatanaNamespace.Social[];
                    };
                }

                export namespace DELETE {
                    export type Params = {
                        serviceID: KatanaNamespace.ServiceId;
                        socialType: string;
                    };
                }
            }

            export namespace info {
                export namespace POST {
                    export type Params = {
                        serviceID: KatanaNamespace.ServiceId;
                        attributes: {
                            title?: string;
                            description?: string;
                            is_active?: string;
                            color?: string;
                            style?: {
                                fonts?: {
                                    body?: string;
                                    heading?: string;
                                    brand?: string;
                                };
                            };
                            favicon?: string;
                        };
                    };
                }
            }

            export namespace uploads {
                export namespace GET {
                    export type Params = {
                        serviceID: KatanaNamespace.ServiceId;
                        uploadID: number | null;
                    };
                }

                export namespace POST {
                    export type Params = {
                        serviceID: KatanaNamespace.ServiceId;
                        formData: {
                            file: File;
                            name: string;
                            context?: 'favicon';
                        };
                    };
                }

                export namespace uploadId {
                    export namespace file {
                        export namespace GET {
                            export type Params = {
                                serviceID: KatanaNamespace.ServiceId;
                                uploadID: number | null;
                            };
                        }
                    }

                    export namespace GET {
                        export type Params = {
                            serviceID: KatanaNamespace.ServiceId;
                            uploadID: number | null;
                        };
                    }
                }
            }

            export namespace user {
                export namespace socials {
                    export type AvailableSocials = 'instagram' | 'instagram_business';

                    export namespace $type {
                        export namespace callback {
                            export namespace POST {
                                export type Attributes = {
                                    serviceID: KatanaNamespace.ServiceId;
                                    socialType: KATANA_API.katana.service_id.user.socials.AvailableSocials;
                                    code: string;
                                };
                            }
                        }
                    }
                }
            }

            export namespace navigation {
                export namespace POST {
                    export type Attributes = {
                        values: {
                            call_to_action?: {
                                enabled: boolean;
                                type: 'link';
                                value: string;
                                text: string;
                            };
                            show_socials_in_header?: boolean;
                            show_socials_in_footer?: boolean;
                            style?: {
                                color?: string;
                                visible?: boolean;
                                transparent?: boolean;
                                header_style?: 'standard' | 'transparent';
                            };
                        };
                    };

                    export type Params = {
                        serviceID: KatanaNamespace.ServiceId;
                        attributes: Attributes;
                    };
                }

                export namespace items {
                    export namespace GET {
                        export type Entry = {
                            targetable_type: 'user' | 'site_page' | 'site_section' | 'site';
                            targetable_id: number;
                            order: number;
                            name?: string;
                        };
                    }

                    export namespace POST {
                        type SiteTargetable = {
                            targetable_type: 'site_page';
                            targetable_id: KatanaNamespace.PageId;
                            order: number;
                        };

                        type SectionTargetable = {
                            targetable_type: 'site_section';
                            targetable_id: KatanaNamespace.SectionId;
                            order: number;
                        };

                        export type NavigationItem = SiteTargetable | SectionTargetable;

                        type Attributes = {
                            items: NavigationItem[];
                        };

                        export type Params = {
                            serviceID: KatanaNamespace.ServiceId;
                            attributes: Attributes;
                        };
                    }
                }
            }

            export namespace register {
                export namespace POST {
                    type Contact = {
                        firstname: string;
                        lastname: string;
                        email: string;
                        phone: string;
                        address1: string;
                        address2: string | null;
                        suburb: string;
                        country: string;
                        state: string;
                        postcode: string;
                        organisation: string | null;
                    };

                    type CustomField = {
                        id: number;
                        value: string;
                    };

                    type Domain = {
                        id_protection?: boolean;
                        auto_renew?: boolean;
                        transfer_lock?: boolean;
                    };

                    export type Attributes = {
                        contact: Contact;
                        domain: Domain;
                        custom_fields: CustomField[];
                    };

                    export type Params = {
                        serviceID: KatanaNamespace.ServiceId;
                        attributes: Partial<Attributes>;
                    };
                }
            }

            export namespace unsplash {
                export namespace GET {
                    export type Params = {
                        search: string;
                        page: number;
                        per_page: number;
                    };
                }
            }

            export namespace domains {
                export namespace POST {
                    export type Params = {
                        serviceID: KatanaNamespace.ServiceId;
                        body: {
                            domain: string;
                            is_active: boolean;
                        };
                    };
                }
            }
        }

        export namespace preview {
            export namespace site {
                export namespace POST {
                    export type Params = {
                        serviceID: KatanaNamespace.ServiceId;
                        attributes?: Partial<Artah.Katana.ID.GET._200['data']>;
                    };
                }
            }

            export namespace section {
                export namespace POST {
                    export type Params = Partial<Artah.Katana.Site.ID.Section.ID.GET._200['data']> & {
                        serviceID: KatanaNamespace.ServiceId;
                        sectionDefinitionID: KatanaNamespace.SectionDefinitionId;
                    };
                }

                export namespace section_id {
                    export namespace POST {
                        export type SiteAttributes = Partial<Pick<Artah.Katana.ID.GET._200['data']['site'], 'color' | 'style'>>;
                        export type Params = PartialDeep<Artah.Katana.Site.ID.Section.ID.GET._200['data']> & {
                            sectionDefinitionID: KatanaNamespace.SectionDefinitionId;
                            serviceID: KatanaNamespace.ServiceId;
                            sectionID: KatanaNamespace.SectionId | 'new';
                            site?: SiteAttributes;
                        };

                        export type QueryParams = Params & { enabled: boolean };
                    }
                }
            }

            export namespace site_preset {
                export namespace POST {
                    export type SiteAttributes = Partial<Pick<Artah.Katana.ID.GET._200['data']['site'], 'color' | 'style'>>;
                    export type Params = {
                        serviceID: KatanaNamespace.ServiceId;
                        presetID?: KatanaNamespace.SitePresetId;
                        site?: SiteAttributes;
                    };
                }
            }

            export namespace page_preset {
                export namespace POST {
                    export type SiteAttributes = Partial<Pick<Artah.Katana.ID.GET._200['data']['site'], 'color' | 'style'>>;
                    export type Params = {
                        serviceID: KatanaNamespace.ServiceId;
                        presetID?: KatanaNamespace.PagePresetId;
                        site?: SiteAttributes;
                    };
                }
            }

            export namespace page {
                export namespace POST {
                    export type Attributes = Partial<Artah.Katana.ID.GET._200['data']>;

                    export type Params = {
                        serviceID: KatanaNamespace.ServiceId;
                        pageID: KatanaNamespace.PageId | null;
                        attributes?: Partial<Attributes>;
                    };
                }
            }
        }
        export namespace site {
            export namespace service_id {
                export namespace section {
                    export namespace section_id {
                        export namespace GET {
                            export type Params = {
                                serviceID: KatanaNamespace.ServiceId;
                                sectionID: KatanaNamespace.SectionId | null;
                            };
                        }

                        export namespace PUT {
                            export type Params = {
                                serviceID: KatanaNamespace.ServiceId;
                                sectionID: KatanaNamespace.SectionId;
                                attributes: PartialDeep<Artah.Katana.Site.ID.Section.ID.GET._200['data']>;
                            };
                        }

                        export namespace DELETE {
                            export type Params = {
                                serviceID: KatanaNamespace.ServiceId;
                                sectionID: KatanaNamespace.SectionId;
                            };
                        }
                    }
                }

                export namespace pages {
                    export namespace POST {
                        export type PageAttributes = {
                            path: string;
                            name: string;
                            title: null | string;
                            is_hidden: number;
                        };

                        type PresetAttributes = {
                            preset_id: KatanaNamespace.PagePresetId;
                            keep_placeholder_data: boolean;
                        };

                        type Attributes = PageAttributes | PresetAttributes;

                        export type Params = {
                            serviceID: KatanaNamespace.ServiceId;
                            attributes: Attributes;
                        };
                    }

                    export namespace page_id {
                        export namespace GET {
                            export type Params = {
                                serviceID: KatanaNamespace.ServiceId;
                                pageID: KatanaNamespace.PageId;
                            };
                        }
                        export namespace PUT {
                            type Attributes = KATANA_API.katana.site.service_id.pages.POST.PageAttributes & {
                                name: string | null;
                            };

                            export type Params = {
                                serviceID: KatanaNamespace.ServiceId;
                                pageID: KatanaNamespace.PageId;
                                attributes: Attributes;
                            };
                        }

                        export namespace DELETE {
                            export type Params = {
                                serviceID: KatanaNamespace.ServiceId;
                                pageID: KatanaNamespace.PageId;
                            };
                        }

                        export namespace reset {
                            export namespace POST {
                                export type Params = {
                                    serviceID: KatanaNamespace.ServiceId;
                                    pageID: KatanaNamespace.PageId;
                                };
                            }
                        }

                        export namespace duplicate {
                            export namespace POST {
                                export type Params = {
                                    serviceID: KatanaNamespace.ServiceId;
                                    pageID: KatanaNamespace.PageId;
                                };
                            }
                        }

                        export namespace sections {
                            export namespace GET {
                                export type Params = {
                                    serviceID: KatanaNamespace.ServiceId;
                                    pageID: KatanaNamespace.PageId;
                                };
                            }

                            export namespace POST {
                                export type Params = {
                                    serviceID: KatanaNamespace.ServiceId;
                                    pageID: KatanaNamespace.PageId;
                                    sectionDefinitionID: KatanaNamespace.SectionDefinitionId;
                                    attributes: Partial<Artah.Katana.Site.ID.Section.ID.GET._200['data']>;
                                };
                            }

                            export namespace section_id {
                                export namespace PUT {
                                    export type Params = {
                                        serviceID: KatanaNamespace.ServiceId;
                                        pageID: KatanaNamespace.PageId;
                                        sectionID: KatanaNamespace.SectionId;
                                        attributes: Partial<Artah.Katana.Site.ID.Section.ID.GET._200['data']>;
                                    };
                                }

                                export namespace DELETE {
                                    export type Params = {
                                        serviceID: KatanaNamespace.ServiceId;
                                        pageID: KatanaNamespace.PageId;
                                        sectionID: KatanaNamespace.SectionId;
                                    };
                                }

                                export namespace duplicate {
                                    export namespace POST {
                                        export type Params = {
                                            serviceID: KatanaNamespace.ServiceId;
                                            pageID: KatanaNamespace.PageId;
                                            sectionID: KatanaNamespace.SectionId;
                                        };
                                    }
                                }
                            }

                            export namespace reorder {
                                export namespace POST {
                                    type Attributes = {
                                        section_ids: KatanaNamespace.SectionId[];
                                    };
                                    export type Params = {
                                        serviceID: KatanaNamespace.ServiceId;
                                        pageID: KatanaNamespace.PageId;
                                        attributes: Attributes;
                                    };
                                }
                            }
                        }
                    }
                }

                export namespace Theme {
                    export namespace POST {
                        export type Params = {
                            serviceID: KatanaNamespace.ServiceId;
                            attributes: {
                                preset_id: string;
                                sections: string[];
                            };
                        };
                    }
                }

                export namespace sections {
                    export namespace POST {
                        export type Params = {
                            serviceID: KatanaNamespace.ServiceId;
                            sectionDefinitionID: KatanaNamespace.SectionDefinitionId;
                            attributes: Partial<Artah.Katana.Site.ID.Section.ID.GET._200['data']>;
                        };
                    }
                }
                export namespace meta {
                    export namespace palette {
                        export namespace GET {
                            export type Params = {
                                serviceID: KatanaNamespace.ServiceId;
                                colour?: string;
                            };
                        }
                    }
                }
            }
        }
    }
}

export const KATANA_API = {
    katana: {
        /**
         * List
         */
        GET: (queryParams: KATANA_API.katana.GET.Filters) => {
            const apiQuery = `/api/katana/${urlQueryBuilder(queryParams)}`;
            return nxAxios.get(apiQuery) as Artah.Katana.GET.NXReturnType;
        },

        /**
         * Create a Site
         */
        POST: (domain: string) => {
            return nxAxios.post('/api/katana', payload({ domain })) as Artah.Katana.POST.NXReturnType;
        },

        domains: {
            GET: (queryParams: KATANA_API.katana.domains.GET.Filters) => {
                const apiQuery = `/api/katana/domains/${urlQueryBuilder(queryParams)}`;
                return nxAxios.get(apiQuery) as Artah.Katana.Domains.GET.NXReturnType;
            },
        },

        check: {
            POST: (domain: string) => {
                return nxAxios.post('/api/katana/check', {
                    attributes: {
                        domain,
                    },
                }) as Artah.Katana.Check.POST.NXReturnType;
            },
        },

        search: {
            GET: (queryParams: string | null) => {
                return nxAxios.get(`/api/katana/search/${queryParams}`) as Artah.Katana.Search.GET.NXReturnType;
            },
        },

        service_id: {
            /**
             * Get Site
             */
            GET: (serviceID: KatanaNamespace.ServiceId, signal: AbortSignal) => {
                return nxAxios.get(`/api/katana/${serviceID}`, { signal }) as Artah.Katana.ID.GET.NXReturnType;
            },

            /**
             * Domains related to the site
             */
            domains: {
                /**
                 * Add a domain to the site
                 */
                POST: ({ serviceID, body }: KATANA_API.katana.service_id.domains.POST.Params) => {
                    return nxAxios.post(`/api/katana/${serviceID}/domains`, body) as Artah.Katana.ID.Domains.POST.NXReturnType;
                },
            },

            enable: {
                /**
                 * Enable site
                 */
                POST: ({ serviceID, attributes }: KATANA_API.katana.service_id.enable.POST.Params) => {
                    return nxAxios.post(`/api/katana/${serviceID}/enable`, { attributes }) as Artah.Katana.ID.Enable.POST.NXReturnType;
                },
            },

            disable: {
                /**
                 * Disables site
                 */
                POST: (serviceID: KatanaNamespace.ServiceId) => {
                    return nxAxios.post(`/api/katana/${serviceID}/disable`) as Artah.Katana.ID.Disable.POST.NXReturnType;
                },
            },

            domain_options: {
                /**
                 * Get Domain Options
                 */
                GET: (serviceID: KatanaNamespace.ServiceId) => {
                    return nxAxios.get(`/api/katana/${serviceID}/domain-options`) as Artah.Katana.ID.DomainOptions.GET.NXReturnType;
                },
            },

            DNS: {
                /**
                 * Gets dns for site
                 */
                GET: (serviceID: KatanaNamespace.ServiceId) => {
                    return nxAxios.get(`/api/katana/${serviceID}/dns`) as Artah.Katana.ID.DNS.GET.NXReturnType;
                },
            },

            service_details: {
                /**
                 * Gets service details
                 */
                GET: (serviceID: KatanaNamespace.ServiceId) => {
                    return nxAxios.get(`/api/katana/${serviceID}/service-details`) as Artah.Katana.ID.ServiceDetails.GET.NXReturnType;
                },
            },

            socials: {
                /**
                 * Get Social Links
                 */
                GET: (serviceID: KatanaNamespace.ServiceId) => {
                    return nxAxios.get(`/api/katana/${serviceID}/socials`) as Artah.Katana.ID.Socials.GET.NXReturnType;
                },

                /**
                 * Update Social Links
                 */
                POST: ({ serviceID, attributes }: KATANA_API.katana.service_id.socials.POST.Params) => {
                    return nxAxios.post(`/api/katana/${serviceID}/socials`, payload(attributes)) as Artah.Katana.ID.Socials.POST.NXReturnType;
                },

                /**
                 * Delete Social Links
                 */
                DELETE: ({ serviceID, socialType }: KATANA_API.katana.service_id.socials.DELETE.Params) => {
                    return nxAxios.delete(`/api/katana/${serviceID}/socials/${socialType}`) as Artah.Katana.ID.Socials.DELETE.NXReturnType;
                },
            },

            info: {
                /**
                 * Get site info
                 */
                GET: (serviceID: KatanaNamespace.ServiceId) => {
                    return nxAxios.get(`/api/katana/${serviceID}/info`) as Artah.Katana.ID.Info.GET.NXReturnType;
                },

                /**
                 * Update site info
                 */
                POST: ({ serviceID, attributes }: KATANA_API.katana.service_id.info.POST.Params) => {
                    return nxAxios.post(`/api/katana/${serviceID}/info`, payload(attributes)) as Artah.Katana.ID.Info.POST.NXReturnType;
                },
            },

            business: {
                /**
                 * Get business details
                 */
                GET: (serviceID: KatanaNamespace.ServiceId) => {
                    return nxAxios.get(`/api/katana/${serviceID}/business`) as Artah.Katana.ID.Business.GET.NXReturnType;
                },

                /**
                 * update business details
                 */
                POST: ({ serviceID, attributes }: KATANA_API.katana.service_id.business.POST.Params) => {
                    return nxAxios.post(`/api/katana/${serviceID}/business`, payload(attributes)) as Artah.Katana.ID.Business.POST.NXReturnType;
                },
            },

            uploads: {
                /**
                 * Get uploads
                 */
                GET: ({ serviceID }: KATANA_API.katana.service_id.uploads.GET.Params) => {
                    return nxAxios.get(`/api/katana/${serviceID}/uploads`) as Artah.Katana.ID.Uploads.GET.NXReturnType;
                },

                /**
                 * Uploads Image
                 */
                POST: ({ serviceID, formData }: KATANA_API.katana.service_id.uploads.POST.Params) => {
                    return nxAxios.post(`/api/katana/${serviceID}/uploads`, formData) as Artah.Katana.ID.Uploads.POST.NXReturnType;
                },

                uploadId: {
                    /**
                     * Get single file upload
                     */
                    file: {
                        GET: ({ serviceID, uploadID }: KATANA_API.katana.service_id.uploads.uploadId.file.GET.Params) => {
                            return nxAxios.get(
                                `/api/katana/${serviceID}/uploads/${uploadID}/file`
                            ) as Artah.Katana.ID.Uploads.UploadID.File.GET.NXReturnType;
                        },
                    },
                    /**
                     * Get single file details. Name, url, size, mime_type ect...
                     */
                    GET: ({ serviceID, uploadID }: KATANA_API.katana.service_id.uploads.uploadId.GET.Params) => {
                        return nxAxios.get(`/api/katana/${serviceID}/uploads/${uploadID}`) as Artah.Katana.ID.Uploads.UploadID.GET.NXReturnType;
                    },
                },
            },

            user: {
                socials: {
                    $type: {
                        link: {
                            /**
                             * This endpoint returns an authorization link, this may be used initially to begin the account linking process.
                             */
                            GET: (serviceID: KatanaNamespace.ServiceId, $type: KATANA_API.katana.service_id.user.socials.AvailableSocials) => {
                                return nxAxios.get(
                                    `/api/katana/${serviceID}/user/socials/${$type}/link`
                                ) as Artah.Katana.ID.User.Socials.InstagramBusiness.Link.GET.NXReturnType;
                            },
                        },

                        callback: {
                            /**
                             * This endpoint is used to finalize a callback received from an external social provider (i.e. Instagram).
                             */
                            POST: (attributes: KATANA_API.katana.service_id.user.socials.$type.callback.POST.Attributes) => {
                                return nxAxios.post(`/api/katana/${attributes.serviceID}/user/socials/${attributes.socialType}/callback`, {
                                    code: attributes.code,
                                }) as Artah.Katana.ID.User.Socials.InstagramBusiness.Callback.POST.NXReturnType;
                            },
                        },
                    },

                    /**
                     * This endpoint returns all social accounts linked to the current user.
                     */
                    GET: (serviceID: KatanaNamespace.ServiceId) => {
                        return nxAxios.get(`/api/katana/${serviceID}/user/socials`) as Artah.Katana.ID.User.Socials.GET.NXReturnType;
                    },

                    $socialID: {
                        /**
                         * This endpoint may be used to return a single social account given its ID.
                         */
                        GET: (serviceID: KatanaNamespace.ServiceId, $socialID: number) => {
                            return nxAxios.get(
                                `/api/katana/${serviceID}/user/socials/${$socialID}`
                            ) as Artah.Katana.ID.User.Socials.ID.GET.NXReturnType;
                        },

                        /**
                         * This endpoint may be used to remove a social account given its ID.
                         */
                        DELETE: (serviceID: KatanaNamespace.ServiceId, $socialID: number) => {
                            return nxAxios.delete(
                                `/api/katana/${serviceID}/user/socials/${$socialID}`
                            ) as Artah.Katana.ID.User.Socials.ID.DELETE.NXReturnType;
                        },
                    },
                },
            },

            navigation: {
                /**
                 * Get Social Links
                 */
                GET: (serviceID: KatanaNamespace.ServiceId) => {
                    return nxAxios.get(`/api/katana/${serviceID}/navigation`) as Artah.Katana.ID.Navigation.GET.NXReturnType;
                },

                /**
                 * Update Navigation
                 */
                POST: ({ serviceID, attributes }: KATANA_API.katana.service_id.navigation.POST.Params) => {
                    return nxAxios.post(`/api/katana/${serviceID}/navigation`, payload(attributes)) as Artah.Katana.ID.Navigation.POST.NXReturnType;
                },

                items: {
                    /**
                     * Get Social Links
                     */
                    GET: (serviceID: KatanaNamespace.ServiceId) => {
                        return nxAxios.get(`/api/katana/${serviceID}/navigation/items`) as Artah.Katana.ID.Navigation.Items.GET.NXReturnType;
                    },

                    /**
                     * Update Navigation Items
                     */
                    POST: ({ serviceID, attributes }: KATANA_API.katana.service_id.navigation.items.POST.Params) => {
                        return nxAxios.post(
                            `/api/katana/${serviceID}/navigation/items`,
                            attributes
                        ) as Artah.Katana.ID.Navigation.Items.POST.NXReturnType;
                    },
                },
            },

            preview: {
                site: {
                    /**
                     * Preview Site
                     */
                    POST: ({ serviceID, attributes = {} }: KATANA_API.katana.preview.site.POST.Params, signal: AbortSignal) => {
                        return nxAxios.post(`/api/katana/site/${serviceID}/preview/site`, attributes, {
                            signal,
                        }) as Artah.Katana.Site.ID.Preview.Site.POST.NXReturnType;
                    },
                },

                section: {
                    /**
                     * Preview Site Section
                     */
                    POST: (
                        { serviceID, sectionDefinitionID, properties, style }: KATANA_API.katana.preview.section.POST.Params,
                        signal: AbortSignal
                    ) => {
                        return nxAxios.post(
                            `/api/katana/site/${serviceID}/preview/section`,
                            {
                                section_id: sectionDefinitionID,
                                properties,
                                style,
                            },
                            {
                                signal,
                            }
                        ) as Artah.Katana.Site.ID.Preview.Section.POST.NXReturnType;
                    },

                    section_id: {
                        /**
                         * Preview Site Section with site
                         */
                        POST: (
                            {
                                serviceID,
                                sectionID,
                                sectionDefinitionID,
                                properties,
                                style,
                                site,
                            }: KATANA_API.katana.preview.section.section_id.POST.Params,
                            signal: AbortSignal
                        ) => {
                            return nxAxios.post(
                                `/api/katana/site/${serviceID}/preview/section/${sectionID}`,
                                {
                                    properties,
                                    style,
                                    section_id: sectionDefinitionID,
                                    ...(site ? { site } : {}),
                                },
                                {
                                    signal,
                                }
                            ) as Artah.Katana.Site.ID.Preview.Section.ID.POST.NXReturnType;
                        },
                    },
                },

                site_preset: {
                    /**
                     * Preview Site Section
                     */
                    POST: ({ serviceID, presetID, site }: KATANA_API.katana.preview.site_preset.POST.Params) => {
                        return nxAxios.post(`/api/katana/site/${serviceID}/preview/site-preset`, {
                            preset_id: presetID,
                            ...(site ? { site } : {}),
                        }) as Artah.Katana.Site.ID.Preview.Preset.POST.NXReturnType;
                    },
                },

                page_preset: {
                    /**
                     * Preview Site Section
                     */
                    POST: ({ serviceID, presetID, site }: KATANA_API.katana.preview.page_preset.POST.Params) => {
                        return nxAxios.post(`/api/katana/site/${serviceID}/preview/page-preset`, {
                            preset_id: presetID,
                            ...(site ? { site } : {}),
                        }) as Artah.Katana.Site.ID.Preview.PagePreset.POST.NXReturnType;
                    },
                },

                page: {
                    /**
                     * Preview Site Page
                     */
                    POST: ({ serviceID, pageID, attributes }: KATANA_API.katana.preview.page.POST.Params) => {
                        return nxAxios.post(
                            `/api/katana/site/${serviceID}/preview/page/${pageID}`,
                            attributes
                        ) as Artah.Katana.Site.ID.Preview.Page.ID.POST.NXReturnType;
                    },
                },
            },

            register: {
                /**
                 * Register Site
                 */
                POST: ({ serviceID, attributes }: KATANA_API.katana.service_id.register.POST.Params) => {
                    return nxAxios.post(`/api/katana/${serviceID}/register`, payload(attributes)) as Artah.Katana.ID.Register.POST.NXReturnType;
                },
            },

            reset: {
                /**
                 * Reset Site
                 */
                POST: (serviceID: KatanaNamespace.ServiceId) => {
                    return nxAxios.post(`/api/katana/${serviceID}/reset`) as Artah.Katana.ID.Reset.POST.NXReturnType;
                },
            },
        },

        site: {
            service_id: {
                meta: {
                    site_presets: {
                        /**
                         * Get theme definitions
                         */
                        GET: (serviceID: KatanaNamespace.ServiceId) => {
                            return nxAxios.get(
                                `/api/katana/site/${serviceID}/meta/site-presets`
                            ) as Artah.Katana.Site.ID.Meta.SitePresets.GET.NXReturnType;
                        },
                    },

                    page_presets: {
                        /**
                         * Get page preset definitions
                         */
                        GET: (serviceID: KatanaNamespace.ServiceId) => {
                            return nxAxios.get(
                                `/api/katana/site/${serviceID}/meta/page-presets`
                            ) as Artah.Katana.Site.ID.Meta.PagePresets.GET.NXReturnType;
                        },
                    },

                    colors: {
                        /**
                         * Get Color Definitions
                         */
                        GET: (serviceID: KatanaNamespace.ServiceId) => {
                            return nxAxios.get(`/api/katana/site/${serviceID}/meta/colors`) as Artah.Katana.Site.ID.Meta.Colors.GET.NXReturnType;
                        },
                    },

                    palette: {
                        /**
                         * Get Palette Definitions
                         */
                        GET: ({ serviceID, colour }: KATANA_API.katana.site.service_id.meta.palette.GET.Params) => {
                            return nxAxios.get(
                                `/api/katana/site/${serviceID}/meta/palette/${colour}`
                            ) as Artah.Katana.Site.ID.Meta.Palette.Blue.GET.NXReturnType;
                        },
                    },

                    sections: {
                        /**
                         * Get Section definitions
                         */
                        GET: (serviceID: KatanaNamespace.ServiceId) => {
                            return nxAxios.get(`/api/katana/site/${serviceID}/meta/sections`) as Artah.Katana.Site.ID.Meta.Sections.GET.NXReturnType;
                        },
                    },

                    socials: {
                        /**
                         * Get Socials definitions
                         */
                        GET: (serviceID: KatanaNamespace.ServiceId) => {
                            return nxAxios.get(`/api/katana/site/${serviceID}/meta/socials`) as Artah.Katana.Site.ID.Meta.Socials.GET.NXReturnType;
                        },
                    },

                    fonts: {
                        /**
                         * Get Font  definitions
                         */
                        GET: (serviceID: KatanaNamespace.ServiceId) => {
                            return nxAxios.get(`/api/katana/site/${serviceID}/meta/fonts`) as Artah.Katana.Site.ID.Meta.Fonts.GET.NXReturnType;
                        },
                    },
                },

                pages: {
                    GET: (serviceID: KatanaNamespace.ServiceId) => {
                        return nxAxios.get(`/api/katana/site/${serviceID}/pages`) as Artah.Katana.Site.ID.Pages.GET.NXReturnType;
                    },

                    POST: ({ serviceID, attributes }: KATANA_API.katana.site.service_id.pages.POST.Params) => {
                        return nxAxios.post(`/api/katana/site/${serviceID}/pages`, attributes) as Artah.Katana.Site.ID.Pages.POST.NXReturnType;
                    },

                    page_id: {
                        GET: ({ serviceID, pageID }: KATANA_API.katana.site.service_id.pages.page_id.GET.Params) => {
                            return nxAxios.get(`/api/katana/site/${serviceID}/pages/${pageID}`) as Artah.Katana.Site.ID.Pages.ID.GET.NXReturnType;
                        },

                        PUT: ({ serviceID, pageID, attributes }: KATANA_API.katana.site.service_id.pages.page_id.PUT.Params) => {
                            return nxAxios.put(
                                `/api/katana/site/${serviceID}/pages/${pageID}`,
                                attributes
                            ) as Artah.Katana.Site.ID.Pages.ID.PUT.NXReturnType;
                        },

                        DELETE: ({ serviceID, pageID }: KATANA_API.katana.site.service_id.pages.page_id.DELETE.Params) => {
                            return nxAxios.delete(
                                `/api/katana/site/${serviceID}/pages/${pageID}`
                            ) as Artah.Katana.Site.ID.Pages.ID.DELETE.NXReturnType;
                        },

                        reset: {
                            POST: ({ serviceID, pageID }: KATANA_API.katana.site.service_id.pages.page_id.reset.POST.Params) => {
                                return nxAxios.post(
                                    `/api/katana/site/${serviceID}/pages/${pageID}/reset`
                                ) as Artah.Katana.Site.ID.Pages.ID.Reset.POST.NXReturnType;
                            },
                        },

                        duplicate: {
                            POST: ({ serviceID, pageID }: KATANA_API.katana.site.service_id.pages.page_id.duplicate.POST.Params) => {
                                return nxAxios.post(
                                    `/api/katana/site/${serviceID}/pages/${pageID}/duplicate`
                                ) as Artah.Katana.Site.ID.Pages.ID.Duplicate.POST.NXReturnType;
                            },
                        },

                        sections: {
                            GET: ({ serviceID, pageID }: KATANA_API.katana.site.service_id.pages.page_id.sections.GET.Params) => {
                                return nxAxios.get(
                                    `/api/katana/site/${serviceID}/pages/${pageID}/sections`
                                ) as Artah.Katana.Site.ID.Pages.ID.Sections.GET.NXReturnType;
                            },

                            POST: ({
                                serviceID,
                                pageID,
                                sectionDefinitionID,
                                attributes,
                            }: KATANA_API.katana.site.service_id.pages.page_id.sections.POST.Params) => {
                                return nxAxios.post(`/api/katana/site/${serviceID}/pages/${pageID}/sections`, {
                                    section_id: sectionDefinitionID,
                                    ...attributes,
                                }) as Artah.Katana.Site.ID.Pages.ID.Sections.POST.NXReturnType;
                            },

                            section_id: {
                                // GET: ({
                                //     serviceID,
                                //     pageID,
                                //     sectionID
                                // }: KATANA_API.katana.site.service_id.pages.page_id.sections.section_id.GET.Params) => {
                                //     return nxAxios.get(`/api/katana/site/${serviceID}/pages/${pageID}/section/${sectionID}`) as Artah.Katana.Site.ID.Pages.ID.Section.ID.GET.NXReturnType
                                // },

                                PUT: ({
                                    serviceID,
                                    pageID,
                                    sectionID,
                                    attributes,
                                }: KATANA_API.katana.site.service_id.pages.page_id.sections.section_id.PUT.Params) => {
                                    return nxAxios.put(
                                        `/api/katana/site/${serviceID}/pages/${pageID}/section/${sectionID}`,
                                        attributes
                                    ) as Artah.Katana.Site.ID.Pages.ID.Section.ID.PUT.NXReturnType;
                                },

                                DELETE: ({
                                    serviceID,
                                    pageID,
                                    sectionID,
                                }: KATANA_API.katana.site.service_id.pages.page_id.sections.section_id.DELETE.Params) => {
                                    return nxAxios.delete(
                                        `/api/katana/site/${serviceID}/pages/${pageID}/section/${sectionID}`
                                    ) as Artah.Katana.Site.ID.Pages.ID.Section.ID.DELETE.NXReturnType;
                                },

                                duplicate: {
                                    POST: ({
                                        serviceID,
                                        pageID,
                                        sectionID,
                                    }: KATANA_API.katana.site.service_id.pages.page_id.sections.section_id.duplicate.POST.Params) => {
                                        return nxAxios.post(
                                            `/api/katana/site/${serviceID}/pages/${pageID}/section/${sectionID}/duplicate`
                                        ) as Artah.Katana.Site.ID.Pages.ID.Section.ID.Duplicate.POST.NXReturnType;
                                    },
                                },
                            },

                            reorder: {
                                POST: ({
                                    serviceID,
                                    pageID,
                                    attributes,
                                }: KATANA_API.katana.site.service_id.pages.page_id.sections.reorder.POST.Params) => {
                                    return nxAxios.post(`/api/katana/site/${serviceID}/pages/${pageID}/sections/reorder`, {
                                        attributes,
                                    }) as Artah.Katana.Site.ID.Pages.ID.Sections.Reorder.POST.NXReturnType;
                                },
                            },
                        },
                    },
                },

                theme: {
                    /**
                     * Apply theme
                     */
                    POST: ({ serviceID, attributes }: KATANA_API.katana.site.service_id.Theme.POST.Params) => {
                        return nxAxios.post(
                            `/api/katana/site/${serviceID}/theme`,
                            payload(attributes)
                        ) as Artah.Katana.Site.ID.Theme.POST.NXReturnType;
                    },
                },

                sections: {
                    /**
                     * List All Site Sections
                     */
                    GET: (serviceID: KatanaNamespace.ServiceId) => {
                        return nxAxios.get(`/api/katana/site/${serviceID}/sections`) as Artah.Katana.Site.ID.Sections.GET.NXReturnType;
                    },

                    /**
                     * Add Site Section
                     */
                    POST: ({ serviceID, sectionDefinitionID, attributes }: KATANA_API.katana.site.service_id.sections.POST.Params) => {
                        return nxAxios.post(`/api/katana/site/${serviceID}/sections`, {
                            section_id: sectionDefinitionID,
                            ...attributes,
                        }) as Artah.Katana.Site.ID.Sections.POST.NXReturnType;
                    },
                },

                section: {
                    section_id: {
                        /**
                         * Get Site Section
                         */
                        GET: ({ serviceID, sectionID }: KATANA_API.katana.site.service_id.section.section_id.GET.Params) => {
                            invariant(sectionID, 'sectionID is required');
                            return nxAxios.get(
                                `/api/katana/site/${serviceID}/section/${sectionID}`
                            ) as Artah.Katana.Site.ID.Section.ID.GET.NXReturnType;
                        },

                        /**
                         * Update Site Section
                         */
                        PUT: ({ serviceID, sectionID, attributes }: KATANA_API.katana.site.service_id.section.section_id.PUT.Params) => {
                            return nxAxios.put(
                                `/api/katana/site/${serviceID}/section/${sectionID}`,
                                attributes
                            ) as Artah.Katana.Site.ID.Section.ID.PUT.NXReturnType;
                        },

                        /**
                         * Remove Site Section
                         */
                        DELETE: ({ serviceID, sectionID }: KATANA_API.katana.site.service_id.section.section_id.DELETE.Params) => {
                            return nxAxios.delete(
                                `/api/katana/site/${serviceID}/section/${sectionID}`
                            ) as Artah.Katana.Site.ID.Section.ID.DELETE.NXReturnType;
                        },
                    },
                },
            },
        },
        trialDomains: {
            GET: () => {
                return nxAxios.get(`/api/katana/trial-domains`) as Artah.Katana.TrialDomains.GET.NXReturnType;
            },
        },

        unsplash: {
            search: {
                GET: (id: KatanaNamespace.ServiceId, params: KATANA_API.katana.service_id.unsplash.GET.Params) => {
                    return nxAxios.get(
                        `/api/katana/${id}/unsplash/search/${urlQueryBuilder(params)}`
                    ) as Artah.Katana.ID.Unsplash.Search.GET.NXReturnType;
                },
            },
            download: {
                trigger: {
                    POST: (id: KatanaNamespace.ServiceId, urls: string[]) => {
                        return nxAxios.post(`/api/katana/${id}/unsplash/download/trigger`, {
                            urls,
                        }) as Artah.Katana.ID.Unsplash.Download.Trigger.POST.NXReturnType;
                    },
                },
            },
        },
    },
};
