/**********************************************************************************************************
 *   DECLARATIONS
 **********************************************************************************************************/

export const DOMAIN_INITIAL_STATE = 'domain/DOMAIN_INITIAL_STATE';

export const DOMAIN_DNS_VIEW = 'domain/DOMAIN_DNS_VIEW';

export const DOMAIN_INFORMATION_REQUEST = 'domain/DOMAIN_INFORMATION_REQUEST';
export const DOMAIN_INFORMATION_SUCCESS = 'domain/DOMAIN_INFORMATION_SUCCESS';
export const DOMAIN_INFORMATION_ERROR = 'domain/DOMAIN_INFORMATION_ERROR';

export const DOMAIN_AVAILABILITY_RESET = 'domain/DOMAIN_AVAILABILITY_RESET';
export const DOMAIN_AVAILABILITY_UPDATE = 'domain/DOMAIN_AVAILABILITY_UPDATE';

export const DOMAIN_ACTIVE_REQUEST = 'domain/DOMAIN_ACTIVE_REQUEST';
export const DOMAIN_ACTIVE_SUCCESS = 'domain/DOMAIN_ACTIVE_SUCCESS';
export const DOMAIN_ACTIVE_ERROR = 'domain/DOMAIN_ACTIVE_ERROR';

export const DOMAIN_SUGGESTION_REQUEST = 'domain/DOMAIN_SUGGESTION_REQUEST';
export const DOMAIN_SUGGESTION_SUCCESS = 'domain/DOMAIN_SUGGESTION_SUCCESS';
export const DOMAIN_SUGGESTION_ERROR = 'domain/DOMAIN_SUGGESTION_ERROR';

export const DOMAIN_RENEW_OPTIONS_REQUEST = 'domain/DOMAIN_RENEW_OPTIONS_REQUEST';
export const DOMAIN_RENEW_OPTIONS_SUCCESS = 'domain/DOMAIN_RENEW_OPTIONS_SUCCESS';
export const DOMAIN_RENEW_OPTIONS_ERROR = 'domain/DOMAIN_RENEW_OPTIONS_ERROR';

export const DOMAIN_RENEW_REQUEST = 'domain/DOMAIN_RENEW_REQUEST';
export const DOMAIN_RENEW_SUCCESS = 'domain/DOMAIN_RENEW_SUCCESS';
export const DOMAIN_RENEW_ERROR = 'domain/DOMAIN_RENEW_ERROR';

export const DOMAIN_RENEW_APPLY_PROMO_REQUEST = 'domain/DOMAIN_RENEW_APPLY_PROMO_REQUEST';
export const DOMAIN_RENEW_APPLY_PROMO_SUCCESS = 'domain/DOMAIN_RENEW_APPLY_PROMO_SUCCESS';
export const DOMAIN_RENEW_APPLY_PROMO_ERROR = 'domain/DOMAIN_RENEW_APPLY_PROMO_ERROR';

export const DOMAIN_AUTO_RENEW_REQUEST = 'domain/DOMAIN_AUTO_RENEW_REQUEST';
export const DOMAIN_AUTO_RENEW_SUCCESS = 'domain/DOMAIN_AUTO_RENEW_SUCCESS';
export const DOMAIN_AUTO_RENEW_ERROR = 'domain/DOMAIN_AUTO_RENEW_ERROR';

export const DOMAIN_CONTACT_RESET = 'domain/DOMAIN_CONTACT_RESET';
export const DOMAIN_CONTACT_REQUEST = 'domain/DOMAIN_CONTACT_REQUEST';
export const DOMAIN_CONTACT_SUCCESS = 'domain/DOMAIN_CONTACT_SUCCESS';
export const DOMAIN_CONTACT_ERROR = 'domain/DOMAIN_CONTACT_ERROR';

export const DOMAIN_CONTACT_UPDATE_REQUEST = 'domain/DOMAIN_CONTACT_UPDATE_REQUEST';
export const DOMAIN_CONTACT_UPDATE_SUCCESS = 'domain/DOMAIN_CONTACT_UPDATE_SUCCESS';
export const DOMAIN_CONTACT_UPDATE_ERROR = 'domain/DOMAIN_CONTACT_UPDATE_ERROR';

export const DOMAIN_CONTACT_THROUGH_SEARCH_RESET = 'domain/DOMAIN_CONTACT_THROUGH_SEARCH_RESET';
export const DOMAIN_CONTACT_THROUGH_SEARCH_REQUEST = 'domain/DOMAIN_CONTACT_THROUGH_SEARCH_REQUEST';
export const DOMAIN_CONTACT_THROUGH_SEARCH_SUCCESS = 'domain/DOMAIN_CONTACT_THROUGH_SEARCH_SUCCESS';
export const DOMAIN_CONTACT_THROUGH_SEARCH_ERROR = 'domain/DOMAIN_CONTACT_THROUGH_SEARCH_ERROR';

export const DOMAIN_EMAIL_UPDATE_REQUEST = 'domain/DOMAIN_EMAIL_UPDATE_REQUEST';
export const DOMAIN_EMAIL_UPDATE_SUCCESS = 'domain/DOMAIN_EMAIL_UPDATE_SUCCESS';
export const DOMAIN_EMAIL_UPDATE_ERROR = 'domain/DOMAIN_EMAIL_UPDATE_ERROR';

export const DOMAIN_CONFIGURATION_REQUEST = 'domain/DOMAIN_CONFIGURATION_REQUEST';
export const DOMAIN_CONFIGURATION_SUCCESS = 'domain/DOMAIN_CONFIGURATION_SUCCESS';
export const DOMAIN_CONFIGURATION_ERROR = 'domain/DOMAIN_CONFIGURATION_ERROR';

export const DOMAIN_CURRENT_CONFIGURATION_REQUEST = 'domain/DOMAIN_CURRENT_CONFIGURATION_REQUEST';
export const DOMAIN_CURRENT_CONFIGURATION_SUCCESS = 'domain/DOMAIN_CURRENT_CONFIGURATION_SUCCESS';
export const DOMAIN_CURRENT_CONFIGURATION_ERROR = 'domain/DOMAIN_CURRENT_CONFIGURATION_ERROR';

export const DOMAIN_DEFAULT_CONFIGURATION_REQUEST = 'domain/DOMAIN_DEFAULT_CONFIGURATION_REQUEST';
export const DOMAIN_DEFAULT_CONFIGURATION_SUCCESS = 'domain/DOMAIN_DEFAULT_CONFIGURATION_SUCCESS';
export const DOMAIN_DEFAULT_CONFIGURATION_ERROR = 'domain/DOMAIN_DEFAULT_CONFIGURATION_ERROR';

export const DOMAIN_RECORD_STORE = 'domain/DOMAIN_RECORD_STORE';
export const DOMAIN_RECORD_STORE_AWAIT = 'domain/DOMAIN_RECORD_STORE_AWAIT';
export const DOMAIN_DEFAULT_RECORD_STORE = 'domain/DOMAIN_DEFAULT_RECORD_STORE';
export const DOMAIN_DEFAULT_RECORD_STORE_AWAIT = 'domain/DOMAIN_DEFAULT_RECORD_STORE_AWAIT';
export const DOMAIN_NAMESERVER_STORE = 'domain/DOMAIN_NAMESERVER_STORE';

export const DOMAIN_DNS_RECORDS_EXPORT_REQUEST = 'domain/DOMAIN_DNS_RECORDS_EXPORT_REQUEST';
export const DOMAIN_DNS_RECORDS_EXPORT_SUCCESS = 'domain/DOMAIN_DNS_RECORDS_EXPORT_SUCCESS';
export const DOMAIN_DNS_RECORDS_EXPORT_ERROR = 'domain/DOMAIN_DNS_RECORDS_EXPORT_ERROR';

export const DOMAIN_UPDATE_CURRENT_CONFIGURATION_REQUEST = 'domain/DOMAIN_UPDATE_CURRENT_CONFIGURATION_REQUEST';
export const DOMAIN_UPDATE_CURRENT_CONFIGURATION_SUCCESS = 'domain/DOMAIN_UPDATE_CURRENT_CONFIGURATION_SUCCESS';
export const DOMAIN_UPDATE_CURRENT_CONFIGURATION_ERROR = 'domain/DOMAIN_UPDATE_CURRENT_CONFIGURATION_ERROR';

export const DOMAIN_RECORD_REQUEST = 'domain/DOMAIN_RECORD_REQUEST';
export const DOMAIN_RECORD_SUCCESS = 'domain/DOMAIN_RECORD_SUCCESS';
export const DOMAIN_RECORD_FAIL = 'domain/DOMAIN_RECORD_FAIL';

export const DOMAIN_WEB_RECORD_LIST_REQUEST = 'domain/DOMAIN_WEB_RECORD_LIST_REQUEST';
export const DOMAIN_WEB_RECORD_LIST_SUCCESS = 'domain/DOMAIN_WEB_RECORD_LIST_SUCCESS';
export const DOMAIN_WEB_RECORD_LIST_ERROR = 'domain/DOMAIN_WEB_RECORD_LIST_ERROR';

export const DOMAIN_WEB_RECORD_REQUEST = 'domain/DOMAIN_WEB_RECORD_REQUEST';
export const DOMAIN_WEB_RECORD_SUCCESS = 'domain/DOMAIN_WEB_RECORD_SUCCESS';
export const DOMAIN_WEB_RECORD_ERROR = 'domain/DOMAIN_WEB_RECORD_ERROR';

export const DOMAIN_WEB_RECORD_STORE = 'domain/DOMAIN_WEB_RECORD_STORE';
export const DOMAIN_WEB_RECORD_STORE_RESET = 'domain/DOMAIN_WEB_RECORD_STORE_RESET';

export const DOMAIN_EMAIL_RECORD_LIST_REQUEST = 'domain/DOMAIN_EMAIL_RECORD_LIST_REQUEST';
export const DOMAIN_EMAIL_RECORD_LIST_SUCCESS = 'domain/DOMAIN_EMAIL_RECORD_LIST_SUCCESS';
export const DOMAIN_EMAIL_RECORD_LIST_ERROR = 'domain/DOMAIN_EMAIL_RECORD_LIST_ERROR';

export const DOMAIN_EMAIL_RECORD_REQUEST = 'domain/DOMAIN_EMAIL_RECORD_REQUEST';
export const DOMAIN_EMAIL_RECORD_SUCCESS = 'domain/DOMAIN_EMAIL_RECORD_SUCCESS';
export const DOMAIN_EMAIL_RECORD_ERROR = 'domain/DOMAIN_EMAIL_RECORD_ERROR';

export const DOMAIN_EMAIL_RECORD_STORE = 'domain/DOMAIN_EMAIL_RECORD_STORE';
export const DOMAIN_EMAIL_RECORD_STORE_RESET = 'domain/DOMAIN_EMAIL_RECORD_STORE_RESET';

export const DOMAIN_THIRD_PARTY_REQUEST = 'domain/DOMAIN_THIRD_PARTY_REQUEST';
export const DOMAIN_THIRD_PARTY_SUCCESS = 'domain/DOMAIN_THIRD_PARTY_SUCCESS';
export const DOMAIN_THIRD_PARTY_ERROR = 'domain/DOMAIN_THIRD_PARTY_ERROR';

export const DOMAIN_CHILD_RECORD_LIST_REQUEST = 'domain/DOMAIN_CHILD_RECORD_LIST_REQUEST';
export const DOMAIN_CHILD_RECORD_LIST_SUCCESS = 'domain/DOMAIN_CHILD_RECORD_LIST_SUCCESS';
export const DOMAIN_CHILD_RECORD_LIST_ERROR = 'domain/DOMAIN_CHILD_RECORD_LIST_ERROR';

export const DOMAIN_CHILD_RECORD_REQUEST = 'domain/DOMAIN_CHILD_RECORD_REQUEST';
export const DOMAIN_CHILD_RECORD_SUCCESS = 'domain/DOMAIN_CHILD_RECORD_SUCCESS';
export const DOMAIN_CHILD_RECORD_ERROR = 'domain/DOMAIN_CHILD_RECORD_ERROR';

export const DOMAIN_CHILD_RECORD_DELETE_RESET = 'domain/DOMAIN_CHILD_RECORD_DELETE_RESET';
export const DOMAIN_CHILD_RECORD_DELETE_REQUEST = 'domain/DOMAIN_CHILD_RECORD_DELETE_REQUEST';
export const DOMAIN_CHILD_RECORD_DELETE_SUCCESS = 'domain/DOMAIN_CHILD_RECORD_DELETE_SUCCESS';
export const DOMAIN_CHILD_RECORD_DELETE_ERROR = 'domain/DOMAIN_CHILD_RECORD_DELETE_ERROR';

export const DOMAIN_DNSSEC_LIST_REQUEST = 'domain/DOMAIN_DNSSEC_LIST_REQUEST';
export const DOMAIN_DNSSEC_LIST_SUCCESS = 'domain/DOMAIN_DNSSEC_LIST_SUCCESS';
export const DOMAIN_DNSSEC_LIST_ERROR = 'domain/DOMAIN_DNSSEC_LIST_ERROR';

export const DOMAIN_DNSSEC_REQUEST = 'domain/DOMAIN_DNSSEC_REQUEST';
export const DOMAIN_DNSSEC_SUCCESS = 'domain/DOMAIN_DNSSEC_SUCCESS';
export const DOMAIN_DNSSEC_ERROR = 'domain/DOMAIN_DNSSEC_ERROR';

export const DOMAIN_DNSSEC_OPTIONS_REQUEST = 'domain/DOMAIN_DNSSEC_OPTIONS_REQUEST';
export const DOMAIN_DNSSEC_OPTIONS_SUCCESS = 'domain/DOMAIN_DNSSEC_OPTIONS_SUCCESS';
export const DOMAIN_DNSSEC_OPTIONS_ERROR = 'domain/DOMAIN_DNSSEC_OPTIONS_ERROR';

export const DOMAIN_COR_REQUEST = 'domain/DOMAIN_COR_REQUEST';
export const DOMAIN_COR_SUCCESS = 'domain/DOMAIN_COR_SUCCESS';
export const DOMAIN_COR_ERROR = 'domain/DOMAIN_COR_ERROR';

export const DOMAIN_ACTION_COR_REQUEST = 'domain/DOMAIN_ACTION_COR_REQUEST';
export const DOMAIN_ACTION_COR_SUCCESS = 'domain/DOMAIN_ACTION_COR_SUCCESS';
export const DOMAIN_ACTION_COR_ERROR = 'domain/DOMAIN_ACTION_COR_ERROR';

export const DOMAIN_ACTION_MANUAL_COR_REQUEST = 'domain/DOMAIN_ACTION_MANUAL_COR_REQUEST';
export const DOMAIN_ACTION_MANUAL_COR_SUCCESS = 'domain/DOMAIN_ACTION_MANUAL_COR_SUCCESS';
export const DOMAIN_ACTION_MANUAL_COR_ERROR = 'domain/DOMAIN_ACTION_MANUAL_COR_ERROR';

export const DOMAIN_ELIGIBILITY_REQUEST = 'domain/DOMAIN_ELIGIBILITY_REQUEST';
export const DOMAIN_ELIGIBILITY_SUCCESS = 'domain/DOMAIN_ELIGIBILITY_SUCCESS';
export const DOMAIN_ELIGIBILITY_ERROR = 'domain/DOMAIN_ELIGIBILITY_ERROR';

export const DOMAIN_MOVE_REQUEST = 'domain/DOMAIN_MOVE_REQUEST';
export const DOMAIN_MOVE_SUCCESS = 'domain/DOMAIN_MOVE_SUCCESS';
export const DOMAIN_MOVE_ERROR = 'domain/DOMAIN_MOVE_ERROR';

export const DOMAIN_PROTECTION_REQUEST = 'domain/DOMAIN_PROTECTION_REQUEST';
export const DOMAIN_PROTECTION_SUCCESS = 'domain/DOMAIN_PROTECTION_SUCCESS';
export const DOMAIN_PROTECTION_ERROR = 'domain/DOMAIN_PROTECTION_ERROR';

export const DOMAIN_PROTECTION_CHECK_REQUEST = 'domain/DOMAIN_PROTECTION_CHECK_REQUEST';
export const DOMAIN_PROTECTION_CHECK_SUCCESS = 'domain/DOMAIN_PROTECTION_CHECK_SUCCESS';
export const DOMAIN_PROTECTION_CHECK_ERROR = 'domain/DOMAIN_PROTECTION_CHECK_ERROR';

export const DOMAIN_WHOIS_REQUEST = 'domain/DOMAIN_WHOIS_REQUEST';
export const DOMAIN_WHOIS_SUCCESS = 'domain/DOMAIN_WHOIS_SUCCESS';
export const DOMAIN_WHOIS_ERROR = 'domain/DOMAIN_WHOIS_ERROR';

export const DOMAIN_LOCK_REQUEST = 'domain/DOMAIN_LOCK_REQUEST';
export const DOMAIN_LOCK_SUCCESS = 'domain/DOMAIN_LOCK_SUCCESS';
export const DOMAIN_LOCK_ERROR = 'domain/DOMAIN_LOCK_ERROR';

export const DOMAIN_LOCK_CHECK_REQUEST = 'domain/DOMAIN_LOCK_CHECK_REQUEST';
export const DOMAIN_LOCK_CHECK_SUCCESS = 'domain/DOMAIN_LOCK_CHECK_SUCCESS';
export const DOMAIN_LOCK_CHECK_ERROR = 'domain/DOMAIN_LOCK_CHECK_ERROR';

export const DOMAIN_EPP_REQUEST = 'domain/DOMAIN_EPP_REQUEST';
export const DOMAIN_EPP_SUCCESS = 'domain/DOMAIN_EPP_SUCCESS';
export const DOMAIN_EPP_ERROR = 'domain/DOMAIN_EPP_ERROR';

export const DOMAIN_SUBACCOUNT_REQUEST = 'domain/DOMAIN_SUBACCOUNT_REQUEST';
export const DOMAIN_SUBACCOUNT_SUCCESS = 'domain/DOMAIN_SUBACCOUNT_SUCCESS';
export const DOMAIN_SUBACCOUNT_FAIL = 'domain/DOMAIN_SUBACCOUNT_FAIL';

export const DOMAIN_ADD_HOSTING_CALCULATE_REQUEST = 'domain/DOMAIN_ADD_HOSTING_CALCULATE_REQUEST';
export const DOMAIN_ADD_HOSTING_CALCULATE_SUCCESS = 'domain/DOMAIN_ADD_HOSTING_CALCULATE_SUCCESS';
export const DOMAIN_ADD_HOSTING_CALCULATE_ERROR = 'domain/DOMAIN_ADD_HOSTING_CALCULATE_ERROR';

export const DOMAIN_GET_COR_PRICE_REQUEST = 'domain/DOMAIN_GET_COR_PRICE_REQUEST';
export const DOMAIN_GET_COR_PRICE_SUCCESS = 'domain/DOMAIN_GET_COR_PRICE_SUCCESS';
export const DOMAIN_GET_COR_PRICE_ERROR = 'domain/DOMAIN_GET_COR_PRICE_ERROR';

export const DOMAIN_DNSSEC_ENABLE_REQUEST = 'domain/DOMAIN_DNSSEC_ENABLE_REQUEST';
export const DOMAIN_DNSSEC_ENABLE_SUCCESS = 'domain/DOMAIN_DNSSEC_ENABLE_SUCCESS';
export const DOMAIN_DNSSEC_ENABLE_ERROR = 'domain/DOMAIN_DNSSEC_ENABLE_ERROR';

export const DOMAIN_DNSSEC_DISABLE_REQUEST = 'domain/DOMAIN_DNSSEC_DISABLE_REQUEST';
export const DOMAIN_DNSSEC_DISABLE_SUCCESS = 'domain/DOMAIN_DNSSEC_DISABLE_SUCCESS';
export const DOMAIN_DNSSEC_DISABLE_ERROR = 'domain/DOMAIN_DNSSEC_DISABLE_ERROR';

export const DOMAIN_DNSSEC_ENABLED_STATUS_REQUEST = 'domain/DOMAIN_DNSSEC_ENABLED_STATUS_REQUEST';
export const DOMAIN_DNSSEC_ENABLED_STATUS_SUCCESS = 'domain/DOMAIN_DNSSEC_ENABLED_STATUS_SUCCESS';
export const DOMAIN_DNSSEC_ENABLED_STATUS_ERROR = 'domain/DOMAIN_DNSSEC_ENABLED_STATUS_ERROR';

export const DOMAIN_PREVIEW_PRESET_CHANGES_REQUEST = 'domain/DOMAIN_PREVIEW_PRESET_CHANGES_REQUEST';
export const DOMAIN_PREVIEW_PRESET_CHANGES_SUCCESS = 'domain/DOMAIN_PREVIEW_PRESET_CHANGES_SUCCESS';
export const DOMAIN_PREVIEW_PRESET_CHANGES_ERROR = 'domain/DOMAIN_PREVIEW_PRESET_CHANGES_ERROR';
