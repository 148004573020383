import React, { useEffect } from 'react';
import { useController } from 'react-hook-form';
import { _ColourSelect as ColourSelect } from 'components/Form/ColourSelect/_ColourSelect';
import { webpageColourThemeFormFieldsEnum } from 'containers/katana/containers/contentEditorModules/webpageColourTheme/consts';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { ColourSelectNamespace } from 'components/Form/ColourSelect/types';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const { CUSTOM_COLOR } = webpageColourThemeFormFieldsEnum;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _ColourSelectHookFormDefault: React.FC<ColourSelectNamespace.HookForm.Props> = ({ name, options, preventDeselect }) => {
    /***** HOOKS *****/
    const { field: colourField } = useController({ name });
    const { value: colourValue, onChange: colorOnChange } = colourField;

    const { field: customColourField } = useController({ name: CUSTOM_COLOR });
    const { onChange: customColourOnChange, value: customColourValue } = customColourField;

    function _onChange(val: string | null) {
        if (val && val !== 'custom') {
            customColourOnChange(null);
            colorOnChange(val);
        } else {
            colorOnChange(null);
        }
    }

    useEffect(() => {
        if (customColourValue) {
            colorOnChange('custom');
        }
    }, [customColourValue]);

    /***** RENDER *****/
    return <ColourSelect value={colourValue} onChange={_onChange} options={options} preventDeselect={preventDeselect} />;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
