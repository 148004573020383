import { useMatches } from '@tanstack/react-router';
import classNames from 'classnames';
import KatanaLoaderImage from 'components/Loaders/App/katanaLoaderImage';
import { IntaserveSvg } from 'components/Loaders/App/svgAnimations/intaserve';
import { VentraSvg } from 'components/Loaders/App/svgAnimations/ventra';
import type { TextNamespace } from 'components/Utils/Text/_Text/types';
import { useBrandStore } from 'config/hooks/useBrandStore';
import React from 'react';
import './_AppLoader.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
interface AppLoaderProps {
    className?: string;
    message?: string | false;
    fullHeight?: boolean;
    backgroundColor?: TextNamespace.Props['color'];
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

const AppLoader: React.FC<AppLoaderProps> = ({ className, message, fullHeight, backgroundColor }) => {
    /***** HOOKS *****/
    const { activeBrand } = useBrandStore();

    /***** RENDER HELPERS *****/
    const loaderType = useMatches({
        select: (matches) => {
            const isVIPSitesRoute = matches.some((match) => match.fullPath === '/my-services/vip-sites');
            const isStaffLoginRoute = matches.some((match) => match.fullPath === '/staff-login/$staffLoginToken');

            if (isVIPSitesRoute) {
                return 'vip-sites';
            }
            if (isStaffLoginRoute) {
                return 'staff-login';
            }

            return 'normal';
        }
    });

    const getLoader = () => {
        if (loaderType === 'vip-sites') {
            return <KatanaLoaderImage />;
        }

        switch (activeBrand) {
            case 'intaserve':
                return (
                    <div className="appLoader__svgContainer">
                        <IntaserveSvg backgroundColor={backgroundColor || 'S_color_background_default_base'} />
                    </div>
                );
            case 'ventra':
                return (
                    <div className="appLoader__svgContainer">
                        <VentraSvg />
                    </div>
                );
        }
    };

    /***** RENDER *****/
    return (
        <div className={classNames('appLoader', className, { 'appLoader--fullHeight': fullHeight })}>
            {getLoader()}
            <div className="loader__text">{loaderType === 'staff-login' ? 'You are logging in as a staff member' : Boolean(message) && message}</div>
        </div>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default AppLoader;
