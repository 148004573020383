import {
    ACCOUNT_ADDITIONAL_USER_LIST_ERROR,
    ACCOUNT_ADDITIONAL_USER_LIST_REQUEST,
    ACCOUNT_ADDITIONAL_USER_LIST_SUCCESS,
    ACCOUNT_ADD_ADDITIONAL_USER_ERROR,
    ACCOUNT_ADD_ADDITIONAL_USER_REQUEST,
    ACCOUNT_ADD_ADDITIONAL_USER_SUCCESS,
    ACCOUNT_ADD_CONTACT_ERROR,
    ACCOUNT_ADD_CONTACT_REQUEST,
    ACCOUNT_ADD_CONTACT_SUCCESS,
    ACCOUNT_APPROVE_ADDITIONAL_USER_ERROR,
    ACCOUNT_APPROVE_ADDITIONAL_USER_REQUEST,
    ACCOUNT_APPROVE_ADDITIONAL_USER_RESET,
    ACCOUNT_APPROVE_ADDITIONAL_USER_SIGNUP_ERROR,
    ACCOUNT_APPROVE_ADDITIONAL_USER_SIGNUP_REQUEST,
    ACCOUNT_APPROVE_ADDITIONAL_USER_SIGNUP_SUCCESS,
    ACCOUNT_APPROVE_ADDITIONAL_USER_SUCCESS,
    ACCOUNT_CANCEL_INVITATION_ERROR,
    ACCOUNT_CANCEL_INVITATION_REQUEST,
    ACCOUNT_CANCEL_INVITATION_SUCCESS,
    ACCOUNT_CLOSE_ERROR,
    ACCOUNT_CLOSE_REQUEST,
    ACCOUNT_CLOSE_SUCCESS,
    ACCOUNT_CONTACT_LIST_ERROR,
    ACCOUNT_CONTACT_LIST_REQUEST,
    ACCOUNT_CONTACT_LIST_SUCCESS,
    ACCOUNT_EMAILS_ERROR,
    ACCOUNT_EMAILS_REQUEST,
    ACCOUNT_EMAILS_RESEND_ERROR,
    ACCOUNT_EMAILS_RESEND_REQUEST,
    ACCOUNT_EMAILS_RESEND_SUCCESS,
    ACCOUNT_EMAILS_SEARCH_ERROR,
    ACCOUNT_EMAILS_SEARCH_REQUEST,
    ACCOUNT_EMAILS_SEARCH_RESET,
    ACCOUNT_EMAILS_SEARCH_SUCCESS,
    ACCOUNT_EMAILS_SUCCESS,
    ACCOUNT_EMAILS_VIEW_ERROR,
    ACCOUNT_EMAILS_VIEW_REQUEST,
    ACCOUNT_EMAILS_VIEW_SUCCESS,
    ACCOUNT_END_SESSIONS_ERROR,
    ACCOUNT_END_SESSIONS_REQUEST,
    ACCOUNT_END_SESSIONS_SUCCESS,
    ACCOUNT_END_SPECIFIC_ERROR,
    ACCOUNT_END_SPECIFIC_REQUEST,
    ACCOUNT_END_SPECIFIC_SUCCESS,
    ACCOUNT_EXPIRED_HISTORY_ERROR,
    ACCOUNT_EXPIRED_HISTORY_REQUEST,
    ACCOUNT_EXPIRED_HISTORY_SUCCESS,
    ACCOUNT_INITIAL_STATE,
    ACCOUNT_LAST_UPDATE_PASSWORD_ERROR,
    ACCOUNT_LAST_UPDATE_PASSWORD_REQUEST,
    ACCOUNT_LAST_UPDATE_PASSWORD_SUCCESS,
    ACCOUNT_LOGIN_HISTORY_ERROR,
    ACCOUNT_LOGIN_HISTORY_REQUEST,
    ACCOUNT_LOGIN_HISTORY_SUCCESS,
    ACCOUNT_PERMISSION_ERROR,
    ACCOUNT_PERMISSION_REQUEST,
    ACCOUNT_PERMISSION_SUCCESS,
    ACCOUNT_PROVIDE_MISSING_DETAILS_ERROR,
    ACCOUNT_PROVIDE_MISSING_DETAILS_REQUEST,
    ACCOUNT_PROVIDE_MISSING_DETAILS_SUCCESS,
    ACCOUNT_QR_CODE_ERROR,
    ACCOUNT_QR_CODE_REQUEST,
    ACCOUNT_QR_CODE_SUCCESS,
    ACCOUNT_REMOVE_ADDITIONAL_USER_ERROR,
    ACCOUNT_REMOVE_ADDITIONAL_USER_REQUEST,
    ACCOUNT_REMOVE_ADDITIONAL_USER_SUCCESS,
    ACCOUNT_REMOVE_CONTACT_ERROR,
    ACCOUNT_REMOVE_CONTACT_REQUEST,
    ACCOUNT_REMOVE_CONTACT_SUCCESS,
    ACCOUNT_RESEND_INVITATION_ERROR,
    ACCOUNT_RESEND_INVITATION_REQUEST,
    ACCOUNT_RESEND_INVITATION_SUCCESS,
    ACCOUNT_REVOKE_ACCESS_ERROR,
    ACCOUNT_REVOKE_ACCESS_REQUEST,
    ACCOUNT_REVOKE_ACCESS_SUCCESS,
    ACCOUNT_SECURITY_INFORMATION_ERROR,
    ACCOUNT_SECURITY_INFORMATION_REQUEST,
    ACCOUNT_SECURITY_INFORMATION_SUCCESS,
    ACCOUNT_SECURITY_QUESTIONS_ERROR,
    ACCOUNT_SECURITY_QUESTIONS_REQUEST,
    ACCOUNT_SECURITY_QUESTIONS_SUCCESS,
    ACCOUNT_SECURITY_UPDATE_ERROR,
    ACCOUNT_SECURITY_UPDATE_REQUEST,
    ACCOUNT_SECURITY_UPDATE_SUCCESS,
    ACCOUNT_SLA_CLAIM_EVENTS_ERROR,
    ACCOUNT_SLA_CLAIM_EVENTS_REQUEST,
    ACCOUNT_SLA_CLAIM_EVENTS_SUCCESS,
    ACCOUNT_SLA_CLAIM_SERVICES_ERROR,
    ACCOUNT_SLA_CLAIM_SERVICES_REQUEST,
    ACCOUNT_SLA_CLAIM_SERVICES_SUCCESS,
    ACCOUNT_SLA_CLAIM_SUBMIT_ERROR,
    ACCOUNT_SLA_CLAIM_SUBMIT_REQUEST,
    ACCOUNT_SLA_CLAIM_SUBMIT_SUCCESS,
    ACCOUNT_SUBSCRIPTION_ERROR,
    ACCOUNT_SUBSCRIPTION_REQUEST,
    ACCOUNT_SUBSCRIPTION_SUCCESS,
    ACCOUNT_TWOFACTOR_CONFIG_ERROR,
    ACCOUNT_TWOFACTOR_CONFIG_REQUEST,
    ACCOUNT_TWOFACTOR_CONFIG_SUCCESS,
    ACCOUNT_TWOFACTOR_DELETE_ERROR,
    ACCOUNT_TWOFACTOR_DELETE_REQUEST,
    ACCOUNT_TWOFACTOR_DELETE_SUCCESS,
    ACCOUNT_TWOFACTOR_ENABLE_ERROR,
    ACCOUNT_TWOFACTOR_ENABLE_REQUEST,
    ACCOUNT_TWOFACTOR_ENABLE_SUCCESS,
    ACCOUNT_TWOFACTOR_ERROR,
    ACCOUNT_TWOFACTOR_PRIMARY_ERROR,
    ACCOUNT_TWOFACTOR_PRIMARY_REQUEST,
    ACCOUNT_TWOFACTOR_PRIMARY_SUCCESS,
    ACCOUNT_TWOFACTOR_REQUEST,
    ACCOUNT_TWOFACTOR_SUCCESS,
    ACCOUNT_TWOFACTOR_VERIFY_ERROR,
    ACCOUNT_TWOFACTOR_VERIFY_REQUEST,
    ACCOUNT_TWOFACTOR_VERIFY_SUCCESS,
    ACCOUNT_UPDATE_ADDITIONAL_USER_PERMISSION_ERROR,
    ACCOUNT_UPDATE_ADDITIONAL_USER_PERMISSION_REQUEST,
    ACCOUNT_UPDATE_ADDITIONAL_USER_PERMISSION_SUCCESS,
    ACCOUNT_UPDATE_CLIENT_TYPE_ERROR,
    ACCOUNT_UPDATE_CLIENT_TYPE_REQUEST,
    ACCOUNT_UPDATE_CLIENT_TYPE_SUCCESS,
    ACCOUNT_UPDATE_CONTACT_ERROR,
    ACCOUNT_UPDATE_CONTACT_REQUEST,
    ACCOUNT_UPDATE_CONTACT_SUCCESS,
    ACCOUNT_UPDATE_EMAIL_ERROR,
    ACCOUNT_UPDATE_EMAIL_REQUEST,
    ACCOUNT_UPDATE_EMAIL_SUCCESS,
    ACCOUNT_UPDATE_PASSWORD_ERROR,
    ACCOUNT_UPDATE_PASSWORD_REQUEST,
    ACCOUNT_UPDATE_PASSWORD_SUCCESS,
    ACCOUNT_UPDATE_USER_ERROR,
    ACCOUNT_UPDATE_USER_REQUEST,
    ACCOUNT_UPDATE_USER_SUCCESS,
    ACCOUNT_USER_ERROR,
    ACCOUNT_USER_REQUEST,
    ACCOUNT_USER_SUCCESS
} from './actionTypes';

const initialState = {
    account_user_status: null,
    account_user_data: null,
    account_user_error: null,
    account_update_email_status: null,
    account_update_email_data: null,
    account_update_email_error: null,
    account_update_client_type_status: null,
    account_update_client_type_data: null,
    account_update_client_type_error: null,
    account_contact_list_status: null,
    account_contact_list_data: null,
    account_contact_list_error: null,
    account_add_contact_status: null,
    account_add_contact_data: null,
    account_add_contact_error: null,
    account_update_contact_status: null,
    account_update_contact_data: null,
    account_update_contact_error: null,
    account_remove_contact_status: null,
    account_remove_contact_data: null,
    account_remove_contact_error: null,
    account_update_user_status: null,
    account_update_user_data: null,
    account_permission_status: null,
    account_permission_data: null,
    account_permission_error: null,
    account_additional_user_list_status: null,
    account_additional_user_list_data: null,
    account_additional_user_list_error: null,
    account_add_additional_user_status: null,
    account_add_additional_user_data: null,
    account_add_additional_user_error: null,
    account_remove_additional_user_status: null,
    account_remove_additional_user_data: null,
    account_remove_additional_user_error: null,
    account_resend_invitation_status: null,
    account_resend_invitation_data: null,
    account_resend_invitation_error: null,
    account_cancel_invitation_status: null,
    account_cancel_invitation_data: null,
    account_cancel_invitation_error: null,
    account_revoke_access_status: null,
    account_revoke_access_data: null,
    account_revoke_access_error: null,
    account_update_additional_user_permission_status: null,
    account_update_additional_user_permission_data: null,
    account_update_additional_user_permission_error: null,
    account_approve_additional_user_status: null,
    account_approve_additional_user_data: null,
    account_approve_additional_user_error: null,
    account_approve_additional_user_signup_status: null,
    account_approve_additional_user_signup_data: null,
    account_approve_additional_user_signup_error: null,
    account_sla_claim_services_status: null,
    account_sla_claim_services_data: null,
    account_sla_claim_services_error: null,
    account_sla_claim_events_status: null,
    account_sla_claim_events_data: null,
    account_sla_claim_events_error: null,
    account_sla_claim_submit_status: null,
    account_sla_claim_submit_data: null,
    account_sla_claim_submit_error: null,
    account_subscription_status: null,
    account_subscription_data: null,
    account_subscription_error: null,
    account_emails_status: null,
    account_emails_data: null,
    account_emails_error: null,
    account_emails_meta: null,
    account_emails_search_status: null,
    account_emails_search_data: null,
    account_emails_search_error: null,
    account_emails_view_status: null,
    account_emails_view_data: null,
    account_emails_view_error: null,
    account_update_password_status: null,
    account_update_password_data: null,
    account_update_password_error: null,
    account_last_update_password_status: null,
    account_last_update_password_data: null,
    account_last_update_password_error: null,
    account_security_information_status: null,
    account_security_information_data: null,
    account_security_information_error: null,
    account_security_questions_status: null,
    account_security_questions_data: null,
    account_security_questions_error: null,
    account_security_update_status: null,
    account_security_update_data: null,
    account_security_update_error: null,
    account_twofactor_status: null,
    account_twofactor_data: null,
    account_twofactor_error: null,
    account_twofactor_enable_status: null,
    account_twofactor_enable_data: null,
    account_twofactor_enable_error: null,
    account_twofactor_config_status: null,
    account_twofactor_config_data: null,
    account_twofactor_config_error: null,
    account_twofactor_verify_status: null,
    account_twofactor_verify_data: null,
    account_twofactor_verify_error: null,
    account_delete_twofactor_status: null,
    account_delete_twofactor_data: null,
    account_delete_twofactor_error: null,
    account_primary_twofactor_status: null,
    account_primary_twofactor_data: null,
    account_primary_twofactor_error: null,
    account_active_history_status: null,
    account_active_history_data: null,
    account_active_history_error: null,
    account_end_sessions_status: null,
    account_end_sessions_data: null,
    account_end_sessions_error: null,
    account_end_specific_status: null,
    account_end_specific_data: null,
    account_end_specific_error: null,
    account_close_status: null,
    account_close_data: null,
    account_close_error: null
};

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        /**********************************************************************************************************
         *   RESET
         **********************************************************************************************************/
        case ACCOUNT_INITIAL_STATE:
            return initialState;

        /**********************************************************************************************************
         *   USER
         **********************************************************************************************************/
        case ACCOUNT_USER_REQUEST:
            return {
                ...state,
                account_user_status: 'loading',
                account_user_data: null,
                account_user_error: null
            };

        case ACCOUNT_USER_SUCCESS:
            return {
                ...state,
                account_user_status: 'success',
                account_user_data: action.account_user_data
            };

        case ACCOUNT_USER_ERROR:
            return {
                ...state,
                account_user_status: 'error',
                account_user_error: action.account_user_error
            };

        case ACCOUNT_UPDATE_USER_REQUEST:
            return {
                ...state,
                account_update_user_status: 'loading',
                account_update_user_data: null
            };

        case ACCOUNT_UPDATE_USER_SUCCESS:
            return {
                ...state,
                account_update_user_status: 'success',
                account_update_user_data: action.account_update_user_data
            };

        case ACCOUNT_UPDATE_USER_ERROR:
            return {
                ...state,
                account_update_user_status: 'error'
            };

        /**********************************************************************************************************
         *   PROVIDE MISSING DETAILS
         **********************************************************************************************************/
        case ACCOUNT_PROVIDE_MISSING_DETAILS_REQUEST:
            return {
                ...state,
                account_provide_missing_details_status: 'loading'
            };

        case ACCOUNT_PROVIDE_MISSING_DETAILS_SUCCESS:
            return {
                ...state,
                account_provide_missing_details_status: 'success'
            };

        case ACCOUNT_PROVIDE_MISSING_DETAILS_ERROR:
            return {
                ...state,
                account_provide_missing_details_status: 'error'
            };

        /**********************************************************************************************************
         *   EMAIL
         **********************************************************************************************************/
        case ACCOUNT_UPDATE_EMAIL_REQUEST:
            return {
                ...state,
                account_update_email_status: 'loading',
                account_update_email_data: null,
                account_update_email_error: null
            };

        case ACCOUNT_UPDATE_EMAIL_SUCCESS:
            return {
                ...state,
                account_update_email_status: 'success',
                account_update_email_data: action.account_update_email_data
            };

        case ACCOUNT_UPDATE_EMAIL_ERROR:
            return {
                ...state,
                account_update_email_status: 'error',
                account_update_email_error: action.account_update_email_error
            };

        /**********************************************************************************************************
         *   CLIENT TYPE
         **********************************************************************************************************/
        case ACCOUNT_UPDATE_CLIENT_TYPE_REQUEST:
            return {
                ...state,
                account_update_client_type_status: 'loading',
                account_update_client_type_data: null,
                account_update_client_type_error: null
            };

        case ACCOUNT_UPDATE_CLIENT_TYPE_SUCCESS:
            return {
                ...state,
                account_update_client_type_status: 'success',
                account_update_client_type_data: action.account_update_client_type_data
            };

        case ACCOUNT_UPDATE_CLIENT_TYPE_ERROR:
            return {
                ...state,
                account_update_client_type_status: 'error',
                account_update_client_type_error: action.account_update_client_type_error
            };
        /**********************************************************************************************************
         *   CONTACTS
         **********************************************************************************************************/
        case ACCOUNT_CONTACT_LIST_REQUEST:
            return {
                ...state,
                account_contact_list_status: 'loading',
                account_contact_list_data: null,
                account_contact_list_error: null
            };

        case ACCOUNT_CONTACT_LIST_SUCCESS:
            return {
                ...state,
                account_contact_list_status: 'success',
                account_contact_list_data: action.account_contact_list_data
            };

        case ACCOUNT_CONTACT_LIST_ERROR:
            return {
                ...state,
                account_contact_list_status: 'error',
                account_contact_list_error: action.account_contact_list_error
            };

        case ACCOUNT_ADD_CONTACT_REQUEST:
            return {
                ...state,
                account_add_contact_status: 'loading',
                account_add_contact_data: null,
                account_add_contact_error: null
            };

        case ACCOUNT_ADD_CONTACT_SUCCESS:
            return {
                ...state,
                account_add_contact_status: 'success',
                account_add_contact_data: action.account_add_contact_data
            };

        case ACCOUNT_ADD_CONTACT_ERROR:
            return {
                ...state,
                account_add_contact_status: 'error',
                account_add_contact_error: action.account_add_contact_error
            };

        case ACCOUNT_UPDATE_CONTACT_REQUEST:
            return {
                ...state,
                account_update_contact_status: 'loading',
                account_update_contact_data: null,
                account_update_contact_error: null
            };

        case ACCOUNT_UPDATE_CONTACT_SUCCESS:
            return {
                ...state,
                account_update_contact_status: 'success',
                account_update_contact_data: action.account_update_contact_data
            };

        case ACCOUNT_UPDATE_CONTACT_ERROR:
            return {
                ...state,
                account_update_contact_status: 'error',
                account_update_contact_error: action.account_update_contact_error
            };

        case ACCOUNT_REMOVE_CONTACT_REQUEST:
            return {
                ...state,
                account_remove_contact_status: 'loading',
                account_remove_contact_data: null,
                account_remove_contact_error: null
            };

        case ACCOUNT_REMOVE_CONTACT_SUCCESS:
            return {
                ...state,
                account_remove_contact_status: 'success',
                account_remove_contact_data: action.account_remove_contact_data
            };

        case ACCOUNT_REMOVE_CONTACT_ERROR:
            return {
                ...state,
                account_remove_contact_status: 'error',
                account_remove_contact_error: action.account_remove_contact_error
            };

        /**********************************************************************************************************
         *   PERMISSION
         **********************************************************************************************************/
        case ACCOUNT_PERMISSION_REQUEST:
            return {
                ...state,
                account_update_contact_status: 'loading',
                account_update_contact_data: null,
                account_update_contact_error: null
            };

        case ACCOUNT_PERMISSION_SUCCESS:
            return {
                ...state,
                account_update_contact_status: 'success',
                account_update_contact_data: action.account_update_contact_data
            };

        case ACCOUNT_PERMISSION_ERROR:
            return {
                ...state,
                account_update_contact_status: 'error',
                account_update_contact_error: action.account_update_contact_error
            };

        /**********************************************************************************************************
         *   ADDITIONAL USERS
         **********************************************************************************************************/
        case ACCOUNT_ADDITIONAL_USER_LIST_REQUEST:
            return {
                ...state,
                account_additional_user_list_status: 'loading',
                account_additional_user_list_data: null,
                account_additional_user_list_error: null
            };

        case ACCOUNT_ADDITIONAL_USER_LIST_SUCCESS:
            return {
                ...state,
                account_additional_user_list_status: 'success',
                account_additional_user_list_data: action.account_additional_user_list_data
            };

        case ACCOUNT_ADDITIONAL_USER_LIST_ERROR:
            return {
                ...state,
                account_additional_user_list_status: 'error',
                account_additional_user_list_error: action.account_additional_user_list_error
            };

        case ACCOUNT_ADD_ADDITIONAL_USER_REQUEST:
            return {
                ...state,
                account_add_additional_user_status: 'loading',
                account_add_additional_user_data: null,
                account_add_additional_user_error: null
            };

        case ACCOUNT_ADD_ADDITIONAL_USER_SUCCESS:
            return {
                ...state,
                account_add_additional_user_status: 'success',
                account_add_additional_user_data: action.account_add_additional_user_data
            };

        case ACCOUNT_ADD_ADDITIONAL_USER_ERROR:
            return {
                ...state,
                account_add_additional_user_status: 'error',
                account_add_additional_user_error: action.account_add_additional_user_error
            };

        case ACCOUNT_UPDATE_ADDITIONAL_USER_PERMISSION_REQUEST:
            return {
                ...state,
                account_update_additional_user_permission_status: 'loading',
                account_update_additional_user_permission_data: null,
                account_update_additional_user_permission_error: null
            };

        case ACCOUNT_UPDATE_ADDITIONAL_USER_PERMISSION_SUCCESS:
            return {
                ...state,
                account_update_additional_user_permission_status: 'success',
                account_update_additional_user_permission_data: action.account_update_additional_user_data
            };

        case ACCOUNT_UPDATE_ADDITIONAL_USER_PERMISSION_ERROR:
            return {
                ...state,
                account_update_additional_user_permission_status: 'error',
                account_update_additional_user_permission_error: action.account_update_additional_user_error
            };

        case ACCOUNT_REMOVE_ADDITIONAL_USER_REQUEST:
            return {
                ...state,
                account_remove_additional_user_status: 'loading',
                account_remove_additional_user_data: null,
                account_remove_additional_user_error: null
            };

        case ACCOUNT_REMOVE_ADDITIONAL_USER_SUCCESS:
            return {
                ...state,
                account_remove_additional_user_status: 'success',
                account_remove_additional_user_data: action.account_remove_additional_user_data
            };

        case ACCOUNT_REMOVE_ADDITIONAL_USER_ERROR:
            return {
                ...state,
                account_remove_additional_user_status: 'error',
                account_remove_additional_user_error: action.account_remove_additional_user_error
            };

        case ACCOUNT_RESEND_INVITATION_REQUEST:
            return {
                ...state,
                account_resend_invitation_status: 'loading',
                account_resend_invitation_data: null,
                account_resend_invitation_error: null
            };

        case ACCOUNT_RESEND_INVITATION_SUCCESS:
            return {
                ...state,
                account_resend_invitation_status: 'success',
                account_resend_invitation_data: action.account_resend_invitation_data
            };

        case ACCOUNT_RESEND_INVITATION_ERROR:
            return {
                ...state,
                account_resend_invitation_status: 'error',
                account_resend_invitation_error: action.account_resend_invitation_error
            };

        case ACCOUNT_CANCEL_INVITATION_REQUEST:
            return {
                ...state,
                account_cancel_invitation_status: 'loading',
                account_cancel_invitation_data: null,
                account_cancel_invitation_error: null
            };

        case ACCOUNT_CANCEL_INVITATION_SUCCESS:
            return {
                ...state,
                account_cancel_invitation_status: 'success',
                account_cancel_invitation_data: action.account_cancel_invitation_data
            };

        case ACCOUNT_CANCEL_INVITATION_ERROR:
            return {
                ...state,
                account_cancel_invitation_status: 'error',
                account_cancel_invitation_error: action.account_cancel_invitation_error
            };

        case ACCOUNT_APPROVE_ADDITIONAL_USER_REQUEST:
            return {
                ...state,
                account_approve_additional_user_status: 'loading',
                account_approve_additional_user_data: null,
                account_approve_additional_user_error: null
            };

        case ACCOUNT_APPROVE_ADDITIONAL_USER_SUCCESS:
            return {
                ...state,
                account_approve_additional_user_status: 'success',
                account_approve_additional_user_data: action.account_approve_additional_user_data,
                account_approve_additional_user_error: null
            };

        case ACCOUNT_APPROVE_ADDITIONAL_USER_ERROR:
            return {
                ...state,
                account_approve_additional_user_status: 'error',
                account_approve_additional_user_error: action.account_approve_additional_user_error,
                account_approve_additional_user_data: null
            };

        case ACCOUNT_APPROVE_ADDITIONAL_USER_RESET:
            return {
                ...state,
                account_approve_additional_user_status: null,
                account_approve_additional_user_error: null,
                account_approve_additional_user_data: null
            };

        case ACCOUNT_APPROVE_ADDITIONAL_USER_SIGNUP_REQUEST:
            return {
                ...state,
                account_approve_additional_user_signup_status: 'loading',
                account_approve_additional_user_signup_data: null,
                account_approve_additional_user_signup_error: null
            };

        case ACCOUNT_APPROVE_ADDITIONAL_USER_SIGNUP_SUCCESS:
            return {
                ...state,
                account_approve_additional_user_signup_status: 'success',
                account_approve_additional_user_signup_data: action.account_approve_additional_user_signup_data,
                account_approve_additional_user_signup_error: null
            };

        case ACCOUNT_APPROVE_ADDITIONAL_USER_SIGNUP_ERROR:
            return {
                ...state,
                account_approve_additional_user_signup_status: 'error',
                account_approve_additional_user_signup_error: action.account_approve_additional_user_signup_error,
                account_approve_additional_user_signup_data: null
            };

        case ACCOUNT_REVOKE_ACCESS_REQUEST:
            return {
                ...state,
                account_revoke_access_status: 'loading',
                account_revoke_access_data: null,
                account_revoke_access_error: null
            };

        case ACCOUNT_REVOKE_ACCESS_SUCCESS:
            return {
                ...state,
                account_revoke_access_status: 'success',
                account_revoke_access_data: action.account_revoke_access_data
            };

        case ACCOUNT_REVOKE_ACCESS_ERROR:
            return {
                ...state,
                account_revoke_access_status: 'error',
                account_revoke_access_error: action.account_revoke_access_error
            };

        /**********************************************************************************************************
         *   SLA
         **********************************************************************************************************/
        case ACCOUNT_SLA_CLAIM_SERVICES_REQUEST:
            return {
                ...state,
                account_sla_claim_services_status: 'loading',
                account_sla_claim_services_data: null,
                account_sla_claim_services_error: null
            };

        case ACCOUNT_SLA_CLAIM_SERVICES_SUCCESS:
            return {
                ...state,
                account_sla_claim_services_status: 'success',
                account_sla_claim_services_data: action.account_sla_claim_services_data
            };

        case ACCOUNT_SLA_CLAIM_SERVICES_ERROR:
            return {
                ...state,
                account_sla_claim_services_status: 'error',
                account_sla_claim_services_error: action.account_sla_claim_services_error
            };

        case ACCOUNT_SLA_CLAIM_EVENTS_REQUEST:
            return {
                ...state,
                account_sla_claim_events_status: 'loading',
                account_sla_claim_events_data: null,
                account_sla_claim_events_error: null
            };

        case ACCOUNT_SLA_CLAIM_EVENTS_SUCCESS:
            return {
                ...state,
                account_sla_claim_events_status: 'success',
                account_sla_claim_events_data: action.account_sla_claim_events_data
            };

        case ACCOUNT_SLA_CLAIM_EVENTS_ERROR:
            return {
                ...state,
                account_sla_claim_events_status: 'error',
                account_sla_claim_events_error: action.account_sla_claim_events_error
            };

        case ACCOUNT_SLA_CLAIM_SUBMIT_REQUEST:
            return {
                ...state,
                account_sla_claim_submit_status: 'loading',
                account_sla_claim_submit_data: null,
                account_sla_claim_submit_error: null
            };

        case ACCOUNT_SLA_CLAIM_SUBMIT_SUCCESS:
            return {
                ...state,
                account_sla_claim_submit_status: 'success',
                account_sla_claim_submit_data: action.account_sla_claim_submit_data
            };

        case ACCOUNT_SLA_CLAIM_SUBMIT_ERROR:
            return {
                ...state,
                account_sla_claim_submit_status: 'error',
                account_sla_claim_submit_error: action.account_sla_claim_submit_error
            };

        /**********************************************************************************************************
         *   SUBSCRIPTIONS
         **********************************************************************************************************/
        case ACCOUNT_SUBSCRIPTION_REQUEST:
            return {
                ...state,
                account_subscription_status: 'loading',
                account_subscription_data: null,
                account_subscription_error: null
            };

        case ACCOUNT_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                account_subscription_status: 'success',
                account_subscription_data: action.account_subscription_data
            };

        case ACCOUNT_SUBSCRIPTION_ERROR:
            return {
                ...state,
                account_subscription_status: 'error',
                account_subscription_error: action.account_subscription_error
            };

        /**********************************************************************************************************
         *   EMAILS
         **********************************************************************************************************/
        case ACCOUNT_EMAILS_REQUEST:
            return {
                ...state,
                account_emails_status: 'loading',
                account_emails_data: null,
                account_emails_error: null,
                account_emails_meta: null
            };

        case ACCOUNT_EMAILS_SUCCESS:
            return {
                ...state,
                account_emails_status: 'success',
                account_emails_data: action.account_emails_data,
                account_emails_meta: action.account_emails_meta
            };

        case ACCOUNT_EMAILS_ERROR:
            return {
                ...state,
                account_emails_status: 'error',
                account_emails_error: action.account_emails_error
            };

        case ACCOUNT_EMAILS_SEARCH_RESET:
            return {
                ...state,
                account_emails_search_status: null,
                account_emails_search_data: null,
                account_emails_search_error: null
            };

        case ACCOUNT_EMAILS_SEARCH_REQUEST:
            return {
                ...state,
                account_emails_search_status: 'loading',
                account_emails_search_data: null,
                account_emails_search_error: null
            };

        case ACCOUNT_EMAILS_SEARCH_SUCCESS:
            return {
                ...state,
                account_emails_search_status: 'success',
                account_emails_search_data: action.account_emails_search_data
            };

        case ACCOUNT_EMAILS_SEARCH_ERROR:
            return {
                ...state,
                account_emails_search_status: 'error',
                account_emails_search_error: action.account_emails_search_error
            };

        case ACCOUNT_EMAILS_VIEW_REQUEST:
            return {
                ...state,
                account_emails_view_status: 'loading',
                account_emails_view_data: null,
                account_emails_view_error: null
            };

        case ACCOUNT_EMAILS_VIEW_SUCCESS:
            return {
                ...state,
                account_emails_view_status: 'success',
                account_emails_view_data: action.account_emails_view_data
            };

        case ACCOUNT_EMAILS_VIEW_ERROR:
            return {
                ...state,
                account_emails_view_status: 'error',
                account_emails_view_error: action.account_emails_view_error
            };

        case ACCOUNT_EMAILS_RESEND_REQUEST:
            return {
                ...state,
                account_emails_resend_status: 'loading',
                account_emails_resend_data: null,
                account_emails_resend_error: null
            };

        case ACCOUNT_EMAILS_RESEND_SUCCESS:
            return {
                ...state,
                account_emails_resend_status: 'success',
                account_emails_resend_data: action.account_emails_resend_data
            };

        case ACCOUNT_EMAILS_RESEND_ERROR:
            return {
                ...state,
                account_emails_resend_status: 'error',
                account_emails_resend_error: action.account_emails_resend_error
            };

        /**********************************************************************************************************
         *   PASSWORD
         **********************************************************************************************************/
        case ACCOUNT_UPDATE_PASSWORD_REQUEST:
            return {
                ...state,
                account_update_password_status: 'loading',
                account_update_password_data: null,
                account_update_password_error: null
            };

        case ACCOUNT_UPDATE_PASSWORD_SUCCESS:
            return {
                ...state,
                account_update_password_status: 'success',
                account_update_password_data: action.account_update_password_data
            };

        case ACCOUNT_UPDATE_PASSWORD_ERROR:
            return {
                ...state,
                account_update_password_status: 'error',
                account_update_password_error: action.account_update_password_error
            };

        case ACCOUNT_LAST_UPDATE_PASSWORD_REQUEST:
            return {
                ...state,
                account_last_update_password_status: 'loading',
                account_last_update_password_data: null,
                account_last_update_password_error: null
            };

        case ACCOUNT_LAST_UPDATE_PASSWORD_SUCCESS:
            return {
                ...state,
                account_last_update_password_status: 'success',
                account_last_update_password_data: action.account_last_update_password_data
            };

        case ACCOUNT_LAST_UPDATE_PASSWORD_ERROR:
            return {
                ...state,
                account_last_update_password_status: 'error',
                account_last_update_password_error: action.account_last_update_password_error
            };

        /**********************************************************************************************************
         *   SECURITY QUESTIONS
         **********************************************************************************************************/
        case ACCOUNT_SECURITY_INFORMATION_REQUEST:
            return {
                ...state,
                account_security_information_status: 'loading',
                account_security_information_data: null,
                account_security_information_error: null
            };

        case ACCOUNT_SECURITY_INFORMATION_SUCCESS:
            return {
                ...state,
                account_security_information_status: 'success',
                account_security_information_data: action.account_security_information_data
            };

        case ACCOUNT_SECURITY_INFORMATION_ERROR:
            return {
                ...state,
                account_security_information_status: 'error',
                account_security_information_error: action.account_security_information_error
            };

        case ACCOUNT_SECURITY_QUESTIONS_REQUEST:
            return {
                ...state,
                account_security_questions_status: 'loading',
                account_security_questions_data: null,
                account_security_questions_error: null
            };

        case ACCOUNT_SECURITY_QUESTIONS_SUCCESS:
            return {
                ...state,
                account_security_questions_status: 'success',
                account_security_questions_data: action.account_security_questions_data
            };

        case ACCOUNT_SECURITY_QUESTIONS_ERROR:
            return {
                ...state,
                account_security_questions_status: 'error',
                account_security_questions_error: action.account_security_questions_error
            };

        case ACCOUNT_SECURITY_UPDATE_REQUEST:
            return {
                ...state,
                account_security_update_status: 'loading',
                account_security_update_data: null,
                account_security_update_error: null
            };

        case ACCOUNT_SECURITY_UPDATE_SUCCESS:
            return {
                ...state,
                account_security_update_status: 'success',
                account_security_update_data: action.account_security_update_data
            };

        case ACCOUNT_SECURITY_UPDATE_ERROR:
            return {
                ...state,
                account_security_update_status: 'error',
                account_security_update_error: action.account_security_update_error
            };

        /**********************************************************************************************************
         *   TWOFACTOR
         **********************************************************************************************************/
        case ACCOUNT_TWOFACTOR_REQUEST:
            return {
                ...state,
                account_twofactor_status: 'loading',
                account_twofactor_data: null,
                account_twofactor_error: null
            };

        case ACCOUNT_TWOFACTOR_SUCCESS:
            return {
                ...state,
                account_twofactor_status: 'success',
                account_twofactor_data: action.account_twofactor_data
            };

        case ACCOUNT_TWOFACTOR_ERROR:
            return {
                ...state,
                account_twofactor_status: 'error',
                account_twofactor_error: action.account_twofactor_error
            };

        case ACCOUNT_QR_CODE_REQUEST:
            return {
                ...state,
                account_qr_code_status: 'loading',
                account_qr_code_data: null
            };

        case ACCOUNT_QR_CODE_SUCCESS:
            return {
                ...state,
                account_qr_code_status: 'success',
                account_qr_code_data: action.account_qr_code_data
            };

        case ACCOUNT_QR_CODE_ERROR:
            return {
                ...state,
                account_qr_code_status: 'error'
            };

        case ACCOUNT_TWOFACTOR_ENABLE_REQUEST:
            return {
                ...state,
                account_twofactor_enable_status: 'loading',
                account_twofactor_enable_data: null,
                account_twofactor_enable_error: null
            };

        case ACCOUNT_TWOFACTOR_ENABLE_SUCCESS:
            return {
                ...state,
                account_twofactor_enable_status: 'success',
                account_twofactor_enable_data: action.account_twofactor_enable_data
            };

        case ACCOUNT_TWOFACTOR_ENABLE_ERROR:
            return {
                ...state,
                account_twofactor_enable_status: 'error',
                account_twofactor_enable_error: action.account_twofactor_enable_error
            };

        case ACCOUNT_TWOFACTOR_CONFIG_REQUEST:
            return {
                ...state,
                account_twofactor_config_status: 'loading',
                account_twofactor_config_data: null,
                account_twofactor_config_error: null
            };

        case ACCOUNT_TWOFACTOR_CONFIG_SUCCESS:
            return {
                ...state,
                account_twofactor_config_status: 'success',
                account_twofactor_config_data: action.account_twofactor_config_data
            };

        case ACCOUNT_TWOFACTOR_CONFIG_ERROR:
            return {
                ...state,
                account_twofactor_config_status: 'error',
                account_twofactor_config_error: action.account_twofactor_config_error
            };

        case ACCOUNT_TWOFACTOR_VERIFY_REQUEST:
            return {
                ...state,
                account_twofactor_verify_status: 'loading',
                account_twofactor_verify_data: null,
                account_twofactor_verify_error: null
            };

        case ACCOUNT_TWOFACTOR_VERIFY_SUCCESS:
            return {
                ...state,
                account_twofactor_verify_status: 'success',
                account_twofactor_verify_data: action.account_twofactor_verify_data
            };

        case ACCOUNT_TWOFACTOR_VERIFY_ERROR:
            return {
                ...state,
                account_twofactor_verify_status: 'error',
                account_twofactor_verify_error: action.account_twofactor_verify_error
            };

        case ACCOUNT_TWOFACTOR_DELETE_REQUEST:
            return {
                ...state,
                account_delete_twofactor_status: 'loading',
                account_delete_twofactor_data: null,
                account_delete_twofactor_error: null
            };

        case ACCOUNT_TWOFACTOR_DELETE_SUCCESS:
            return {
                ...state,
                account_delete_twofactor_status: 'success',
                account_delete_twofactor_data: action.account_delete_twofactor_data
            };

        case ACCOUNT_TWOFACTOR_DELETE_ERROR:
            return {
                ...state,
                account_delete_twofactor_status: 'error',
                account_delete_twofactor_error: action.account_delete_twofactor_error
            };

        case ACCOUNT_TWOFACTOR_PRIMARY_REQUEST:
            return {
                ...state,
                account_primary_twofactor_status: 'loading',
                account_primary_twofactor_data: null,
                account_primary_twofactor_error: null
            };

        case ACCOUNT_TWOFACTOR_PRIMARY_SUCCESS:
            return {
                ...state,
                account_primary_twofactor_status: 'success',
                account_primary_twofactor_data: action.account_primary_twofactor_data
            };

        case ACCOUNT_TWOFACTOR_PRIMARY_ERROR:
            return {
                ...state,
                account_primary_twofactor_status: 'error',
                account_primary_twofactor_error: action.account_primary_twofactor_error
            };

        /**********************************************************************************************************
         *   LOGIN HISTORY
         **********************************************************************************************************/
        case ACCOUNT_LOGIN_HISTORY_REQUEST:
            return {
                ...state,
                account_active_history_status: 'loading',
                account_active_history_data: null,
                account_active_history_error: null
            };

        case ACCOUNT_LOGIN_HISTORY_SUCCESS:
            return {
                ...state,
                account_active_history_status: 'success',
                account_active_history_data: action.account_active_history_data
            };

        case ACCOUNT_LOGIN_HISTORY_ERROR:
            return {
                ...state,
                account_active_history_status: 'error',
                account_active_history_error: action.account_active_history_error
            };

        case ACCOUNT_EXPIRED_HISTORY_REQUEST:
            return {
                ...state,
                account_expired_history_status: 'loading',
                account_expired_history_data: null,
                account_expired_history_error: null
            };

        case ACCOUNT_EXPIRED_HISTORY_SUCCESS:
            return {
                ...state,
                account_expired_history_status: 'success',
                account_expired_history_data: action.account_expired_history_data
            };

        case ACCOUNT_EXPIRED_HISTORY_ERROR:
            return {
                ...state,
                account_expired_history_status: 'error',
                account_expired_history_error: action.account_expired_history_error
            };

        case ACCOUNT_END_SPECIFIC_REQUEST:
            return {
                ...state,
                account_end_specific_status: 'loading',
                account_end_specific_data: null,
                account_end_specific_error: null
            };

        case ACCOUNT_END_SPECIFIC_SUCCESS:
            return {
                ...state,
                account_end_specific_status: 'success',
                account_end_specific_data: action.account_end_specific_data
            };

        case ACCOUNT_END_SPECIFIC_ERROR:
            return {
                ...state,
                account_end_specific_status: 'error',
                account_end_specific_error: action.account_end_specific_error
            };

        case ACCOUNT_END_SESSIONS_REQUEST:
            return {
                ...state,
                account_end_sessions_status: 'loading',
                account_end_sessions_data: null,
                account_end_sessions_error: null
            };

        case ACCOUNT_END_SESSIONS_SUCCESS:
            return {
                ...state,
                account_end_sessions_status: 'success',
                account_end_sessions_data: action.account_end_sessions_data
            };

        case ACCOUNT_END_SESSIONS_ERROR:
            return {
                ...state,
                account_end_sessions_status: 'error',
                account_end_sessions_error: action.account_end_sessions_error
            };

        /**********************************************************************************************************
         *   CLOSE ACCOUNT
         **********************************************************************************************************/
        case ACCOUNT_CLOSE_REQUEST:
            return {
                ...state,
                account_close_status: 'loading',
                account_close_data: null,
                account_close_error: null
            };

        case ACCOUNT_CLOSE_SUCCESS:
            return {
                ...state,
                account_close_status: 'success',
                account_close_data: action.account_close_data
            };

        case ACCOUNT_CLOSE_ERROR:
            return {
                ...state,
                account_close_status: 'error',
                account_close_error: action.account_close_error
            };

        default:
            return state;
    }
};

export default accountReducer;
