import { NXSelect } from 'components/Form/NXSelect';
import { CategoryIcon } from 'containers/katana/components/pageOrganiser/CategoryIcon';
import Grid from 'components/Grid';
import type { KatanaNamespace } from 'containers/katana/types';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import './_PageOptions.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type PageOptions = React.FC<{
    page: KatanaNamespace.Page;
    onSelectPage: (page: KatanaNamespace.Page) => void;
    onSelectSection: (section: KatanaNamespace.Section) => void;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const PageOptions: PageOptions = ({ page, onSelectPage, onSelectSection }) => {
    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();

    /***** QUERIES *****/
    const { data: page_section_data } = katanaQuery.serviceID.pageSections.useQuery({ serviceID: katanaServiceId, pageID: page.id });
    const { data: section_definitions_data } = katanaQuery.serviceID.meta.sectionDefinitions.useQuery(katanaServiceId);

    /***** RENDER HELPERS *****/
    const isHomePage = page.path === '/';

    /***** RENDER *****/
    return (
        <>
            <NXSelect.Option value={page.id} className="PageOption" onSelect={() => onSelectPage(page)}>
                <Grid columns="auto 1fr">
                    <CategoryIcon size={21} category={isHomePage ? 'home' : page.name} /> {page.name}
                </Grid>
            </NXSelect.Option>

            {page_section_data?.map((section) => (
                <NXSelect.Option key={section.id} value={section.id} className="SectionOption" onSelect={() => onSelectSection(section)}>
                    <Grid columns="auto 1fr">
                        <PhosphorIcons.Section size={18} /> {section.name || section_definitions_data?.[section.section_id]?.name}
                    </Grid>
                </NXSelect.Option>
            ))}
        </>
    );
};
