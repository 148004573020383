import Grid from 'components/Grid';
import RequestLoader from 'components/Loaders/Request';
import OutlineTag from 'components/Tags/OutlineTag';
import Tooltip from 'components/Tooltip';
import { Border } from 'components/Utils/Border';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { WrapWithComponent } from 'components/WrapWithComponent';
import CheckListItems from 'containers/katana/modules/launchChecklist/checkListItems';
import { SitePreviewContent } from 'containers/katana/modules/presetContent/SitePreviewContent';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import KatanaServiceListEntryActionButton from 'containers/services/modules/katanaServicesList/katanaServiceListEntryActionButton';
import React from 'react';
import { useIntersectionObserver } from 'usehooks-ts';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import './_katanaServiceListEntryVisual.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type KatanaServiceListEntryVisualComponent = React.FC<{
    katanaSiteData: Artah.Katana.GET._200['data'][number];
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const KatanaServiceListEntryVisual: KatanaServiceListEntryVisualComponent = ({ katanaSiteData }) => {
    const { attributes, id: serviceID } = katanaSiteData;

    /***** HOOKS *****/
    const isTabletAndSmaller = useAppViewport(['md', 'sm', 'xs']);
    const { isIntersecting, ref } = useIntersectionObserver({
        threshold: 0.5,
    });

    /***** QUERIES *****/
    const { data: dnsData, isLoading } = katanaQuery.serviceID.dns.useQuery(serviceID);

    /***** RENDER HELPERS *****/
    const status = attributes?.site_status;
    const serviceStatus = attributes?.status;
    const isPendingCancellation = serviceStatus === 'pending cancellation';
    function renderListEntrySiteInfo() {
        if (isLoading) {
            return (
                <OutlineTag color="notice">
                    <RequestLoader />
                </OutlineTag>
            );
        }
        if (isPendingCancellation) {
            return <OutlineTag color="info">Pending Cancellation</OutlineTag>;
        }
        switch (status) {
            case 'in-progress':
                return <OutlineTag color="notice">In Progress</OutlineTag>;

            case 'published':
                return (
                    <Flex align="center">
                        <OutlineTag color={dnsData?.is_site_live ? 'confirm' : 'notice'}>
                            {dnsData?.is_site_live ? 'Published' : 'Published - DNS Propagation'}
                        </OutlineTag>
                        <Tooltip info="Your website has been published. Please allow up to 24 hours for DNS propagation to take place for your VIPsites website to appear online." />
                    </Flex>
                );

            case 'unpublished':
                return <OutlineTag color="notice">Unpublished</OutlineTag>;

            case 'suspended':
                return <OutlineTag color="warn">Suspended</OutlineTag>;

            default:
                return <OutlineTag color="primary">Unconfigured</OutlineTag>;
        }
    }

    const isPublished = status === 'published';
    /***** RENDER *****/
    return (
        <Border all ref={ref}>
            <Flex direction="column" gap={0} fullHeight>
                <Border bottom>
                    <SitePreviewContent serviceID={serviceID} ratio={0.5} isEnabled={isIntersecting} />
                </Border>
                <Flex.Child grow inject>
                    <Padding paddingOnly xy={4} inject>
                        <Flex alignChildren="stretch" fullWidth>
                            <Grid
                                columns={isTabletAndSmaller ? '100%' : '1fr 150px'}
                                justify-children--stretch
                                alignItems--stretch
                                gap={isTabletAndSmaller ? 3 : 4}
                                fullWidth
                            >
                                <Flex direction="column" justify="between" align="start" fullHeight>
                                    <Text size--s black medium lead--1>
                                        {attributes?.name}
                                    </Text>

                                    <WrapWithComponent component={Grid} wrap={isTabletAndSmaller} columns="1fr 1fr" fullWidth>
                                        {isPublished || isTabletAndSmaller ? (
                                            renderListEntrySiteInfo()
                                        ) : (
                                            <Flex
                                                justify="between"
                                                fullWidth
                                                relative
                                                className="KatanaServiceListEntryVisual__checkListItemsWrapper"
                                            >
                                                <CheckListItems
                                                    showIconHoverToolTip
                                                    circleSize={36}
                                                    serviceID={serviceID}
                                                    showItemTitle={false}
                                                    showItemButton={false}
                                                    separateLinePath
                                                />
                                            </Flex>
                                        )}
                                        {isTabletAndSmaller && <KatanaServiceListEntryActionButton katanaSiteData={katanaSiteData} />}
                                    </WrapWithComponent>
                                </Flex>
                                {!isTabletAndSmaller && (
                                    <Flex direction="column" justify="center">
                                        {!isPublished && renderListEntrySiteInfo()}
                                        <KatanaServiceListEntryActionButton katanaSiteData={katanaSiteData} />
                                    </Flex>
                                )}
                            </Grid>
                        </Flex>
                    </Padding>
                </Flex.Child>
            </Flex>
        </Border>
    );
};
