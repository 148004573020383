/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { queryOptions, useQuery } from '@tanstack/react-query';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/

function createQueryKey(serviceID: KatanaNamespace.ServiceId) {
    return katanaQueryKeys.katana.service.ID.dns(serviceID);
}

function createQueryOptions(serviceID: KatanaNamespace.ServiceId) {
    return queryOptions({
        queryKey: createQueryKey(serviceID),
        queryFn: () => KATANA_API.katana.service_id.DNS.GET(serviceID),
        retryOnMount: false,
        enabled: Boolean(serviceID),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        },
    });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/

/**
 * Get Katana DNS data
 */
function _useQuery(serviceID: KatanaNamespace.ServiceId, options?: ReturnType<typeof createQueryOptions>) {
    return useQuery({
        ...createQueryOptions(serviceID),
        ...options,
    });
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const dns = Object.freeze({
    useQuery: _useQuery,
    createQueryKey,
    createQueryOptions,
});
