import { pushNotification } from 'components/Toast/functions';
import { getHostingList } from 'containers/services/action';
import { API } from 'utilities/api/hosting';
import { getDataFromSuccessResponse, getErrorFromFailResponse } from 'utilities/methods/commonActions';
import {
    HOSTING_ADMIN_INITIAL_STATE,
    HOSTING_ADMIN_REQUEST_RESET,
    HOSTING_CANCEL_REQUEST,
    HOSTING_CANCEL_SUCCESS,
    HOSTING_CANCEL_FAIL
} from './actionTypes';



/**********************************************************************************************************
 *   RESET
 **********************************************************************************************************/

export const resetHostingAdmin = () => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_ADMIN_INITIAL_STATE
        });
    };
};

export const resetHostingAdminRequests = () => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_ADMIN_REQUEST_RESET
        });
    };
};

/**********************************************************************************************************
 *   CANCEL HOSTING
 **********************************************************************************************************/

export const cancelHosting = (id, attributes, history) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_CANCEL_REQUEST,
            hosting_cancel_status: 'loading'
        });
        API.hosting.POST.cancel(id, attributes)
            .then((response) => {
                const hosting_cancel_data = getDataFromSuccessResponse(response);
                pushNotification(getDataFromSuccessResponse(response));
                dispatch({
                    type: HOSTING_CANCEL_SUCCESS,
                    hosting_cancel_data
                });
                getHostingList();
                if (history) {
                    history.push(`/my-services/hosting`);
                }
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(getErrorFromFailResponse(error));
                dispatch({
                    type: HOSTING_CANCEL_FAIL,
                    hosting_cancel_error: response
                });
            });
    };
};

export const cancelRequest = (id) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_CANCEL_REQUEST,
            hosting_cancel_status: 'loading'
        });
        API.hosting.POST.cancelRequest(id)
            .then((response) => {
                const hosting_cancel_data = getDataFromSuccessResponse(response);
                pushNotification(getDataFromSuccessResponse(response));
                dispatch({
                    type: HOSTING_CANCEL_SUCCESS,
                    hosting_cancel_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(getErrorFromFailResponse(error));
                dispatch({
                    type: HOSTING_CANCEL_FAIL,
                    hosting_cancel_error: response
                });
            });
    };
};
