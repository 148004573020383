import type { BrowserBox } from 'components/Lightboxes/BrowserBox';

type ViewportRecord = Record<BrowserBox.Viewports, number>;
export const viewPortWidths: ViewportRecord = {
    desktop: 1820,
    tablet: 1100,
    mobile: 668,

    /**
     * special
     */
    oldPhone3310: 668
};
