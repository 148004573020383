import { useMutation } from '@tanstack/react-query';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions/handleDefaultErrorNotification';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation(serviceID: number) {
    /***** FUNCTIONS *****/

    function onMutate(socialType: string) {
        return katanaQuery.serviceID.getSocials.optimistic.filter(serviceID, 'data', ({ type }) => type !== socialType);
    }

    function mutationFn(socialType: string) {
        return KATANA_API.katana.service_id.socials.DELETE({ serviceID, socialType });
    }

    /***** HOOK RESULTS *****/
    return useMutation({
        mutationFn,
        onMutate,
        onError: (error, variables, context) => {
            context?.restore();
            handleDefaultErrorNotification(error);
        },
        onSettled: () => {
            katanaQuery.serviceID.getSocials.invalidateQueries(serviceID);
        }
    });
}

export const deleteSocialType = Object.freeze({
    useMutation: _useMutation
});
