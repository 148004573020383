import { useMutation } from '@tanstack/react-query';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import { service } from 'containers/katana/queries/serviceID/service';
import type { KatanaNamespace } from 'containers/katana/types';
import { queryClient } from 'store/queryClient';
import { API } from 'utilities/api/services';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions/handleDefaultErrorNotification';
import { handleDefaultSuccessNotification } from 'utilities/methods/commonActions/handleDefaultSuccessNotification';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation(serviceID: KatanaNamespace.ServiceId) {
    function handleMutation() {
        return API.service.service_id.cancellation.DELETE(serviceID);
    }

    return useMutation({
        mutationFn: handleMutation,
        onSuccess: handleDefaultSuccessNotification,
        onError: handleDefaultErrorNotification,
        onSettled: () => {
            service.resetQueries(serviceID);
            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.list(),
            });
        },
    });
}

export const removeCancellation = Object.freeze({
    useMutation: _useMutation,
});
