import RequestLoader from 'components/Loaders/Request';
import { KatanaHookFormHandler } from 'containers/katana/components/HookFormHandler';
import { useColourThemeDefaultFormValues } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/webpageColourThemeFormHandler/useColourThemeDefaultFormValues';
import { useHandleColorThemeSubmit } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/webpageColourThemeFormHandler/useHandleColourThemeSubmit';
import React from 'react';
import { regexes } from 'utilities/methods/regexes';
import { z } from 'zod';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';
import { requiredFieldErrorMessage } from 'utilities/methods/form';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type WebPageColourThemeFormHandlerProps = {
    subpageRouteData: KatanaNamespace.SubPageRouteData;
};

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/

const webPageColourThemeFormHandlerZodSchema = z
    .object({
        color: z.string(),
        style: z.object({
            fonts: z.object({
                body: z.string({ invalid_type_error: requiredFieldErrorMessage, message: requiredFieldErrorMessage }).optional(),
                heading: z.string({ invalid_type_error: requiredFieldErrorMessage, message: requiredFieldErrorMessage }).optional()
            }),
            custom_color: z.string().regex(regexes.hexCode()).nullable()
        })
    })
    .superRefine((val, ctx) => {
        if (val.color === 'custom' && !val.style.custom_color) {
            ctx.addIssue({
                code: 'custom',
                message: 'No value for custom color'
            });
        }
    });

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const WebPageColourThemeFormHandler: React.FC<WebPageColourThemeFormHandlerProps> = ({ subpageRouteData }) => {
    /***** HOOKS *****/
    const defaultValues = useColourThemeDefaultFormValues(subpageRouteData);
    const handleSubmit = useHandleColorThemeSubmit(subpageRouteData);

    /***** RENDER *****/
    if (!defaultValues) {
        return <RequestLoader message="Loading Colours & Fonts..." />;
    }

    return (
        <KatanaHookFormHandler handleOnSubmit={handleSubmit} defaultValues={defaultValues} zodSchema={webPageColourThemeFormHandlerZodSchema}>
            {subpageRouteData.modules}
        </KatanaHookFormHandler>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
