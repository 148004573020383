import { useNavigate } from '@tanstack/react-router';
import { OutlineButton } from 'components/Buttons/OutlineButton';
import FetchComponentError from 'components/Errors/FetchComponentError';
import { BannerCarousel } from 'components/Promo/Carousel/Banner';
import WithAnimationLoop from 'components/Promo/Carousel/Banner/WithAnimationLoop';
import { DotOnlineBanner } from 'components/Promo/FreeDomain/DotOnlineBanner';
import { DotStoreBanner, dotStoreOnlineAndXYZAvailabilityRequestParams } from 'components/Promo/FreeDomain/DotStoreBanner';
import { freeDotOnlinePromoCode, freeDotStorePromoCode } from 'components/Promo/promoCodes';
import { ReferralBanner } from 'components/Promo/ReferralBanner';
import { ScrollableComponent } from 'components/ScrollableComponent';
import { RenderForBrands } from 'config/brandRenderer/component';
import { AdditionalUserBanner } from 'config/containers/promotions/additionalUsersBanner';
import { checkPromoCodeEligibility, checkYearPromoCode } from 'config/containers/promotions/methods';
import { VIPControlAppBanner } from 'config/containers/promotions/vipcontrolApp/banner';
import { createDomainAvailabilityQueryKey, postDomainAvailability } from 'containers/domain/action';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { getHostingList } from 'containers/services/action';
import { PromotionalVIPSiteBanner } from 'containers/services/modules/banners/katana/promotionalVIPSiteBanner';
import { SoftlockVIPSiteBanner } from 'containers/services/modules/banners/katana/softlockVIPSitesBanner';
import htmr from 'htmr';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { activePromotion, getCurrentDate, truncateSimple } from 'utilities/methods/commonActions';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const Account = ({ promoDealsRef }) => {
    /***** HOOKS *****/
    const navigate = useNavigate();
    const promotion_promo_code_status_request_data = useSelector((state) => state.promotion.promotion_promo_code_status_request_data);
    const domain_availability_data = useSelector((state) => state.domain.domain_availability_data);
    const hosting_information_data = useSelector((state: any) => state.services.hosting_list_data);

    const hasSelectHosting = hosting_information_data?.some((service) =>
        service.included?.some(({ attributes }) => attributes?.name === 'Select Hosting' && attributes?.status === 'active')
    );

    /***** QUERIES *****/
    const { data: trialDomains, isLoading: isTrialDomainsLoading } = katanaQuery.getTrialDomains.useQuery();
    const { data: user, status: user_data_status } = NXQuery.auth.userData.useSelectSuspenseQuery(void 0, ({ data }) => ({
        id: data.id,
        firstName: data.attributes?.firstname,
        email: data.attributes?.email,
        contact: data.attributes?.phone,
        lastName: data.attributes?.lastname
    }));

    /***** EFFECTS *****/
    useEffect(() => {
        getHostingList();
        let shouldFetchTldPricing = false;
        if (activePromotion('freeDotOnline2024')) {
            checkYearPromoCode(freeDotOnlinePromoCode, '.online');
            shouldFetchTldPricing = true;
        }
        if (activePromotion('freeDotStore2024')) {
            checkYearPromoCode(freeDotStorePromoCode, '.store');
            shouldFetchTldPricing = true;
        }
        if (shouldFetchTldPricing) {
            const domainAvailabilityQueryKey = createDomainAvailabilityQueryKey(dotStoreOnlineAndXYZAvailabilityRequestParams);

            if (!domain_availability_data[domainAvailabilityQueryKey]?.data) {
                postDomainAvailability(dotStoreOnlineAndXYZAvailabilityRequestParams, domainAvailabilityQueryKey);
            }
        }
    }, []);

    /***** RENDER HELPERS *****/
    const getWelcome = () => {
        let welcome = null;
        const now = getCurrentDate();

        const split_afternoon = 12;
        const split_evening = 17;
        const currentHour = parseFloat(now.toFormat('HH'));

        if (currentHour >= split_afternoon && currentHour <= split_evening) {
            welcome = 'afternoon';
        } else if (currentHour >= split_evening) {
            welcome = 'evening';
        } else {
            welcome = 'morning';
        }

        return htmr(`Good ${welcome}, <span className='firstName'>${truncateSimple(user.firstName, 60, '...')}</span>.`);
    };

    const handleProfileRender = (text: string, icon: string) => {
        return (
            <div key={icon} className="profile__item">
                <div className="profile__icon">
                    <i className={`icon icon-${icon}`}></i>
                </div>
                <div className="profile__text">{text}</div>
            </div>
        );
    };

    const getInitials = () => {
        const { firstName, lastName } = user;
        let initial = '';
        if (firstName) {
            initial += firstName.substring(0, 1);
        }
        if (lastName) {
            initial += lastName.substring(0, 1);
        }
        return initial;
    };

    const renderPromoBanner = () => {
        const initialBanners = [
            {
                key: 1,
                ui: <SoftlockVIPSiteBanner />,
                dismissKey: '',
                showCondition: trialDomains?.length > 0
            },
            {
                key: 2,
                ui: <PromotionalVIPSiteBanner />,
                dismissKey: PromotionalVIPSiteBanner.DISMISS_KEY,
                showCondition: trialDomains?.length === 0
            },
            {
                key: 3,
                ui: <DotStoreBanner />,
                dismissKey: DotStoreBanner.DISMISS_KEY,
                showCondition:
                    activePromotion('freeDotStore2024') && checkPromoCodeEligibility(freeDotStorePromoCode, promotion_promo_code_status_request_data)
            },
            /***** VIPControl App START *****/
            {
                key: 4,
                ui: <VIPControlAppBanner />,
                dismissKey: VIPControlAppBanner.DISMISS_KEY,
                showCondition: activePromotion('vipcontrolApp')
            },
            /***** VIPControl App END *****/
            {
                key: 5,
                ui: <DotOnlineBanner />,
                dismissKey: DotOnlineBanner.DISMISS_KEY,
                showCondition:
                    activePromotion('freeDotOnline2024') &&
                    checkPromoCodeEligibility(freeDotOnlinePromoCode, promotion_promo_code_status_request_data)
            },
            {
                key: 6,
                ui: <ReferralBanner />,
                dismissKey: ReferralBanner.DISMISS_KEY,
                showCondition: true
            },
            {
                key: 7,
                ui: <AdditionalUserBanner />,
                dismissKey: AdditionalUserBanner.DISMISS_KEY,
                showCondition: activePromotion('additionalUser')
            }
        ];

        // Use this condition to hold back the carousel rendering until any requests that certain banners depend on have completed (change it to a "let" variable when its needed)
        const showBannerCarousel = !isTrialDomainsLoading; //This is needed for the <SoftlockVIPSiteBanner />. Wait until the trial domain data is fetched before rendering the banners.

        if (!showBannerCarousel) return '';

        return (
            <WithAnimationLoop>
                {(elapsed, start, pause, resume, restart) => (
                    <BannerCarousel initialBanners={initialBanners} elapsed={elapsed} start={start} pause={pause} resume={resume} restart={restart} />
                )}
            </WithAnimationLoop>
        );
    };

    const accountContent = () => {
        switch (user_data_status) {
            case 'success':
                return (
                    <div className="dashboardAccount">
                        <RenderForBrands brands={['ventra']}>{renderPromoBanner()}</RenderForBrands>
                        <div className="dashboardAccount__footer">
                            <div className="dashboardAccount__client">
                                <div className="client__profile--image">
                                    <div className="client__profile--initials">{getInitials()}</div>
                                </div>
                                <div className="client__profile--name">{getWelcome()}</div>
                            </div>
                            <div className="dashboardAccount__profile">
                                {handleProfileRender(user.email, 'email2')}
                                {handleProfileRender(user.contact, 'phone1')}
                                <OutlineButton
                                    type="onClick"
                                    color="primary"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate({ to: '/account/general', hash: 'overview' });
                                    }}
                                    className="profile__action"
                                >
                                    Edit Profile
                                </OutlineButton>
                            </div>
                        </div>
                    </div>
                );

            case 'error':
                return (
                    <div className="ComponentError">
                        <div className="ComponentError__wrapper">
                            <FetchComponentError />
                            <div className="error__refresh">
                                <button
                                    type="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.location.reload();
                                    }}
                                >
                                    <i className="icon icon-reload"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                );

            default:
                return '';
        }
    };

    /***** RENDER *****/
    return <ScrollableComponent ready={user_data_status === 'success'}>{accountContent()}</ScrollableComponent>;
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
