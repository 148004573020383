import { useSuperUserItem } from 'components/StaffMenu/SuperUser/hooks/useSuperUserItem';

const isSpecialDay = new Date().getDate() === 1 && new Date().getMonth() === 3;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useShouldRenderOldPhoneViewport() {
    /***** HOOKS *****/
    const isEnabled = useSuperUserItem('KATANA_VIEWPORT_OLD_PHONE');
    /***** HOOK RESULTS *****/
    return isSpecialDay || isEnabled;
}
