import { CheckBoxList } from 'components/Form/CheckBoxList';
import { Input } from 'components/Form/Input';
import { Radio } from 'components/Form/Radio';
import RequestLoader from 'components/Loaders/Request';
import NXBox from 'components/NXBox';
import { Revealer } from 'components/Revealer';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import { fieldLabelWithOptional } from 'containers/katana/components/fieldLabelWithOptional';
import type { NavigationFormHandlerNamespace } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/navigationFormHandler/types';
import { CallToActionType } from 'containers/katana/containers/contentEditorModules/callToAction/consts';
import { PageOrSectionSelect } from 'containers/katana/containers/contentEditorModules/callToAction/pageOrSectionSelect';
import { useGetMultiLoaderData } from 'containers/katana/containers/contentEditorModules/callToAction/useGetMultiLoaderData';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import './_CallToActionContentModule.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const CallToActionContentModule = Object.assign(function CallToActionContentModule() {
    /***** HOOKS *****/
    const values = useWatch<NavigationFormHandlerNamespace.NavigationFormValues>();
    const type = values.call_to_action?.type;
    const multiLoaderData = useGetMultiLoaderData();
    const { setValue, clearErrors } = useFormContext();
    /***** RENDER HELPERS *****/
    const isLinkOrSectionOrPage = type === CallToActionType.LINK || type === CallToActionType.SECTION || type === CallToActionType.PAGE;

    /***** EFFECTS *****/
    useEffect(() => {
        // Only do dynamic change if the value for type is falsy
        if (!type) {
            setValue('call_to_action.type', CallToActionType.LINK);
        }
    }, [type]);

    /***** RENDER *****/
    return (
        <NXBox.TopAccordion.WithSessionState
            className="CallToActionContentModule"
            title="Customer Engagement Button"
            description="A button on your website that tells the user to take a specified action."
            isOpenDefault
        >
            <NXBox.DefaultPadding>
                <RequestLoader.MultiLoader loaders={multiLoaderData}>
                    <CheckBoxList>
                        <CheckBoxList.Item.HookForm name="call_to_action.enabled">Display customer engagement button</CheckBoxList.Item.HookForm>
                        {values.call_to_action?.enabled && (
                            <Radio.Circle.Button.ActiveRender.HookForm
                                name="call_to_action.type"
                                fullWidth
                                onSelect={(newSelection) => {
                                    if (newSelection === type) {
                                        return;
                                    }
                                    setValue('call_to_action.enabled', true);
                                    setValue('call_to_action.text', '');
                                    setValue('call_to_action.value', '');
                                    setValue('call_to_action.section_id', null);
                                    setValue('call_to_action.page_id', null);
                                    clearErrors('call_to_action.text');
                                    clearErrors('call_to_action.value');
                                    clearErrors('call_to_action.section_id');
                                    clearErrors('call_to_action.page_id');
                                }}
                            >
                                <Radio.Circle.Button.ActiveRender.Item
                                    color="confirm"
                                    label="A button that takes the user somewhere"
                                    value={(() => {
                                        if (type === CallToActionType.LINK) {
                                            return CallToActionType.LINK;
                                        }
                                        if (type === CallToActionType.SECTION) {
                                            return CallToActionType.SECTION;
                                        }
                                        return CallToActionType.PAGE;
                                    })()}
                                    isSelected={isLinkOrSectionOrPage}
                                >
                                    {({ shouldRenderChildren }) => (
                                        <Revealer isOpen={shouldRenderChildren}>
                                            <CheckBoxList>
                                                <CheckBoxList>
                                                    <Input.HookForm
                                                        label={
                                                            <Text size--m medium black>
                                                                What will the button say?{' '}
                                                                <Text warn bold>
                                                                    *
                                                                </Text>
                                                            </Text>
                                                        }
                                                        name="call_to_action.text"
                                                    />

                                                    <Radio.Circle.Button.ActiveRender.HookForm
                                                        name="call_to_action.type"
                                                        label={
                                                            <Text size--m medium black>
                                                                Where will it go when clicked?
                                                            </Text>
                                                        }
                                                        onSelect={() => {
                                                            setValue('call_to_action.value', '');
                                                            setValue('call_to_action.section_id', null);
                                                            setValue('call_to_action.page_id', null);
                                                            clearErrors('call_to_action.text');
                                                            clearErrors('call_to_action.value');
                                                            clearErrors('call_to_action.section_id');
                                                            clearErrors('call_to_action.page_id');
                                                        }}
                                                    >
                                                        <Radio.Circle.Button.ActiveRender.Item
                                                            color="confirm"
                                                            label={
                                                                <>
                                                                    Somewhere on my website{' '}
                                                                    <Text warn bold>
                                                                        *
                                                                    </Text>
                                                                </>
                                                            }
                                                            value={
                                                                type === CallToActionType.SECTION ? CallToActionType.SECTION : CallToActionType.PAGE
                                                            }
                                                        >
                                                            <PageOrSectionSelect.HookForm />
                                                        </Radio.Circle.Button.ActiveRender.Item>

                                                        <Radio.Circle.Button.ActiveRender.Item
                                                            color="confirm"
                                                            label={
                                                                <>
                                                                    To an external URL{' '}
                                                                    <Text warn bold>
                                                                        *
                                                                    </Text>
                                                                </>
                                                            }
                                                            value={CallToActionType.LINK}
                                                        >
                                                            <Input.HookForm name="call_to_action.value" placeholder="E.g. https://ventraip.com.au/" />
                                                        </Radio.Circle.Button.ActiveRender.Item>
                                                    </Radio.Circle.Button.ActiveRender.HookForm>
                                                </CheckBoxList>
                                            </CheckBoxList>
                                        </Revealer>
                                    )}
                                </Radio.Circle.Button.ActiveRender.Item>

                                <Radio.Circle.Button.ActiveRender.Item
                                    color="confirm"
                                    label="A button that shows my phone number"
                                    value={CallToActionType.PHONE}
                                >
                                    {({ shouldRenderChildren }) => (
                                        <Revealer isOpen={shouldRenderChildren}>
                                            <CheckBoxList>
                                                <CheckBoxList>
                                                    <Flex direction="column">
                                                        <Input.HookForm
                                                            label={
                                                                <Text black size--m medium>
                                                                    {fieldLabelWithOptional('Pretext')}
                                                                </Text>
                                                            }
                                                            name="call_to_action.text"
                                                            placeholder="E.g. Call Us on"
                                                        />
                                                        <Flex direction="column" gap={1}>
                                                            <Text black size--m medium>
                                                                Business phone number
                                                            </Text>
                                                            <Input.HookForm name="call_to_action.value" placeholder="04 1234 5678" />
                                                        </Flex>
                                                    </Flex>
                                                </CheckBoxList>
                                            </CheckBoxList>
                                        </Revealer>
                                    )}
                                </Radio.Circle.Button.ActiveRender.Item>
                            </Radio.Circle.Button.ActiveRender.HookForm>
                        )}
                    </CheckBoxList>
                </RequestLoader.MultiLoader>
            </NXBox.DefaultPadding>
        </NXBox.TopAccordion.WithSessionState>
    );
});
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
