/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { zodResolver } from '@hookform/resolvers/zod';
import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useBoolean } from 'usehooks-ts';
import { useHistory } from 'utilities/hooks/router/useHistory';
import { z } from 'zod';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { HookFormButton } from 'components/Form/Button/hookForm';
import { Input } from 'components/Form/Input';
import { Select } from 'components/Form/Select';
import type { SelectNamespace } from 'components/Form/Select/types';
import Grid from 'components/Grid';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import RequestLoader from 'components/Loaders/Request';
import NXBox from 'components/NXBox';
import { DialogNotification } from 'components/Notifications/DialogNotification';
import StickyButtonWrapper from 'components/StickyButtonWrapper';
import Switch from 'components/Switch';
import SolidTag from 'components/Tags/SolidTag';
import Tooltip from 'components/Tooltip';
import BackgroundColour from 'components/Utils/BackgroundColour';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import EligibilityFormSection from 'containers/katana/containers/ContentEditorLightbox/formHandlers/registerDomainFormHandler/EligibilityFormSection';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { useGetInvoiceQuery } from 'containers/billing/queries/invoice/useGetInvoiceQuery';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import useDomainOptionsCustomField from 'containers/katana/containers/ContentEditorLightbox/formHandlers/registerDomainFormHandler/useDomainOptionsCustomField';
import { useKatanaNextSetupStageRoute } from 'containers/katana/hooks/useKatanaNextSetupStageRoute';
import useKatanaURL from 'containers/katana/hooks/useKatanaURL';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useRegisterSiteMutation } from 'containers/katana/queries/useRegisterSiteMutation';
import { useCountriesData } from 'utilities/hooks/redux/useCountriesData';
import { useLocalStates } from 'utilities/hooks/redux/useLocalStates';
import { validatorCountryPostCode } from 'utilities/methods/validators';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { katanaTrialStateSimplifiedLaunch } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/consts';
import { requiredFieldErrorMessage } from 'utilities/methods/form';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import { katanaServicePages } from 'containers/katana/consts';
import type { KATANA_API } from 'utilities/api/katana';
import { NXQuery } from 'utilities/query';

const accountAndBillingSchema = z.object({
    contact: z
        .object({
            firstname: z.string().min(1, requiredFieldErrorMessage),
            lastname: z.string().min(1, requiredFieldErrorMessage),
            email: z.string().email().min(1, requiredFieldErrorMessage),
            phone: z.string().min(1, requiredFieldErrorMessage),
            address1: z.string().min(1, requiredFieldErrorMessage),
            address2: z.string(),
            suburb: z.string().min(1, requiredFieldErrorMessage),
            country: z.string().min(1, requiredFieldErrorMessage),
            state: z.string().min(1, requiredFieldErrorMessage),
            postcode: z.string().min(1, requiredFieldErrorMessage),
            organisation: z.string(),
        })
        .superRefine(({ country, postcode }, refinementContext) => {
            const isValidPostcode = validatorCountryPostCode(country, postcode);
            if (!isValidPostcode) {
                return refinementContext.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Invalid Postcode',
                    path: ['postcode'],
                });
            }
        }),
});

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const RegisterDomainFormHandler = () => {
    /***** STATE *****/
    const [invoiceID, setInvoiceID] = React.useState<number>(null);
    const [loadingRenderCondition, setLoadingRenderCondition] = React.useState(true);
    const { value: isInvoiceLightboxOpen, setTrue: openInvoiceLightbox, setFalse: closeInvoiceLightbox } = useBoolean(false);

    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();
    const { app_countries_status, app_countries_data } = useCountriesData();
    const { getKatanaDestination } = useKatanaURL();

    const history = useHistory();
    const { app_local_states_status, app_local_states_data } = useLocalStates();
    const nextSetupRoute = useKatanaNextSetupStageRoute();

    /***** QUERIES *****/
    const { data: app_user_data } = NXQuery.auth.userData.useSelectSuspenseQuery(void 0, ({ data }) => data.attributes);
    const { data: katana_service_data, isLoading: isServiceIDLoading } = katanaQuery.serviceID.service.useQuery(katanaServiceId);
    const { data: domain_options_data, isLoading: isDomainOptionsLoading } = katanaQuery.serviceID.getDomainOptions.useQuery(katanaServiceId);
    const { mutateAsync: mutateRegisterSite } = useRegisterSiteMutation(katanaServiceId);
    const { data: trial_invoice_data, isLoading: isTrialInvoiceLoading } = useGetInvoiceQuery(katana_service_data?.attributes?.trial_invoice_id);
    const { data: is_user_detail_completed } = NXQuery.auth.login.checkToken.useSelectSuspenseQuery(void 0, ({ data }) => {
        return data.attributes.is_user_detail_completed;
    });

    /***** RENDER HELPERS *****/
    const { firstname, email, address1, address2, city, lastname, phone, country, company, state, postcode, post_code } = app_user_data;
    const isABNorACNRequired = useMemo(() => Boolean(domain_options_data?.attributes?.custom_fields), [domain_options_data]);
    const eligibilityTypesData = useDomainOptionsCustomField('Eligibility Type');
    const eligibilityNumberData = useDomainOptionsCustomField('Eligibility Number');
    const domainEligibilitySchema = useMemo(() => {
        if (!domain_options_data?.attributes?.custom_fields) return z.object({});
        if (!eligibilityTypesData) return z.object({});
        return z.object({
            eligibility_type: z.string().refine((value) => eligibilityTypesData?.options.includes(value)),
            eligibility_number: z.string(),
        });
    }, [domain_options_data]);

    function getZodSchema() {
        if (!is_user_detail_completed && !isABNorACNRequired) return accountAndBillingSchema;
        if (isABNorACNRequired && is_user_detail_completed) {
            return domainEligibilitySchema;
        }
        if (!is_user_detail_completed && isABNorACNRequired) return z.intersection(accountAndBillingSchema, domainEligibilitySchema);
        return z.object({});
    }

    /***** RENDER HELPERS *****/
    const isTrialInvoicePaid = trial_invoice_data?.attributes?.status === 'paid';

    const accountAndBillingDefaultValues: Partial<Pick<KATANA_API.katana.service_id.register.POST.Attributes, 'contact'>> = useMemo(() => {
        if (is_user_detail_completed) return {};
        return {
            contact: {
                firstname: firstname || '',
                lastname: lastname || '',
                email: email || '',
                phone: phone || '',
                address1: address1 || '',
                address2: address2 || '',
                suburb: city || '',
                country: country || '',
                state: state || '',
                postcode: postcode || post_code || '',
                organisation: company || '',
            },
        };
    }, [is_user_detail_completed]);
    const eligibilityDetailsDefaultValues = useMemo(() => {
        if (isABNorACNRequired)
            return {
                eligibility_type: '',
                eligibility_number: '',
            };
        return {};
    }, [isABNorACNRequired]);

    const domainValues: Partial<Pick<KATANA_API.katana.service_id.register.POST.Attributes, 'domain'>> = {
        domain: {
            id_protection: true,
            auto_renew: true,
            transfer_lock: false,
        },
    };

    const defaultValues = useMemo(() => {
        return {
            ...accountAndBillingDefaultValues,
            ...eligibilityDetailsDefaultValues,
            ...domainValues,
        };
    }, [accountAndBillingDefaultValues, eligibilityDetailsDefaultValues]);

    const form = useForm({
        defaultValues: defaultValues,
        resolver: zodResolver(
            z.intersection(
                getZodSchema(),
                z.object({
                    domain: z.object({
                        id_protection: z.boolean(),
                        auto_renew: z.boolean(),
                        transfer_lock: z.boolean(),
                    }),
                })
            )
        ),
        mode: 'all',
    });

    function onManualClose() {
        closeInvoiceLightbox();
        if (nextSetupRoute?.includes('launch') && trial_invoice_data?.attributes?.status === 'unpaid') {
            history.push(getKatanaDestination(katanaServicePages.OVERVIEW));
            return;
        }
        history.push(nextSetupRoute);
    }

    function onSuccessClose() {
        katanaTrialStateSimplifiedLaunch.setState(() => true);
        onManualClose();
    }

    async function handleSubmit(data: typeof defaultValues) {
        const transformedData: Partial<KATANA_API.katana.service_id.register.POST.Attributes> = _.cloneDeep(data);
        const hasEligibilityType = _.has(data, 'eligibility_type');
        const hasEligibilityNumber = _.has(data, 'eligibility_number');
        if (hasEligibilityType || hasEligibilityNumber) {
            transformedData.custom_fields = [];
            if (hasEligibilityType) {
                transformedData.custom_fields.push({
                    id: eligibilityTypesData.id,
                    value: data.eligibility_type,
                });

                _.unset(transformedData, 'eligibility_type');
            }

            if (hasEligibilityNumber) {
                transformedData.custom_fields.push({
                    id: eligibilityNumberData.id,
                    value: data.eligibility_number,
                });
                _.unset(transformedData, 'eligibility_number');
            }
        }

        const registerResult = await mutateRegisterSite(transformedData).catch(_.noop);

        if (!registerResult) return;
        if (registerResult?.status === 200) {
            setInvoiceID(registerResult.data.invoice_id);

            if (!isTrialInvoicePaid) {
                openInvoiceLightbox();
            }
        } else if (registerResult.status === 400) {
            // applyEndpointErrors(registerResult.errors);
        }
        // applyEndpointErrors()
    }

    const domainOptionsHasIDProtection = useMemo(
        () => Boolean(domain_options_data?.attributes?.meta?.find((meta) => meta?.identifier === 'allow_id_protection')),
        [domain_options_data]
    );

    const { watch, trigger } = form;
    const contactCountry = watch('contact.country');

    const loaderState = RequestLoader.MultiLoader.useLoadersData([
        { condition: app_countries_status === 'loading', message: 'Loading countries data' },
        { condition: app_local_states_status === 'loading', message: 'Loading states data' },
        {
            condition: isServiceIDLoading || isDomainOptionsLoading || isTrialInvoiceLoading || loadingRenderCondition,
            message: 'Loading domain information...',
        },
    ]);
    const currentLoading = RequestLoader.MultiLoader.useCurrentMessage(loaderState);
    const isLoading = Boolean(currentLoading?.condition);

    useEffect(() => {
        trigger()
            .then(() => {
                const formValues = form.getValues();
                const touchedFields = ['domain.id_protection', 'domain.auto_renew', 'domain.transfer_lock'] as const;
                touchedFields.forEach((field) => {
                    const value = _.get(formValues, field);
                    form.setValue(field, value, { shouldTouch: true });
                });
            })
            .catch(_.noop);
    }, [currentLoading]);

    const finalCountryData: SelectNamespace.Option[] = useMemo(() => {
        if (!app_countries_data) return [];
        // return getSortedCountryData(app_countries_data).map((country) => ({
        //     label: country.attributes.country,
        //     value: country.attributes.country_code
        // }));
        return [
            {
                label: 'Australia',
                value: 'AU',
            },
            {
                label: 'New Zealand',
                value: 'NZ',
            },
        ];
    }, [app_countries_data]);

    /***** EFFECTS *****/
    useEffect(() => {
        trigger('contact.postcode');
    }, [contactCountry]);

    useEffect(() => {
        if (isServiceIDLoading || isTrialInvoiceLoading) {
            setLoadingRenderCondition(true);
            return;
        }
        if (
            katana_service_data?.attributes?.is_trial_finalised &&
            katana_service_data?.attributes?.trial_invoice_id &&
            trial_invoice_data?.attributes?.status === 'unpaid'
        ) {
            openInvoiceLightbox();
            setLoadingRenderCondition(false);
        } else if (trial_invoice_data?.attributes?.status === 'paid' && !is_user_detail_completed) {
            setLoadingRenderCondition(false);
        } else if (
            katana_service_data?.attributes?.is_trial_finalised &&
            katana_service_data?.attributes?.trial_invoice_id &&
            trial_invoice_data?.attributes?.status === 'paid'
        ) {
            history.push(nextSetupRoute);
            setLoadingRenderCondition(false);
        } else if (
            !katana_service_data?.attributes?.is_trial_finalised &&
            katana_service_data?.attributes?.trial_invoice_id &&
            trial_invoice_data?.attributes?.status === 'unpaid'
        ) {
            setLoadingRenderCondition(false);
        }
    }, [katana_service_data, trial_invoice_data, is_user_detail_completed]);

    const finalInvoiceID = invoiceID || katana_service_data?.attributes?.trial_invoice_id;
    const domainCost = isTrialInvoicePaid ? trial_invoice_data.attributes.total : trial_invoice_data?.attributes?.amount_due;

    /***** RENDER *****/
    return (
        <>
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(handleSubmit)}>
                    <NXBox className="KatanaRegisterDomainFormHandler">
                        <NXBox.Top title="Your Domain Name" />
                        <BackgroundColour secondary>
                            <NXBox.DefaultPadding paddingOnly>
                                <Flex direction="column">
                                    <Flex>
                                        <Flex.Child grow>
                                            <Flex direction="column" gap={1}>
                                                <Text secondary size--s>
                                                    VIPsites Domain Name
                                                </Text>
                                                <Text black size--m medium align--left>
                                                    {katana_service_data?.attributes?.name}
                                                </Text>
                                            </Flex>
                                        </Flex.Child>
                                        <Flex align="end">
                                            <Flex align="center" gap={1}>
                                                {/* // TODO: Put actual price in here */}
                                                <Text secondary medium>
                                                    {trial_invoice_data?.included?.[0]?.attributes?.billing_cycle} -{' '}
                                                </Text>
                                                <Text black medium>
                                                    ${domainCost}/yr
                                                </Text>
                                                <Tooltip
                                                    info="Your VIPsites website is free to use. However, to make your site accessible with a unique web address (domain name), you will need to purchase a domain name. This is an additional cost that ensures your site has a distinct and memorable address on the internet."
                                                    iconOverride={<PhosphorIcons.WarningCircle.Bold size={16} />}
                                                />
                                                {isTrialInvoicePaid ? <SolidTag color="confirm">Paid</SolidTag> : ''}
                                            </Flex>
                                        </Flex>
                                    </Flex>

                                    <DialogNotification type="info" tail={{ pos: 'top' }}>
                                        PLEASE NOTE: The contact details for the domain names listed above will be taken from your current account
                                        details. You can change the contact details once the domains have been registered through your VIPControl
                                        account in the Domain Names area.
                                    </DialogNotification>
                                </Flex>
                            </NXBox.DefaultPadding>
                        </BackgroundColour>
                        {isLoading ? (
                            <NXBox.DefaultPadding>
                                <RequestLoader.MultiLoader loaders={loaderState} />
                            </NXBox.DefaultPadding>
                        ) : (
                            <>
                                <NXBox.DefaultPadding>
                                    <Flex align="start" gap={4}>
                                        {/* TODO: We might have to consider premium domains here as well */}
                                        <Flex align="start" direction="column" inject gap={0}>
                                            <Switch.HookForm.Default
                                                label={
                                                    <>
                                                        <Text size--xs>Auto Renew</Text>
                                                        <Tooltip
                                                            info="We'll automatically invoice you 14 days before your domain is due to expire and attempt to charge any stored credit card"
                                                            iconOverride={<PhosphorIcons.WarningCircle.Bold size={16} />}
                                                        />
                                                    </>
                                                }
                                                name="domain.auto_renew"
                                            />
                                        </Flex>

                                        {domainOptionsHasIDProtection && (
                                            <Flex align="start" direction="column" inject gap={0}>
                                                <Switch.HookForm.Default
                                                    label={
                                                        <>
                                                            <Text size--xs>ID Protection</Text>
                                                            <Tooltip
                                                                info="Protect your personal information and reduce the amount of spam to your inbox by enabling ID Protection. We recommend you keep this enabled"
                                                                iconOverride={<PhosphorIcons.WarningCircle.Bold size={16} />}
                                                            />
                                                        </>
                                                    }
                                                    name="domain.id_protection"
                                                />
                                            </Flex>
                                        )}
                                    </Flex>
                                </NXBox.DefaultPadding>
                                {!is_user_detail_completed && (
                                    <>
                                        <NXBox.Top title="Enter your Account & Billing address" description="Enter your details" />
                                        <NXBox.DefaultPadding>
                                            <Flex direction="column">
                                                <Grid columns="1fr 1fr" justify-children--stretch alignItems--start>
                                                    <Input.HookForm label="First name" name="contact.firstname" />
                                                    <Input.HookForm label="Last name" name="contact.lastname" />
                                                </Grid>

                                                <Grid columns="1fr 1fr" justify-children--stretch alignItems--start>
                                                    <Input.HookForm label="Email" name="contact.email" disabled={Boolean(email)} />
                                                    <Input.HookForm label="Phone" name="contact.phone" />
                                                </Grid>

                                                <Input.HookForm label="Address Line 1" name="contact.address1" />
                                                <Input.HookForm label="Address Line 2" name="contact.address2" />
                                                <Grid columns="1fr 1fr" justify-children--stretch alignItems--start>
                                                    <Select.HookForm label="Country" name="contact.country" options={finalCountryData} />
                                                    <Select.HookForm
                                                        label="State"
                                                        name="contact.state"
                                                        options={app_local_states_data.processedOptions[contactCountry.toLocaleLowerCase()]}
                                                    />
                                                </Grid>

                                                <Grid columns="1fr 1fr" justify-children--stretch alignItems--start>
                                                    <Input.HookForm label="City" name="contact.suburb" />
                                                    <Input.HookForm label="Postcode" name="contact.postcode" />
                                                </Grid>

                                                <Input.HookForm label="Organisation" name="contact.organisation" />
                                            </Flex>
                                        </NXBox.DefaultPadding>
                                    </>
                                )}

                                <EligibilityFormSection />
                            </>
                        )}
                    </NXBox>
                    {!isLoading && (
                        <StickyButtonWrapper>
                            <HookFormButton ignorePristine>Submit Details</HookFormButton>
                        </StickyButtonWrapper>
                    )}
                </form>
            </FormProvider>
            <OverlayLightbox
                invoiceid={finalInvoiceID}
                title={`Pay Invoice #${finalInvoiceID}`}
                onOpen={isInvoiceLightboxOpen}
                onClose={onManualClose}
                onSuccessClose={onSuccessClose}
            />
        </>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default RegisterDomainFormHandler;
