import type { PhosphorIconNamespace } from 'components/Icons/Phosphor/types';
import { findCategoryIcon } from 'containers/katana/components/pageOrganiser/PageOrganiserBar/findCategoryIcon';
import React from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type CategoryIcon = React.FC<
    {
        category?: string | null;
    } & Omit<PhosphorIconNamespace.Props, 'icon'>
>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const CategoryIcon: CategoryIcon = React.memo(({ category, size = 25, ...props }) => {
    /***** RENDER HELPERS *****/
    const Icon = findCategoryIcon(category);
    /***** RENDER *****/
    return <Icon size={size} {...props} />;
});
