import { useMutation } from '@tanstack/react-query';
import { newSectionTrackerData } from 'containers/katana/modules/presetContent/components/sectionOrganiser/newSectionTrackerData';
import { pageSections } from 'containers/katana/queries/serviceID/pageSections';
import { section } from 'containers/katana/queries/serviceID/section';
import { sections } from 'containers/katana/queries/serviceID/sections';
import { service } from 'containers/katana/queries/serviceID/service';
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions/handleDefaultErrorNotification';
import { handleDefaultSuccessNotification } from 'utilities/methods/commonActions/handleDefaultSuccessNotification';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Params = Parameters<typeof KATANA_API.katana.site.service_id.pages.page_id.sections.section_id.DELETE>[0];
type MainParams = Omit<Params, 'sectionID'>;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation(params: MainParams) {
    async function onMutate(sectionID: Params['sectionID']) {
        const { serviceID, pageID } = params;
        /**
         * Optimistically remove the section from the view.
         * first cancel existing queries, then set the data to the new data.
         */
        await Promise.all([sections.cancelQueries(serviceID), pageSections.cancelQueries(params)]);

        section.removeQueries({ serviceID, sectionID });

        newSectionTrackerData.removeID(sectionID);

        const { restore: sectionsRestore } = sections.optimistic.filter(serviceID, 'data', (section) => section.id !== sectionID);
        const { restore: pageSectionsRestore } = pageSections.optimistic.filter({ serviceID, pageID }, 'data', (section) => section.id !== sectionID);

        return {
            restore: () => {
                sectionsRestore();
                pageSectionsRestore();
            }
        };
    }

    function handleMutation(sectionID: Params['sectionID']) {
        return KATANA_API.katana.site.service_id.pages.page_id.sections.section_id.DELETE({ ...params, sectionID });
    }

    return useMutation({
        onMutate,
        mutationFn: handleMutation,
        onSuccess: handleDefaultSuccessNotification,
        onError: (e, v, context) => {
            context?.restore();
            handleDefaultErrorNotification(e);
        },
        onSettled: () => {
            service.invalidateQueries(params.serviceID);
        }
    });
}

export const deletePageSection = Object.freeze({
    useMutation: _useMutation
});
