import classNames from 'classnames';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import 'containers/orderingForm/orderingFormInputs/textInput/_orderingFormTextInputIntaserve.scss';
import type { TextInputProps } from 'containers/orderingForm/orderingFormInputs/types';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type TextInput = React.FC<TextInputProps>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const TextInput: TextInput = ({
    label,
    placeHolder,
    disabled = false,
    type = 'text',
    errorBorder,
    successBorder,
    value,
    ref,
    onBlur,
    onChange
}) => {
    const inputClassNames = classNames('orderingFormTextInput__inputField', {
        'orderingFormTextInput__inputField--success': successBorder,
        'orderingFormTextInput__inputField--error': errorBorder
    });

    /***** RENDER *****/
    return (
        <div className="orderingFormTextInput__container">
            <Flex direction="column">
                {label && <Text semiBold>{label}</Text>}
                <input
                    className={inputClassNames}
                    placeholder={placeHolder}
                    type={type}
                    disabled={disabled}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    ref={ref}
                    onBlur={onBlur}
                />
            </Flex>
        </div>
    );
};
