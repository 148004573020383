/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import axios from 'axios';
import { pushNotification } from 'components/Toast/functions';
import store from 'store/store';
import { API as SERVICES } from 'utilities/api/services';
import { API, cancelGetAstroVPSInfo, cancelGetSolusVPSInfo, cancelReverseDNS } from 'utilities/api/vps';
import { createPopup, getDataFromSuccessResponse, getErrorFromFailResponse } from 'utilities/methods/commonActions';
import {
    VPS_INITIAL_STATE,
    VPS_WHM_LOGIN_REQUEST,
    VPS_WHM_LOGIN_SUCCESS,
    VPS_ADDON_REQUEST,
    VPS_ADDON_SUCCESS,
    VPS_ADDON_FAIL,
    VPS_SOFTWARE_ADDON_PRICING_REQUEST,
    VPS_SOFTWARE_ADDON_PRICING_SUCCESS,
    VPS_SOFTWARE_ADDON_PRICING_FAIL,
    VPS_HARDWARE_ADDON_PRICING_REQUEST,
    VPS_HARDWARE_ADDON_PRICING_SUCCESS,
    VPS_HARDWARE_ADDON_PRICING_FAIL,
    VPS_ADD_HARDWARE_ADDON_REQUEST,
    VPS_ADD_HARDWARE_ADDON_SUCCESS,
    VPS_ADD_HARDWARE_ADDON_FAIL,
    VPS_ADD_SOFTWARE_ADDON_REQUEST,
    VPS_ADD_SOFTWARE_ADDON_SUCCESS,
    VPS_ADD_SOFTWARE_ADDON_FAIL,
    VPS_ADDON_CANCEL_REQUEST,
    VPS_ADDON_CANCEL_SUCCESS,
    VPS_ADDON_CANCEL_FAIL,
    VPS_INFORMATION_REQUEST,
    VPS_INFORMATION_SUCCESS,
    VPS_INFORMATION_FAIL,
    VPS_INFORMATION_RESET,
    VPS_BILLING_CYCLE_REQUEST,
    VPS_BILLING_CYCLE_SUCCESS,
    VPS_BILLING_CYCLE_FAIL,
    VPS_BILLING_CYCLE_UPDATE_REQUEST,
    VPS_BILLING_CYCLE_UPDATE_SUCCESS,
    VPS_BILLING_CYCLE_UPDATE_FAIL,
    VPS_RENEW_INFO_REQUEST,
    VPS_RENEW_INFO_SUCCESS,
    VPS_RENEW_INFO_ERROR,
    VPS_RENEW_DETAILS_REQUEST,
    VPS_RENEW_DETAILS_SUCCESS,
    VPS_RENEW_DETAILS_ERROR,
    VPS_RENEW_REQUEST,
    VPS_RENEW_SUCCESS,
    VPS_RENEW_ERROR,
    VPS_REMOVE_CANCELLATION_REQUEST,
    VPS_REMOVE_CANCELLATION_SUCCESS,
    VPS_REMOVE_CANCELLATION_ERROR,
    VPS_SUBMIT_JOB_REQUEST,
    VPS_SUBMIT_JOB_SUCCESS,
    VPS_SUBMIT_JOB_FAIL,
    VPS_POWER_STATE_REQUEST,
    VPS_POWER_STATE_SUCCESS,
    VPS_POWER_STATE_ERROR,
    VPS_REBOOT_REQUEST,
    VPS_REBOOT_SUCCESS,
    VPS_REBOOT_FAIL,
    VPS_STARTUP_REQUEST,
    VPS_STARTUP_SUCCESS,
    VPS_STARTUP_ERROR,
    VPS_SHUTDOWN_REQUEST,
    VPS_SHUTDOWN_SUCCESS,
    VPS_SHUTDOWN_FAIL,
    VPS_UPGRADE_LIST_REQUEST,
    VPS_UPGRADE_LIST_SUCCESS,
    VPS_UPGRADE_LIST_FAIL,
    VPS_LEGACY_UPGRADE_LIST_REQUEST,
    VPS_SELF_UPGRADE_LIST_SUCCESS,
    VPS_SELF_UPGRADE_LIST_FAIL,
    VPS_FULLY_UPGRADE_LIST_SUCCESS,
    VPS_FULLY_UPGRADE_LIST_FAIL,
    VPS_UPGRADE_REQUEST,
    VPS_UPGRADE_SUCCESS,
    VPS_UPGRADE_FAIL,
    VPS_SOLUS_INFORMATION_REQUEST,
    VPS_SOLUS_INFORMATION_SUCCESS,
    VPS_SOLUS_INFORMATION_FAIL,
    VPS_ASTRO_INFORMATION_REQUEST,
    VPS_ASTRO_INFORMATION_SUCCESS,
    VPS_ASTRO_INFORMATION_FAIL,
    VPS_USER_INFORMATION_REQUEST,
    VPS_USER_INFORMATION_SUCCESS,
    VPS_USER_INFORMATION_FAIL,
    VPS_LEGACY_ADDON_REQUEST,
    VPS_LEGACY_ADDON_SUCCESS,
    VPS_LEGACY_ADDON_FAIL,
    VPS_VNC_INFORMATION_REQUEST,
    VPS_VNC_INFORMATION_SUCCESS,
    VPS_VNC_INFORMATION_FAIL,
    VPS_CREATE_ASTRO_VNC_REQUEST,
    VPS_CREATE_ASTRO_VNC_SUCCESS,
    VPS_CREATE_ASTRO_VNC_ERROR,
    VPS_CHANGE_VNC_PASSWORD_REQUEST,
    VPS_CHANGE_VNC_PASSWORD_SUCCESS,
    VPS_CHANGE_VNC_PASSWORD_FAIL,
    VPS_REVERSE_DNS_REQUEST,
    VPS_REVERSE_DNS_SUCCESS,
    VPS_REVERSE_DNS_FAIL,
    VPS_OS_LIST_REQUEST,
    VPS_OS_LIST_SUCCESS,
    VPS_OS_LIST_FAIL,
    VPS_FORMAT_REQUEST,
    VPS_FORMAT_SUCCESS,
    VPS_FORMAT_FAIL
} from './actionTypes';



/**********************************************************************************************************
 *   ACTIONS - INITIAL
 **********************************************************************************************************/

export const resetVPSState = () => {
    return (dispatch) => {
        dispatch({
            type: VPS_INITIAL_STATE
        });
    };
};

/**********************************************************************************************************
 *   WHM LOGIN
 **********************************************************************************************************/
export const WHMVPSLogin = (id) => {
    return (dispatch) => {
        const { closePopup, goToTargetUrl } = createPopup();

        dispatch({
            type: VPS_WHM_LOGIN_REQUEST
        });

        API.getWHMLink(id)
            .then((response) => {
                const vps_whm_login_data = getDataFromSuccessResponse(response);
                const { attributes } = vps_whm_login_data;
                const { url } = attributes;

                dispatch({
                    type: VPS_WHM_LOGIN_SUCCESS,
                    vps_whm_login_data
                });

                goToTargetUrl(url);
            })
            .catch((error) => {
                const vps_whm_login_error = getErrorFromFailResponse(error);

                pushNotification(vps_whm_login_error);

                closePopup();
            });
    };
};

/**********************************************************************************************************
 *   VPS ADDONS
 **********************************************************************************************************/
export const getVPSAddons = (id) => {
    return (dispatch) => {
        dispatch({
            type: VPS_ADDON_REQUEST
        });
        API.getAddons(id)
            .then((response) => {
                const vps_addon_list_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: VPS_ADDON_SUCCESS,
                    vps_addon_list_data
                });
            })
            .catch((error) => {
                const vps_addon_list_error = getErrorFromFailResponse(error);
                dispatch({
                    type: VPS_ADDON_FAIL,
                    vps_addon_list_error
                });
            });
    };
};

export const getSoftwareAddonPricing = (id) => {
    return (dispatch) => {
        dispatch({
            type: VPS_SOFTWARE_ADDON_PRICING_REQUEST
        });
        API.getSoftwareAddonPricing(id)
            .then((response) => {
                const vps_software_addon_pricing_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: VPS_SOFTWARE_ADDON_PRICING_SUCCESS,
                    vps_software_addon_pricing_data
                });
            })
            .catch((error) => {
                const vps_software_addon_pricing_error = getErrorFromFailResponse(error);
                dispatch({
                    type: VPS_SOFTWARE_ADDON_PRICING_FAIL,
                    vps_software_addon_pricing_error
                });
            });
    };
};

export const getHardwareAddonPricing = (id) => {
    return (dispatch) => {
        dispatch({
            type: VPS_HARDWARE_ADDON_PRICING_REQUEST
        });
        API.getHardwareAddonPricing(id)
            .then((response) => {
                const vps_hardware_addon_pricing_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: VPS_HARDWARE_ADDON_PRICING_SUCCESS,
                    vps_hardware_addon_pricing_data
                });
            })
            .catch((error) => {
                const vps_hardware_addon_pricing_error = getDataFromSuccessResponse(error);
                dispatch({
                    type: VPS_HARDWARE_ADDON_PRICING_FAIL,
                    vps_hardware_addon_pricing_error
                });
            });
    };
};

export const addHardwareAddon = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: VPS_ADD_HARDWARE_ADDON_REQUEST
        });
        API.addHardwareAddon(id, attributes)
            .then((response) => {
                const vps_add_hardware_addon_data = getDataFromSuccessResponse(response);
                pushNotification(vps_add_hardware_addon_data);
                dispatch({
                    type: VPS_ADD_HARDWARE_ADDON_SUCCESS,
                    vps_add_hardware_addon_data
                });
            })
            .catch((error) => {
                const vps_add_hardware_addon_error = getErrorFromFailResponse(error);
                pushNotification(vps_add_hardware_addon_error);
                dispatch({
                    type: VPS_ADD_HARDWARE_ADDON_FAIL,
                    vps_add_hardware_addon_error
                });
            });
    };
};

export const addSoftwareAddon = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: VPS_ADD_SOFTWARE_ADDON_REQUEST
        });
        API.addSoftwareAddon(id, attributes)
            .then((response) => {
                const vps_add_software_addon_data = getDataFromSuccessResponse(response);
                pushNotification(vps_add_software_addon_data);
                dispatch({
                    type: VPS_ADD_SOFTWARE_ADDON_SUCCESS,
                    vps_add_software_addon_data
                });
            })
            .catch((error) => {
                const vps_add_software_addon_error = getErrorFromFailResponse(error);
                pushNotification(vps_add_software_addon_error);
                dispatch({
                    type: VPS_ADD_SOFTWARE_ADDON_FAIL,
                    vps_add_software_addon_error
                });
            });
    };
};

export const cancelAddon = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: VPS_ADDON_CANCEL_REQUEST
        });
        API.cancelAddon(id, attributes)
            .then((response) => {
                const vps_addon_cancel_data = getDataFromSuccessResponse(response);
                pushNotification(vps_addon_cancel_data);
                dispatch({
                    type: VPS_ADDON_CANCEL_SUCCESS
                });
            })
            .catch((error) => {
                const vps_addon_cancel_error = getErrorFromFailResponse(error);
                pushNotification(vps_addon_cancel_error);
                dispatch({
                    type: VPS_ADDON_CANCEL_FAIL,
                    vps_addon_cancel_error
                });
            });
    };
};

export const removeVpsSoftwareAddon = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: VPS_ADDON_CANCEL_REQUEST
        });
        SERVICES.service.post
            .cancel(id, attributes)
            .then((response) => {
                const vps_addon_cancel_data = getDataFromSuccessResponse(response);
                pushNotification(vps_addon_cancel_data);
                dispatch({
                    type: VPS_ADDON_CANCEL_SUCCESS
                });
            })
            .catch((error) => {
                const vps_addon_cancel_error = getErrorFromFailResponse(error);
                pushNotification(vps_addon_cancel_error);
                dispatch({
                    type: VPS_ADDON_CANCEL_FAIL,
                    vps_addon_cancel_error
                });
            });
    };
};

/**********************************************************************************************************
 *   VPS DETAILS
 **********************************************************************************************************/
export const getSelectedVPS = (id) => {
    return (dispatch) => {
        dispatch({
            type: VPS_INFORMATION_REQUEST
        });

        return API.getSingleVPSDetails(id)
            .then((response) => {
                const vps_information_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: VPS_INFORMATION_SUCCESS,
                    vps_information_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: VPS_INFORMATION_FAIL,
                    vps_information_error: response
                });
            });
    };
};

export function resetVPSData() {
    const { dispatch } = store;
    dispatch({ type: VPS_INFORMATION_RESET });
}

/**********************************************************************************************************
 *   BILLING
 **********************************************************************************************************/
export const getVPSBillingCycles = (id) => {
    return (dispatch) => {
        dispatch({
            type: VPS_BILLING_CYCLE_REQUEST
        });
        API.getBillingCycles(id)
            .then((response) => {
                const vps_billing_cycle_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: VPS_BILLING_CYCLE_SUCCESS,
                    vps_billing_cycle_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: VPS_BILLING_CYCLE_FAIL,
                    vps_billing_cycle_error: response
                });
            });
    };
};

export const updateVPSBillingCycle = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: VPS_BILLING_CYCLE_UPDATE_REQUEST
        });
        API.updateBillingCycle(id, attributes)
            .then((response) => {
                const vps_billing_cycle_update_data = getDataFromSuccessResponse(response);
                pushNotification(vps_billing_cycle_update_data);
                dispatch({
                    type: VPS_BILLING_CYCLE_UPDATE_SUCCESS,
                    vps_billing_cycle_update_data
                });
            })
            .catch((error) => {
                const vps_billing_cycle_update_error = getErrorFromFailResponse(error);
                pushNotification(vps_billing_cycle_update_error);
                dispatch({
                    type: VPS_BILLING_CYCLE_UPDATE_FAIL,
                    vps_billing_cycle_update_error
                });
            });
    };
};

export const getRenewVPSInfo = (id) => {
    return (dispatch) => {
        dispatch({ type: VPS_RENEW_INFO_REQUEST });
        SERVICES.service.get
            .renewInfo(id)
            .then((response) => {
                const vps_renew_info_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: VPS_RENEW_INFO_SUCCESS,
                    vps_renew_info_data
                });
            })
            .catch((error) => {
                dispatch({ type: VPS_RENEW_INFO_ERROR });
            });
    };
};

export const getRenewVPSDetails = (id, attributes) => {
    return (dispatch) => {
        dispatch({ type: VPS_RENEW_DETAILS_REQUEST });
        SERVICES.service.post
            .renewDetails(id, attributes)
            .then((response) => {
                const vps_renew_details_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: VPS_RENEW_DETAILS_SUCCESS,
                    vps_renew_details_data
                });
            })
            .catch((error) => {
                dispatch({ type: VPS_RENEW_DETAILS_ERROR });
            });
    };
};

export const renewVPS = (id, attributes) => {
    return (dispatch) => {
        dispatch({ type: VPS_RENEW_REQUEST });
        SERVICES.service.post
            .renew(id, attributes)
            .then((response) => {
                const vps_renew_data = getDataFromSuccessResponse(response);
                pushNotification(vps_renew_data);
                dispatch({
                    type: VPS_RENEW_SUCCESS,
                    vps_renew_data
                });
            })
            .catch((error) => {
                pushNotification(getErrorFromFailResponse(error));
                dispatch({ type: VPS_RENEW_ERROR });
            });
    };
};

export const removeVPSCancellation = (id) => {
    const { dispatch } = store;

    dispatch({ type: VPS_REMOVE_CANCELLATION_REQUEST });
    API.removeCancelVPS(id)
        .then((response) => {
            const vps_remove_cancellation_data = getDataFromSuccessResponse(response);
            pushNotification(vps_remove_cancellation_data);
            dispatch({
                type: VPS_REMOVE_CANCELLATION_SUCCESS,
                vps_remove_cancellation_data
            });
        })
        .catch((error) => {
            pushNotification(getErrorFromFailResponse(error));
            dispatch({ type: VPS_REMOVE_CANCELLATION_ERROR });
        });
};

/**********************************************************************************************************
 *   SERVER JOBS
 **********************************************************************************************************/
export const submitServerJob = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: VPS_SUBMIT_JOB_REQUEST
        });
        API.submitServerJob(id, attributes)
            .then((response) => {
                const vps_submit_job_data = getDataFromSuccessResponse(response);
                pushNotification(getDataFromSuccessResponse(response));
                dispatch({
                    type: VPS_SUBMIT_JOB_SUCCESS,
                    vps_submit_job_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(getErrorFromFailResponse(error));
                dispatch({
                    type: VPS_SUBMIT_JOB_FAIL,
                    vps_submit_job_error: response
                });
            });
    };
};

/**********************************************************************************************************
 *   VPS POWER STATE
 **********************************************************************************************************/
export const getVPSPowerState = (id) => {
    return (dispatch) => {
        dispatch({
            type: VPS_POWER_STATE_REQUEST
        });
        API.powerState(id)
            .then((response) => {
                const vps_power_state_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: VPS_POWER_STATE_SUCCESS,
                    vps_power_state_data
                });
            })
            .catch(() => {
                dispatch({
                    type: VPS_POWER_STATE_ERROR
                });
            });
    };
};

export const getVPSPowerStateSilent = (id) => {
    return (dispatch) => {
        API.powerState(id)
            .then((response) => {
                const vps_power_state_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: VPS_POWER_STATE_SUCCESS,
                    vps_power_state_data
                });
            })
            .catch((error) => {
                const vps_power_state_error = getErrorFromFailResponse(error);
                pushNotification(vps_power_state_error);
            });
    };
};

/**********************************************************************************************************
 *   REBOOT VPS SERVER
 **********************************************************************************************************/
export const rebootVPS = (id) => {
    return (dispatch) => {
        dispatch({
            type: VPS_REBOOT_REQUEST
        });
        API.reboot(id)
            .then((response) => {
                const vps_reboot_data = getDataFromSuccessResponse(response);
                pushNotification(vps_reboot_data);
                dispatch({
                    type: VPS_REBOOT_SUCCESS,
                    vps_reboot_data
                });
            })
            .catch((error) => {
                const vps_reboot_error = getErrorFromFailResponse(error);
                pushNotification(vps_reboot_error);
                dispatch({
                    type: VPS_REBOOT_FAIL,
                    vps_reboot_error
                });
            });
    };
};

/**********************************************************************************************************
 *   STARTUP VPS SERVER
 **********************************************************************************************************/
export const startupVPS = (id) => {
    return (dispatch) => {
        dispatch({
            type: VPS_STARTUP_REQUEST
        });
        API.startup(id)
            .then(() => {
                dispatch({
                    type: VPS_STARTUP_SUCCESS
                });
            })
            .catch(() => {
                dispatch({
                    type: VPS_STARTUP_ERROR
                });
            });
    };
};

/**********************************************************************************************************
 *   SHUTDOWN VPS SERVER
 **********************************************************************************************************/
export const shutdownVPS = (id) => {
    return (dispatch) => {
        dispatch({
            type: VPS_SHUTDOWN_REQUEST
        });
        API.shutdown(id)
            .then((response) => {
                const vps_shutdown_data = getDataFromSuccessResponse(response);
                pushNotification(vps_shutdown_data);
                dispatch({
                    type: VPS_SHUTDOWN_SUCCESS,
                    vps_shutdown_data
                });
            })
            .catch((error) => {
                const vps_shutdown_error = getErrorFromFailResponse(error);
                pushNotification(vps_shutdown_error);
                dispatch({
                    type: VPS_SHUTDOWN_FAIL,
                    vps_shutdown_error
                });
            });
    };
};

/**********************************************************************************************************
 *   UPGRADE
 **********************************************************************************************************/
export const getVPSProducts = (attributes) => {
    return (dispatch) => {
        dispatch({
            type: VPS_UPGRADE_LIST_REQUEST
        });
        API.products(attributes)
            .then((response) => {
                const vps_upgrade_list_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: VPS_UPGRADE_LIST_SUCCESS,
                    vps_upgrade_list_data
                });
            })
            .catch((error) => {
                const vps_upgrade_list_error = getErrorFromFailResponse(error);
                dispatch({
                    type: VPS_UPGRADE_LIST_FAIL,
                    vps_upgrade_list_error
                });
            });
    };
};

export const getLegacyVPS = () => {
    return (dispatch) => {
        dispatch({
            type: VPS_LEGACY_UPGRADE_LIST_REQUEST
        });
        API.products('svps')
            .then((response) => {
                const vps_self_upgrade_list_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: VPS_SELF_UPGRADE_LIST_SUCCESS,
                    vps_self_upgrade_list_data
                });
            })
            .catch((error) => {
                const vps_self_upgrade_list_error = getErrorFromFailResponse(error);
                dispatch({
                    type: VPS_SELF_UPGRADE_LIST_FAIL,
                    vps_self_upgrade_list_error
                });
            });

        API.products('fvps')
            .then((response) => {
                const vps_fully_upgrade_list_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: VPS_FULLY_UPGRADE_LIST_SUCCESS,
                    vps_fully_upgrade_list_data
                });
            })
            .catch((error) => {
                const vps_fully_upgrade_list_error = getErrorFromFailResponse(error);
                dispatch({
                    type: VPS_FULLY_UPGRADE_LIST_FAIL,
                    vps_fully_upgrade_list_error
                });
            });
    };
};

export const upgradeVPSService = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: VPS_UPGRADE_REQUEST
        });
        API.upgrade(id, attributes)
            .then((response) => {
                const vps_upgrade_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: VPS_UPGRADE_SUCCESS,
                    vps_upgrade_data
                });
                pushNotification(vps_upgrade_data);
            })
            .catch((error) => {
                const vps_upgrade_error = getErrorFromFailResponse(error);
                dispatch({
                    type: VPS_UPGRADE_FAIL,
                    vps_upgrade_error
                });
                pushNotification(vps_upgrade_error);
            });
    };
};

export const updateSelectedVPSInSilent = (id) => {
    return (dispatch) => {
        API.getSingleVPSDetails(id)
            .then((response) => {
                const getSelectedVPSData = getDataFromSuccessResponse(response);
                dispatch({
                    type: VPS_INFORMATION_SUCCESS,
                    getSelectedVPSData
                });
            })
            .catch((error) => {
                const vps_select_error = getErrorFromFailResponse(error);
                pushNotification(vps_select_error);
            });
    };
};

export const getSolusVPSInfo = (id) => {
    return (dispatch) => {
        if (cancelGetSolusVPSInfo) {
            cancelGetSolusVPSInfo();
        }
        dispatch({
            type: VPS_SOLUS_INFORMATION_REQUEST
        });
        API.solusVPSInfo(id)
            .then((response) => {
                const vps_solus_information_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: VPS_SOLUS_INFORMATION_SUCCESS,
                    vps_solus_information_data
                });
            })
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    dispatch({
                        type: VPS_SOLUS_INFORMATION_FAIL
                    });
                }
            });
    };
};

export const getAstroVPSInfo = (id) => {
    return (dispatch) => {
        if (cancelGetAstroVPSInfo) {
            cancelGetAstroVPSInfo();
        }
        dispatch({
            type: VPS_ASTRO_INFORMATION_REQUEST
        });
        API.astroVPSInfo(id)
            .then((response) => {
                const vps_astro_information_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: VPS_ASTRO_INFORMATION_SUCCESS,
                    vps_astro_information_data
                });
            })
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    dispatch({
                        type: VPS_ASTRO_INFORMATION_FAIL
                    });
                }
            });
    };
};

export const getVPSUserInfo = (id) => {
    return (dispatch) => {
        dispatch({
            type: VPS_USER_INFORMATION_REQUEST
        });
        API.getUserInfo(id)
            .then((response) => {
                const vps_user_information_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: VPS_USER_INFORMATION_SUCCESS,
                    vps_user_information_data
                });
            })
            .catch((error) => {
                const vps_user_information_error = getErrorFromFailResponse(error);
                dispatch({
                    type: VPS_USER_INFORMATION_FAIL,
                    vps_user_information_error
                });
            });
    };
};

export const getLegacyAddon = (id) => {
    return (dispatch) => {
        dispatch({
            type: VPS_LEGACY_ADDON_REQUEST
        });
        API.getLegacyAddon(id)
            .then((response) => {
                const vps_legacy_addon_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: VPS_LEGACY_ADDON_SUCCESS,
                    vps_legacy_addon_data
                });
            })
            .catch((error) => {
                const vps_legacy_addon_error = getErrorFromFailResponse(error);
                dispatch({
                    type: VPS_LEGACY_ADDON_FAIL,
                    vps_legacy_addon_error
                });
            });
    };
};

export const getVNCInfo = (id) => {
    return (dispatch) => {
        dispatch({
            type: VPS_VNC_INFORMATION_REQUEST
        });
        API.getVNCInfo(id)
            .then((response) => {
                const vps_vnc_information_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: VPS_VNC_INFORMATION_SUCCESS,
                    vps_vnc_information_data
                });
            })
            .catch((error) => {
                const vps_vnc_information_error = getErrorFromFailResponse(error);
                dispatch({
                    type: VPS_VNC_INFORMATION_FAIL,
                    vps_vnc_information_error
                });
            });
    };
};

export const createAstroVNCSession = (id) => {
    return (dispatch) => {
        dispatch({
            type: VPS_CREATE_ASTRO_VNC_REQUEST
        });
        API.createAstroVNC(id)
            .then((response) => {
                const {
                    attributes: { url }
                } = getDataFromSuccessResponse(response);
                window.open(url, '_blank');
                dispatch({
                    type: VPS_CREATE_ASTRO_VNC_SUCCESS
                });
            })
            .catch((error) => {
                pushNotification(getErrorFromFailResponse(error));
                dispatch({
                    type: VPS_CREATE_ASTRO_VNC_ERROR
                });
            });
    };
};

export const changeVNCPassword = (id) => {
    return (dispatch) => {
        dispatch({
            type: VPS_CHANGE_VNC_PASSWORD_REQUEST
        });
        API.changeVNCPassword(id)
            .then((response) => {
                pushNotification(getDataFromSuccessResponse(response));
                dispatch({
                    type: VPS_CHANGE_VNC_PASSWORD_SUCCESS
                });
            })
            .catch((error) => {
                pushNotification(getErrorFromFailResponse(error));
                dispatch({
                    type: VPS_CHANGE_VNC_PASSWORD_FAIL
                });
            });
    };
};

export const getReverseDNS = (id) => {
    return (dispatch) => {
        if (cancelReverseDNS) {
            cancelReverseDNS();
        }
        dispatch({
            type: VPS_REVERSE_DNS_REQUEST
        });
        API.getReverseDNS(id)
            .then((response) => {
                const vps_reverse_dns_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: VPS_REVERSE_DNS_SUCCESS,
                    vps_reverse_dns_data
                });
            })
            .catch((error) => {
                const vps_reverse_dns_error = getErrorFromFailResponse(error);
                dispatch({
                    type: VPS_REVERSE_DNS_FAIL,
                    vps_reverse_dns_error
                });
            });
    };
};

export const updateReverseDNS = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: VPS_REVERSE_DNS_REQUEST
        });
        API.updateReverseDNS(id, attributes)
            .then((response) => {
                const vps_reverse_dns_data = getDataFromSuccessResponse(response);
                pushNotification(vps_reverse_dns_data);
                dispatch(getReverseDNS(id));
            })
            .catch((error) => {
                const vps_reverse_dns_error = getErrorFromFailResponse(error);
                pushNotification(vps_reverse_dns_error);
                dispatch(getReverseDNS(id));
            });
    };
};

/**********************************************************************************************************
 *   FORMAT VPS
 **********************************************************************************************************/
export const getOperatingSystemList = (id) => {
    return (dispatch) => {
        dispatch({
            type: VPS_OS_LIST_REQUEST
        });
        API.getOperatingSystemList(id)
            .then((response) => {
                const vps_os_list_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: VPS_OS_LIST_SUCCESS,
                    vps_os_list_data
                });
            })
            .catch((error) => {
                const vps_os_list_error = getErrorFromFailResponse(error);
                dispatch({
                    type: VPS_OS_LIST_FAIL,
                    vps_os_list_error
                });
            });
    };
};

export const formatVPS = (id, os) => {
    return (dispatch) => {
        dispatch({
            type: VPS_FORMAT_REQUEST
        });
        API.formatVPS(id, os)
            .then((response) => {
                const vps_format_data = getDataFromSuccessResponse(response);
                pushNotification(vps_format_data);
                dispatch({
                    type: VPS_FORMAT_SUCCESS,
                    vps_format_data
                });
            })
            .catch((error) => {
                const vps_format_error = getErrorFromFailResponse(error);
                pushNotification(vps_format_error);
                dispatch({
                    type: VPS_FORMAT_FAIL,
                    vps_format_error
                });
            });
    };
};
