/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Link } from '@tanstack/react-router';
import Tooltip from 'react-tooltip-lite';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { IconButtonProps } from './types';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_IconButton.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const IconButton: React.FC<IconButtonProps> = (props) => {
    const { group, disabled } = props;

    const renderIconButton = (button) => {
        const { icon, label, color, onClick, className, target, href, to } = button;

        const keyValue = `${button?.key}_${button?.props?.className}_${label}`;
        const key = `${keyValue}-id`;
        function renderIcon() {
            if (typeof icon === 'string') {
                return <i className={`icon icon-${icon}`} />;
            }

            return icon;
        }

        if (to) {
            return (
                <Link key={`IconButton-${key}`} className={`IconButton ${className ? ' ' + className : ''} ${color ? color : ''}`} to={to}>
                    <Tooltip
                        className={`IconTooltip ${color ? color : ''}`}
                        direction="up"
                        distance={5}
                        arrowSize={5}
                        content={<div className="IconTooltip__content">{label}</div>}
                    >
                        {renderIcon()}
                    </Tooltip>
                </Link>
            );
        }

        if (target && href && !onClick) {
            return (
                <a
                    key={`IconButton-${key}`}
                    className={`IconButton ${className ? ' ' + className : ''} ${color ? color : ''}`}
                    target={target}
                    href={href}
                >
                    <Tooltip
                        className={`IconTooltip ${color ? color : ''}`}
                        direction="up"
                        distance={5}
                        arrowSize={5}
                        content={<div className="IconTooltip__content">{label}</div>}
                    >
                        {renderIcon()}
                    </Tooltip>
                </a>
            );
        }

        if (href && !onClick) {
            return (
                <a key={`IconButton-${key}`} className={`IconButton ${className ? ' ' + className : ''} ${color ? color : ''}`} href={href}>
                    <Tooltip
                        className={`IconTooltip ${color ? color : ''}`}
                        direction="up"
                        distance={5}
                        arrowSize={5}
                        content={<div className="IconTooltip__content">{label}</div>}
                    >
                        {renderIcon()}
                    </Tooltip>
                </a>
            );
        }

        return (
            <button
                key={`IconButton-${key}`}
                className={`IconButton ${className ? ' ' + className : ''} ${color ? color : ''} ${disabled ? 'disabled' : ''}`}
                type="button"
                onClick={onClick}
                disabled={disabled}
            >
                <Tooltip
                    className={`IconTooltip ${color ? color : ''}`}
                    direction="up"
                    distance={5}
                    arrowSize={5}
                    content={<div className="IconTooltip__content">{label}</div>}
                >
                    {renderIcon()}
                </Tooltip>
            </button>
        );
    };

    if (group) {
        return (
            <section key={`IconGroup-${group.length}`} className="IconGroup">
                {group.map((button) => {
                    return renderIconButton(button);
                })}
            </section>
        );
    }

    return renderIconButton(props);
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default IconButton;
