import VipRewardsLogo from 'assets/images/vipRewards/vipRewardsLogo.svg';
import classNames from 'classnames';
import Anchor from 'components/Anchor';
import InactiveButton from 'components/Buttons/InactiveButton';
import { SolidButton } from 'components/Buttons/SolidButton';
import { CheckBoxList } from 'components/Form/CheckBoxList';
import { ValidationMessage } from 'components/Form/ValidationMessage';
import RequestLoader from 'components/Loaders/Request';
import { DialogNotification } from 'components/Notifications/DialogNotification';
import NXBox from 'components/NXBox';
import PricingTable from 'components/PricingTable';
import { dotStoreOnlineAndXYZAvailabilityRequestParams } from 'components/Promo/FreeDomain/DotStoreBanner';
import Switch from 'components/Switch';
import Text from 'components/Utils/Text';
import { claimFreeDomainPromo } from 'config/containers/promotions/action';
import { useBrandStore } from 'config/hooks/useBrandStore';
import { getLoggedInAccountData } from 'containers/dashboard/modules/accounts/methods';
import { createDomainAvailabilityQueryKey, postDomainAvailability, resetDomainAvailability } from 'containers/domain/action';
import { vipRewardsSignup } from 'containers/vipRewards/action';
import { useVipRewards } from 'containers/vipRewards/hooks';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getCurrentDate } from 'utilities/methods/commonActions';
import { returnErrorAndWarnClass } from 'utilities/methods/form';
import { validatorSecondLevelDomain } from 'utilities/methods/validators';
import { NXQuery } from 'utilities/query';
import './_FreeDomainClaimForm.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type FreeDomainClaimFormProp = {
    /**
     * The tld being claimed
     */
    tld: '.store' | '.online' | '.xyz';

    /**
     * The promo code being used to get the free domain
     */
    promoCode: string;

    /**
     * Function to close the lightbox
     */
    closeLightbox: () => void;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export let FreeDomainClaimForm: React.FC<FreeDomainClaimFormProp> = ({
    tld,
    promoCode,
    closeLightbox,

    // Redux props
    domain_availability_data,
    promotion_claim_free_domain_status
}) => {
    /***** HOOKS *****/
    const { application } = useBrandStore();

    /***** STATE *****/
    const [domainState, setDomainState] = useState({
        auto_renew: true,
        id_protection: true
    });
    const [domainValue, setDomainValue] = useState('');
    const [vipChecked, setVipChecked] = useState(false);

    const { data: login_account_list_data } = NXQuery.auth.accountList.useSuspenseQuery();
    const { data: app_check_token_data } = NXQuery.auth.login.checkToken.useSuspenseQuery(void 0, { select: ({ data }) => data.attributes });

    const { currentTier } = useVipRewards();

    const domainAvailabilityRequestParams = { domain: domainValue, tlds: [tld], promos: [], action: 'register' };
    const domainAvailabilityQueryKey = createDomainAvailabilityQueryKey(domainAvailabilityRequestParams);

    const domainAvailabilityStatus = domain_availability_data[domainAvailabilityQueryKey]?.status;
    const domainAvailabilityData = domain_availability_data[domainAvailabilityQueryKey]?.data?.find(({ domain }) => domainValue === domain);
    const domainAvailabilityError = domain_availability_data[domainAvailabilityQueryKey]?.error;

    // This one is used for renewal pricing
    const freeDomainsQueryKey = createDomainAvailabilityQueryKey(dotStoreOnlineAndXYZAvailabilityRequestParams);
    const freeDomainAvailabilityData = domain_availability_data[freeDomainsQueryKey]?.data?.find(({ tld: dataTld }) => tld === dataTld);

    /***** FUNCTIONS *****/
    function getYearPrice() {
        if (domainAvailabilityData?.premium || !freeDomainAvailabilityData?.price) {
            return 0;
        }

        const foundYearPrice = freeDomainAvailabilityData.price.find((priceData) => priceData.name === '1 Year' && priceData.type === 'renew')?.price;

        return foundYearPrice;
    }

    const yearPrice = getYearPrice();

    const isValid = validatorSecondLevelDomain(domainValue);
    const errorMessage = isValid ? null : "Invalid domain format, please ensure you don't include www., .online, .store, .co etc.";

    const wrapperboxClassNames = classNames('wrapperbox', {
        error: returnErrorAndWarnClass(domainValue !== '', errorMessage, false, false)
    });

    const domainName =
        domainAvailabilityData?.domain && domainAvailabilityData?.tld
            ? domainAvailabilityData.domain + domainAvailabilityData.tld
            : domainValue + tld;

    function checkDomain() {
        postDomainAvailability(domainAvailabilityRequestParams, domainAvailabilityQueryKey);
    }

    function onSubmitClaim() {
        if (vipChecked) {
            vipRewardsSignup({
                accountId: getLoggedInAccountData(login_account_list_data, app_check_token_data)?.id
            });
        }

        const claimItem = {
            id: domainAvailabilityData.product_id,
            billing_cycle_id: domainAvailabilityData.price.find((priceData) => priceData.name === '1 Year')?.billing_cycle_id,
            name: domainName,
            order_type: 'register',
            ...domainState
        };

        claimFreeDomainPromo({ items: [claimItem], promo_code: promoCode, tld, options: { onSuccess: closeLightbox } });
    }

    /***** EFFECTS *****/
    useEffect(() => {
        resetDomainAvailability(domainAvailabilityQueryKey);

        return () => resetDomainAvailability(domainAvailabilityQueryKey);
    }, []);

    /***** RENDER HELPERS *****/

    function renderRewardsSignup() {
        // Checks if the currentTier is null, meaning that the user does not have an account
        if (!currentTier) {
            return (
                <NXBox className="freeDomainClaimLightbox__NXBox">
                    <NXBox.Bottom>
                        <NXBox.Title title={<img src={VipRewardsLogo} alt="VIPRewards" />} />
                        <Text className="freeDomainClaimLightbox__NXBox__Text" size--s color="S_color_text_grey_base">
                            Unlock exclusive VIPrewards benefits, including discounts on new services, renewals, access to giveaways, and much more.
                            Discover all the amazing offers.{' '}
                            <Anchor target="_blank" href="https://ventraip.com.au/vip-rewards/">
                                Learn more.
                            </Anchor>
                        </Text>
                        <CheckBoxList.Item isChecked={vipChecked} onChange={() => setVipChecked(!vipChecked)}>
                            <Text color="S_color_text_grey_base" bold>
                                Sign up to VIPrewards - it&apos;s Free!
                            </Text>
                        </CheckBoxList.Item>
                    </NXBox.Bottom>
                </NXBox>
            );
        }
    }

    function renderResultForm() {
        if (domainAvailabilityStatus === 'loading') return <RequestLoader />;

        if (!domainAvailabilityData && ['success', 'error'].includes(domainAvailabilityStatus)) {
            return (
                <DialogNotification type="error" tail={{ pos: 'top' }}>
                    {domainAvailabilityError?.details || 'Something went wrong, please try again'}
                </DialogNotification>
            );
        }

        if (domainAvailabilityData?.available && domainAvailabilityData?.premium && domainAvailabilityStatus === 'success') {
            return (
                <DialogNotification type="error" tail={{ pos: 'top' }}>
                    Sorry! this promotion does not apply to premium domains
                </DialogNotification>
            );
        }

        if (domainAvailabilityData && !domainAvailabilityData.available && domainAvailabilityStatus === 'success') {
            return (
                <DialogNotification type="error" tail={{ pos: 'top' }}>
                    Sorry! {domainName} is already registered
                </DialogNotification>
            );
        }

        if (domainAvailabilityData?.available && !domainAvailabilityData.premium) {
            const freeUntilDate = getCurrentDate().plus({ years: 1 }).toFormat('LLLL yyyy');

            return (
                <form className="freeDomainClaimLightbox__form">
                    <ul className="freeDomainClaimLightbox__list">
                        <li className="freeDomainClaimLightbox__listItem">
                            <div className="freeDomainClaimLightbox__domainContainer">
                                <p className="freeDomainClaimLightbox__domain">{domainName}</p>
                                <div className="freeDomainClaimLightbox__priceContainer">
                                    <span className="freeDomainClaimLightbox__priceContainer--free">FREE&nbsp;&nbsp;</span>
                                    <p className="freeDomainClaimLightbox__priceContainer--price">${yearPrice}/yr</p>
                                </div>
                            </div>
                            <div className="freeDomainClaimLightbox__switchContainer">
                                <small>Auto Renew</small>
                                <Switch
                                    switchvalue={domainState.auto_renew ? '1' : '0'}
                                    onClick={() => setDomainState({ ...domainState, auto_renew: !domainState.auto_renew })}
                                />
                            </div>
                            <div className="freeDomainClaimLightbox__switchContainer">
                                <small>ID Protection</small>
                                <Switch
                                    switchvalue={domainState.id_protection ? '1' : '0'}
                                    onClick={() => setDomainState({ ...domainState, id_protection: !domainState.id_protection })}
                                />
                            </div>
                        </li>
                    </ul>

                    <DialogNotification type="warning" tail={{ pos: 'top' }}>
                        PLEASE NOTE: The contact details for the domain names listed above will be taken from your current account details. You can
                        change the contact details once the domains have been registered through your {application} account in the Domain Names area.
                    </DialogNotification>

                    <PricingTable total={{ label: 'Total Due Today', amount: '$0.00 AUD' }} rows={[{ label: domainName, amount: '$0.00 AUD' }]} />
                    {renderRewardsSignup()}
                    <div className="freeDomainClaimLightbox__tandcContainer">
                        <Text secondary italic size--xs align--right lead--s>
                            *Your domain registration is complimentary until {freeUntilDate}. At the end of the free period you will be able to renew
                            the name at the standard renewal rate of ${yearPrice}/yr should you wish to keep the name past the complimentary year. If
                            auto-renew is enabled, your domain will attempt renewal automatically 14 days before the end of the free period. Please
                            note the renewal price is subject to change. Excludes premium domain names.
                        </Text>
                    </div>

                    {promotion_claim_free_domain_status === 'loading' ? (
                        <InactiveButton>
                            <RequestLoader />
                        </InactiveButton>
                    ) : (
                        <SolidButton type="onClick" onClick={onSubmitClaim}>
                            Confirm
                        </SolidButton>
                    )}
                </form>
            );
        }

        return '';
    }

    /***** RENDER *****/
    return (
        <div className="freeDomainClaimLightbox">
            <div className="freeDomainClaimLightbox__domainSearch">
                <div className="freeDomainClaimLightbox__searchWrapper">
                    <div className="form__item">
                        <div className="form__item__inner">
                            <div className={wrapperboxClassNames}>
                                <input
                                    type="text"
                                    value={domainValue}
                                    onChange={(e) => setDomainValue(e.target.value)}
                                    onKeyDown={(e) => e.key === 'Enter' && checkDomain()}
                                />

                                <span className="freeDomainClaimLightbox__tldSign">{tld}</span>
                            </div>
                            {domainAvailabilityStatus === 'loading' || !isValid || promotion_claim_free_domain_status === 'loading' ? (
                                <InactiveButton>Search</InactiveButton>
                            ) : (
                                <SolidButton type="onClick" onClick={checkDomain}>
                                    Search
                                </SolidButton>
                            )}
                        </div>
                        <ValidationMessage.ReduxForm.Default touched={domainValue !== ''} error={errorMessage} warning={false} initial={false} />
                    </div>
                </div>

                {renderResultForm()}
            </div>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state) => ({
    domain_availability_data: state.domain.domain_availability_data,
    promotion_claim_free_domain_status: state.promotion.promotion_claim_free_domain_status
});

FreeDomainClaimForm = connect(mapStateToProps)(FreeDomainClaimForm);
