import type { NXSelectNamespace } from 'components/Form/NXSelect/_NXSelect/types';
import { createContext, useContext } from 'react';

type ContextType = {
    isListOpen: boolean;
    setIsListOpen: (isListOpen: boolean) => void;
    selectedOption: NXSelectNamespace.OptionValue | null;
    setSelectedOption: (option: NXSelectNamespace.OptionValue) => void;
    value?: NXSelectNamespace.OptionValue['value'] | null;
    onChange: (option: NXSelectNamespace.OptionValue) => void;
};

export const NXSelectContext = createContext<ContextType>({
    isListOpen: false,
    setIsListOpen: (isListOpen: boolean) => {},
    selectedOption: null,
    setSelectedOption: (option: NXSelectNamespace.OptionValue) => {},
    onChange: (option: NXSelectNamespace.OptionValue) => {}
});

export const useNXSelectContext = () => useContext(NXSelectContext);
