import Actions from 'components/NXTable/Actions';
import Body from 'components/NXTable/Body';
import ErrorMessage from 'components/NXTable/ErrorMessage';
import Footer from 'components/NXTable/Footer';
import Header from 'components/NXTable/Header';
import Row from 'components/NXTable/Row';
import SortableButtonWrapper from 'components/NXTable/SortableButtonWrapper';
import TableAccordion from 'components/NXTable/TableAccordion';
import Table from './Table';
import { _useTableSearchSort } from './hooks/useSearchSort';

const NXTable = Object.assign(Table, {
    Accordion: TableAccordion,
    Actions,
    Body,
    ErrorMessage,
    Footer,
    Header,
    Row,
    SortableButtonWrapper,

    /**
     * @description
     * Provides a "handleSort" function that will update the sort and sort_by query parameters. This should be used
     * with the NXTable component. To access the query params, use the `useSearch` hook which will provide the
     * type safe params for your route.
     *
     * @note Ensure that the literals that can be sorted by are added as literals in the search schema, otherwise
     * the endpoint may fail since the user can enter any value in the search params.
     */
    useSearchSort: _useTableSearchSort
});

export default NXTable;
