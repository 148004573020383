/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useState } from 'react';
import { useController } from 'react-hook-form';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { OutlineButton } from 'components/Buttons/OutlineButton';
import { Input } from 'components/Form/Input';
import Grid from 'components/Grid';
import NXBox from 'components/NXBox';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { PillMultiSelect } from 'components/Form/PillMultiSelect';
import ContentEditorModuleContent from 'containers/katana/containers/ContentEditorLightbox/contentEditorModuleContent';
import { useUpdateKatanaSiteBusinessInfoMutation } from 'containers/katana/queries/useUpdateKatanaSiteBusinessInfoMutation';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { FormFieldsEnum } from 'containers/katana/containers/contentEditorModules/webpageFonts/consts';
import './_webPageTypeContentModule.scss';
const { BUSINESS_CATEGORIES } = useUpdateKatanaSiteBusinessInfoMutation.FORM_FIELD_KEYS;

const WEBPAGE_TYPES_ENUM = {
    BUSINESS_SERVICES: 'Business Services',
    CAFE_RESTAURANT: 'Cafe/Restaurant',
    REAL_ESTATE: 'Real Estate',
    MARKETING: 'Marketing',
    PHOTOGRAPHY: 'Photography',
    GYMS: 'Gyms',
    WEB_DEVELOPMENT: 'Web Development',
    GARDENING: 'Gardening',
    TRADE: 'Trade',
    NEWS_MEDIA: 'News & Media',
    IT_SERVICES: 'IT Services'
} as const;

const webPageTypeEnumValues = Object.values(WEBPAGE_TYPES_ENUM);
/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _WebPageTypeContentModule = () => {
    /***** STATE *****/
    const [otherValue, setOtherValue] = useState('');

    /***** HOOKS *****/
    const { field } = useController({ name: 'business_categories' });

    /***** FUNCTIONS *****/
    function addOtherValue() {
        if (!otherValue) return;

        const keyWords = field.value ?? [];
        setOtherValue('');
        if (keyWords.includes(otherValue)) {
            return;
        }
        field.onChange([...keyWords, otherValue]);
    }

    const handleOnKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
        if (e.code === 'Enter') {
            e.preventDefault();
            addOtherValue();
        }
    };

    /***** RENDER *****/
    return (
        <NXBox.TopAccordion.WithSessionState
            title="Choose Your Website's Category"
            description="Please select the category that best fits your website. You can select multiple options. If your category isn't listed, you can add a custom one below."
            isOpenDefault={true}
        >
            <NXBox.DefaultPadding>
                <ContentEditorModuleContent>
                    <PillMultiSelect.HookForm name={BUSINESS_CATEGORIES} options={webPageTypeEnumValues} />
                    <Grid columns="3fr 1fr" alignItems--end className="WebPageTypeContentModule__otherGrid">
                        <Input.Decoupled
                            name="other"
                            label="Can't see one above? Type your own below"
                            placeholder="Eg. Mechanic"
                            value={otherValue}
                            intrinsicProps={{
                                onChange: (e) => setOtherValue(e.target.value),
                                onKeyDown: handleOnKeyDown
                            }}
                        />
                        <OutlineButton type="onClick" onClick={addOtherValue}>
                            Add
                        </OutlineButton>
                    </Grid>
                </ContentEditorModuleContent>
            </NXBox.DefaultPadding>
        </NXBox.TopAccordion.WithSessionState>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export const WebPageTypeContentModule = Object.assign(_WebPageTypeContentModule, {
    formFields: FormFieldsEnum
});
