import classNames from 'classnames';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { BrowserBoxViewPortControls } from 'components/Lightboxes/BrowserBox/viewPortControls';
import { Flex } from 'components/Utils/Flex';
import React from 'react';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import './_BrowserBox.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
export namespace BrowserBox {
    export type SpecialViewports = 'oldPhone3310';
    export type Viewports = 'desktop' | 'tablet' | 'mobile' | SpecialViewports;

    export type ViewportState = {
        viewportMode: Viewports;
        setViewportMode: React.Dispatch<React.SetStateAction<BrowserBox.Viewports>>;
    };

    export type Props = {
        children: React.ReactNode;
        isOpen?: boolean;
        className?: string;
        showSearchBar?: boolean;
        pageSlot?: React.ReactNode;
        controlCirclesContent?: Array<React.ReactNode>;
        browserBoxPageRef?: React.RefObject<HTMLDivElement>;
        browserBoxHeaderRef?: React.RefObject<HTMLDivElement>;
        browserBoxContentRef?: React.RefObject<HTMLDivElement>;
    } & ViewportState;
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const BrowserBox: React.FC<BrowserBox.Props> = ({
    children,
    className,
    controlCirclesContent,
    viewportMode,
    setViewportMode,
    browserBoxPageRef,
    browserBoxHeaderRef,
    browserBoxContentRef,
}) => {
    /***** HOOKS *****/
    const isMobile = useAppViewport(['xs', 'sm']);

    /***** RENDER *****/
    return (
        <Flex
            fullHeight
            fullWidth
            justify="center"
            align="center"
            className={classNames('BrowserBox', `BrowserBox--viewportMode-${viewportMode}`, className)}
        >
            <div className="BrowserBox__page" ref={browserBoxPageRef}>
                <div className="BrowserBox__header" ref={browserBoxHeaderRef}>
                    <Flex className="BrowserBox__controls" align="center" gap={4}>
                        <Flex className="BrowserBox__controlCircles" gap={1}>
                            <div className="BrowserBox__controlCircle">{controlCirclesContent?.[0]}</div>
                            <div className="BrowserBox__controlCircle">{controlCirclesContent?.[1]}</div>
                            {!isMobile && <div className="BrowserBox__controlCircle">{controlCirclesContent?.[2]}</div>}
                        </Flex>
                    </Flex>

                    {!isMobile && <BrowserBoxViewPortControls viewportMode={viewportMode} setViewportMode={setViewportMode} />}

                    <div className="BrowserBox__navArrows">
                        <PhosphorIcons.Arrow.Left.Bold faded-light-gray size={20} />
                        <PhosphorIcons.Arrow.Right.Bold faded-light-gray size={20} />
                    </div>
                </div>
                <div className="BrowserBox__content" ref={browserBoxContentRef}>
                    {children}
                </div>
            </div>
        </Flex>
    );
};
