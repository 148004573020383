import classNames from 'classnames';
import BackgroundImage from 'components/BackgroundImage';
import Grid from 'components/Grid';
import { NXSquare } from 'components/Utils/NXSquare';
import TitleDescriptionBlock from 'containers/katana/components/titleDescriptionBlock';
import { getKatanaSectionIDColour } from 'containers/katana/methods';
import type { KatanaNamespace } from 'containers/katana/types';
import React from 'react';
import './_ImageTitleDescriptionBlock.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Params = {
    imageUrl: string;
    title: React.ReactNode;
    description: React.ReactNode;
    sectionDefinitionID: KatanaNamespace.SectionDefinitionId;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

function ImageTitleDescriptionBlock({ imageUrl, title, description, sectionDefinitionID }: Params) {
    /***** RENDER *****/
    return (
        <Grid
            columns="1fr 3fr"
            gap={5}
            className={classNames('ImageTitleDescriptionBlock', `ImageTitleDescriptionBlock--${getKatanaSectionIDColour(sectionDefinitionID)}`)}
        >
            <NXSquare>
                <BackgroundImage imageURL={imageUrl} contain fill />
            </NXSquare>
            <TitleDescriptionBlock title={title} description={description} />
        </Grid>
    );
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export default ImageTitleDescriptionBlock;
