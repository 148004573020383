import { useMutation } from '@tanstack/react-query';
import { API } from 'utilities/api/services';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions/handleDefaultErrorNotification';
import type { NXQueryUtils } from 'utilities/methods/tanstack/types';
import type { DefaultErrorResponse } from 'utilities/methods/types';
import { NXQuery } from 'utilities/query';
/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type UseMutationOpts<TContext> = NXQueryUtils.TMutationOptions<typeof API.cart.POST.addItems, TContext, DefaultErrorResponse>;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
const _useMutation = <TContext>(opts?: UseMutationOpts<TContext>) => {
    return useMutation({
        ...opts,
        mutationKey: ['addCartItem'],
        mutationFn: ({ items, cartToken }) => API.cart.POST.addItems({ cartToken, items }),
        onSuccess: (_, { cartToken }) => {
            NXQuery.orderingForm.cart.cartId.getCart.invalidateQuery(cartToken);
        },
        // Hide default error message if the error is no active cart, because we go on and create a new cart in this situation
        onError: (response) => {
            if (response?.errors?.[0]?.details.includes('No token or active cart found')) {
                return;
            }

            handleDefaultErrorNotification(response);
        }
    });
};
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const addItems = Object.freeze({
    useMutation: _useMutation
});
