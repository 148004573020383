import { NXSelect } from 'components/Form/NXSelect';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { PageOptions } from 'containers/katana/containers/contentEditorModules/callToAction/pageOrSectionSelect/pageOptions';
import type { KatanaNamespace } from 'containers/katana/types';
import { useDynamicFormActions } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useDynamicFormActions';
import { useGetDynamicFormFieldValue } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useGetDynamicFormFieldValue';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _PageOrSectionSelectReduxForm: KatanaNamespace.PropertyComponent = ({ property }) => {
    const { key } = property;
    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();
    const { dynamicChange } = useDynamicFormActions();
    const pageFieldValue = useGetDynamicFormFieldValue(`${key}.page`);
    const sectionFieldValue = useGetDynamicFormFieldValue(`${key}.section`);
    const typeFieldValue = useGetDynamicFormFieldValue(`${key}.type`);

    /***** QUERIES *****/
    const { data: pages_data } = katanaQuery.serviceID.pages.useQuery(katanaServiceId);

    /***** FUNCTIONS *****/
    function handleOnSelectPage(page: KatanaNamespace.Page) {
        dynamicChange(`${key}.page`, page.id);
        dynamicChange(`${key}.section`, null);
        dynamicChange(`${key}.type`, 'page');
    }

    function handleOnSelectSection(section: KatanaNamespace.Section) {
        dynamicChange(`${key}.section`, section.id);
        dynamicChange(`${key}.page`, null);
        dynamicChange(`${key}.type`, 'section');
    }

    const value = typeFieldValue === 'page' ? pageFieldValue : sectionFieldValue;

    /***** RENDER *****/
    return (
        <NXSelect value={value}>
            {pages_data?.map((page) => (
                <PageOptions key={page.id} page={page} onSelectPage={handleOnSelectPage} onSelectSection={handleOnSelectSection} />
            ))}
        </NXSelect>
    );
};
