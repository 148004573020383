/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { withRouter } from 'utilities/methods/tanstack/router/withRouter';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import AutocompleteAddress from 'components/AutocompleteAddress';
import RenderPhoneField from 'components/Form/PhoneInput/RenderPhoneField';
import RequestLoader from 'components/Loaders/Request';

/*   ACTIONS
 *****************************************************/
import { RenderField, renderButton, requiredFieldValidation, validateReactPhoneNumberInput } from 'utilities/methods/form';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const nameField = () => {
    return (
        <>
            <div className="form__row">
                <div className="form__column full">
                    <Field
                        label="First Name"
                        name="firstname"
                        component={RenderField}
                        type="text"
                        placeholder=""
                        validate={[requiredFieldValidation]}
                        className="form__textfield"
                    />
                </div>
            </div>
            <div className="form__row">
                <div className="form__column full">
                    <Field
                        label="Last Name"
                        name="lastname"
                        component={RenderField}
                        type="text"
                        placeholder=""
                        validate={[requiredFieldValidation]}
                        className="form__textfield"
                    />
                </div>
            </div>
        </>
    );
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let DetailsForm = ({
    selectedCountry,
    selectedState,
    form,
    pristine,
    submitting,
    valid,
    handleSubmit,
    template,
    /**
     * Redux Props
     */
    account_update_user_status,
    service_move_register_status,
    account_approve_additional_user_signup_status
}) => {
    /***** RENDER HELPERS *****/
    const isLoading = [account_update_user_status, service_move_register_status, account_approve_additional_user_signup_status].includes('loading');

    /***** RENDER *****/
    return (
        <form className="accountPersonalDetails__form" onSubmit={handleSubmit}>
            {['additional-user', 'move-service'].includes(template) && nameField()}
            <div className="form__row">
                <div className="form__column full">
                    <Field
                        label="Phone Number"
                        name="phone"
                        component={RenderPhoneField}
                        required
                        type="tel"
                        country={selectedCountry}
                        validate={[requiredFieldValidation, validateReactPhoneNumberInput]}
                        className="form__textfield"
                    />
                </div>
            </div>
            <div className="form__row">
                <div className="form__column full">
                    <Field label="Company Name" name="company" component={RenderField} type="text" placeholder="" className="form__textfield" />
                </div>
            </div>

            <AutocompleteAddress
                form={form}
                selectedCountry={selectedCountry}
                selectedState={selectedState}
                onlyAUandNZ={true}
                fieldOrder={['country', 'address1', 'address2', 'city', 'postcode', 'state']}
            />

            <div className="form__row">
                <div className="form__column full">
                    {isLoading ? <RequestLoader /> : renderButton(pristine, submitting, valid, 'Submit & Login', 'confirm')}
                </div>
            </div>
        </form>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
DetailsForm = reduxForm({
    enableReinitialize: true
})(DetailsForm);

const mapStateToProps = (state) => {
    const selector = formValueSelector('personalDetailsForm');
    const selectedState = selector(state, 'state');
    const selectedCountry = selector(state, 'country');

    return {
        form: 'personalDetailsForm',
        selectedState,
        selectedCountry,
        service_move_register_status: state.services.service_move_register_status,
        account_approve_additional_user_signup_status: state.account.account_approve_additional_user_signup_status,
        account_update_user_status: state.account.account_update_user_status
    };
};

DetailsForm = connect(mapStateToProps)(DetailsForm);
DetailsForm = withRouter(DetailsForm);

const ExportedDetailsForm = (props) => {
    const { data: app_user_data } = NXQuery.auth.userData.useQuery(void 0, { select: ({ data }) => data.attributes });

    return <DetailsForm {...props} initialValues={app_user_data} />;
};

export default ExportedDetailsForm;
