/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import IconButton from 'components/Buttons/IconButton';
import { FormLabel } from 'components/Form/FormLabel';
import { Input } from 'components/Form/Input';
import { NXDropZone } from 'components/Form/NXDropZone';
import { Radio } from 'components/Form/Radio';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import NXBox from 'components/NXBox';
import { NXHelpBox } from 'components/NXHelpBox';
import { Revealer } from 'components/Revealer';
import { Slider } from 'components/Slider';
import Tooltip from 'components/Tooltip';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import ContentEditorModuleContent from 'containers/katana/containers/ContentEditorLightbox/contentEditorModuleContent';
import LimitedWidthContentModule from 'containers/katana/containers/ContentEditorLightbox/contentEditorModuleContent/limitedWidthContentModule';
import { _PickLogoStyle as PickLogoStyle } from 'containers/katana/containers/contentEditorModules/basicDetails/pickLogoStyle';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { usePostNavigationMutation } from 'containers/katana/queries/usePostNavigationMutation';
import { useUpdateKatanaSiteBusinessInfoMutation } from 'containers/katana/queries/useUpdateKatanaSiteBusinessInfoMutation';
import { useUpdateKatanaSiteInfoMutation } from 'containers/katana/queries/useUpdateKatanaSiteInfoMutation';
import { useCallback, useRef } from 'react';
import type { DropzoneRef } from 'react-dropzone';
import { useFormContext, useWatch } from 'react-hook-form';
import './_BasicDetailsContentModule.scss';
const { NAME, TAG_LINE, LOGO } = useUpdateKatanaSiteBusinessInfoMutation.FORM_FIELD_KEYS;
const { STYLE } = useUpdateKatanaSiteInfoMutation.FORM_FIELD_KEYS;
const { LOGO_SCALE } = usePostNavigationMutation.FORM_FIELD_KEYS;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _BasicDetailsContentModule = () => {
    /***** STATE *****/
    const logoSize = useWatch({ name: LOGO_SCALE });
    const { setValue } = useFormContext();

    function setLogoSize(value: number) {
        setValue(LOGO_SCALE, value);
    }

    /***** HOOKS *****/
    const { katanaServiceId, page } = useKatanaParams();
    const zoneRef = useRef<DropzoneRef>();

    /***** FUNCTIONS *****/
    const handleOnEqualIdentifierLogoClick = useCallback(() => {
        // Open Dropzone
        if (zoneRef.current) {
            zoneRef.current?.open?.();
        }
    }, []);

    const handleOnZoneRef = useCallback((ref: DropzoneRef) => {
        zoneRef.current = ref;
    }, []);

    function handleOnClickSmaller() {
        if (logoSize > 1) {
            setLogoSize(logoSize - 1);
        }
    }

    function handleOnClickBigger() {
        if (logoSize < 6) {
            setLogoSize(logoSize + 1);
        }
    }

    /***** RENDER *****/
    return (
        <NXBox.TopAccordion.WithSessionState isOpenDefault title="Your Website Details">
            <NXBox.DefaultPadding>
                <ContentEditorModuleContent>
                    <Input.HookForm
                        name={NAME}
                        label={
                            <Flex align="center" gap={1}>
                                Your Website Name?
                                <Tooltip info="Don't worry, you'll be able to change this later if you need to!" />
                            </Flex>
                        }
                        placeholder={`Eg. John Smith's Cabinet Making`}
                    />

                    <Radio.HookForm label="Add Your Logo" name="logo_choice" fullWidth>
                        <Flex direction="column" gap={1}>
                            <LimitedWidthContentModule>
                                <Radio.CheckBoxPanel
                                    type="circle"
                                    identifier={LOGO}
                                    fullWidth
                                    onEqualIdentifierClick={handleOnEqualIdentifierLogoClick}
                                >
                                    Upload my website logo
                                </Radio.CheckBoxPanel>
                            </LimitedWidthContentModule>

                            <Radio.IdentifierMatch identifier={LOGO}>
                                {(isMatch) => (
                                    <Revealer isOpen={isMatch}>
                                        <Padding paddingOnly top={2} bottom={4}>
                                            <NXDropZone.HookForm.WithKatanaImageUploadAndControl
                                                name={LOGO}
                                                onZoneRef={handleOnZoneRef}
                                                serviceID={katanaServiceId}
                                                singleFile
                                            />
                                        </Padding>
                                        <NXHelpBox isOpen={isMatch} position="left">
                                            <NXHelpBox.Title>Tips for an optimal logo</NXHelpBox.Title>
                                            <NXHelpBox.Description>
                                                Below are a few pointers to make your logo look as good possible on your website.
                                            </NXHelpBox.Description>
                                            <NXHelpBox.Bullets>
                                                <NXHelpBox.BulletItem>Use a horizontal orientation if possible.</NXHelpBox.BulletItem>
                                                <NXHelpBox.BulletItem>Upload your logo as a .PNG with a transparent background.</NXHelpBox.BulletItem>
                                                <NXHelpBox.BulletItem>
                                                    Remove any empty space around your logo for better alignment.
                                                </NXHelpBox.BulletItem>
                                            </NXHelpBox.Bullets>
                                            <NXHelpBox.Link href="https://ventraip.com.au/support-centre/optimising-your-logo-for-vipsites/">
                                                More helpful tips and resources
                                            </NXHelpBox.Link>
                                        </NXHelpBox>
                                    </Revealer>
                                )}
                            </Radio.IdentifierMatch>

                            <LimitedWidthContentModule>
                                <Radio.CheckBoxPanel type="circle" identifier={STYLE} fullWidth>
                                    {`I don't have a website logo to upload yet`}
                                </Radio.CheckBoxPanel>
                            </LimitedWidthContentModule>
                            <Radio.IdentifierMatch identifier={STYLE}>
                                {(isMatch) => (
                                    <Revealer isOpen={isMatch}>
                                        <Padding paddingOnly top={2} bottom={4}>
                                            <PickLogoStyle />
                                        </Padding>
                                    </Revealer>
                                )}
                            </Radio.IdentifierMatch>
                            {page !== 'setup' ? (
                                <Padding className="basicDetailsContentModule__logoSizeWrapper" paddingOnly top={1} bottom={0}>
                                    <FormLabel>Logo size</FormLabel>
                                    <Flex direction="row" gap={1} className="basicDetailsContentModule__SliderWrapper">
                                        <IconButton
                                            icon={<PhosphorIcons.ZoomOut size={24} />}
                                            label="Smaller"
                                            onClick={handleOnClickSmaller}
                                            disabled={logoSize <= 1}
                                        />
                                        <Slider.HookForm name={LOGO_SCALE} min={1} max={6} step={1} />
                                        <IconButton
                                            icon={<PhosphorIcons.ZoomIn size={24} />}
                                            label="Bigger"
                                            onClick={handleOnClickBigger}
                                            disabled={logoSize >= 6}
                                        />
                                    </Flex>
                                </Padding>
                            ) : null}
                        </Flex>
                    </Radio.HookForm>
                </ContentEditorModuleContent>
            </NXBox.DefaultPadding>
        </NXBox.TopAccordion.WithSessionState>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export const BasicDetailsContentModule = Object.assign(_BasicDetailsContentModule, {
    formFields: { NAME, TAG_LINE, LOGO, STYLE, logo_choice: 'logo_choice', LOGO_SCALE },
});
