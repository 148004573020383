/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { SortableButton } from 'components/SortableButton';
import Title from '../Title';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {NXTable.Header.TSortableTitle}
 */
export const SortableTitle = ({ sortKey, children, disabled, initialMethod }) =>
    disabled ? (
        <Title>{children}</Title>
    ) : (
        <SortableButton sortKey={sortKey} initialMethod={initialMethod}>
            <Title>{children}</Title>
        </SortableButton>
    );
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
