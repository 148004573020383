import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { SortableNavigationItemsListEntry } from 'containers/katana/containers/contentEditorModules/navigationLabels/SortableNavigationItemsListEntry';
import type { NavigationLabelsNamespace } from 'containers/katana/containers/contentEditorModules/navigationLabels/types';
import React from 'react';
import './_SortableNavigationItemsList.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    navigationItems: NavigationLabelsNamespace.SortableEntry[];
    setNavigationItems: React.Dispatch<React.SetStateAction<NavigationLabelsNamespace.SortableEntry[]>>;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const SortableNavigationItemsList: React.FC<Props> = ({ navigationItems, setNavigationItems }) => {
    /***** HOOKS *****/
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    /***** FUNCTIONS *****/
    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;

        if (active?.id && over?.id && active?.id !== over?.id) {
            setNavigationItems((navigationItems) => {
                const oldIndex = navigationItems.findIndex((item) => item.id === active.id);
                const newIndex = navigationItems.findIndex((item) => item.id === over.id);
                return arrayMove(navigationItems, oldIndex, newIndex);
            });
        }
    }

    /***** RENDER *****/
    return (
        <DndContext modifiers={[restrictToVerticalAxis, restrictToParentElement]} sensors={sensors} onDragEnd={handleDragEnd}>
            <SortableContext items={navigationItems} strategy={verticalListSortingStrategy}>
                <div className="SortableNavigationItemsList">
                    {navigationItems.map((navigationItem) => (
                        <SortableNavigationItemsListEntry
                            key={navigationItem.id}
                            navigationItem={navigationItem}
                            setNavigationItems={setNavigationItems}
                        />
                    ))}
                </div>
            </SortableContext>
        </DndContext>
    );
};
