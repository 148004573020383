/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { batchActions } from 'redux-batched-actions';
import store from 'store/store';
import { API as ACCOUNT } from 'utilities/api/account';
import { API, cancelCalculateHostingCost, cancelGetDomainRenew, cancelPreviewPresetChanges } from 'utilities/api/domain';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/*   ACTIONS
 *****************************************************/
import { pushNotification } from 'components/Toast/functions';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { getDataFromSuccessResponse, getErrorFromFailResponse } from 'utilities/methods/commonActions';
import { generateId } from 'utilities/methods/commonActions/generateId';
import { handleDefaultSuccessNotification } from 'utilities/methods/commonActions/handleDefaultSuccessNotification';
import {
    DOMAIN_ACTION_COR_ERROR,
    DOMAIN_ACTION_COR_REQUEST,
    DOMAIN_ACTION_COR_SUCCESS,
    DOMAIN_ACTION_MANUAL_COR_ERROR,
    DOMAIN_ACTION_MANUAL_COR_REQUEST,
    DOMAIN_ACTION_MANUAL_COR_SUCCESS,
    DOMAIN_ACTIVE_ERROR,
    DOMAIN_ACTIVE_REQUEST,
    DOMAIN_ACTIVE_SUCCESS,
    DOMAIN_ADD_HOSTING_CALCULATE_ERROR,
    DOMAIN_ADD_HOSTING_CALCULATE_REQUEST,
    DOMAIN_ADD_HOSTING_CALCULATE_SUCCESS,
    DOMAIN_AUTO_RENEW_ERROR,
    DOMAIN_AUTO_RENEW_REQUEST,
    DOMAIN_AUTO_RENEW_SUCCESS,
    DOMAIN_AVAILABILITY_RESET,
    DOMAIN_AVAILABILITY_UPDATE,
    DOMAIN_CHILD_RECORD_DELETE_ERROR,
    DOMAIN_CHILD_RECORD_DELETE_REQUEST,
    DOMAIN_CHILD_RECORD_DELETE_SUCCESS,
    DOMAIN_CHILD_RECORD_ERROR,
    DOMAIN_CHILD_RECORD_LIST_ERROR,
    DOMAIN_CHILD_RECORD_LIST_REQUEST,
    DOMAIN_CHILD_RECORD_LIST_SUCCESS,
    DOMAIN_CHILD_RECORD_REQUEST,
    DOMAIN_CHILD_RECORD_SUCCESS,
    DOMAIN_CONFIGURATION_ERROR,
    DOMAIN_CONFIGURATION_REQUEST,
    DOMAIN_CONFIGURATION_SUCCESS,
    DOMAIN_CONTACT_ERROR,
    DOMAIN_CONTACT_REQUEST,
    DOMAIN_CONTACT_SUCCESS,
    DOMAIN_CONTACT_THROUGH_SEARCH_ERROR,
    DOMAIN_CONTACT_THROUGH_SEARCH_REQUEST,
    DOMAIN_CONTACT_THROUGH_SEARCH_SUCCESS,
    DOMAIN_CONTACT_UPDATE_ERROR,
    DOMAIN_CONTACT_UPDATE_REQUEST,
    DOMAIN_CONTACT_UPDATE_SUCCESS,
    DOMAIN_COR_ERROR,
    DOMAIN_COR_REQUEST,
    DOMAIN_COR_SUCCESS,
    DOMAIN_CURRENT_CONFIGURATION_ERROR,
    DOMAIN_CURRENT_CONFIGURATION_SUCCESS,
    DOMAIN_DEFAULT_RECORD_STORE,
    DOMAIN_DNS_RECORDS_EXPORT_ERROR,
    DOMAIN_DNS_RECORDS_EXPORT_REQUEST,
    DOMAIN_DNS_RECORDS_EXPORT_SUCCESS,
    DOMAIN_DNSSEC_DISABLE_ERROR,
    DOMAIN_DNSSEC_DISABLE_REQUEST,
    DOMAIN_DNSSEC_DISABLE_SUCCESS,
    DOMAIN_DNSSEC_ENABLE_ERROR,
    DOMAIN_DNSSEC_ENABLE_REQUEST,
    DOMAIN_DNSSEC_ENABLE_SUCCESS,
    DOMAIN_DNSSEC_ENABLED_STATUS_ERROR,
    DOMAIN_DNSSEC_ENABLED_STATUS_REQUEST,
    DOMAIN_DNSSEC_ENABLED_STATUS_SUCCESS,
    DOMAIN_DNSSEC_ERROR,
    DOMAIN_DNSSEC_LIST_ERROR,
    DOMAIN_DNSSEC_LIST_REQUEST,
    DOMAIN_DNSSEC_LIST_SUCCESS,
    DOMAIN_DNSSEC_OPTIONS_ERROR,
    DOMAIN_DNSSEC_OPTIONS_REQUEST,
    DOMAIN_DNSSEC_OPTIONS_SUCCESS,
    DOMAIN_DNSSEC_REQUEST,
    DOMAIN_DNSSEC_SUCCESS,
    DOMAIN_ELIGIBILITY_ERROR,
    DOMAIN_ELIGIBILITY_REQUEST,
    DOMAIN_ELIGIBILITY_SUCCESS,
    DOMAIN_EMAIL_RECORD_ERROR,
    DOMAIN_EMAIL_RECORD_LIST_ERROR,
    DOMAIN_EMAIL_RECORD_LIST_REQUEST,
    DOMAIN_EMAIL_RECORD_LIST_SUCCESS,
    DOMAIN_EMAIL_RECORD_REQUEST,
    DOMAIN_EMAIL_RECORD_STORE,
    DOMAIN_EMAIL_RECORD_STORE_RESET,
    DOMAIN_EMAIL_RECORD_SUCCESS,
    DOMAIN_EMAIL_UPDATE_ERROR,
    DOMAIN_EMAIL_UPDATE_REQUEST,
    DOMAIN_EMAIL_UPDATE_SUCCESS,
    DOMAIN_EPP_ERROR,
    DOMAIN_EPP_REQUEST,
    DOMAIN_EPP_SUCCESS,
    DOMAIN_GET_COR_PRICE_ERROR,
    DOMAIN_GET_COR_PRICE_REQUEST,
    DOMAIN_GET_COR_PRICE_SUCCESS,
    DOMAIN_INFORMATION_ERROR,
    DOMAIN_INFORMATION_REQUEST,
    DOMAIN_INFORMATION_SUCCESS,
    DOMAIN_INITIAL_STATE,
    DOMAIN_LOCK_CHECK_ERROR,
    DOMAIN_LOCK_CHECK_REQUEST,
    DOMAIN_LOCK_CHECK_SUCCESS,
    DOMAIN_LOCK_ERROR,
    DOMAIN_LOCK_REQUEST,
    DOMAIN_LOCK_SUCCESS,
    DOMAIN_MOVE_ERROR,
    DOMAIN_MOVE_REQUEST,
    DOMAIN_MOVE_SUCCESS,
    DOMAIN_NAMESERVER_STORE,
    DOMAIN_PREVIEW_PRESET_CHANGES_ERROR,
    DOMAIN_PREVIEW_PRESET_CHANGES_REQUEST,
    DOMAIN_PREVIEW_PRESET_CHANGES_SUCCESS,
    DOMAIN_PROTECTION_CHECK_ERROR,
    DOMAIN_PROTECTION_CHECK_REQUEST,
    DOMAIN_PROTECTION_CHECK_SUCCESS,
    DOMAIN_PROTECTION_ERROR,
    DOMAIN_PROTECTION_REQUEST,
    DOMAIN_PROTECTION_SUCCESS,
    DOMAIN_RECORD_FAIL,
    DOMAIN_RECORD_REQUEST,
    DOMAIN_RECORD_STORE,
    DOMAIN_RECORD_STORE_AWAIT,
    DOMAIN_RECORD_SUCCESS,
    DOMAIN_RENEW_APPLY_PROMO_ERROR,
    DOMAIN_RENEW_APPLY_PROMO_SUCCESS,
    DOMAIN_RENEW_ERROR,
    DOMAIN_RENEW_OPTIONS_ERROR,
    DOMAIN_RENEW_OPTIONS_REQUEST,
    DOMAIN_RENEW_OPTIONS_SUCCESS,
    DOMAIN_RENEW_REQUEST,
    DOMAIN_RENEW_SUCCESS,
    DOMAIN_SUBACCOUNT_FAIL,
    DOMAIN_SUBACCOUNT_REQUEST,
    DOMAIN_SUBACCOUNT_SUCCESS,
    DOMAIN_SUGGESTION_ERROR,
    DOMAIN_SUGGESTION_REQUEST,
    DOMAIN_SUGGESTION_SUCCESS,
    DOMAIN_THIRD_PARTY_ERROR,
    DOMAIN_THIRD_PARTY_REQUEST,
    DOMAIN_THIRD_PARTY_SUCCESS,
    DOMAIN_UPDATE_CURRENT_CONFIGURATION_ERROR,
    DOMAIN_UPDATE_CURRENT_CONFIGURATION_REQUEST,
    DOMAIN_UPDATE_CURRENT_CONFIGURATION_SUCCESS,
    DOMAIN_WEB_RECORD_ERROR,
    DOMAIN_WEB_RECORD_LIST_ERROR,
    DOMAIN_WEB_RECORD_LIST_REQUEST,
    DOMAIN_WEB_RECORD_LIST_SUCCESS,
    DOMAIN_WEB_RECORD_REQUEST,
    DOMAIN_WEB_RECORD_STORE,
    DOMAIN_WEB_RECORD_SUCCESS,
    DOMAIN_WHOIS_ERROR,
    DOMAIN_WHOIS_REQUEST,
    DOMAIN_WHOIS_SUCCESS
} from './actionTypes';


/**********************************************************************************************************
 *   ACTIONS - INITIAL
 **********************************************************************************************************/

export const resetDomainsState = () => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_INITIAL_STATE
        });
    };
};

/**********************************************************************************************************
 *   ACTIONS - GENERAL
 **********************************************************************************************************/

export const getDomainInformation = (id) => {
    const { dispatch } = store;
    dispatch({
        type: DOMAIN_INFORMATION_REQUEST
    });
    return API.domain.GET.information(id)
        .then((response) => {
            const domain_information_data = getDataFromSuccessResponse(response);

            getDomainRenew(id);
            dispatch(getIdProtection(id));
            dispatch({
                type: DOMAIN_INFORMATION_SUCCESS,
                domain_information_data
            });
        })
        .catch((error) => {
            const domain_information_error = getErrorFromFailResponse(error);
            dispatch({
                type: DOMAIN_INFORMATION_ERROR,
                domain_information_error
            });
        });
};

export const resetDomainAvailability = (queryKey) => {
    const { dispatch } = store;
    dispatch({
        type: DOMAIN_AVAILABILITY_RESET,
        queryKey
    });
};

export function createDomainAvailabilityQueryKey({ domain, tlds, promos, action }) {
    return `${JSON.stringify(tlds)}/${JSON.stringify(promos)}/${domain}/${action}`;
}

export const postDomainAvailability = ({ domain, tlds, promos, action }, queryKey) => {
    const { dispatch } = store;

    dispatch({
        type: DOMAIN_AVAILABILITY_UPDATE,
        payload: {
            key: queryKey,
            data: {
                status: 'loading',
                data: null,
                error: null
            }
        }
    });

    const domainData = {
        domain,
        action,
        tlds,
        promos
    };

    API.domain.POST.availability(domainData)
        .then((response) => {
            const domain_availability_data = getDataFromSuccessResponse(response);

            dispatch({
                type: DOMAIN_AVAILABILITY_UPDATE,
                payload: {
                    key: queryKey,
                    data: {
                        status: 'success',
                        data: domain_availability_data,
                        error: null
                    }
                }
            });
        })
        .catch((error) => {
            const domain_availability_error = getErrorFromFailResponse(error);

            dispatch({
                type: DOMAIN_AVAILABILITY_UPDATE,
                payload: {
                    key: queryKey,
                    data: {
                        status: 'error',
                        data: null,
                        error: domain_availability_error
                    }
                }
            });
        });
};

export const getActiveDomains = () => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_ACTIVE_REQUEST
        });
        API.domain.GET.active()
            .then((response) => {
                const domain_active_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: DOMAIN_ACTIVE_SUCCESS,
                    domain_active_data
                });
            })
            .catch((error) => {
                const domain_active_error = getErrorFromFailResponse(error);
                dispatch({
                    type: DOMAIN_ACTIVE_ERROR,
                    domain_active_error
                });
            });
    };
};

export const getDomainSuggestions = (id) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_SUGGESTION_REQUEST
        });
        API.domain.GET.suggestion(id)
            .then((response) => {
                const domain_suggestion_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: DOMAIN_SUGGESTION_SUCCESS,
                    domain_suggestion_data
                });
            })
            .catch((error) => {
                const domain_suggestion_error = getErrorFromFailResponse(error);
                dispatch({
                    type: DOMAIN_SUGGESTION_ERROR,
                    domain_suggestion_error
                });
            });
    };
};

let dedupeGetDomainRenew = false;
export const getDomainRenew = (id = false) => {
    const { dispatch } = store;
    // Fuck fixing this, we're so close to getting TanStack query i'm not going to waste precious katana hours to fix such a stupid issue.
    if (String(dedupeGetDomainRenew) === String(id) || !id) {
        return;
    }
    dedupeGetDomainRenew = id;
    cancelGetDomainRenew?.();

    dispatch({
        type: DOMAIN_RENEW_OPTIONS_REQUEST
    });
    API.domain.GET.renew(id)
        .then((response) => {
            const domain_renew_options_data = getDataFromSuccessResponse(response);
            dispatch({
                type: DOMAIN_RENEW_OPTIONS_SUCCESS,
                domain_renew_options_data
            });
        })
        .catch((error) => {
            const domain_renew_options_error = getErrorFromFailResponse(error);
            pushNotification(domain_renew_options_error);
            dispatch({
                type: DOMAIN_RENEW_OPTIONS_ERROR,
                domain_renew_options_error
            });
        })
        .finally(() => {
            dedupeGetDomainRenew = false;
        })
        .catch((error) => {
            pushNotification({ status: 400, details: error.message });
        });
};

export const renewDomain = (id, attributes, options) => {
    const { dispatch } = store;

    dispatch({
        type: DOMAIN_RENEW_REQUEST
    });

    API.domain.POST.renew(id, attributes)
        .then((response) => {
            const domain_renew_data = getDataFromSuccessResponse(response);
            dispatch({
                type: DOMAIN_RENEW_SUCCESS,
                domain_renew_data
            });

            options?.onSuccess?.(domain_renew_data);
        })
        .catch((error) => {
            const domain_renew_error = getErrorFromFailResponse(error);
            pushNotification(domain_renew_error);
            dispatch({
                type: DOMAIN_RENEW_ERROR,
                domain_renew_error
            });

            options?.onError?.();
        });
};

export const applyRenewPromo = (id, attributes, options) => {
    const { dispatch } = store;

    API.domain.POST.promo(id, attributes)
        .then((response) => {
            const domain_renew_apply_promo_data = getDataFromSuccessResponse(response);
            dispatch({
                type: DOMAIN_RENEW_APPLY_PROMO_SUCCESS,
                domain_renew_apply_promo_data
            });

            options?.onSuccess?.(domain_renew_apply_promo_data);
        })
        .catch((error) => {
            const domain_renew_apply_promo_error = getErrorFromFailResponse(error);
            pushNotification(domain_renew_apply_promo_error);
            dispatch({
                type: DOMAIN_RENEW_APPLY_PROMO_ERROR,
                domain_renew_apply_promo_error
            });

            options?.onError?.(domain_renew_apply_promo_error);
        });
};

/**
 * @param {string} id
 * @param {{
 *   onSuccess?: () => void
 * }} [options]
 */
export const enableAutoRenew = (id, options) => {
    const successNotification = (
        <span>
            Successfully enabled auto-renew.
            <br />
            <br />
            <Text bold>
                <span>Please note: </span>
            </Text>
            If this domain is due for renewal, please allow some time for the renewal invoice to be generated.
        </span>
    );

    return (dispatch) => {
        dispatch({
            type: DOMAIN_AUTO_RENEW_REQUEST
        });
        API.domain.POST.enableRenew(id)
            .then((response) => {
                const domain_auto_renew_data = getDataFromSuccessResponse(response);
                pushNotification({ status: 200, details: successNotification });
                options?.onSuccess?.();

                return API.domain.GET.information(id).then((response) => {
                    const domain_information_data = getDataFromSuccessResponse(response);
                    dispatch({
                        type: DOMAIN_INFORMATION_SUCCESS,
                        domain_information_data
                    });
                    dispatch({
                        type: DOMAIN_AUTO_RENEW_SUCCESS,
                        domain_auto_renew_data
                    });
                });
            })
            .catch((error) => {
                const domain_auto_renew_error = getErrorFromFailResponse(error);
                pushNotification(domain_auto_renew_error);
                dispatch({
                    type: DOMAIN_AUTO_RENEW_ERROR,
                    domain_auto_renew_error
                });
            });
    };
};

export const disableAutoRenew = (id) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_AUTO_RENEW_REQUEST
        });
        API.domain.POST.disableRenew(id)
            .then((response) => {
                const domain_auto_renew_data = getDataFromSuccessResponse(response);
                pushNotification(domain_auto_renew_data);

                return API.domain.GET.information(id).then((response) => {
                    const domain_information_data = getDataFromSuccessResponse(response);
                    dispatch({
                        type: DOMAIN_INFORMATION_SUCCESS,
                        domain_information_data
                    });
                    dispatch({
                        type: DOMAIN_AUTO_RENEW_SUCCESS,
                        domain_auto_renew_data
                    });
                });
            })
            .catch((error) => {
                const domain_auto_renew_error = getErrorFromFailResponse(error);
                pushNotification(domain_auto_renew_error);
                dispatch({
                    type: DOMAIN_AUTO_RENEW_ERROR,
                    domain_auto_renew_error
                });
            });
    };
};

export const getDomainContacts = (id, search = false) => {
    return (dispatch) => {
        if (search) {
            dispatch({
                type: DOMAIN_CONTACT_THROUGH_SEARCH_REQUEST
            });
            API.domain.GET.contact(id)
                .then((response) => {
                    const domain_contact_through_search_data = getDataFromSuccessResponse(response);
                    dispatch({
                        type: DOMAIN_CONTACT_THROUGH_SEARCH_SUCCESS,
                        domain_contact_through_search_data
                    });
                })
                .catch((error) => {
                    const domain_contact_through_search_error = getErrorFromFailResponse(error);
                    dispatch({
                        type: DOMAIN_CONTACT_THROUGH_SEARCH_ERROR,
                        domain_contact_through_search_error
                    });
                });
        } else {
            dispatch({
                type: DOMAIN_CONTACT_REQUEST
            });
            API.domain.GET.contact(id)
                .then((response) => {
                    const domain_contact_data = getDataFromSuccessResponse(response);
                    dispatch({
                        type: DOMAIN_CONTACT_SUCCESS,
                        domain_contact_data
                    });
                })
                .catch((error) => {
                    const domain_contact_error = getErrorFromFailResponse(error);
                    dispatch({
                        type: DOMAIN_CONTACT_ERROR,
                        domain_contact_error
                    });
                });
        }
    };
};

export const updateRegistrantEmail = (id, attributes) => {
    const { dispatch } = store;
    dispatch({
        type: DOMAIN_EMAIL_UPDATE_REQUEST
    });
    API.domain.PUT.contact(id, attributes)
        .then((response) => {
            const domain_email_update_data = getDataFromSuccessResponse(response);
            pushNotification(domain_email_update_data);

            return API.domain.GET.contact(id).then((response) => {
                const domain_contact_data = getDataFromSuccessResponse(response);
                dispatch(
                    batchActions([
                        {
                            type: DOMAIN_CONTACT_SUCCESS,
                            domain_contact_data
                        },
                        {
                            type: DOMAIN_EMAIL_UPDATE_SUCCESS,
                            domain_email_update_data
                        }
                    ])
                );
                getDomainInformation(id);
            });
        })
        .catch((error) => {
            const domain_email_update_error = getErrorFromFailResponse(error);
            pushNotification(domain_email_update_error);
            dispatch({
                type: DOMAIN_EMAIL_UPDATE_ERROR,
                domain_email_update_error
            });
        });
};

export const updateDomainContact = (id, attributes, contactType) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_CONTACT_UPDATE_REQUEST
        });
        API.domain.PUT.contact(id, attributes)
            .then((response) => {
                const domain_contact_update_data = getDataFromSuccessResponse(response);
                const newDomainContactUpdateData = {
                    ...domain_contact_update_data,
                    details: `${domain_contact_update_data.details} for ${contactType} contact`
                };
                pushNotification(newDomainContactUpdateData);

                return API.domain.GET.contact(id).then((response) => {
                    const domain_contact_data = getDataFromSuccessResponse(response);

                    if ('registrant' in attributes) {
                        return API.domain.GET.information(id).then((response) => {
                            const domain_information_data = getDataFromSuccessResponse(response);

                            dispatch(
                                batchActions([
                                    {
                                        type: DOMAIN_INFORMATION_SUCCESS,
                                        domain_information_data
                                    },
                                    {
                                        type: DOMAIN_CONTACT_SUCCESS,
                                        domain_contact_data
                                    },
                                    {
                                        type: DOMAIN_CONTACT_UPDATE_SUCCESS,
                                        domain_contact_update_data
                                    }
                                ])
                            );
                        });
                    } else {
                        dispatch(
                            batchActions([
                                {
                                    type: DOMAIN_CONTACT_SUCCESS,
                                    domain_contact_data
                                },
                                {
                                    type: DOMAIN_CONTACT_UPDATE_SUCCESS,
                                    domain_contact_update_data
                                }
                            ])
                        );
                    }
                });
            })
            .catch((error) => {
                const domain_contact_update_error = getErrorFromFailResponse(error);
                dispatch({
                    type: DOMAIN_CONTACT_UPDATE_ERROR,
                    domain_contact_update_error
                });
                pushNotification(domain_contact_update_error);
            });
    };
};

/**********************************************************************************************************
 *   ACTIONS - DNS
 **********************************************************************************************************/

export const getDomainRecords = (id, options) => {
    const { dispatch } = store;
    dispatch({
        type: DOMAIN_CONFIGURATION_REQUEST
    });

    return Promise.all([API.domain.GET.default(id), API.domain.GET.dns(id)])
        .then(([defaultConfig, currentConfig]) => {
            const domain_default_configuration_data = getDataFromSuccessResponse(defaultConfig);
            const domain_current_configuration_data = getDataFromSuccessResponse(currentConfig);
            const { attributes } = domain_current_configuration_data;

            const currentRecordArr = [];
            Object.keys(attributes.dns_records).forEach((index) => {
                currentRecordArr.push({
                    id: attributes.dns_records[index].id,
                    attributes: attributes.dns_records[index].attributes,
                    type: attributes.dns_records[index].type
                });
            });

            const defaultRecordArr = [];
            Object.keys(domain_default_configuration_data).forEach((index) => {
                defaultRecordArr.push({
                    id: generateId(),
                    attributes: domain_default_configuration_data[index].attributes,
                    type: domain_default_configuration_data[index].type
                });
            });

            const domain_record_store = dispatch(
                processRecords({
                    records: attributes.dns_records,
                    stores: 'records',
                    result: true
                })
            );

            const domain_nameserver_store = dispatch(
                processRecords({
                    records: domain_current_configuration_data,
                    stores: 'nameservers',
                    result: true
                })
            );

            const domain_default_record_store = dispatch(
                processRecords({
                    records: defaultRecordArr,
                    stores: 'default',
                    result: true
                })
            );

            dispatch({
                type: DOMAIN_CONFIGURATION_SUCCESS,
                domain_current_configuration_data,
                domain_default_configuration_data,
                domain_dns_view: {
                    state: 'view',
                    config: attributes.config
                },
                domain_record_store,
                domain_default_record_store,
                domain_nameserver_store
            });
        })
        .catch((error) => {
            const domain_configuration_error = getErrorFromFailResponse(error);
            if (!options || !options.hideError) {
                pushNotification(domain_configuration_error);
            }
            dispatch({
                type: DOMAIN_CONFIGURATION_ERROR,
                domain_configuration_error
            });
        });
};

export const overrideCurrentConfiguration = (id) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_RECORD_STORE_AWAIT
        });

        API.domain.GET.dns(id)
            .then((response) => {
                const domain_current_configuration_data = getDataFromSuccessResponse(response);
                const { attributes } = domain_current_configuration_data;

                const currentRecordArr = [];
                Object.keys(attributes.dns_records).forEach((index) => {
                    currentRecordArr.push({
                        id: attributes.dns_records[index].id,
                        attributes: attributes.dns_records[index].attributes,
                        type: attributes.dns_records[index].type
                    });
                });

                dispatch(
                    batchActions([
                        {
                            type: DOMAIN_CURRENT_CONFIGURATION_SUCCESS,
                            domain_current_configuration_data
                        },
                        processRecords({
                            records: currentRecordArr,
                            stores: 'records',
                            result: false
                        })
                    ])
                );
            })
            .catch((error) => {
                const domain_current_configuration_error = getErrorFromFailResponse(error);
                pushNotification(domain_current_configuration_error);
                dispatch({
                    type: DOMAIN_CURRENT_CONFIGURATION_ERROR,
                    domain_current_configuration_error
                });
            });
    };
};

export const processRecords = (data) => {
    return (dispatch) => {
        const recordArr = [];
        const nameserverArr = [];

        if (data.records) {
            if (data.records.attributes && data.records.attributes.dns_records) {
                data.records.attributes.dns_records
                    .filter((record) => {
                        return record.attributes.dns_type !== 'SOA';
                    })
                    .forEach((record) => {
                        recordArr.push(record);
                    });
            }

            if (data.records.data && data.records.data.length > 0) {
                data.records.data
                    .filter((record) => {
                        return record.attributes.dns_type !== 'SOA';
                    })
                    .forEach((record) => {
                        recordArr.push(record);
                    });
            }

            if (data.records.attributes && data.records.attributes.nameservers) {
                data.records.attributes.nameservers.forEach((record) => {
                    nameserverArr.push(record);
                    recordArr.push(record);
                });
            }

            if (data.records.length > 0) {
                data.records
                    .filter((record) => {
                        return record.attributes.dns_type !== 'SOA';
                    })
                    .forEach((record) => {
                        recordArr.push(record);
                    });
            }
        }

        if (data.result) {
            if (Array.isArray(data.stores)) {
                data.stores.forEach((type) => {
                    switch (type) {
                        case 'nameservers':
                            return nameserverArr;

                        default:
                            return recordArr;
                    }
                });
            } else {
                switch (data.stores) {
                    case 'nameservers':
                        return nameserverArr;

                    default:
                        return recordArr;
                }
            }
        }

        if (data.stores) {
            if (Array.isArray(data.stores)) {
                data.stores.forEach((type) => {
                    type === 'nameservers' ? dispatch(storeRecords(nameserverArr, type)) : dispatch(storeRecords(recordArr, type));
                });
            } else {
                dispatch(storeRecords(recordArr, data.stores));
            }
        } else {
            dispatch(batchActions([storeRecords(recordArr, 'records'), storeRecords(nameserverArr, 'nameservers')]));
        }
    };
};

export const storeRecords = (values, type) => {
    return (dispatch) => {
        switch (type) {
            case 'nameservers':
                dispatch({
                    type: DOMAIN_NAMESERVER_STORE,
                    domain_nameserver_store: values
                });
                break;

            case 'records':
                dispatch({
                    type: DOMAIN_RECORD_STORE,
                    domain_record_store: values
                });
                break;

            default:
                dispatch({
                    type: DOMAIN_DEFAULT_RECORD_STORE,
                    domain_default_record_store: values
                });
                break;
        }
    };
};

export const exportDNSRecord = (id) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_DNS_RECORDS_EXPORT_REQUEST
        });
        API.domain.POST.export(id)
            .then((response) => {
                const domain_dns_records_export_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: DOMAIN_DNS_RECORDS_EXPORT_SUCCESS,
                    domain_dns_records_export_data
                });
            })
            .catch((error) => {
                const domain_dns_records_export_error = getErrorFromFailResponse(error);
                dispatch({
                    type: DOMAIN_DNS_RECORDS_EXPORT_ERROR,
                    domain_dns_records_export_error
                });
            });
    };
};

export const updateDnsConfiguration = (id, attributes) => {
    const { dispatch } = store;

    // Temporary plaster to fix the type because it's meant to be a number
    const ID = Number(id);

    // Whenever the user updates their DNS configuration, we need to remove the domain service details for that domain id in katana
    katanaQuery.serviceID.domainServiceDetails.resetQueriesByDomainID(ID);

    dispatch({
        type: DOMAIN_UPDATE_CURRENT_CONFIGURATION_REQUEST
    });

    return API.domain.PUT.configuration(ID, attributes)
        .then((domain_update_current_configuration_data) => {
            handleDefaultSuccessNotification(domain_update_current_configuration_data);
            dispatch({
                type: DOMAIN_RECORD_STORE_AWAIT
            });

            getDomainRecords(ID);
            dispatch(
                batchActions([
                    {
                        type: DOMAIN_RECORD_STORE_AWAIT
                    },
                    {
                        type: DOMAIN_UPDATE_CURRENT_CONFIGURATION_SUCCESS,
                        domain_update_current_configuration_data
                    }
                ])
            );
            return getDomainRecords(id);
        })
        .catch((error) => {
            const domain_update_current_configuration_error = getErrorFromFailResponse(error);
            pushNotification(domain_update_current_configuration_error);
            dispatch(
                batchActions([
                    {
                        type: DOMAIN_UPDATE_CURRENT_CONFIGURATION_ERROR,
                        domain_update_current_configuration_error
                    }
                ])
            );
        });
};

export const addDnsRecord = (id, attributes, callbackFunc) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_RECORD_REQUEST
        });
        API.domain.POST.record(id, attributes)
            .then((response) => {
                const domain_record_data = getDataFromSuccessResponse(response);
                pushNotification(domain_record_data);

                dispatch(
                    batchActions([
                        {
                            type: DOMAIN_RECORD_SUCCESS,
                            domain_record_data
                        },
                        callbackFunc ? callbackFunc() : overrideCurrentConfiguration(id)
                    ])
                );
            })
            .catch((error) => {
                const domain_record_error = getErrorFromFailResponse(error);
                pushNotification(domain_record_error);
                dispatch(
                    batchActions([
                        {
                            type: DOMAIN_RECORD_FAIL,
                            domain_record_error
                        }
                    ])
                );
            });
    };
};

export const editDnsRecord = (id, rid, attributes) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_RECORD_REQUEST
        });
        API.domain.PUT.record(id, rid, attributes)
            .then((response) => {
                const domain_record_data = getDataFromSuccessResponse(response, 'Successfully updated DNS record');

                pushNotification(domain_record_data);
                dispatch(
                    batchActions([
                        {
                            type: DOMAIN_RECORD_SUCCESS,
                            domain_record_data
                        },
                        overrideCurrentConfiguration(id)
                    ])
                );
            })
            .catch((error) => {
                const domain_record_error = getErrorFromFailResponse(error);
                pushNotification(domain_record_error);
                dispatch(
                    batchActions([
                        {
                            type: DOMAIN_RECORD_FAIL,
                            domain_record_error
                        }
                    ])
                );
            });
    };
};

export const deleteDnsRecord = (id, record) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_RECORD_REQUEST
        });
        API.domain.DELETE.record(id, record)
            .then((response) => {
                const domain_record_data = getDataFromSuccessResponse(response);

                pushNotification(domain_record_data);
                dispatch(
                    batchActions([
                        {
                            type: DOMAIN_RECORD_SUCCESS,
                            domain_record_data
                        },
                        overrideCurrentConfiguration(id)
                    ])
                );
            })
            .catch((error) => {
                const domain_record_error = getErrorFromFailResponse(error);
                pushNotification(domain_record_error);
                dispatch(
                    batchActions([
                        {
                            type: DOMAIN_RECORD_FAIL,
                            domain_record_error
                        }
                    ])
                );
            });
    };
};

/**
 * retrieves the changes that will be made to the domain when a preset is applied
 *
 * @param {number} domainId - Obfuscated domain ID
 * @param {import('containers/domain/forms/presets/types').TApiPresetName} preset - Preset name
 * @param {{
 *   replacements: {} | {
 *     verification_code: number
 *   }
 * }} attributes - Attributes to send to the API
 */
export const previewPresetChanges = (domainId, preset, attributes) => {
    const { dispatch } = store;
    cancelPreviewPresetChanges?.();

    dispatch({
        type: DOMAIN_PREVIEW_PRESET_CHANGES_REQUEST
    });

    API.domain.POST.presetPreview({ id: domainId, preset, attributes })
        .then((response) => {
            const domain_preview_preset_changes_data = getDataFromSuccessResponse(response);

            dispatch({
                type: DOMAIN_PREVIEW_PRESET_CHANGES_SUCCESS,
                domain_preview_preset_changes_data
            });
        })
        .catch((e) => {
            // It's only a real error if we didn't cancel the request manually
            if (e.code !== 'ERR_CANCELED') {
                dispatch({
                    type: DOMAIN_PREVIEW_PRESET_CHANGES_ERROR
                });
            }
        });
};

export const resetDnsRecord = (id) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_RECORD_REQUEST
        });
        API.domain.POST.reset(id)
            .then((response) => {
                const domain_record_data = getDataFromSuccessResponse(response);

                pushNotification(domain_record_data);
                dispatch(
                    batchActions([
                        {
                            type: DOMAIN_RECORD_SUCCESS,
                            domain_record_data
                        },
                        overrideCurrentConfiguration(id)
                    ])
                );
            })
            .catch((error) => {
                const domain_record_error = getErrorFromFailResponse(error);
                pushNotification(domain_record_error);
                dispatch(
                    batchActions([
                        {
                            type: DOMAIN_RECORD_FAIL,
                            domain_record_error
                        }
                    ])
                );
            });
    };
};

export const getChildNameservers = (id) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_CHILD_RECORD_LIST_REQUEST
        });
        API.domain.GET.child(id)
            .then((response) => {
                const domain_child_record_list_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: DOMAIN_CHILD_RECORD_LIST_SUCCESS,
                    domain_child_record_list_data
                });
            })
            .catch((error) => {
                const domain_child_record_list_error = getErrorFromFailResponse(error);
                pushNotification(domain_child_record_list_error);
                dispatch(
                    batchActions([
                        {
                            type: DOMAIN_CHILD_RECORD_LIST_ERROR,
                            domain_child_record_list_error
                        }
                    ])
                );
            });
    };
};

export const addChildNameserver = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_CHILD_RECORD_REQUEST
        });
        API.domain.POST.child(id, attributes)
            .then((response) => {
                const domain_child_record_data = getDataFromSuccessResponse(response);
                pushNotification(domain_child_record_data);
                dispatch(
                    batchActions([
                        {
                            type: DOMAIN_CHILD_RECORD_SUCCESS,
                            domain_child_record_data
                        },
                        getChildNameservers(id)
                    ])
                );
            })
            .catch((error) => {
                const domain_child_record_error = getErrorFromFailResponse(error);
                pushNotification(domain_child_record_error);
                dispatch(
                    batchActions([
                        {
                            type: DOMAIN_CHILD_RECORD_ERROR,
                            domain_child_record_error
                        }
                    ])
                );
            });
    };
};

export const deleteChildNameserver = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_CHILD_RECORD_REQUEST
        });
        API.domain.DELETE.child(id, attributes)
            .then((response) => {
                const domain_child_record_data = getDataFromSuccessResponse(response);
                pushNotification(domain_child_record_data);
                dispatch(
                    batchActions([
                        {
                            type: DOMAIN_CHILD_RECORD_SUCCESS,
                            domain_child_record_data
                        },
                        getChildNameservers(id)
                    ])
                );
            })
            .catch((error) => {
                const domain_child_record_error = getErrorFromFailResponse(error);
                pushNotification(domain_child_record_error);
                dispatch(
                    batchActions([
                        {
                            type: DOMAIN_CHILD_RECORD_ERROR,
                            domain_child_record_error
                        }
                    ])
                );
            });
    };
};

export const addHostIP = (id, hostname, attributes) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_CHILD_RECORD_REQUEST
        });
        API.domain.POST.hostip(id, hostname, attributes)
            .then((response) => {
                const domain_child_record_data = getDataFromSuccessResponse(response);
                pushNotification(domain_child_record_data);
                dispatch(
                    batchActions([
                        {
                            type: DOMAIN_CHILD_RECORD_SUCCESS,
                            domain_child_record_data
                        },
                        getChildNameservers(id)
                    ])
                );
            })
            .catch((error) => {
                const domain_child_record_error = getErrorFromFailResponse(error);
                pushNotification(domain_child_record_error);
                dispatch(
                    batchActions([
                        {
                            type: DOMAIN_CHILD_RECORD_ERROR,
                            domain_child_record_error
                        }
                    ])
                );
            });
    };
};

export const deleteHostIP = (id, hostname, attributes) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_CHILD_RECORD_DELETE_REQUEST
        });
        API.domain.DELETE.hostip(id, hostname, attributes)
            .then((response) => {
                const domain_child_record_delete_data = getDataFromSuccessResponse(response);
                pushNotification(domain_child_record_delete_data);
                dispatch(
                    batchActions([
                        {
                            type: DOMAIN_CHILD_RECORD_DELETE_SUCCESS,
                            domain_child_record_delete_data
                        },
                        getChildNameservers(id)
                    ])
                );
            })
            .catch((error) => {
                const domain_child_record_delete_error = getErrorFromFailResponse(error);
                pushNotification(domain_child_record_delete_error);
                dispatch(
                    batchActions([
                        {
                            type: DOMAIN_CHILD_RECORD_DELETE_ERROR,
                            domain_child_record_delete_error
                        }
                    ])
                );
            });
    };
};

/**
 * Only shows Toast if it is not a known/handled error
 */
const handleKnownDNSSECErrors = (error) => {
    // Only show Toast if it is not a known/handled error
    if (error?.details !== 'DNSSEC is not supported for this domain extension.') {
        pushNotification(error);
    }
};

export const getDnssecOptions = () => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_DNSSEC_OPTIONS_REQUEST
        });
        API.domain.GET.dnssecOptions()
            .then((response) => {
                const domain_dnssec_options_data = getDataFromSuccessResponse(response);

                dispatch({
                    type: DOMAIN_DNSSEC_OPTIONS_SUCCESS,
                    domain_dnssec_options_data
                });
            })
            .catch((error) => {
                const domain_dnssec_options_error = getErrorFromFailResponse(error);

                handleKnownDNSSECErrors(domain_dnssec_options_error);

                dispatch({
                    type: DOMAIN_DNSSEC_OPTIONS_ERROR,
                    domain_dnssec_options_error
                });
            });
    };
};

/**
 * @param {number} id - Obfuscated domain ID
 *
 * Redux State: (state.domain)
 * - domain_dnssec_list_status
 * - domain_dnssec_list_data
 * - domain_dnssec_list_error
 *
 * @link {src/containers/domain/reducer.js}
 */
export const getDnssecData = (id) => {
    const { dispatch } = store;

    dispatch({
        type: DOMAIN_DNSSEC_LIST_REQUEST
    });

    API.domain.GET.dnssec(id)
        .then((response) => {
            const domain_dnssec_list_data = getDataFromSuccessResponse(response);
            dispatch({
                type: DOMAIN_DNSSEC_LIST_SUCCESS,
                domain_dnssec_list_data
            });
        })
        .catch((error) => {
            const domain_dnssec_list_error = getErrorFromFailResponse(error);

            handleKnownDNSSECErrors(domain_dnssec_list_error);

            // This endpoint throws an error when DNSSEC is not available for the TLD
            dispatch({
                type: DOMAIN_DNSSEC_LIST_ERROR,
                domain_dnssec_list_error
            });
        });
};

export const addDnssecData = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_DNSSEC_REQUEST
        });
        API.domain.POST.dnssec(id, attributes)
            .then((response) => {
                const domain_dnssec_data = getDataFromSuccessResponse(response);
                pushNotification(domain_dnssec_data);
                getDnssecData(id);
                dispatch({
                    type: DOMAIN_DNSSEC_SUCCESS,
                    domain_dnssec_data
                });
            })
            .catch((error) => {
                const domain_dnssec_error = getErrorFromFailResponse(error);
                pushNotification(domain_dnssec_error);
                dispatch({
                    type: DOMAIN_DNSSEC_ERROR,
                    domain_dnssec_error
                });
            });
    };
};

export const deleteDnssecData = (id, uuid) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_DNSSEC_REQUEST
        });
        API.domain.DELETE.dnssec(id, uuid)
            .then((response) => {
                const domain_dnssec_data = getDataFromSuccessResponse(response);
                pushNotification(domain_dnssec_data);
                getDnssecData(id);
                dispatch({
                    type: DOMAIN_DNSSEC_SUCCESS,
                    domain_dnssec_data
                });
            })
            .catch((error) => {
                const domain_dnssec_error = getErrorFromFailResponse(error);
                pushNotification(domain_dnssec_error);
                dispatch({
                    type: DOMAIN_DNSSEC_ERROR,
                    domain_dnssec_error
                });
            });
    };
};

/**
 * @param {number} id Domain ID
 */
export const enableDnssec = (id) => {
    const { dispatch } = store;

    dispatch({
        type: DOMAIN_DNSSEC_ENABLE_REQUEST
    });
    API.domain.POST.dnssecEnable(id)
        .then((response) => {
            getDnssecData(id);
            getDnssecEnabledStatus(id);
            pushNotification({ status: 200, details: 'Successfully Enabled DNSSEC' });
            dispatch({
                type: DOMAIN_DNSSEC_ENABLE_SUCCESS
            });
        })
        .catch((error) => {
            const domain_dnssec_enable_error = getErrorFromFailResponse(error);

            pushNotification(domain_dnssec_enable_error);
            dispatch({
                type: DOMAIN_DNSSEC_ENABLE_ERROR
            });
        });
};

/**
 * @param {number} id Domain ID
 */
export const disableDnssec = (id) => {
    const { dispatch } = store;

    dispatch({
        type: DOMAIN_DNSSEC_DISABLE_REQUEST
    });
    API.domain.POST.dnssecDisable(id)
        .then((response) => {
            getDnssecData(id);
            getDnssecEnabledStatus(id);
            pushNotification({ status: 200, details: 'Successfully Disabled DNSSEC' });
            dispatch({
                type: DOMAIN_DNSSEC_DISABLE_SUCCESS
            });
        })
        .catch((error) => {
            const domain_dnssec_disable_error = getErrorFromFailResponse(error);
            pushNotification(domain_dnssec_disable_error);
            dispatch({
                type: DOMAIN_DNSSEC_DISABLE_ERROR
            });
        });
};

/**
 * @param {number} id Domain ID
 */
export const getDnssecEnabledStatus = (id) => {
    const { dispatch } = store;

    dispatch({
        type: DOMAIN_DNSSEC_ENABLED_STATUS_REQUEST
    });
    API.domain.GET.dnssecEnabledStatus(id)
        .then((response) => {
            const domain_dnssec_enabled_status_data = getDataFromSuccessResponse(response);
            dispatch({
                type: DOMAIN_DNSSEC_ENABLED_STATUS_SUCCESS,
                domain_dnssec_enabled_status_data
            });
        })
        .catch((error) => {
            const domain_dnssec_enabled_status_error = getErrorFromFailResponse(error);
            pushNotification(domain_dnssec_enabled_status_error);
            // DNSSEC component handles error

            dispatch({
                type: DOMAIN_DNSSEC_ENABLED_STATUS_ERROR
            });
        });
};

export const getWebForwarders = (id) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_WEB_RECORD_LIST_REQUEST
        });
        API.domain.GET.webForwarder(id)
            .then((response) => {
                const domain_web_record_list_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: DOMAIN_WEB_RECORD_LIST_SUCCESS,
                    domain_web_record_list_data
                });
                dispatch({
                    type: DOMAIN_WEB_RECORD_STORE,
                    domain_web_record_store: domain_web_record_list_data
                });
            })
            .catch((error) => {
                const domain_web_record_error = getErrorFromFailResponse(error);
                pushNotification(domain_web_record_error);
                dispatch({
                    type: DOMAIN_WEB_RECORD_LIST_ERROR,
                    domain_web_record_error
                });
            });
    };
};

export const addWebForwarder = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_WEB_RECORD_REQUEST
        });
        API.domain.POST.webForwarder(id, attributes)
            .then((response) => {
                const domain_web_record_data = getDataFromSuccessResponse(response);

                pushNotification(domain_web_record_data);
                dispatch(getWebForwarders(id));

                dispatch({
                    type: DOMAIN_WEB_RECORD_SUCCESS,
                    domain_web_record_data
                });
            })
            .catch((error) => {
                const domain_web_record_error = getErrorFromFailResponse(error);
                pushNotification(domain_web_record_error);
                dispatch({
                    type: DOMAIN_WEB_RECORD_ERROR,
                    domain_web_record_error
                });
            });
    };
};

export const editWebForwarder = (id, record, attributes) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_WEB_RECORD_REQUEST
        });
        API.domain.PUT.webForwarder(id, record, attributes)
            .then((response) => {
                const domain_web_record_data = getDataFromSuccessResponse(response);

                pushNotification(domain_web_record_data);
                dispatch(getWebForwarders(id));

                dispatch({
                    type: DOMAIN_WEB_RECORD_SUCCESS,
                    domain_web_record_data
                });
            })
            .catch((error) => {
                const domain_web_record_error = getErrorFromFailResponse(error);
                pushNotification(domain_web_record_error);
                dispatch({
                    type: DOMAIN_WEB_RECORD_ERROR,
                    domain_web_record_error
                });
            });
    };
};

export const deleteWebForwarder = (id, record) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_WEB_RECORD_REQUEST
        });
        API.domain.DELETE.webForwarder(id, record)
            .then((response) => {
                const domain_web_record_data = getDataFromSuccessResponse(response);

                pushNotification(domain_web_record_data);
                dispatch(getWebForwarders(id));

                dispatch({
                    type: DOMAIN_WEB_RECORD_SUCCESS,
                    domain_web_record_data
                });
            })
            .catch((error) => {
                const domain_web_record_error = getErrorFromFailResponse(error);
                pushNotification(domain_web_record_error);
                dispatch({
                    type: DOMAIN_WEB_RECORD_ERROR,
                    domain_web_record_error
                });
            });
    };
};

export const storeEmailRecords = (values) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_EMAIL_RECORD_STORE,
            domain_email_record_store: values
        });
    };
};

export const resetEmailRecords = () => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_EMAIL_RECORD_STORE_RESET
        });
    };
};

export const getEmailForwarders = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_EMAIL_RECORD_LIST_REQUEST
        });
        API.domain.GET.emailForwarder(id, attributes)
            .then((response) => {
                const domain_email_record_list_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: DOMAIN_EMAIL_RECORD_LIST_SUCCESS,
                    domain_email_record_list_data
                });
                dispatch({
                    type: DOMAIN_EMAIL_RECORD_STORE,
                    domain_email_record_store: domain_email_record_list_data
                });
            })
            .catch((error) => {
                const domain_email_record_error = getErrorFromFailResponse(error);
                pushNotification(domain_email_record_error);
                dispatch({
                    type: DOMAIN_EMAIL_RECORD_LIST_ERROR,
                    domain_email_record_error
                });
            });
    };
};

export const addEmailForwarder = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_EMAIL_RECORD_REQUEST
        });
        API.domain.POST.emailForwarder(id, attributes)
            .then((response) => {
                const domain_email_record_data = getDataFromSuccessResponse(response);

                pushNotification(domain_email_record_data);
                dispatch(getEmailForwarders(id));

                dispatch({
                    type: DOMAIN_EMAIL_RECORD_SUCCESS,
                    domain_email_record_data
                });
            })
            .catch((error) => {
                const domain_email_record_error = getErrorFromFailResponse(error);
                pushNotification(domain_email_record_error);
                dispatch({
                    type: DOMAIN_EMAIL_RECORD_ERROR,
                    domain_email_record_error
                });
            });
    };
};

export const editEmailForwarder = (id, record, attributes) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_EMAIL_RECORD_REQUEST
        });
        API.domain.PUT.emailForwarder(id, record, attributes)
            .then((response) => {
                const domain_email_record_data = getDataFromSuccessResponse(response);

                pushNotification(domain_email_record_data);
                dispatch(getEmailForwarders(id));

                dispatch({
                    type: DOMAIN_EMAIL_RECORD_SUCCESS,
                    domain_email_record_data
                });
            })
            .catch((error) => {
                const domain_email_record_error = getErrorFromFailResponse(error);
                pushNotification(domain_email_record_error);
                dispatch({
                    type: DOMAIN_EMAIL_RECORD_ERROR,
                    domain_email_record_error
                });
            });
    };
};

export const deleteEmailForwarder = (id, record) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_EMAIL_RECORD_REQUEST
        });
        API.domain.DELETE.emailForwarder(id, record)
            .then((response) => {
                const domain_email_record_data = getDataFromSuccessResponse(response);

                pushNotification(domain_email_record_data);
                dispatch(getEmailForwarders(id));

                dispatch({
                    type: DOMAIN_EMAIL_RECORD_SUCCESS,
                    domain_email_record_data
                });
            })
            .catch((error) => {
                const domain_email_record_error = getErrorFromFailResponse(error);
                pushNotification(domain_email_record_error);
                dispatch({
                    type: DOMAIN_EMAIL_RECORD_ERROR,
                    domain_email_record_error
                });
            });
    };
};

/**********************************************************************************************************
 *   ACTIONS - SECURITY
 **********************************************************************************************************/

export const getIdProtection = (id) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_PROTECTION_CHECK_REQUEST
        });
        API.domain.GET.protection(id)
            .then((response) => {
                const domain_protection_check_data = getDataFromSuccessResponse(response);

                dispatch({
                    type: DOMAIN_PROTECTION_CHECK_SUCCESS,
                    domain_protection_check_data
                });
            })
            .catch((error) => {
                const domain_protection_check_error = getErrorFromFailResponse(error);
                dispatch({
                    type: DOMAIN_PROTECTION_CHECK_ERROR,
                    domain_protection_check_error
                });
            });
    };
};

export const enableIdProtection = (id) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_PROTECTION_REQUEST
        });
        API.domain.PUT.enableProtection(id)
            .then((response) => {
                const domain_protection_data = getDataFromSuccessResponse(response);
                pushNotification(domain_protection_data);
                dispatch({
                    type: DOMAIN_PROTECTION_SUCCESS,
                    domain_protection_data
                });
                dispatch(getIdProtection(id));
            })
            .catch((error) => {
                const domain_protection_error = getErrorFromFailResponse(error);
                pushNotification(domain_protection_error);
                dispatch({
                    type: DOMAIN_PROTECTION_ERROR,
                    domain_protection_error
                });
            });
    };
};

export const disableIdProtection = (id) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_PROTECTION_REQUEST
        });
        API.domain.PUT.disableProtection(id)
            .then((response) => {
                const domain_protection_data = getDataFromSuccessResponse(response);
                pushNotification(domain_protection_data);
                dispatch({
                    type: DOMAIN_PROTECTION_SUCCESS,
                    domain_protection_data
                });
                dispatch(getIdProtection(id));
            })
            .catch((error) => {
                const domain_protection_error = getErrorFromFailResponse(error);
                pushNotification(domain_protection_error);
                dispatch({
                    type: DOMAIN_PROTECTION_ERROR,
                    domain_protection_error
                });
            });
    };
};

export const getWhois = (id) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_WHOIS_REQUEST
        });
        API.domain.GET.whois(id)
            .then((response) => {
                const domain_whois_data = getDataFromSuccessResponse(response);

                dispatch({
                    type: DOMAIN_WHOIS_SUCCESS,
                    domain_whois_data
                });
            })
            .catch((error) => {
                const domain_whois_error = getErrorFromFailResponse(error);

                dispatch({
                    type: DOMAIN_WHOIS_ERROR,
                    domain_whois_error
                });
            });
    };
};

export const getTransferLock = (id) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_LOCK_CHECK_REQUEST
        });
        API.domain.GET.lock(id)
            .then((response) => {
                const domain_lock_check_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: DOMAIN_LOCK_CHECK_SUCCESS,
                    domain_lock_check_data
                });
            })
            .catch((error) => {
                const domain_lock_check_error = getErrorFromFailResponse(error);
                dispatch({
                    type: DOMAIN_LOCK_CHECK_ERROR,
                    domain_lock_check_error
                });
            });
    };
};

export const enableTransferLock = (id) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_LOCK_REQUEST
        });
        API.domain.PUT.lock(id)
            .then((response) => {
                const domain_lock_data = getDataFromSuccessResponse(response);
                pushNotification(domain_lock_data);
                dispatch({
                    type: DOMAIN_LOCK_SUCCESS,
                    domain_lock_data
                });
                dispatch(getTransferLock(id));
            })
            .catch((error) => {
                const domain_lock_error = getErrorFromFailResponse(error);
                pushNotification(domain_lock_error);
                dispatch({
                    type: DOMAIN_LOCK_ERROR,
                    domain_lock_error
                });
            });
    };
};

export const disableTransferLock = (id) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_LOCK_REQUEST
        });
        API.domain.PUT.unlock(id)
            .then((response) => {
                const domain_lock_data = getDataFromSuccessResponse(response);
                pushNotification(domain_lock_data);
                dispatch({
                    type: DOMAIN_LOCK_SUCCESS,
                    domain_lock_data
                });
                dispatch(getTransferLock(id));
            })
            .catch((error) => {
                const domain_lock_error = getErrorFromFailResponse(error);
                pushNotification(domain_lock_error);
                dispatch({
                    type: DOMAIN_LOCK_ERROR,
                    domain_lock_error
                });
            });
    };
};

/**********************************************************************************************************
 *   EPP CODE
 **********************************************************************************************************/

export const getEppCode = (id) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_EPP_REQUEST
        });
        API.domain.GET.epp(id)
            .then((response) => {
                const domain_epp_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: DOMAIN_EPP_SUCCESS,
                    domain_epp_data
                });
            })
            .catch((error) => {
                const domain_epp_error = getErrorFromFailResponse(error);
                dispatch({
                    type: DOMAIN_EPP_ERROR,
                    domain_epp_error
                });
            });
    };
};

/**********************************************************************************************************
 *   ACTIONS - ADMIN
 **********************************************************************************************************/

export const getEligibility = (id) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_ELIGIBILITY_REQUEST
        });
        API.domain.GET.eligibility(id)
            .then((response) => {
                const domain_eligibility_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: DOMAIN_ELIGIBILITY_SUCCESS,
                    domain_eligibility_data
                });
            })
            .catch((error) => {
                const domain_eligibility_error = getErrorFromFailResponse(error);
                dispatch({
                    type: DOMAIN_ELIGIBILITY_ERROR,
                    domain_eligibility_error
                });
            });
    };
};

export const getPendingCor = (id) => {
    const { dispatch } = store;
    dispatch({
        type: DOMAIN_COR_REQUEST
    });
    API.domain.GET.cor_pending(id)
        .then((response) => {
            const domain_cor_data = getDataFromSuccessResponse(response);
            dispatch({
                type: DOMAIN_COR_SUCCESS,
                domain_cor_data
            });
        })
        .catch((error) => {
            const pending_cor_error = getErrorFromFailResponse(error);
            pushNotification(pending_cor_error);
            dispatch({
                type: DOMAIN_COR_ERROR
            });
        });
};

export const cancelCor = (id, domainid) => {
    const { dispatch } = store;
    dispatch({
        type: DOMAIN_ACTION_COR_REQUEST
    });
    ACCOUNT.user.invoice.single.cancel
        .POST(id, 'domainCOR')
        .then((response) => {
            const domain_cor_action_data = getDataFromSuccessResponse(response);
            dispatch({
                type: DOMAIN_ACTION_COR_SUCCESS,
                domain_cor_action_data
            });
            getPendingCor(domainid);
        })
        .catch(() => {
            dispatch({
                type: DOMAIN_ACTION_COR_ERROR
            });
        });
};

export const initiateCor = (id, attributes) => {
    const { dispatch } = store;
    dispatch({
        type: DOMAIN_ACTION_COR_REQUEST
    });

    if (attributes.method === 'create') {
        API.account
            .create(id, attributes)
            .then(() => {
                const newAccDetails = {
                    email: attributes.email
                };
                API.domain.POST.cor(id, newAccDetails)
                    .then((response) => {
                        const domain_cor_action_data = getDataFromSuccessResponse(response);
                        dispatch({
                            type: DOMAIN_ACTION_COR_SUCCESS,
                            domain_cor_action_data
                        });
                        getPendingCor(id);
                    })
                    .catch(() => {
                        dispatch({
                            type: DOMAIN_ACTION_COR_ERROR
                        });
                    });
            })
            .catch(() => {
                dispatch({
                    type: DOMAIN_ACTION_COR_ERROR
                });
            });
    } else {
        API.domain.POST.cor(id, attributes)
            .then((response) => {
                const domain_cor_action_data = getDataFromSuccessResponse(response);
                pushNotification(domain_cor_action_data);
                dispatch({
                    type: DOMAIN_ACTION_COR_SUCCESS,
                    domain_cor_action_data
                });
                getPendingCor(id);
            })
            .catch((error) => {
                const domain_cor_action_error = getErrorFromFailResponse(error);
                pushNotification(domain_cor_action_error);
                dispatch({
                    type: DOMAIN_ACTION_COR_ERROR
                });
            });
    }
};

export const initiateManualCOR = (id, attributes) => {
    const { dispatch } = store;

    dispatch({
        type: DOMAIN_ACTION_MANUAL_COR_REQUEST
    });

    return API.domain.POST.manualCor(id, attributes)
        .then((response) => {
            const domain_cor_action_data = getDataFromSuccessResponse(response);
            dispatch({
                type: DOMAIN_ACTION_MANUAL_COR_SUCCESS,
                domain_cor_action_data
            });
            pushNotification(domain_cor_action_data);
            getPendingCor(id);
        })
        .catch((error) => {
            const domain_cor_action_error = getErrorFromFailResponse(error);
            pushNotification(domain_cor_action_error);
            dispatch({
                type: DOMAIN_ACTION_MANUAL_COR_ERROR
            });
        });
};

export const moveDomain = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_MOVE_REQUEST
        });

        if (attributes.method === 'new') {
            API.account
                .create(id, attributes)
                .then((response) => {
                    const domain_move_data = getDataFromSuccessResponse(response);
                    dispatch({
                        type: DOMAIN_MOVE_SUCCESS,
                        domain_move_data
                    });
                })
                .catch((error) => {
                    const domain_move_error = getErrorFromFailResponse(error);
                    pushNotification(domain_move_error);
                    dispatch({
                        type: DOMAIN_MOVE_ERROR,
                        domain_move_error
                    });
                });
        } else {
            API.domain.POST.move(id, attributes)
                .then((response) => {
                    const domain_move_data = getDataFromSuccessResponse(response);
                    dispatch({
                        type: DOMAIN_MOVE_SUCCESS,
                        domain_move_data
                    });
                })
                .catch((error) => {
                    const domain_move_error = getErrorFromFailResponse(error);
                    pushNotification(domain_move_error);
                    dispatch({
                        type: DOMAIN_MOVE_ERROR,
                        domain_move_error
                    });
                });
        }
    };
};

/**********************************************************************************************************
 *   THIRD PARTY RECORDS
 **********************************************************************************************************/

export const setOfficeMXRecords = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_THIRD_PARTY_REQUEST
        });
        API.domain.POST.officeMX(id, attributes)
            .then((response) => {
                const domain_third_party_data = getDataFromSuccessResponse(response);
                pushNotification(domain_third_party_data);
                getDomainRecords(id);
                dispatch(
                    batchActions([
                        {
                            type: DOMAIN_THIRD_PARTY_SUCCESS,
                            domain_third_party_data
                        }
                    ])
                );
            })
            .catch((error) => {
                const domain_third_party_error = getErrorFromFailResponse(error);
                pushNotification(domain_third_party_error);
                dispatch(
                    batchActions([
                        {
                            type: DOMAIN_THIRD_PARTY_ERROR,
                            domain_third_party_error
                        }
                    ])
                );
            });
    };
};

export const setGoogleDNSRecords = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_THIRD_PARTY_REQUEST
        });
        API.domain.POST.googleDNS(id, attributes)
            .then((response) => {
                const domain_third_party_data = getDataFromSuccessResponse(response);
                pushNotification(domain_third_party_data);
                getDomainRecords(id);
                dispatch(
                    batchActions([
                        {
                            type: DOMAIN_THIRD_PARTY_SUCCESS,
                            domain_third_party_data
                        }
                    ])
                );
            })
            .catch((error) => {
                const domain_third_party_error = getErrorFromFailResponse(error);
                pushNotification(domain_third_party_error);
                dispatch(
                    batchActions([
                        {
                            type: DOMAIN_THIRD_PARTY_ERROR,
                            domain_third_party_error
                        }
                    ])
                );
            });
    };
};

export const setVentraMXRecords = (id, dkim, attributes) => {
    return (dispatch) => {
        const handleRequest = () => {
            dispatch({
                type: DOMAIN_THIRD_PARTY_REQUEST
            });

            API.domain.POST.ventraMX(id, attributes)
                .then((response) => {
                    const domain_third_party_data = getDataFromSuccessResponse(response);
                    pushNotification(domain_third_party_data);
                    dispatch(
                        batchActions([
                            {
                                type: DOMAIN_THIRD_PARTY_SUCCESS,
                                domain_third_party_data
                            },
                            overrideCurrentConfiguration(id)
                        ])
                    );
                })
                .catch((error) => {
                    const domain_third_party_error = getErrorFromFailResponse(error);
                    pushNotification(domain_third_party_error);
                    dispatch(
                        batchActions([
                            {
                                type: DOMAIN_THIRD_PARTY_ERROR,
                                domain_third_party_error
                            }
                        ])
                    );
                });
        };

        if (dkim) dispatch(addDnsRecord(id, { type: 'TXT', hostname: dkim.domain, content: dkim.record, ttl: 3600, prio: 0 }, handleRequest));
        else handleRequest();
    };
};

export const setShopifyDNSRecords = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_THIRD_PARTY_REQUEST
        });
        API.domain.POST.shopifyDNS(id, attributes)
            .then((response) => {
                const domain_third_party_data = getDataFromSuccessResponse(response);
                pushNotification(domain_third_party_data);
                getDomainRecords(id);
                dispatch(
                    batchActions([
                        {
                            type: DOMAIN_THIRD_PARTY_SUCCESS,
                            domain_third_party_data
                        }
                    ])
                );
            })
            .catch((error) => {
                const domain_third_party_error = getErrorFromFailResponse(error);
                pushNotification(domain_third_party_error);
                dispatch(
                    batchActions([
                        {
                            type: DOMAIN_THIRD_PARTY_ERROR,
                            domain_third_party_error
                        }
                    ])
                );
            });
    };
};

export const setSquarespaceDNSRecords = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_THIRD_PARTY_REQUEST
        });
        API.domain.POST.squarespaceDNS(id, attributes)
            .then((response) => {
                const domain_third_party_data = getDataFromSuccessResponse(response);
                pushNotification(domain_third_party_data);
                getDomainRecords(id);
                dispatch(
                    batchActions([
                        {
                            type: DOMAIN_THIRD_PARTY_SUCCESS,
                            domain_third_party_data
                        }
                    ])
                );
            })
            .catch((error) => {
                const domain_third_party_error = getErrorFromFailResponse(error);
                pushNotification(domain_third_party_error);
                dispatch(
                    batchActions([
                        {
                            type: DOMAIN_THIRD_PARTY_ERROR,
                            domain_third_party_error
                        }
                    ])
                );
            });
    };
};

/**********************************************************************************************************
 *   SEARCH FOR SUB ACCOUNTS (USED FOR DNS VENTRA HOSTING)
 **********************************************************************************************************/
export const subaccountSearch = (domainId) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_SUBACCOUNT_REQUEST
        });
        API.domain.GET.subaccountSearch(domainId)
            .then((response) => {
                const domain_subaccount_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: DOMAIN_SUBACCOUNT_SUCCESS,
                    domain_subaccount_data
                });
            })
            .catch((error) => {
                const domain_subaccount_error = getErrorFromFailResponse(error);
                pushNotification(domain_subaccount_error);
                dispatch({
                    type: DOMAIN_SUBACCOUNT_FAIL,
                    domain_subaccount_error
                });
            });
    };
};

/**********************************************************************************************************
 *   ADD HOSTING
 **********************************************************************************************************/
export const calculateAddHostingCost = (id, attributes, billingCycle) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_ADD_HOSTING_CALCULATE_REQUEST
        });
        if (cancelCalculateHostingCost) cancelCalculateHostingCost();
        API.domain.POST.calculateHostingCost(id, attributes)
            .then((response) => {
                const domain_add_hosting_calculate_data = getDataFromSuccessResponse(response);
                if (billingCycle) domain_add_hosting_calculate_data.billingCycle = billingCycle;
                dispatch({
                    type: DOMAIN_ADD_HOSTING_CALCULATE_SUCCESS,
                    domain_add_hosting_calculate_data
                });
            })
            .catch((error) => {
                const domain_add_hosting_calculate_error = getErrorFromFailResponse(error);
                if (domain_add_hosting_calculate_error.status !== 'Failed') {
                    dispatch({
                        type: DOMAIN_ADD_HOSTING_CALCULATE_ERROR,
                        domain_add_hosting_calculate_error
                    });
                }
            });
    };
};

export const getCoRPrice = (id) => {
    const { dispatch } = store;

    dispatch({
        type: DOMAIN_GET_COR_PRICE_REQUEST
    });

    API.domain.GET.cor(id)
        .then((response) => {
            const domain_get_cor_price_data = getDataFromSuccessResponse(response);
            dispatch({
                type: DOMAIN_GET_COR_PRICE_SUCCESS,
                domain_get_cor_price_data
            });
        })
        .catch(() => {
            dispatch({
                type: DOMAIN_GET_COR_PRICE_ERROR
            });
        });
};
