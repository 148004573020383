import { useSearch } from '@tanstack/react-router';
import { SolidButton } from 'components/Buttons/SolidButton';
import Grid from 'components/Grid';
import NXBox from 'components/NXBox';
import { SuperUserItemPortal } from 'components/StaffMenu/SuperUser/components/SuperUserItemPortal/SuperUserItemPortal';
import { useSuperUserItem } from 'components/StaffMenu/SuperUser/hooks/useSuperUserItem';
import { katanaServiceBannerStateTanstackStore } from 'containers/services/modules/katanaServicesList/consts';
import { KatanaServiceBanner } from 'containers/services/modules/katanaServicesList/katanaServiceBanner';
import KatanaServiceListTable from 'containers/services/modules/katanaServicesList/katanaServiceListTable';
import { useEffect } from 'react';
import { useBooleanTanstackStore } from 'utilities/hooks/tanstack-store/useBooleanStore';
import { scrollToRef } from 'utilities/methods/commonActions';
import './_katanaServicesList.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const KatanaServicesList = () => {
    /***** HOOKS *****/
    const { setTrue: openGetKatana } = useBooleanTanstackStore(katanaServiceBannerStateTanstackStore);
    const isVMAXEnabled = useSuperUserItem('KATANA_VMAX');
    const { 'scroll-to-banner': scrollToBanner } = useSearch({ from: '/my-services/vip-sites' });

    /***** FUNCTIONS *****/
    function handleOnClick() {
        openGetKatana();
        scrollToRef(document.querySelector('.KatanaServiceBanner'));
    }

    useEffect(() => {
        if (scrollToBanner) {
            handleOnClick();
        }
    }, []);

    /***** RENDER *****/
    return (
        <div className="ServiceOverview KatanaServicesList">
            <Grid columns="1fr 160px">
                <NXBox.Title title="Manage VIPsites" />
                <SolidButton onClick={handleOnClick}>Add a new {isVMAXEnabled && 'VMAX'} Website </SolidButton>
            </Grid>
            <KatanaServiceListTable />
            <KatanaServiceBanner />

            <SuperUserItemPortal actionKey="KATANA_VMAX">
                <SolidButton.Link size="small" to="/vmax">
                    Go to VMAX
                </SolidButton.Link>
            </SuperUserItemPortal>
        </div>
    );
};
