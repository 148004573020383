/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useState } from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { SolidButton } from 'components/Buttons/SolidButton';
import SolidTag from 'components/Tags/SolidTag';
import Text from 'components/Utils/Text';

import { VipRewardsTierIcon } from '../tierIcon';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useVipRewards } from 'containers/vipRewards/hooks';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { tiers, vipRewardsReadableName } from 'containers/vipRewards/consts';

import OverlayLightbox from 'components/Lightboxes/OverlayLightbox';
import './_vipRewardsSignupSuccess.scss';
import { SignupSuccessModalContext } from './context';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type VipRewardsSignupSuccessModal = React.FC<VipRewardsSignupSuccessModalProps>;
type VipRewardsSignupSuccessModalProps = {
    children: React.ReactNode;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const VipRewardsSignupSuccessModal: VipRewardsSignupSuccessModal = ({ children }) => {
    /***** STATE *****/
    const [isOpen, setIsOpen] = useState(false);

    /***** HOOKS *****/
    const { customerFrom, currentTier } = useVipRewards();

    /***** RENDER *****/
    return (
        <SignupSuccessModalContext.Provider value={{ openSuccessModal: () => void setIsOpen(true) }}>
            {children}
            <OverlayLightbox onOpen={isOpen} onClose={() => setIsOpen(false)} title="Welcome to VentraIP Rewards">
                <div className="vipRewardsSignupSuccess">
                    <Text medium primary>
                        <h3>You&apos;re in! Check out your reward level</h3>
                    </Text>
                    <VipRewardsTierIcon tier={currentTier?.lowercase} />
                    <SolidTag
                        className="vipRewardsSignupSuccess__tag"
                        color={currentTier?.lowercase === tiers.VIP ? 'primary' : currentTier?.lowercase}
                    >
                        {currentTier?.lowercase.toUpperCase()}
                    </SolidTag>
                    <Text secondary size--s>
                        <p>Customer since {customerFrom.readable}</p>
                    </Text>
                    <SolidButton type="onClick" onClick={() => setIsOpen(false)}>
                        See my {vipRewardsReadableName} benefits
                    </SolidButton>
                </div>
            </OverlayLightbox>
        </SignupSuccessModalContext.Provider>
    );
};
/**********************************************************************************************************
 *   COMPONENT end
 **********************************************************************************************************/
