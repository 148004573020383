import { getFilesFromValues, processValueFileObject } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/methods';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import type { KatanaNamespace } from 'containers/katana/types';
import type { KATANA_API } from 'utilities/api/katana';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useFormValuesFileUpload(
    serviceID: KatanaNamespace.ServiceId,
    options: { context?: KATANA_API.katana.service_id.uploads.POST.Params['formData']['context'] } = {}
) {
    /***** QUERIES *****/
    const { mutateAsync: mutateUploadFile } = katanaQuery.serviceID.uploadFile.useMutation(serviceID, null);

    /***** FUNCTIONS *****/
    /**
     *  @returns The file urls or an error.
     */
    async function processAndUploadFiles(formValues: Record<string, Array<File> | File> | {}): Promise<Record<string, string | string[]> | {}> {
        const files = getFilesFromValues(formValues);
        return await processValueFileObject(files, mutateUploadFile, options);
    }

    return processAndUploadFiles;
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
