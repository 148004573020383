/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import htmr from 'htmr';
import React, { Component } from 'react';
import { connect } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import { SolidButton } from 'components/Buttons/SolidButton';
import OverlayCancel from 'components/Cancel/form/cancel';
import { Modal } from 'components/Lightboxes/Modal';
import RequestLoader from 'components/Loaders/Request';
import SolidNotification from '../../Notifications/SolidNotification';
import { OverlayLightboxBackLink } from './Components/BackLink';
import OverlayConfirm from './Components/confirm';
import OverlayInvoice from './Components/invoice';
import OverlayRestore from './Components/restore';

/*   ACTIONS
 *****************************************************/
import { COPY_back } from 'containers/billing/modules/consts';
import { getAlerts } from 'containers/dashboard/action';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import './_OverlayLightbox.scss';
import type { OverlayLightboxProps } from './types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @deprecated For the sake of sanity, this component is now in __*Component Prison*__, The new `Modal` is much more flexible and this is just a monster of a component. just don't use it.
 *
 * For the sake of sanity: wrap usage of this component in a conditional statement as the Modal component that's being used has issues with removing styling
 */
class OverlayLightbox extends Component<OverlayLightboxProps> {
    constructor(props) {
        super(props);
        this.timeout = null;
        this.state = {
            fadeout: false,
            postPayment_status: null,
            postPayment_data: null
        };
        this.closeLightbox = this.closeLightbox.bind(this);
        this.closeLightboxAfterSuccess = this.closeLightboxAfterSuccess.bind(this);
        this.postPaymentLightbox = this.postPaymentLightbox.bind(this);
    }

    closeLightbox() {
        const { onClose } = this.props;
        const { postPayment_status } = this.state;
        const { closeLightboxAfterSuccess } = this;

        if (postPayment_status === 'success') {
            return closeLightboxAfterSuccess();
        }

        onClose();
    }

    closeLightboxAfterSuccess() {
        const { getAlerts, onSuccessClose, onClose } = this.props;
        const { postPayment_status } = this.state;

        if (postPayment_status === 'success') {
            getAlerts();
        }

        if (onSuccessClose) onSuccessClose();
        else onClose();
    }

    postPaymentLightbox(status, data) {
        this.setState({
            postPayment_status: status,
            postPayment_data: data
        });
    }

    render() {
        const {
            children,
            className,
            color,
            warningMsg,
            customMsg,
            title,
            desc,
            icon,
            invoiceid,
            serviceid,
            confirm,
            loading,
            onOpen,
            cancel,
            removeCloseButton,
            initialFocus,
            goBackLink
        } = this.props;

        const { postPayment_status, postPayment_data } = this.state;

        const { closeLightbox, closeLightboxAfterSuccess, postPaymentLightbox } = this;

        const childrenWithExtraProp = React.Children.map(children, (child) => {
            return React.cloneElement(child, {
                closeLightbox
            });
        });

        const handleOverlayRender = () => {
            if (loading && ['pending', 'loading'].includes(loading)) {
                return (
                    <div className="OverlayLoader">
                        <RequestLoader />
                    </div>
                );
            } else if (confirm) {
                return <OverlayConfirm confirm={confirm} color={color} />;
            } else if (cancel) {
                return <OverlayCancel cancel={cancel} />;
            } else if (invoiceid && !postPayment_status) {
                return <OverlayInvoice invoiceID={invoiceid} closeInvoice={closeLightbox} togglePostPayment={postPaymentLightbox} />;
            } else if (invoiceid && postPayment_status) {
                return (
                    <div className="PostPayment__wrapper">
                        <div className="PostPayment__description">{postPayment_data.desc}</div>
                        <SolidButton className="PostPayment__button" type="onClick" onClick={closeLightboxAfterSuccess}>
                            {COPY_back}
                        </SolidButton>
                    </div>
                );
            } else if (serviceid) {
                return <OverlayRestore id={serviceid} closeInvoice={closeLightbox} />;
            } else {
                return (
                    <>
                        {childrenWithExtraProp}
                        {goBackLink ? (
                            <Anchor className="Overlay__goBackLink" onClick={goBackLink}>
                                Go Back
                            </Anchor>
                        ) : (
                            ''
                        )}
                    </>
                );
            }
        };

        return (
            <Modal
                className={classNames({
                    [className]: !!className,
                    hasNotification: !!(warningMsg || customMsg)
                })}
                isOpen={onOpen}
                onClose={closeLightbox}
                ariaLabel={postPayment_status ? postPayment_data.title : title}
                removeCloseButton={removeCloseButton}
                initialFocus={initialFocus}
            >
                {warningMsg ? <SolidNotification color="warning">{htmr(warningMsg)}</SolidNotification> : ''}
                {customMsg ? <div className="Overlay__customNotification">{customMsg}</div> : ''}

                <div className="Overlay__container">
                    {postPayment_status || icon ? (
                        <div className="Overlay__icon">
                            {postPayment_status ? (
                                <i className={`icon icon-${postPayment_data.icon} ${postPayment_status}`}></i>
                            ) : (
                                <i className={`icon icon-${icon.icon} ${icon.status}`}></i>
                            )}
                        </div>
                    ) : (
                        ''
                    )}
                    <div className="Overlay__title">{postPayment_status ? postPayment_data.title : title}</div>
                    {desc && !(loading && ['loading', 'pending'].includes(loading)) && <div className="Overlay__desc">{desc}</div>}
                    {handleOverlayRender()}
                </div>
            </Modal>
        );
    }
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

OverlayLightbox.BackLink = OverlayLightboxBackLink;

/**
 * @deprecated For the sake of sanity, this component is now in __*Component Prison*__, The new `Modal` is much more flexible and this is just a monster of a component. just don't use it.
 *
 * For the sake of sanity: wrap usage of this component in a conditional statement as the Modal component that's being used has issues with removing styling
 */
OverlayLightbox = connect(null, {
    getAlerts
})(OverlayLightbox);

export default OverlayLightbox;
/**********************************************************************************************************
 *   COMPONENT EXPORTS
 **********************************************************************************************************/
