/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMemo } from 'react';
import _ from 'lodash';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { modifyInitialValues } from 'containers/katana/hooks/useModifiedInitialValues/methods';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * To be used within a Hook Form FormProvider
 */
export function useBasicDetailsDefaultFormValues(subpageRouteData: KatanaNamespace.SubPageRouteData) {
    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();

    /***** QUERIES *****/
    const { data: get_katana_site_business_info_data, isLoading: isGetKatanaSiteBusinessInfoLoading } =
        katanaQuery.serviceID.getBusinessInfo.useQuery(katanaServiceId);
    const { data: get_katana_site_info_data, isLoading: isGetKatanaSiteInfoLoading } = katanaQuery.serviceID.getSiteInfo.useQuery(katanaServiceId);
    const { data: get_katana_site_navigation_data, isLoading: isGetKatanaSiteNavigationLoading } =
        katanaQuery.serviceID.getNavigation.useQuery(katanaServiceId);

    /***** HOOK RESULTS *****/
    return useMemo(() => {
        function getLogoChoice() {
            const hasLogo = Boolean(get_katana_site_business_info_data?.logo);
            if (hasLogo) {
                return 'logo';
            }
            const hasBrandStyle = Boolean(get_katana_site_info_data?.style?.fonts?.brand);
            if (hasBrandStyle) {
                return 'style';
            }
        }

        const queryData = {
            ...(get_katana_site_business_info_data ?? {}),
            ...(get_katana_site_info_data ?? {}),
            logo_choice: getLogoChoice()
        };

        _.set(queryData, 'style.logo_scale', get_katana_site_navigation_data?.style?.logo_scale);

        const modifiedInitialValues = modifyInitialValues({ initialValues: queryData, modules: subpageRouteData.modules });

        if (
            !isGetKatanaSiteBusinessInfoLoading &&
            !isGetKatanaSiteInfoLoading &&
            !isGetKatanaSiteNavigationLoading &&
            get_katana_site_business_info_data &&
            get_katana_site_info_data &&
            get_katana_site_navigation_data
        ) {
            return modifiedInitialValues;
        }

        return undefined;
    }, [
        get_katana_site_business_info_data,
        get_katana_site_info_data,
        get_katana_site_navigation_data,
        isGetKatanaSiteBusinessInfoLoading,
        isGetKatanaSiteInfoLoading,
        isGetKatanaSiteNavigationLoading
    ]);
}
