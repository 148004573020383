import { Tooltip } from '@mui/material';
import { OutlineButton } from 'components/Buttons/OutlineButton';
import type { EntryCreationBehaviourHandlerCoreProps } from 'containers/katana/components/EntryCreationBehaviourHandler/wrapper/types';
import { useAccordionTitleValueTargetCaveat } from 'containers/katana/formFields/caveats/useAccordionTitleValueTargetCaveat';
import { useSingularNameCaveat } from 'containers/katana/formFields/caveats/useSingularNameCaveat';
import { getValidationValue } from 'containers/katana/formFields/methods/getValidation';

function hasName(nameChecking: string, names: string[], index: number) {
    for (const name of names) {
        if (name === `${nameChecking} ${index}`) {
            return true;
        }
    }
    return false;
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const RenderDefaultButton: React.FC<EntryCreationBehaviourHandlerCoreProps> = ({ property, fields, properties }) => {
    /***** HOOKS *****/
    const accordionTitleFormValueTarget = useAccordionTitleValueTargetCaveat(property);
    const singularName = useSingularNameCaveat(property);
    const maxLength = getValidationValue(property?.validation, 'max');

    /***** FUNCTIONS *****/
    function addNew() {
        const fieldValues = fields.getAll();
        if (accordionTitleFormValueTarget && fieldValues) {
            const names = [];

            for (const fieldValue of fieldValues) {
                names.push(fieldValue[accordionTitleFormValueTarget]);
            }

            if (!names.includes(singularName)) {
                fields.push({ [accordionTitleFormValueTarget]: singularName ?? property.name });
            } else {
                for (let i = 1; i <= names.length; i++) {
                    if (!hasName(singularName, names, i)) {
                        fields.push({ [accordionTitleFormValueTarget]: singularName ? `${singularName} ${i}` : property.name });
                        break;
                    }
                }
            }
        } else if (accordionTitleFormValueTarget) {
            // for the cases has value in accordionTitleFormValueTarget, but no items in the list
            fields.push({ [accordionTitleFormValueTarget]: singularName ?? property.name });
        } else {
            // for the cases like Logos section, it might get null for accordionTitleFormValueTarget
            fields.push({});
        }
    }

    const isDisabled = fields.length >= maxLength;

    /***** RENDER *****/
    return (
        <Tooltip title={`No more than ${maxLength} entries`} arrow placement="top" disableHoverListener={!isDisabled}>
            <span>
                <OutlineButton className="KatanaRepeatedFormField__addAnotherButton" type="onClick" onClick={addNew} disabled={isDisabled}>
                    Add another {singularName ?? property.name}
                </OutlineButton>
            </span>
        </Tooltip>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
