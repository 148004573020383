/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import RequestLoader from 'components/Loaders/Request';
import { KatanaHookFormHandler } from 'containers/katana/components/HookFormHandler';
import { useBasicDetailsDefaultFormValues } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/basicDetailsFormHandler/useBasicDetailsDefaultFormValues';
import { useHandleBasicDetailsSubmit } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/basicDetailsFormHandler/useHandleBasicDetailsSubmit';
import type { KatanaNamespace } from 'containers/katana/types';
import _ from 'lodash';
import React from 'react';
import { requiredFieldErrorMessage } from 'utilities/methods/form';
import { z } from 'zod';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const basicDetailsZodSchema = z
    .object({
        business_categories: z.array(z.string()).optional(),
        name: z.string({ message: requiredFieldErrorMessage }).min(1, requiredFieldErrorMessage),
        logo: z
            .union([
                z.object(
                    {
                        name: z.string(),
                        file: z.string(),
                        mime_type: z.string(),
                        id: z.number(),
                        url: z.string()
                    },
                    {
                        required_error: requiredFieldErrorMessage
                    }
                ),
                z.string()
            ])
            .nullable()
            .optional(),
        style: z
            .object(
                {
                    fonts: z
                        .object(
                            {
                                body: z.string().optional().nullable(),
                                heading: z.string().optional().nullable(),
                                brand: z.string().optional().nullable()
                            },
                            {
                                required_error: requiredFieldErrorMessage
                            }
                        )
                        .optional()
                        .nullable(),
                    logo_scale: z.number().default(1)
                },
                {
                    required_error: requiredFieldErrorMessage
                }
            )
            .nullable(),
        logo_choice: z.union([z.literal('logo', { message: 'Please select an option' }), z.literal('style', { message: 'Please select an option' })])
    })
    .superRefine(({ logo_choice, logo, style }, context) => {
        if (logo_choice === 'logo' && _.isNil(logo)) {
            context.addIssue({
                code: 'custom',
                message: requiredFieldErrorMessage,
                path: ['logo']
            });
        }

        if (logo_choice === 'style' && _.isNil(style?.fonts?.brand)) {
            context.addIssue({
                code: 'custom',
                message: requiredFieldErrorMessage,
                path: ['style.fonts']
            });
        }
    });

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    subpageRouteData: KatanaNamespace.SubPageRouteData;
};

export const BasicDetailsFormHandler: React.FC<Props> = ({ subpageRouteData }) => {
    /***** HOOKS *****/
    const defaultValues = useBasicDetailsDefaultFormValues(subpageRouteData);
    const handleBasicDetailsSubmit = useHandleBasicDetailsSubmit(subpageRouteData);

    /***** RENDER *****/
    if (!defaultValues) {
        return <RequestLoader message="Loading Basic Details..." />;
    }

    return (
        <KatanaHookFormHandler defaultValues={defaultValues} zodSchema={basicDetailsZodSchema} handleOnSubmit={handleBasicDetailsSubmit}>
            {subpageRouteData.modules}
        </KatanaHookFormHandler>
    );
};
