import { lastOccurrenceOfArrayIndexInString } from 'utilities/methods/regexes/lastOccurrenceOfArrayIndexInString/lastOccurrenceOfArrayIndexInString';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
export const regexes = {
    regularCharactersSpacesHyphensApostrophesAccents: () => /[a-zA-ZÀ-ÖØ-öø-ÿ\s'-]/g,
    regularCharactersSpacesHyphensApostrophesAccentsNegate: () => /[^a-zA-ZÀ-ÖØ-öø-ÿ\s'-]/g,
    // eslint-disable-next-line no-control-regex
    ASCII: () => /[\x00-\x7F]/g,
    // eslint-disable-next-line no-control-regex
    ASCIINegate: () => /[^\x00-\x7F]/g,

    emailRegex: () => /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi,
    descriptionDomainRegex: () => /[^!@][a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z0-9-]{2,})+/g,
    isHexCode: () => /^#?([a-fA-F0-9]{3}|[a-fA-F0-9]{6})$/,
    lastOccurrenceOfArrayIndexInString,
    // Source: https://stackoverflow.com/a/8234912/2013580
    href: () =>
        new RegExp(
            /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/
        ),
    youtubeLink: () => /^.*((youtu.be\/)|(youtube.com\/)((v\/)|(e\/)|(\/u\/\w\/)|(embed\/)|(watch)))\??v?=?([^#&?]*).*/,
    youtubeLinkWithID: () => /(?:youtube\.com\/(?:[^\/]+\/.+\/|[^\/]+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/,
    numbersAndNumbersWithFullStops: () => /\d+\.\d+|\d+/gm,
    hexCode: () => /^#(?:[0-9a-fA-F]{3}){1,2}$/
};
