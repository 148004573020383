import { useMutation } from '@tanstack/react-query';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import type { KatanaNamespace } from 'containers/katana/types';
import { queryClient } from 'store/queryClient';
import { KATANA_API } from 'utilities/api/katana';

/**********************************************************************************************************
 *   Create Katana Site
 **********************************************************************************************************/

export function useCreateKatanaSite(options = {}) {
    return useMutation({
        mutationKey: useCreateKatanaSite.mutationKey,
        mutationFn: KATANA_API.katana.POST,
        onSettled: (data) => {
            const serviceID = data?.data?.site?.id;
            if (serviceID) {
                katanaQuery.serviceID.service.invalidateQueries(serviceID);
                katanaQuery.getSiteList.invalidateQueries();
                katanaQuery.getDomainsList.invalidateQueries();
            }
        },
        onSuccess: (data, domain) => {
            katanaQuery.checkDomain.invalidateQueries(domain);
        },
        ...options,
    });
}
useCreateKatanaSite.mutationKey = ['useCreateKatanaSite'];

/**********************************************************************************************************
 *   Katana Enable Site
 **********************************************************************************************************/
export function useEnableKatanaSiteMutation(serviceID: KatanaNamespace.ServiceId, options = {}) {
    function mutationFn(attributes) {
        return KATANA_API.katana.service_id.enable.POST({ serviceID, attributes });
    }
    return useMutation({
        mutationFn,
        mutationKey: ['useEnableKatanaSiteMutation'],
        onSettled: () => katanaQuery.serviceID.service.invalidateQueries(serviceID),
        ...options,
    });
}

/**********************************************************************************************************
 *   Katana Disable Site
 **********************************************************************************************************/
export function useDisableKatanaSiteMutation(serviceID: KatanaNamespace.ServiceId, options = {}) {
    return useMutation({
        mutationFn: () => KATANA_API.katana.service_id.disable.POST(serviceID),
        onSettled: () => katanaQuery.serviceID.service.invalidateQueries(serviceID),
        mutationKey: ['useDisableKatanaSiteMutation'],
        ...options,
    });
}

/**********************************************************************************************************
 *   Katana Reset Service
 **********************************************************************************************************/
export function useResetKatanaService(serviceID: KatanaNamespace.ServiceId) {
    return useMutation({
        mutationFn: () => KATANA_API.katana.service_id.reset.POST(serviceID),
        mutationKey: useResetKatanaService.mutationKey,
        onSuccess: () => {
            const queryKey = katanaQueryKeys.katana.service.ID(serviceID);
            queryClient.resetQueries({ queryKey });
        },
    });
}
useResetKatanaService.mutationKey = ['useResetKatanaService'];
