import { Tooltip } from '@mui/material';
import { SolidButton } from 'components/Buttons/SolidButton';
import Grid from 'components/Grid';
import Chevron from 'components/Icons/Chevron';
import { Revealer } from 'components/Revealer';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { CategoryIcon } from 'containers/katana/components/pageOrganiser/CategoryIcon';
import { NavigationItemsSection } from 'containers/katana/containers/contentEditorModules/navigationLabels/NavigationItemsSection';
import type { NavigationLabelsNamespace } from 'containers/katana/containers/contentEditorModules/navigationLabels/types';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { SectionOrganiserChildBaseBar } from 'containers/katana/modules/presetContent/components/sectionOrganiser/sectionOrganiserChild/baseBar';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import type { KatanaNamespace } from 'containers/katana/types';
import React, { useState } from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    page: KatanaNamespace.Page;
    onAdd: (page: NavigationLabelsNamespace.SortableEntry) => void;
    navigationItems: NavigationLabelsNamespace.SortableEntry[];
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const NavigationItemsPage: React.FC<Props> = ({ page, onAdd, navigationItems }) => {
    /***** STATE *****/
    const [canShowTooltip, setCanShowTooltip] = useState(true);
    const [isOpen, setIsOpen] = useState(false);

    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();

    /***** QUERIES *****/
    const { data: page_sections_data, isSuccess: isPageSectionsSuccess } = katanaQuery.serviceID.pageSections.useQuery({
        serviceID: katanaServiceId,
        pageID: page.id
    });

    /***** RENDER HELPERS *****/
    const isDisabled = navigationItems.some((item) => item.id === page.id);
    const tooltipTitle = isDisabled && canShowTooltip ? 'This page is already included in your navigation' : '';
    const isMultiPage = katanaQuery.serviceID.pages.useIsMultiPage(katanaServiceId);
    const isHomePage = page.path === '/';
    /***** RENDER *****/
    return (
        <>
            <Grid columns={isMultiPage ? 'auto 1fr auto auto' : 'auto 1fr'} alignItems--center inject>
                <SectionOrganiserChildBaseBar className="NavigationItemsPage" noPadding>
                    <Padding left={3} right={2}>
                        <CategoryIcon category={isHomePage ? 'home' : page.name} black />
                    </Padding>

                    <Text bold black>
                        {page.name}
                    </Text>

                    {isMultiPage && (
                        <Tooltip title={tooltipTitle} placement="top" arrow disableInteractive>
                            <div
                                onMouseLeave={() => setCanShowTooltip(true)}
                                onMouseEnter={() => {
                                    if (!isDisabled) {
                                        setCanShowTooltip(false);
                                    }
                                }}
                            >
                                <SolidButton
                                    type="onClick"
                                    onClick={() => {
                                        onAdd(page);
                                    }}
                                    disabled={isDisabled}
                                    size="small"
                                >
                                    <Padding left={2} right={3}>
                                        Add to menu
                                    </Padding>
                                </SolidButton>
                            </div>
                        </Tooltip>
                    )}

                    {isMultiPage && (
                        <button
                            type="button"
                            onClick={() => {
                                setIsOpen(!isOpen);
                            }}
                        >
                            <Padding left={2} right={3}>
                                <Chevron state={isOpen} />
                            </Padding>
                        </button>
                    )}
                </SectionOrganiserChildBaseBar>
            </Grid>
            <Revealer isOpen={isMultiPage ? isOpen : true}>
                {isPageSectionsSuccess && !!page_sections_data?.length && (
                    <Padding left={4}>
                        <Flex direction="column" gap={1}>
                            {page_sections_data.map((section) => (
                                <NavigationItemsSection key={section.id} section={section} onAdd={onAdd} navigationItems={navigationItems} />
                            ))}
                        </Flex>
                    </Padding>
                )}
            </Revealer>
        </>
    );
};
