/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API as SERVICES } from 'utilities/api/services';
import { MINUTE } from 'utilities/consts';
import { getDataFromSuccessResponse } from 'utilities/methods/commonActions';
import { createQueryBoilerPlate } from 'utilities/methods/tanstack';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';
import { PROMOTIONS } from './api';

/**
 * Get all services in a users account, optionally omitting certain service types
 */
async function promotionGetAllServices(requiredServiceTypes = ['domains', 'hosting', 'email', 'gsuite', 'ms365', 'vps', 'ssl']) {
    const requests = [
        {
            name: 'domains',
            request: SERVICES.domain.list
        },
        {
            name: 'hosting',
            request: SERVICES.hosting.list
        },
        {
            name: 'email',
            request: SERVICES.email.list
        },
        {
            name: 'gsuite',
            request: SERVICES.gsuite.list
        },
        {
            name: 'ms365',
            request: SERVICES.ms365.list
        },
        {
            name: 'vps',
            request: SERVICES.vps.list
        },
        {
            name: 'ssl',
            request: SERVICES.ssl.list
        }
    ];

    try {
        const responseArray = await Promise.allSettled(
            requests
                .filter((req) => requiredServiceTypes.includes(req.name))
                .map((req) =>
                    req.request({
                        record_per_page: '999'
                    })
                )
        );

        const promotion_all_services_data = {};

        requiredServiceTypes.forEach((serviceType, index) => {
            if (responseArray[index]?.status === 'fulfilled')
                promotion_all_services_data[serviceType] = getDataFromSuccessResponse(responseArray[index].value);
        });

        return promotion_all_services_data;
    } catch (e) {
        return Promise.reject(e);
    }
}

export const promotionAllServicesBoilerPlate = createQueryBoilerPlate({
    service: 'promotion',
    serviceKey: 'PROMOTION_ALL_SERVICES',
    APIRoute: (requiredServiceTypes) => () => promotionGetAllServices(requiredServiceTypes),
    defaultOptions: {
        select: () => (data) => data,
        staleTime: MINUTE * 5
    }
});

const { getDefaults } = promotionAllServicesBoilerPlate;

export function usePromotionAllServicesQuery(requiredServiceTypes = null) {
    return useQuery({
        ...getDefaults(requiredServiceTypes)
    });
}

/***** DRAW PROMO QUERIES *****/
const createGetDrawEligibilityQueryKey = (id: string) => {
    return createBaseQueryKey(['drawEligibility', id]);
};

export function useGetDrawEligibilityQuery(id: string) {
    return useQuery({
        queryKey: createGetDrawEligibilityQueryKey(id),
        queryFn: () => PROMOTIONS.GET.drawEligibility(id),
        enabled: Boolean(id)
    });
}

export function useEnterDrawMutation(id: string, team?: string) {
    /***** HOOKS *****/
    const queryClient = useQueryClient();

    /***** HOOK RESULTS *****/
    return useMutation({
        mutationFn: () => {
            return PROMOTIONS.POST.enterDraw(id, team);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: createGetDrawEligibilityQueryKey(id) });
        }
    });
}

/***** NRL PROMO QUERIES *****/
// This is not used for the NRL Grand Final Promo. Use the dynamic Promo Queries below.

const nrlEligibilityQueryKey = createBaseQueryKey(['nrlDrawEligibility']);

export function useGetNrlEligibilityQuery() {
    return useQuery({ queryKey: nrlEligibilityQueryKey, queryFn: () => PROMOTIONS.GET.nrlOffer.drawEligibilty() });
}

export function useEnterNrlDrawMutation() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: () => PROMOTIONS.POST.nrlOffer.enterDraw(),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: nrlEligibilityQueryKey }),
        onError: () => {}
    });
}
