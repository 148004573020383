import { useMutation } from '@tanstack/react-query';
import { newPageTrackerData } from 'containers/katana/components/pageOrganiser/newPageTracker';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import { queryClient } from 'store/queryClient';
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions/handleDefaultErrorNotification';
import { handleDefaultSuccessNotification } from 'utilities/methods/commonActions/handleDefaultSuccessNotification';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Params = Parameters<typeof KATANA_API.katana.site.service_id.pages.page_id.sections.reorder.POST>[0];

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation() {
    // TODO: Add optimistic updates

    /***** FUNCTIONS *****/
    function handleMutation(params: Params) {
        return KATANA_API.katana.site.service_id.pages.page_id.sections.reorder.POST(params);
    }

    function onMutate({ serviceID, pageID }: Params) {
        queryClient.cancelQueries({
            queryKey: katanaQueryKeys.katana.service.ID(serviceID)
        });

        queryClient.cancelQueries({
            queryKey: katanaQueryKeys.katana.service.ID.sections(serviceID)
        });

        queryClient.cancelQueries({
            queryKey: katanaQueryKeys.katana.service.ID.pages.ID.sections({ serviceID, pageID })
        });

        newPageTrackerData.removeID(pageID);
    }

    /***** HOOK RESULTS *****/
    return useMutation({
        onMutate,
        mutationFn: handleMutation,
        onSuccess: handleDefaultSuccessNotification,
        onError: handleDefaultErrorNotification,

        onSettled: (data, error, { serviceID, pageID }) => {
            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID(serviceID),
                exact: true
            });

            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID.preview(serviceID)
            });

            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID.sections(serviceID),
                exact: true
            });

            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID.pages.ID.sections({ serviceID, pageID }),
                exact: true
            });
        }
    });
}

export const reorderPageSections = Object.freeze({
    useMutation: _useMutation
});
