/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { createContext, useContext } from 'react';

/**
 * @typedef {{
 *   columns: string,
 *   columnsDefinition: string,
 *   _onSort: (sort: {
 *     sortKey: string | false,
 *     sortMethod: "asc" | "desc" | false
 *   }) => void,
 *   _currentSort: {
 *     sortKey: string | false,
 *     sortMethod: "asc" | "desc" | false
 *   },
 *   setAllActions: React.Dispatch<React.SetStateAction<Record<string, unknown>>>,
 *   allActions: {},
 *   setColumns: React.Dispatch<React.SetStateAction<string>>
 *}} NXTableContextProps
 */

const defaultNXTableContext = /** @type {NXTableContextProps} */ ({
    columns: '',
    columnsDefinition: '',
    _onSort: (_) => {},
    _currentSort: { sortKey: false, sortMethod: false },
    setAllActions: () => {},
    allActions: {},
    setColumns: () => {}
});

/***** HOOKS *****/
export const NXTableContext = createContext(defaultNXTableContext);
export const NXToggleWrapperContext = createContext({
    activeIndex: 0,
    /** @param {number | string} index */
    onToggle: (index) => {},
    initialised: false
});

export const useNXTableContext = () => useContext(NXTableContext);
export const useNXToggleWrapperContext = () => useContext(NXToggleWrapperContext);
