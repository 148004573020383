import useResizeObserver from '@react-hook/resize-observer';
import classNames from 'classnames';
import FetchComponentError from 'components/Errors/FetchComponentError';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { IsDataUpdatingOverlay } from 'components/IsDataUpdatingOverlay';
import { BrowserBox } from 'components/Lightboxes/BrowserBox';
import { ControlCircle } from 'components/Lightboxes/BrowserBox/controlCircle/controlCircle';
import { useShouldRenderOldPhoneViewport } from 'components/Lightboxes/BrowserBox/special/useShouldRenderOldPhoneViewport';
import RequestLoader from 'components/Loaders/Request';
import { NXIframe } from 'components/NXIframe';
import { viewPortWidths } from 'components/PicturedIframeSourceDocPreview/methods';
import { PictureInPicture } from 'components/PictureInPicture';
import type { PictureInPictureNamespace as PictureInPictureType } from 'components/PictureInPicture/types';
import { Padding } from 'components/Utils/Padding';
import { PresetCustomiser } from 'containers/katana/components/sitePreviewBrowserBox/presetCustomiser';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTanstackStore } from 'utilities/hooks/tanstack-store/useTanstackStore';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';
import './_PicturedIframeSourceDocPreview.scss';

const OldPhone = React.lazy(() =>
    import('components/Lightboxes/BrowserBox/special/OldPhone.lazy').then((res) => ({
        default: res.OldPhoneLazy
    }))
);
function truthyRef<TRef>(ref: React.RefObject<TRef>): ref is React.MutableRefObject<TRef> {
    return Boolean(ref?.current);
}

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type NXPicturedIframeSourceDocPreviewComponent = React.FC<{
    leftAnchorElement?: HTMLElement | null;
    stateStoreData: PictureInPictureType.CreateStateData;
    loaderData?: NRequestLoader.MultiLoader.Data[];
    srcDoc?: HTMLIFrameElement['srcdoc'];
    isError?: boolean;
    className?: string;
    canHide?: boolean;
    canMinimise?: boolean;
    canResize?: boolean;
    controlsWrapperPostContent?: React.ReactNode;
    handleJumpBetweenPages?: (pagePath: string) => void;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const NXPicturedIframeSourceDocPreview: NXPicturedIframeSourceDocPreviewComponent = ({
    leftAnchorElement,
    stateStoreData,
    srcDoc,
    loaderData = [],
    isError,
    className,
    canHide = true,
    canMinimise = true,
    canResize = true,
    handleJumpBetweenPages
}) => {
    /***** STATE *****/
    const [viewportMode, setViewportMode] = useState<BrowserBox.Viewports>('desktop');
    const [isSwappingSourceDoc, setIsSwappingSourceDoc] = useState(false);
    const [targetSectionId, setTargetSectionId] = useState<string | null>(null);
    const [refreshFactor, setRefreshFactor] = useState(false);

    /***** HOOKS *****/
    const isMobile = useAppViewport(['xs', 'sm']);
    const [storeState, setStoreState] = useTanstackStore(stateStoreData.store);
    const canRenderOldPhoneViewport = useShouldRenderOldPhoneViewport();
    const viewportModeRef = useRef(viewportMode);

    const iframeRef = useRef<HTMLIFrameElement>(null);

    const iframeWrapperRef = useRef<HTMLDivElement>(null);

    const pictureInPictureRef = useRef<HTMLDivElement>(null);

    const browserBoxPageRef = useRef<HTMLDivElement>(null);

    const browserBoxHeaderRef = useRef<HTMLDivElement>(null);

    const browserBoxContentRef = useRef<HTMLDivElement>(null);

    const touchedSrcDoc = useMemo(() => {
        // change SrcDoc to force refresh, controlled by refreshFactor
        if (refreshFactor) {
            return srcDoc;
        } else {
            return srcDoc + ' ';
        }
    }, [refreshFactor, srcDoc]);

    function refresh() {
        // show loading message as cover to prevent frequent refresh resulting preview collapse
        setIsSwappingSourceDoc(true);
        setRefreshFactor(!refreshFactor);
        setTimeout(() => {
            setIsSwappingSourceDoc(false);
        }, 1000);
    }

    /**
     * This function can be called during any part of the render lifecycle therefore we have to rely on refs for the latest data
     */
    const performUpdate = () => {
        if (
            !truthyRef(iframeRef) ||
            !truthyRef(iframeWrapperRef) ||
            !truthyRef(pictureInPictureRef) ||
            !truthyRef(browserBoxPageRef) ||
            !truthyRef(browserBoxHeaderRef) ||
            !truthyRef(browserBoxContentRef) ||
            !iframeRef?.current?.contentDocument?.firstElementChild
        ) {
            return;
        }

        /** Disconnect the observers for the elements we are modifying in the update */

        /**
         * Reset Iframe Parent styles
         * - Set width to auto
         * - Set height to auto
         * - Set overflow to visible
         */
        iframeWrapperRef.current.style.width = 'auto';
        iframeWrapperRef.current.style.height = 'auto';
        iframeWrapperRef.current.style.overflow = 'visible';

        /**
         * Reset Iframe styles
         * - Set transform to none
         * - Set position to relative
         */
        iframeRef.current.style.transform = 'none';
        iframeRef.current.style.position = 'relative';

        /** Set Iframe width to the width of the desired iframe display width, i.e. viewport width */
        const iframeDisplayWidth = viewPortWidths[viewportModeRef.current];

        /** Set the iframe height to the window.innerHeight */
        iframeRef.current.height = String(window.innerHeight);

        /** Get the width of the Browser Box content */
        const browserBoxContentRect = browserBoxContentRef.current.getBoundingClientRect();
        const browserBoxContentWidth = Math.ceil(browserBoxContentRect.width);

        /** Calculate simulated width */
        const difference = iframeDisplayWidth - browserBoxContentWidth;
        const simulatedWidth = browserBoxContentWidth + Math.ceil(difference / 2);
        iframeRef.current.width = String(simulatedWidth);

        /** Get the height of the iframe's document height */
        const iframeDocumentHeight = Math.ceil(iframeRef.current.contentDocument.firstElementChild.getBoundingClientRect().height);

        /** Get the height of the Browser Box Content (BrowserBox Wrapper height - BrowserBox Header height) */
        const browserBoxWrapperRect = browserBoxPageRef.current.getBoundingClientRect();
        const browserBoxHeaderRect = browserBoxHeaderRef.current.getBoundingClientRect();
        const browserBoxContentHeight = browserBoxWrapperRect.height;
        const browserBoxHeaderHeight = browserBoxHeaderRect.height;
        const calculatedContentHeight = browserBoxContentHeight - browserBoxHeaderHeight;

        /** Calculate the iframe target scale based on the iframe's width and the BrowserBox content width */
        const calculatedTargetScale = browserBoxContentWidth / simulatedWidth;
        const calculatedOriginScale = simulatedWidth / browserBoxContentWidth;

        /** Calculate the target height of the iframe */
        const calculatedIframeTargetHeight = iframeDocumentHeight * calculatedTargetScale;

        /** Is the calculated height more than the browser box content height? */
        const isCalculatedHeightMoreThanBrowserBoxContentHeight = calculatedIframeTargetHeight > calculatedContentHeight;

        /** If the calculated height is more than the browser box content height,
         * set the iframe height to the browser box content height multiplied by the calculated scale
         */
        if (isCalculatedHeightMoreThanBrowserBoxContentHeight) {
            const scaledBrowserBoxHeight = calculatedContentHeight * calculatedOriginScale;
            iframeRef.current.height = String(scaledBrowserBoxHeight);
        } else {
            const scaledBrowserBoxHeight = calculatedIframeTargetHeight * calculatedOriginScale;
            iframeRef.current.height = String(scaledBrowserBoxHeight);
        }

        /** Set the iframe scale to the calculated scale */
        iframeRef.current.style.transform = `scale(${calculatedTargetScale})`;

        /**
         * Set the iframe parent styles:
         * - Set width to browser box content width
         * - If the calculated height is more than the browser box content height, set height to the calculated content height
         * - If the calculated height is less than the browser box content height, set height to the calculated iframe target height
         * - Set overflow to hidden
         */
        iframeWrapperRef.current.style.width = `${browserBoxContentWidth}px`;
        if (isCalculatedHeightMoreThanBrowserBoxContentHeight) {
            iframeWrapperRef.current.style.height = `${calculatedContentHeight}px`;
        } else {
            iframeWrapperRef.current.style.height = `${calculatedIframeTargetHeight}px`;
        }
        iframeWrapperRef.current.style.overflow = 'hidden';

        /** set Iframe position to absolute */
        iframeRef.current.style.position = 'absolute';
    };

    const [iframeFirstElementChild, setIframeFirstElementChild] = useState(iframeRef?.current?.contentDocument?.firstElementChild ?? null);

    const iframe = iframeRef?.current;
    const aTags = iframe?.contentDocument?.querySelectorAll('a');

    const currentPath = useMemo(() => {
        // it will return path when it is multipage, and return null when it is single page
        let path = null;
        aTags?.forEach((aTag) => {
            if (aTag?.ariaSelected === 'true') {
                path = aTag?.dataset?.navigationPagePath;
            }
        });
        return path;
    }, [aTags]);

    aTags?.forEach((aTag) => {
        if (aTag?.dataset?.callToActionPagePath) {
            const pagePath = aTag?.dataset?.callToActionPagePath;
            const sectionId = aTag?.dataset?.callToActionSectionId;
            if (!currentPath && !sectionId) {
                aTag.setAttribute('href', 'javascript:void(0)'); // to keep the cursor as pointer and not affect scroll to section
                aTag.addEventListener('click', refresh);
            }
            if (!currentPath && sectionId) {
                aTag.setAttribute('href', `about:srcdoc#${sectionId}`);
            }
            if (currentPath && pagePath === currentPath && !sectionId) {
                aTag.setAttribute('href', 'javascript:void(0)');
                aTag.addEventListener('click', refresh);
            }
            if (currentPath && pagePath === currentPath && sectionId) {
                aTag.setAttribute('href', `about:srcdoc#${sectionId}`);
            }
            if (currentPath && pagePath !== currentPath && sectionId && !handleJumpBetweenPages) {
                aTag.setAttribute('href', `about:srcdoc#${sectionId}`);
            }
            if (currentPath && pagePath !== currentPath && handleJumpBetweenPages) {
                aTag.setAttribute('href', 'javascript:void(0)');
                aTag.addEventListener('click', () => {
                    handleJumpBetweenPages(pagePath);
                    setTargetSectionId(sectionId ?? null);
                });
            }
        }

        if (aTag?.dataset?.navigationPagePath) {
            const pagePath = aTag?.dataset?.navigationPagePath;
            const sectionId = aTag?.dataset?.navigationSectionId;
            if (!currentPath && sectionId) {
                aTag.setAttribute('href', `about:srcdoc#${sectionId}`);
            }
            if (currentPath && pagePath === currentPath) {
                aTag.setAttribute('href', 'javascript:void(0)');
                aTag.addEventListener('click', refresh);
            }
            if (currentPath && pagePath !== currentPath && handleJumpBetweenPages) {
                aTag.setAttribute('href', 'javascript:void(0)');
                aTag.addEventListener('click', () => {
                    handleJumpBetweenPages(pagePath);
                    setTargetSectionId(null);
                });
            }
            if (!currentPath && !sectionId) {
                // cover the cases of page preset preview for multipage site
                aTag.removeAttribute('href');
            }
        }

        if (aTag?.dataset?.logoPagePath) {
            const pagePath = aTag?.dataset?.logoPagePath;
            if (!currentPath || (currentPath && pagePath === currentPath)) {
                aTag.setAttribute('href', 'javascript:void(0)');
                aTag.addEventListener('click', refresh);
            }
            if (currentPath && pagePath !== currentPath && handleJumpBetweenPages) {
                aTag.setAttribute('href', 'javascript:void(0)');
                aTag.addEventListener('click', () => {
                    handleJumpBetweenPages(pagePath);
                    setTargetSectionId(null);
                });
            }
        }
    });

    const handleScrollToSection = useCallback(
        (sectionId: string | null) => {
            const iframe = iframeRef?.current;
            const targetSection = sectionId ? iframe?.contentDocument?.getElementById(sectionId) : null;
            if (targetSection) {
                const rect = targetSection.getBoundingClientRect();
                const top = rect.top;
                iframe?.contentWindow?.scrollTo(0, top);
            }
            setTargetSectionId(null);
            // set targetSectionId to null to prevent auto scrolling when rendering new srcdoc
        },
        [targetSectionId]
    );

    /***** EFFECTS *****/
    useResizeObserver(pictureInPictureRef, performUpdate);
    useResizeObserver(iframeFirstElementChild, performUpdate);

    useEffect(() => {
        if (isMobile) {
            setViewportMode('mobile');
        } else {
            setViewportMode('desktop');
        }
    }, [isMobile]);

    useLayoutEffect(() => {
        viewportModeRef.current = viewportMode;
    }, [viewportMode]);

    useLayoutEffect(() => {
        performUpdate();
    }, [viewportMode, storeState, srcDoc]);

    useLayoutEffect(() => {
        setIsSwappingSourceDoc(true);
    }, [srcDoc]);

    useLayoutEffect(() => {
        if (['minimised', 'visible'].includes(storeState) && isMobile) {
            setStoreState('hidden');
        }
    }, [isMobile, storeState]);

    /***** RENDER HELPERS *****/
    const multiLoaderDataMemo = useMemo(
        () => [...loaderData, { condition: isSwappingSourceDoc, message: 'Fetching latest site preview...' }],
        [loaderData, isSwappingSourceDoc]
    );
    const multiLoaderData = RequestLoader.MultiLoader.useLoadersData(multiLoaderDataMemo);
    const currentMultiLoaderMessage = RequestLoader.MultiLoader.useCurrentMessage(multiLoaderData);

    /***** RENDER *****/
    return (
        <PictureInPicture
            className={classNames('PicturedIframeSourceDocPreview', className)}
            leftAnchorElement={leftAnchorElement}
            stateStoreData={stateStoreData}
            pictureInPictureRef={pictureInPictureRef}
        >
            <PictureInPicture.Content>
                <BrowserBox
                    viewportMode={viewportMode}
                    setViewportMode={setViewportMode}
                    browserBoxHeaderRef={browserBoxHeaderRef}
                    browserBoxContentRef={browserBoxContentRef}
                    browserBoxPageRef={browserBoxPageRef}
                    controlCirclesContent={[
                        canHide ? (
                            <ControlCircle
                                key={0}
                                hoverColour="warn"
                                ControlIcon={PhosphorIcons.X.Bold}
                                onClick={() => {
                                    setStoreState('hidden');
                                }}
                            />
                        ) : null,
                        canMinimise ? (
                            <ControlCircle
                                key={1}
                                hoverColour="notice"
                                ControlIcon={PhosphorIcons.Minus.Bold}
                                onClick={() => {
                                    setStoreState('minimised');
                                }}
                            />
                        ) : null,
                        canResize ? (
                            <ControlCircle
                                key={2}
                                hoverColour="confirm"
                                ControlIcon={storeState === 'maximised' ? PhosphorIcons.CornersIn.Fill : PhosphorIcons.CornersOut.Fill}
                                onClick={() => {
                                    setStoreState(storeState === 'maximised' ? 'visible' : 'maximised');
                                }}
                            >
                                {(iconProps) => (
                                    <>
                                        <PhosphorIcons.Caret.Left.Fill {...iconProps} />
                                        <PhosphorIcons.Caret.Right.Fill {...iconProps} />
                                    </>
                                )}
                            </ControlCircle>
                        ) : null
                    ]}
                >
                    <IsDataUpdatingOverlay.StableDomStructure
                        noBorder
                        isDataUpdating={currentMultiLoaderMessage?.condition}
                        message={currentMultiLoaderMessage?.message}
                        Variant={RequestLoader.WithBackground}
                        height={35}
                    >
                        <div
                            ref={iframeWrapperRef}
                            style={{
                                position: 'relative'
                            }}
                        >
                            {isError ? (
                                <Padding xy={2}>
                                    <FetchComponentError />
                                </Padding>
                            ) : (
                                <>
                                    <NXIframe
                                        srcDoc={touchedSrcDoc ?? ''}
                                        onLoad={() => {
                                            setIsSwappingSourceDoc(false);
                                            setIframeFirstElementChild(iframeRef?.current?.contentDocument?.firstElementChild ?? null);
                                            handleScrollToSection(targetSectionId);
                                        }}
                                        iframeRef={iframeRef}
                                        title="NXPicturedIframeSourceDocPreview"
                                        width={window.innerWidth}
                                        height={window.innerHeight}
                                        style={{ transformOrigin: '0 0', left: '0', top: '0' }}
                                    />
                                    {canRenderOldPhoneViewport && viewportMode === 'oldPhone3310' && (
                                        <RequestLoader.Suspense>
                                            <OldPhone>
                                                <NXIframe
                                                    width={106 * 3}
                                                    height={78 * 3}
                                                    style={{ transform: 'scale(0.33)' }}
                                                    srcDoc={touchedSrcDoc ?? ''}
                                                />
                                            </OldPhone>
                                        </RequestLoader.Suspense>
                                    )}
                                </>
                            )}
                        </div>
                    </IsDataUpdatingOverlay.StableDomStructure>
                </BrowserBox>
                <PictureInPicture.ControlsWrapper>
                    <PresetCustomiser>
                        {canResize && (
                            <>
                                <PictureInPicture.ShrinkButton text="Shrink Preview" />
                                <PictureInPicture.MaximiseButton text="Full Preview" />
                            </>
                        )}
                        {canHide && <PictureInPicture.MinimiseButton text="Hide" />}
                    </PresetCustomiser>
                </PictureInPicture.ControlsWrapper>
            </PictureInPicture.Content>
            <PictureInPicture.ShowButton text="Show Preview" />
        </PictureInPicture>
    );
};
