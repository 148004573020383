/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import Anchor from 'components/Anchor';
import SelectDropdown from 'components/Dropdowns/SelectDropdown';
import RequestLoader from 'components/Loaders/Request';
import NXTable from 'components/NXTable';
import TableAccordion from 'components/NXTable/TableAccordion';
import { Border } from 'components/Utils/Border';
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { pluralize } from 'utilities/methods/commonActions';
import { renderBulkServiceExpiry } from '../../methods';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { RenewTableProps } from 'containers/services/Bulk/Components/renew/types';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const headingKeys = {
    domain_name: 'domain_name',
    expires: 'expires',
    renewal_duration: 'renewal_duration'
};

const tableHeadings = [
    [headingKeys.domain_name, 'Domain Name'],
    [headingKeys.expires, 'Expires'],
    [headingKeys.renewal_duration, 'Renewal Duration']
];

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
let RenewTable: React.FC<RenewTableProps> = ({ servicesWithRenewDurationSelected, setServicesWithRenewDurationSelected, ...props }) => {
    const { app_viewport, domain_bulk_info_data, domain_bulk_info_status } = props as any;
    /***** FUNCTIONS *****/
    function pushServicesWithRenewDurationSelected(data, id) {
        const newServicesWithRenewDurationSelected = servicesWithRenewDurationSelected.filter((action) => action.id !== id);

        newServicesWithRenewDurationSelected.push({
            type: 'domain',
            id,
            attributes: data
        });

        setServicesWithRenewDurationSelected(newServicesWithRenewDurationSelected);
    }

    useEffect(() => {
        if (!domain_bulk_info_data) return;

        const renewableServicesList = domain_bulk_info_data?.domains?.filter(({ id, attributes, included }) => {
            const hasInvoice = included ? included.filter((inc) => inc.type === 'invoice') : false;
            const hasDomainSelectedDuration = servicesWithRenewDurationSelected.find((durationSelectedDomain) => durationSelectedDomain.id === id);

            return attributes.is_renewable && !hasInvoice && hasDomainSelectedDuration;
        });

        const newServicesWithRenewDurationSelected = renewableServicesList?.map((updatedDomainData) => {
            const foundUpdatedPrice = updatedDomainData?.attributes?.prices?.find((priceData) => {
                const foundPricesSameDetails = servicesWithRenewDurationSelected.find(
                    (durationSelectedDomain) => durationSelectedDomain.id === updatedDomainData.id
                );

                return priceData.years === foundPricesSameDetails?.attributes.years;
            });

            return {
                type: 'domain',
                id: updatedDomainData.id,
                attributes: foundUpdatedPrice
            };
        });

        setServicesWithRenewDurationSelected(newServicesWithRenewDurationSelected);
    }, [domain_bulk_info_data]);

    /***** RENDER HELPERS *****/
    function renderOptions(prices, id) {
        return prices.map(({ years, price, promo_applies, base_price, discount_price }) => ({
            label: promo_applies ? (
                <>
                    <Text primary medium className="bulkRenew__domainDiscountedPrice">
                        {`${years} Year${pluralize(years)} - $${discount_price} `}
                    </Text>
                    <del>
                        {years} Year{pluralize(years)} - ${base_price}
                    </del>
                </>
            ) : (
                <>
                    {years} Year{pluralize(years)} - ${price}
                </>
            ),
            onClick: () => {
                pushServicesWithRenewDurationSelected({ years, price, promo_applies, base_price, discount_price }, id);
            }
        }));
    }

    const contentComponent = {
        [headingKeys.domain_name]: ({ attributes: { domain } }) => (
            <Text secondary size--s>
                {domain}
            </Text>
        ),
        [headingKeys.expires]: ({ attributes: { expiry_date } }) => (
            <Text secondary size--s>
                {renderBulkServiceExpiry(expiry_date)}
            </Text>
        ),
        [headingKeys.renewal_duration]: ({ attributes: { prices, is_renewable, is_in_rgp }, included, id }) => {
            const hasInvoice = included ? included.filter((inc) => inc.type === 'invoice') : false;
            const foundRenewDurationService = servicesWithRenewDurationSelected.find((service) => service.id === id);
            const preselectedOption = prices.find((price) => price.years === foundRenewDurationService?.attributes?.years);
            const preselectedOptionIndex = prices.indexOf(preselectedOption);
            if (is_renewable && !hasInvoice)
                return (
                    <SelectDropdown
                        preselectedOptionIndex={preselectedOptionIndex >= 0 ? preselectedOptionIndex : null}
                        noSelectionLabel="Please Select"
                        options={renderOptions(prices, id)}
                    />
                );
            if (is_in_rgp)
                return (
                    <div className="contact__link">
                        <Anchor to="/support/tickets/submit">Contact Us</Anchor>
                    </div>
                );
            if (hasInvoice)
                return (
                    <Text size--s warn bold>
                        Domain has an outstanding invoice
                    </Text>
                );
            return (
                <Text size--s warn bold>
                    No renewal options available
                </Text>
            );
        }
    };

    /**
     * Filter the domain list so we don't have invalid data
     */
    const filteredDomainList =
        domain_bulk_info_data?.domains?.filter?.((domainData) => {
            if (!domainData || !domainData.attributes) return false;

            const {
                attributes: { domain, prices, expiry_date },
                id
            } = domainData;

            if (!domain || !prices || !expiry_date || !id) return false;

            return true;
        }) ?? [];

    function renderLoadingState(contentRenderFunction) {
        if (domain_bulk_info_status === 'loading') return <RequestLoader message="Loading bulk domain info..." />;
        if (domain_bulk_info_status === 'error') return <Text> Something went wrong loading the Bulk domain info. </Text>;
        if (!(domain_bulk_info_data?.domains?.length > 0))
            return <NXTable.ErrorMessage message="You have no domains selected. Select a few domains to get started!" />;
        return contentRenderFunction();
    }

    function renderDesktopTableBody() {
        /**
         * Then we render the Rows
         */
        return filteredDomainList.map((domainData, i) => {
            return <NXTable.Row key={i}>{tableHeadings.map(([sortKey]) => contentComponent[sortKey](domainData))}</NXTable.Row>;
        });
    }

    function renderDesktopTable() {
        return (
            <Border all>
                <NXTable columns="1fr 1fr 1fr">
                    <NXTable.Header>
                        {tableHeadings.map(([sortKey, content]) => (
                            <NXTable.Header.Title key={sortKey}>{content}</NXTable.Header.Title>
                        ))}
                    </NXTable.Header>
                    <NXTable.Body>{renderDesktopTableBody()}</NXTable.Body>
                </NXTable>
            </Border>
        );
    }

    function renderMobileTable() {
        return (
            <div className="renewTable--mobile">
                <NXTable.Accordion.ToggleWrapper>
                    {filteredDomainList.map((domainData, i) => (
                        <NXTable.Accordion index={i} key={i} content={null}>
                            {tableHeadings.map(([sortKey, content]) => (
                                <TableAccordion.Child key={sortKey}>
                                    <Text bold uppercase size--xs>
                                        {content}
                                    </Text>
                                    {contentComponent[sortKey](domainData)}
                                </TableAccordion.Child>
                            ))}
                        </NXTable.Accordion>
                    ))}
                </NXTable.Accordion.ToggleWrapper>
            </div>
        );
    }
    /***** RENDER *****/
    if (['sm', 'xs'].includes(app_viewport)) return renderLoadingState(renderMobileTable);
    return renderLoadingState(renderDesktopTable);
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

const mapStateToProps = (state: any) => ({
    app_viewport: state.app.app_viewport,
    domain_bulk_info_data: state.servicesBulk.domain_bulk_info_data,
    domain_bulk_info_status: state.servicesBulk.domain_bulk_info_status
});

RenewTable = connect(mapStateToProps)(RenewTable);

export default RenewTable;
