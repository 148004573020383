import { NXSelect } from 'components/Form/NXSelect';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { useController } from 'react-hook-form';
import { PageOptions } from 'containers/katana/containers/contentEditorModules/callToAction/pageOrSectionSelect/pageOptions';
import type { NavigationFormHandlerNamespace } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/navigationFormHandler/types';
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _PageOrSectionSelectHookForm = () => {
    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();
    const { field: sectionIdField } = useController<NavigationFormHandlerNamespace.NavigationFormValues>({ name: 'call_to_action.section_id' });
    const { field: pageIdField } = useController<NavigationFormHandlerNamespace.NavigationFormValues>({ name: 'call_to_action.page_id' });
    const { field: typeField } = useController<NavigationFormHandlerNamespace.NavigationFormValues>({ name: 'call_to_action.type' });

    /***** QUERIES *****/
    const { data: pages_data } = katanaQuery.serviceID.pages.useQuery(katanaServiceId);

    /***** FUNCTIONS *****/

    function handleOnSelectPage(page: KatanaNamespace.Page) {
        pageIdField.onChange(page.id);
        typeField.onChange('page');
        sectionIdField.onChange(null);
    }

    function handleOnSelectSection(section: KatanaNamespace.Section) {
        sectionIdField.onChange(section.id);
        typeField.onChange('section');
        pageIdField.onChange(null);
    }

    const value = typeField.value === 'page' ? pageIdField.value : sectionIdField.value;

    /***** RENDER *****/
    return (
        <NXSelect value={value}>
            {pages_data?.map((page) => (
                <PageOptions key={page.id} page={page} onSelectPage={handleOnSelectPage} onSelectSection={handleOnSelectSection} />
            ))}
        </NXSelect>
    );
};
