import { NavigationItemsManager } from 'containers/katana/containers/contentEditorModules/navigationLabels/NavigationItemsManager';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { usePostNavigationItemsMutation } from 'containers/katana/queries/usePostNavigationItemsMutation';

const { SECTION_IDS } = usePostNavigationItemsMutation.FORM_FIELD_KEYS;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _NavigationLabelsContentModule = () => {
    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();

    /***** QUERIES *****/
    const isMultiPage = katanaQuery.serviceID.pages.useIsMultiPage(katanaServiceId);

    /***** RENDER *****/
    if (isMultiPage) return null;
    return <NavigationItemsManager />;
};

const NavigationLabelsContentModule = Object.assign(_NavigationLabelsContentModule, {
    formFields: { SECTION_IDS }
});

export { NavigationLabelsContentModule };
