/**********************************************************************************************************
 *   FUNCTION START
 **********************************************************************************************************/

import type { AnyRouteId, AnyRouteOptions } from 'router/types';
import type { PathObject } from 'router/utils';
import { parentConfigSchema, routeConfigSchema } from 'router/utils/schemas';

/**
 * Takes an options object and returns the full pathname of the route. This is useful for determining the correct route config object
 * to use.
 */
export function getFullPathname(options: AnyRouteOptions | Pick<PathObject, 'path'>): AnyRouteId {
    try {
        const parsed = routeConfigSchema.parse(options);
        const parentPath = parentConfigSchema.parse(parsed.getParentRoute());
        const isParentRoot = parentPath === '/' || parentPath === '__root__';

        const routeId = parsed.id || parsed.path || '';
        const unslashedPath = routeId.replace(/^\//, '');

        // When we are working with a routeOptions object, Assuming no race conditions, routeId should've been (re)created by tanstack to include the full Id.
        // If this is not the case, then we are working with a pathObject and we need to create the full path ourselves from the path.
        if (routeId.includes(parentPath) && !isParentRoot) {
            return routeId as AnyRouteId;
        }

        return `${isParentRoot ? '' : parentPath}/${unslashedPath}` as AnyRouteId;
    } catch (e) {
        return (options as PathObject)?.path as AnyRouteId;
    }
}
