import { SolidButton } from 'components/Buttons/SolidButton';
import { getInvoiceActionUrl } from 'components/Lightboxes/OverlayLightbox/Components/invoice/invoiceUrlLightbox/methods';
import RequestLoader from 'components/Loaders/Request';
import { DialogNotification } from 'components/Notifications/DialogNotification';
import NXBox from 'components/NXBox';
import Text from 'components/Utils/Text';
import { useGetInvoiceQuery } from 'containers/billing/queries/invoice/useGetInvoiceQuery';
import ContentEditorModuleContent from 'containers/katana/containers/ContentEditorLightbox/contentEditorModuleContent';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import './_domainNameContentModule.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const DomainNameContentModule = () => {
    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();

    /***** QUERIES *****/
    const { data: katana_service_data } = katanaQuery.serviceID.service.useQuery(katanaServiceId);

    const isExpressFlowAndNoDomain = katana_service_data?.attributes.order_meta?.vip_sites_express === '0';
    const unpaidDomainInvoiceId = katana_service_data?.attributes.unpaid_domain_invoice;

    const { data: get_invoice_data, isFetching: isInvoiceFetching } = useGetInvoiceQuery(unpaidDomainInvoiceId);

    const isDomainInvoiceUnpaid = get_invoice_data?.attributes.status === 'unpaid';

    const currentDomainName = katana_service_data?.attributes.name;

    /***** RENDER *****/
    return (
        <NXBox.TopAccordion.WithSessionState
            title="Domain Name"
            description="The domain name that your VIPsites website is using. When someone types this name into a search engine, they will be sent to this VIPsites website."
            isOpenDefault
        >
            <NXBox.DefaultPadding>
                {isInvoiceFetching ? (
                    <RequestLoader />
                ) : (
                    <ContentEditorModuleContent>
                        <Text semiBold>Current VIPsites domain name</Text>
                        <div className="domainNameContentModule__currentDomainContainer">
                            <Text className="domainNameContentModule__currentDomain">{currentDomainName}</Text>
                            {isDomainInvoiceUnpaid ? (
                                <SolidButton
                                    className="domainNameContentModule__payInvoiceButton"
                                    to={getInvoiceActionUrl('pay', unpaidDomainInvoiceId)}
                                >
                                    Pay Invoice
                                </SolidButton>
                            ) : null}
                        </div>
                        {isExpressFlowAndNoDomain ? (
                            <>
                                <DialogNotification type="warning" tail={{ pos: 'top' }}>
                                    PLEASE NOTE: You can choose to launch your website on the above domain name, however we strongly recommend that
                                    you register a proper domain name instead. If you do choose to launch on the above domain name, your VIPsites
                                    website will include the displaying of VentraIP branding.
                                </DialogNotification>
                                <SolidButton.Link
                                    to="/my-services/vip-sites/$katanaServiceId/overview"
                                    from="/my-services/vip-sites/$katanaServiceId"
                                    search={{ 'add-domains': 'open' }}
                                >
                                    Add a personalized domain name
                                </SolidButton.Link>
                            </>
                        ) : null}
                        {isDomainInvoiceUnpaid ? (
                            <DialogNotification type="warning" tail={{ pos: 'top' }}>
                                PLEASE NOTE: In order to launch your website on your chosen domain name the domain name registration invoice must be
                                paid.
                            </DialogNotification>
                        ) : null}
                    </ContentEditorModuleContent>
                )}
            </NXBox.DefaultPadding>
        </NXBox.TopAccordion.WithSessionState>
    );
};
