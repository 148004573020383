/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMemo } from 'react';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useGetRoutableCallToActionSections() {
    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();

    /***** QUERIES *****/
    const { data: sections_data } = katanaQuery.serviceID.sections.useQuery(katanaServiceId);
    const { data: section_definitions_data } = katanaQuery.serviceID.meta.sectionDefinitions.useQuery(katanaServiceId);

    /***** RENDER *****/
    return useMemo(() => {
        if (!Array.isArray(sections_data) || !section_definitions_data) {
            return [];
        }

        const sortableSections = [...sections_data].sort((a, b) => (a.order < b.order ? -1 : 1));
        return (
            sortableSections?.map?.((section) => {
                return {
                    label: section_definitions_data?.[section.section_id]?.name,
                    value: section.id
                };
            }) ?? []
        );
    }, [section_definitions_data, sections_data]);
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
