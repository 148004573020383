import { Input } from 'components/Form/Input';
import { Select } from 'components/Form/Select';
import { SingleFieldAutoCompleteAddress } from 'components/Form/SingleFieldAutoCompleteAddress';
import { TextArea } from 'components/Form/TextArea';
import InteractiveGoogleMapsIframe from 'components/InteractiveGoogleMapsIframe';
import NXBox from 'components/NXBox';
import Tooltip from 'components/Tooltip';
import { Flex } from 'components/Utils/Flex';
import { fieldLabelWithOptional } from 'containers/katana/components/fieldLabelWithOptional';
import ContentEditorModuleContent from 'containers/katana/containers/ContentEditorLightbox/contentEditorModuleContent';
import { formatBusinessNumber } from 'containers/katana/containers/contentEditorModules/contactEmailMobile/methods';
import { useUpdateKatanaSiteBusinessInfoMutation } from 'containers/katana/queries/useUpdateKatanaSiteBusinessInfoMutation';
import { useFormContext, useWatch } from 'react-hook-form';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const { EMAIL, PHONE, ADDRESS, OPERATING_HOURS, BUSINESS_NUMBER, BUSINESS_NUMBER_TYPE } = useUpdateKatanaSiteBusinessInfoMutation.FORM_FIELD_KEYS;

const operationHoursPlaceholder = `Monday: 8am-7pm
Tuesday: 8am-7pm
Wednesday: 8am-7pm
Thursday: 8am-7pm
Friday: 8am-7pm
Saturday: 9am-5pm
Sunday: Closed`;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const _ContactEmailMobileContentModule = () => {
    /***** HOOKS *****/
    const { setValue } = useFormContext();
    const formValues = useWatch();

    /***** RENDER *****/
    return (
        <NXBox>
            <NXBox.Top
                title="Contact Details"
                description="Let's add some contact details where your customers can reach out to you. You can choose which details to display on your website later."
            />
            <NXBox.DefaultPadding>
                <ContentEditorModuleContent>
                    <Input.HookForm
                        name={EMAIL}
                        label={
                            <Flex align="center" gap={1}>
                                {fieldLabelWithOptional('Enter a contact email address')}
                                <Tooltip info=" If you decide to include a 'Contact Us' section on your website, any inquiries submitted through the contact form will be sent to this email address." />
                            </Flex>
                        }
                        placeholder="E.g. example@example.com"
                        intrinsicProps={{ type: 'email' }}
                    />

                    <Input.HookForm
                        label={
                            <Flex align="center" gap={1}>
                                {fieldLabelWithOptional('Enter a contact mobile number')}
                                <Tooltip info="If you opt to show a phone number on your website, this is the number that will be displayed" />
                            </Flex>
                        }
                        name={PHONE}
                        intrinsicProps={{ type: 'tel' }}
                        placeholder="E.g. +61 491 570 159"
                    />

                    <SingleFieldAutoCompleteAddress.HookForm
                        label={
                            <Flex align="center" gap={1}>
                                {fieldLabelWithOptional('Enter your business address')}
                                <Tooltip
                                    info={`If you choose to have a 'Contact Us' area on your site, you can display your business address using Google Maps.`}
                                />
                            </Flex>
                        }
                        name={ADDRESS}
                        placeholder="E.g. Level 1/66 Victor Cres, Narre Warren VIC 3805"
                    />

                    <InteractiveGoogleMapsIframe address={formValues?.[ADDRESS] || 'Melbourne 3000'} />

                    <TextArea.HookForm
                        label={
                            <Flex align="center" gap={1}>
                                {fieldLabelWithOptional('Add your business operating hours')}
                                <Tooltip
                                    info={`If you choose to have a 'Contact Us' area on your site, you can list your business' operating hours by entering them above.`}
                                />
                            </Flex>
                        }
                        name={OPERATING_HOURS}
                        placeholder={operationHoursPlaceholder}
                        rows={7}
                    />

                    <Select.HookForm
                        label={
                            <Flex align="center" gap={1}>
                                {fieldLabelWithOptional('Include other business information')}
                                <Tooltip info="You can display some other business information on your website like your ABN or ACN number." />
                            </Flex>
                        }
                        name={BUSINESS_NUMBER_TYPE}
                        onSelect={() => {
                            setValue(BUSINESS_NUMBER, '');
                        }}
                        options={[
                            ...(formValues?.[BUSINESS_NUMBER_TYPE] ? [{ label: 'Please select', value: null }] : []),
                            { label: 'ABN', value: 'ABN' },
                            { label: 'ACN', value: 'ACN' },
                        ]}
                    />
                    {Boolean(formValues?.[BUSINESS_NUMBER_TYPE]) && (
                        <Input.HookForm
                            key={formValues?.[BUSINESS_NUMBER_TYPE]}
                            name={BUSINESS_NUMBER}
                            valueTransform={(value) => formatBusinessNumber(value, formValues?.business_number_type)}
                            onChangeTransform={(value) => {
                                const formatResult = formatBusinessNumber(value, formValues?.business_number_type);
                                if (typeof formatResult === 'string') {
                                    return formatResult.replaceAll(' ', '');
                                }
                                return formatResult;
                            }}
                            placeholder={formValues?.business_number_type === 'ABN' ? 'E.g. 91 137 543 018' : 'E.g. 137 543 018'}
                        />
                    )}
                </ContentEditorModuleContent>
            </NXBox.DefaultPadding>
        </NXBox>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
const ContactEmailMobileContentModule = Object.assign(_ContactEmailMobileContentModule, {
    formFields: { EMAIL, PHONE, ADDRESS, OPERATING_HOURS, BUSINESS_NUMBER_TYPE, BUSINESS_NUMBER },
});

export { ContactEmailMobileContentModule };
