import _ from 'lodash';
import { regexes } from 'utilities/methods/regexes';

export function formFieldYouTubeUrlParser(value: unknown) {
    if (_.isNil(value)) {
        return 'Nil';
    }
    if (typeof value !== 'string') {
        return 'Not a string';
    }
    const regExp = regexes.youtubeLink;
    const match = value.match(regExp());

    if (!match) {
        return 'Invalid YouTube URL';
    }
    return undefined;
}
