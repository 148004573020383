/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { has } from 'lodash';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';

export function transformAttachmentDataToAttachment(
    attachmentData: Artah.Katana.ID.Uploads.POST._200['data']
): KatanaNamespace.FileAttachmentDataAttachment {
    return {
        type: 'attachment',
        attachment: {
            id: attachmentData.id
        }
    };
}

export function transformAttachmentDataToLink(attachmentData: string): KatanaNamespace.FileAttachmentDataLink {
    if (typeof attachmentData === 'string') {
        return {
            type: 'link',
            url: attachmentData
        };
    }
    return attachmentData;
}

export function transformToVideoEmbed(attachmentData: string): KatanaNamespace.MediaDataEmbed {
    if (typeof attachmentData === 'string') {
        return {
            type: 'embed',
            url: attachmentData
        };
    }
    return attachmentData;
}

export function isAttachmentDataAttachment(value: any): value is KatanaNamespace.FileAttachmentDataAttachment {
    if (typeof value !== 'object' || value === null) {
        return false;
    }
    return has(value, 'attachment.id') && typeof value.attachment.id === 'number' && has(value, 'type') && value.type === 'attachment';
}

export function isAttachmentDataLink(value: any): value is KatanaNamespace.FileAttachmentDataLink {
    if (typeof value !== 'object' || value === null) {
        return false;
    }
    return has(value, 'url') && typeof value.url === 'string' && has(value, 'type') && value.type === 'link';
}

export function isAttachmentDataEmbed(value: any): value is KatanaNamespace.MediaDataEmbed {
    if (typeof value !== 'object' || value === null) {
        return false;
    }
    return has(value, 'url') && typeof value.url === 'string' && has(value, 'type') && value.type === 'embed';
}

export function isFileData(value: any): value is KatanaNamespace.FileAttachmentData {
    return isAttachmentDataAttachment(value) || isAttachmentDataLink(value) || isAttachmentDataEmbed(value);
}
