import { useMutation } from '@tanstack/react-query';
import { pushNotification } from 'components/Toast/functions';
import { newPageTrackerData } from 'containers/katana/components/pageOrganiser/newPageTracker';
import { newSectionTrackerData } from 'containers/katana/modules/presetContent/components/sectionOrganiser/newSectionTrackerData';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import { section } from 'containers/katana/queries/serviceID/section';
import type { KatanaNamespace } from 'containers/katana/types';
import { queryClient } from 'store/queryClient';
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions/handleDefaultErrorNotification';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Params = {
    serviceID: KatanaNamespace.ServiceId;
    sectionID: KatanaNamespace.SectionId;
    newPageID: KatanaNamespace.PageId;
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation() {
    /***** FUNCTIONS *****/

    async function onMutate({ serviceID, sectionID, newPageID }: Params) {
        const cancelQueries = [
            queryClient.cancelQueries({
                queryKey: katanaQueryKeys.katana.service.ID(serviceID),
            }),

            queryClient.cancelQueries({
                queryKey: katanaQueryKeys.katana.service.ID.sections(serviceID),
            }),

            queryClient.cancelQueries({
                queryKey: katanaQueryKeys.katana.service.ID.pages.ID.sections({ serviceID, pageID: newPageID }),
            }),
        ];

        const sectionResult = section.getQueryData({
            serviceID,
            sectionID,
        });

        newPageTrackerData.removeID(newPageID);
        if (sectionResult?.data.site_page_id) {
            const oldPageID = sectionResult?.data.site_page_id;
            newPageTrackerData.removeID(oldPageID);

            cancelQueries.push(
                queryClient.cancelQueries({
                    queryKey: katanaQueryKeys.katana.service.ID.pages.ID.sections({ serviceID, pageID: oldPageID }),
                })
            );
        }
        newSectionTrackerData.removeID(sectionID);

        await Promise.all(cancelQueries);

        return {
            sectionResult,
            restore: () => {
                if (sectionResult?.data.site_page_id) {
                    const oldPageID = sectionResult?.data.site_page_id;
                    newPageTrackerData.addNewID(oldPageID);
                }
                newSectionTrackerData.addNewID(sectionID);
            },
        };
    }

    function mutationFn({ serviceID, sectionID, newPageID }: Params) {
        return KATANA_API.katana.site.service_id.section.section_id.PUT({
            serviceID,
            sectionID,
            attributes: {
                site_page_id: newPageID,
            },
        });
    }

    /***** HOOK RESULTS *****/
    return useMutation({
        onMutate,
        mutationFn,
        onError: (error, variables, context) => {
            handleDefaultErrorNotification(error);
            // Restore Optimistic Update
            context?.restore();
        },
        onSuccess: (e) => {
            pushNotification({
                status: e.status,
                details: 'Section moved successfully',
            });
        },
        onSettled: (data, error, { serviceID, newPageID, sectionID }, context) => {
            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID(serviceID),
                exact: true,
            });

            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID.sections(serviceID),
                exact: true,
            });

            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID.preview(serviceID),
            });

            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID.sections.ID({ serviceID, sectionID }),
                exact: true,
            });

            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID.pages.ID({ serviceID, pageID: newPageID }),
                exact: true,
            });

            if (!context?.sectionResult) return;
            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID.pages.ID({ serviceID, pageID: context.sectionResult.data.site_page_id }),
                exact: true,
            });
        },
    });
}

export const moveSection = Object.freeze({
    useMutation: _useMutation,
});
