import { queryOptions, useQuery } from '@tanstack/react-query';
import { API } from 'utilities/api/login';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Params = {
    provider: string;
    accessToken: string;
    token: string;
    redirectUrl: string;
};

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
const createQueryKey = (params: Params) => createBaseQueryKey(['login', 'oAuth', 'additionalUser', params]);

/***** FUNCTIONS *****/
function createQueryOptions({ provider, accessToken, token, redirectUrl }: Params) {
    return queryOptions({
        queryKey: createQueryKey({ provider, accessToken, token, redirectUrl }),
        queryFn: () => API.login.additionalUser.oAuthSubmit({ provider, accessToken, token, redirectUrl })
    });
}
/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useQuery({ provider, accessToken, token, redirectUrl }: Params) {
    return useQuery(createQueryOptions({ provider, accessToken, token, redirectUrl }));
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const additionalUserOAuthLogin = Object.freeze({
    useQuery: _useQuery,
    createQueryKey,
    createQueryOptions
});
