import backgroundImage from 'assets/images/katana/katana_banner_background.jpeg';
import katanaLogo from 'assets/images/katana/vipsites_banner_icon.svg';
import katanaLogoWide from 'assets/images/katana/vipsites_banner_logo.svg';
import Anchor from 'components/Anchor';
import { SolidButton } from 'components/Buttons/SolidButton';
import SelectDropdown from 'components/Dropdowns/SelectDropdown';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import RequestLoader from 'components/Loaders/Request';
import NXBox from 'components/NXBox';
import { Revealer } from 'components/Revealer';
import { ServiceOverviewBanner } from 'components/ServiceOverviewBanner';
import { Border } from 'components/Utils/Border';
import { Flex } from 'components/Utils/Flex';
import Text from 'components/Utils/Text';
import { katanaPages } from 'containers/katana/consts';
import useKatanaURL from 'containers/katana/hooks/useKatanaURL';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { katanaServiceBannerStateTanstackStore } from 'containers/services/modules/katanaServicesList/consts';
import { useState } from 'react';
import { useBooleanTanstackStore } from 'utilities/hooks/tanstack-store/useBooleanStore';
import './_katanaServiceBanner.scss';

const { REGISTER } = katanaPages;

const filters = {
    page: 1,
    sort: 'desc',
};
/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export function KatanaServiceBanner() {
    /***** STATE *****/
    const {
        value: isGetKatanaOpen,
        setTrue: openGetKatana,
        setFalse: closeGetKatana,
    } = useBooleanTanstackStore(katanaServiceBannerStateTanstackStore);
    const [selectedDomain, setSelectedDomain] = useState('');

    /***** HOOKS *****/
    const { getKatanaDestination } = useKatanaURL();

    /***** QUERIES *****/
    const { data: get_katana_domains_list_data, isLoading: isGetKatanaDomainsListLoading } = katanaQuery.getDomainsList.useInfiniteQuery(filters);
    /***** RENDER HELPERS *****/
    const selectableDomains =
        get_katana_domains_list_data?.pages?.flat()?.map((domain) => ({
            label: domain,
            value: domain,
            onClick: () => setSelectedDomain(domain),
        })) ?? [];

    const hasSelectableDomains = selectableDomains.length > 0;

    const multiLoaderData = RequestLoader.MultiLoader.useLoadersData([
        {
            condition: isGetKatanaDomainsListLoading,
            message: 'Loading domains list...',
        },
    ]);

    /***** RENDER *****/
    return (
        <div className="KatanaServiceBanner">
            <ServiceOverviewBanner src={backgroundImage}>
                <ServiceOverviewBanner.ContentWrapper>
                    <ServiceOverviewBanner.Heading className="KatanaServiceBanner__bannerHeading">
                        Add a VIPsites Service
                    </ServiceOverviewBanner.Heading>
                    <ServiceOverviewBanner.Description>
                        Explore a range of free easy-to-use website templates to get your business online in just a few clicks.
                    </ServiceOverviewBanner.Description>
                    <Flex>
                        <SolidButton color="white" type="onClick" onClick={openGetKatana}>
                            <Text black medium>
                                Get Started
                            </Text>
                        </SolidButton>
                    </Flex>
                </ServiceOverviewBanner.ContentWrapper>
                <ServiceOverviewBanner.Image src={katanaLogo} alt="Katana Logo" />
            </ServiceOverviewBanner>

            <Border all>
                <Revealer isOpen={isGetKatanaOpen}>
                    <NXBox.DefaultPadding paddingOnly>
                        <Flex className="KatanaServiceBanner__contentWrapper" justify="center">
                            <button className="KatanaServiceBanner__closeButton" type="button" onClick={closeGetKatana}>
                                <PhosphorIcons.X.Bold size={24} secondary />
                            </button>
                            <Flex className="KatanaServiceBanner__revealedContent" align="center" direction="column">
                                <img src={katanaLogoWide} alt="VIPsites Logo" />
                                <Text primary size--xxl align--center>
                                    VIPsites is a simple and{' '}
                                    <Text bold span>
                                        free
                                    </Text>{' '}
                                    website builder that lets you easily create stunning web pages
                                </Text>
                                <Text secondary size--m align--center medium className="KatanaServiceBanner__revealedContentPitch">
                                    With an easy to use interface and a guided setup process, setting up a new website has never been easier. Get
                                    started today by selecting one of your domains below.
                                </Text>
                                {hasSelectableDomains ? (
                                    <>
                                        <Text black size--m align--center medium>
                                            Which domain name would you like to create a VIPsites website for?
                                        </Text>
                                        <RequestLoader.MultiLoader loaders={multiLoaderData}>
                                            <SelectDropdown optionDisplayLimit={4} noSelectionLabel="Please Select" options={selectableDomains} />
                                            <SolidButton disabled={!selectedDomain} to={getKatanaDestination(REGISTER, selectedDomain)}>
                                                Confirm
                                            </SolidButton>
                                        </RequestLoader.MultiLoader>
                                    </>
                                ) : (
                                    <>
                                        <Text black size--m align--center medium>
                                            Looks like you have no domains available to use for a new VIPsites website.
                                        </Text>
                                        <Anchor href="https://ventraip.com.au/website-builder/?flow=vipsites&fullscreen=true" target="_blank">
                                            Purchase a new domain
                                        </Anchor>
                                    </>
                                )}
                            </Flex>
                        </Flex>
                    </NXBox.DefaultPadding>
                </Revealer>
            </Border>
        </div>
    );
}
