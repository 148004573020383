/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { Tooltip } from '@mui/material';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import type { IconifyIconNamespace } from 'components/Icons/types';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const PictureInPictureIconControlButton: React.FC<{
    text?: string;
    Icon: IconifyIconNamespace.Component;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    preventHover?: boolean;
    buttonRef?: React.MutableRefObject<HTMLButtonElement | null>;
}> = ({ text = 'Control', Icon, onClick, preventHover, buttonRef }) => (
    <Flex inject>
        <Tooltip disableHoverListener={preventHover} title={text} arrow placement="top">
            <span>
                <Padding x={2} inject>
                    <Flex fullHeight inject align="center">
                        <button type="button" onClick={onClick} ref={buttonRef}>
                            <Icon blackest size={26} />
                        </button>
                    </Flex>
                </Padding>
            </span>
        </Tooltip>
    </Flex>
);

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { PictureInPictureIconControlButton };
