/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { IsDataUpdatingOverlay } from 'components/IsDataUpdatingOverlay';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _NXDropZone as NXDropZone } from 'components/Form/NXDropZone/_NXDropZone';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useKatanaFileUpload } from 'components/Form/NXDropZone/wrappers/withKatanaFileUpload/useKatanaFileUpload';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @type {React.FC<import('components/Form/NXDropZone/types').NXDropZoneNamespace.WithKatanaFileUpload.Props>}
 */
const _NXDropZoneWithKatanaFileUpload = ({ serviceID, ...baseProps }) => {
    const { onChange } = baseProps;
    /***** HOOKS *****/
    const { isUploading, onChangeTransform } = useKatanaFileUpload(serviceID, onChange);

    /***** RENDER *****/
    return (
        <IsDataUpdatingOverlay.StableDomStructure noBorder isDataUpdating={isUploading} message="Uploading File...">
            <NXDropZone {...baseProps} onChange={onChangeTransform} />
        </IsDataUpdatingOverlay.StableDomStructure>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { _NXDropZoneWithKatanaFileUpload };
