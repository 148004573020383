import { APP_MOUNTING } from 'App/actionTypes';
import { CanceledError } from 'axios';
import { notificationScopes } from 'components/Toast/consts';
import { pushNotification } from 'components/Toast/functions';
import Text from 'components/Utils/Text';
import { batchActions } from 'redux-batched-actions';
import store from 'store/store';
import { API } from 'utilities/api/services';
import { getDataFromSuccessResponse, getErrorFromFailResponse, getMetaFromSuccessResponse } from 'utilities/methods/commonActions';

import { loadAppConfig } from "App/actions/loadAppConfig";
import {
    DOMAIN_AUTO_RENEW_FAIL,
    DOMAIN_AUTO_RENEW_REQUEST,
    DOMAIN_AUTO_RENEW_SUCCESS,
    DOMAIN_BULK_MANAGE_UPDATE,
    DOMAIN_EXPORT_ERROR,
    DOMAIN_EXPORT_REQUEST,
    DOMAIN_EXPORT_SUCCESS,
    DOMAIN_FORM_FAIL,
    DOMAIN_FORM_REQUEST,
    DOMAIN_FORM_SUCCESS,
    DOMAIN_LIST_ERROR,
    DOMAIN_LIST_MORE_ERROR,
    DOMAIN_LIST_MORE_REQUEST,
    DOMAIN_LIST_MORE_SUCCESS,
    DOMAIN_LIST_REQUEST,
    DOMAIN_LIST_RESET,
    DOMAIN_LIST_SUCCESS,
    DOMAIN_SEARCH_ERROR,
    DOMAIN_SEARCH_REQUEST,
    DOMAIN_SEARCH_RESET,
    DOMAIN_SEARCH_SUCCESS,
    EMAIL_LIST_ERROR,
    EMAIL_LIST_MORE_ERROR,
    EMAIL_LIST_MORE_REQUEST,
    EMAIL_LIST_MORE_SUCCESS,
    EMAIL_LIST_REQUEST,
    EMAIL_LIST_RESET,
    EMAIL_LIST_SUCCESS,
    EMAIL_SEARCH_ERROR,
    EMAIL_SEARCH_REQUEST,
    EMAIL_SEARCH_RESET,
    EMAIL_SEARCH_SUCCESS,
    GET_SSL_LIST,
    GET_SSL_LIST_ERROR,
    GET_SSL_LIST_MORE_ERROR,
    GET_SSL_LIST_MORE_REQUEST,
    GET_SSL_LIST_MORE_SUCCESS,
    GET_SSL_LIST_SUCCESS,
    GET_SSL_PRODUCTS,
    GET_SSL_PRODUCTS_ERROR,
    GET_SSL_PRODUCTS_SUCCESS,
    GSUITE_LIST_ERROR,
    GSUITE_LIST_MORE_ERROR,
    GSUITE_LIST_MORE_REQUEST,
    GSUITE_LIST_MORE_SUCCESS,
    GSUITE_LIST_REQUEST,
    GSUITE_LIST_SUCCESS,
    GSUITE_SEARCH_ERROR,
    GSUITE_SEARCH_REQUEST,
    GSUITE_SEARCH_RESET,
    GSUITE_SEARCH_SUCCESS,
    HOSTING_LIST_ERROR,
    HOSTING_LIST_MORE_ERROR,
    HOSTING_LIST_MORE_REQUEST,
    HOSTING_LIST_MORE_SUCCESS,
    HOSTING_LIST_REQUEST,
    HOSTING_LIST_RESET,
    HOSTING_LIST_SUCCESS,
    HOSTING_SEARCH_ERROR,
    HOSTING_SEARCH_REQUEST,
    HOSTING_SEARCH_RESET,
    HOSTING_SEARCH_SUCCESS,
    MS365_LIST_ERROR,
    MS365_LIST_MORE_ERROR,
    MS365_LIST_MORE_REQUEST,
    MS365_LIST_MORE_SUCCESS,
    MS365_LIST_REQUEST,
    MS365_LIST_SUCCESS,
    MS365_SEARCH_ERROR,
    MS365_SEARCH_REQUEST,
    MS365_SEARCH_RESET,
    MS365_SEARCH_SUCCESS,
    PURCHASE_SSL_PRODUCT,
    PURCHASE_SSL_PRODUCT_ERROR,
    PURCHASE_SSL_PRODUCT_RESET,
    PURCHASE_SSL_PRODUCT_SUCCESS,
    SERVICES_INITIAL_STATE,
    SERVICE_CHANGE_PLAN_PATHS_ERROR,
    SERVICE_CHANGE_PLAN_PATHS_REQUEST,
    SERVICE_CHANGE_PLAN_PATHS_SUCCESS,
    SERVICE_MOVE_APPROVE_REQUEST_RESET,
    SERVICE_MOVE_APPROVE_SUCCESS,
    SERVICE_MOVE_CANCEL_ERROR,
    SERVICE_MOVE_CANCEL_REQUEST,
    SERVICE_MOVE_CANCEL_SUCCESS,
    SERVICE_MOVE_ERROR,
    SERVICE_MOVE_INVITATION_ERROR,
    SERVICE_MOVE_INVITATION_REQUEST,
    SERVICE_MOVE_INVITATION_SUCCESS,
    SERVICE_MOVE_REGISTER_ERROR,
    SERVICE_MOVE_REGISTER_REQUEST,
    SERVICE_MOVE_REGISTER_SUCCESS,
    SERVICE_MOVE_REQUEST,
    SERVICE_MOVE_SUCCESS,
    SERVICE_RESTORE_INVOICE_ERROR,
    SERVICE_RESTORE_INVOICE_REQUEST,
    SERVICE_RESTORE_INVOICE_SUCCESS,
    SERVICE_UNSUSPENSION_REASON_ERROR,
    SERVICE_UNSUSPENSION_REASON_REQUEST,
    SERVICE_UNSUSPENSION_REASON_SUCCESS,
    SSL_LIST_RESET,
    SSL_SEARCH_ERROR,
    SSL_SEARCH_REQUEST,
    SSL_SEARCH_RESET,
    SSL_SEARCH_SUCCESS,
    VPS_LIST_ERROR,
    VPS_LIST_MORE_ERROR,
    VPS_LIST_MORE_REQUEST,
    VPS_LIST_MORE_SUCCESS,
    VPS_LIST_REQUEST,
    VPS_LIST_SUCCESS,
    VPS_SEARCH_ERROR,
    VPS_SEARCH_REQUEST,
    VPS_SEARCH_RESET,
    VPS_SEARCH_SUCCESS
} from './actionTypes';

/**********************************************************************************************************
 *   MY SERVICES RESET
 **********************************************************************************************************/

export const resetMyServices = () => {
    return (dispatch) => {
        dispatch({
            type: SERVICES_INITIAL_STATE
        });
    };
};

/**********************************************************************************************************
 *   SERVICE MOVE
 **********************************************************************************************************/
export const moveStatus = (id) => {
    return (dispatch) => {
        dispatch({
            type: SERVICE_MOVE_INVITATION_REQUEST
        });
        API.service.get
            .moveInvitation(id)
            .then((response) => {
                const service_move_invitation_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: SERVICE_MOVE_INVITATION_SUCCESS,
                    service_move_invitation_data
                });
            })
            .catch((error) => {
                const service_move_invitation_error = getErrorFromFailResponse(error);
                dispatch({
                    type: SERVICE_MOVE_INVITATION_ERROR,
                    service_move_invitation_error
                });
            });
    };
};

export const moveService = (attributes) => {
    return (dispatch) => {
        dispatch({
            type: SERVICE_MOVE_REQUEST
        });
        API.service.post
            .move(attributes)
            .then((response) => {
                const service_move_data = getDataFromSuccessResponse(response);
                const { service_id } = attributes;
                pushNotification(service_move_data);
                dispatch(
                    batchActions([
                        {
                            type: SERVICE_MOVE_SUCCESS,
                            service_move_data
                        },
                        moveStatus(service_id)
                    ])
                );
            })
            .catch((error) => {
                const service_move_error = getErrorFromFailResponse(error);
                pushNotification(service_move_error);
                dispatch(
                    batchActions([
                        {
                            type: SERVICE_MOVE_ERROR,
                            service_move_error
                        }
                    ])
                );
            });
    };
};

export const registerMoveService = (router, attributes, token) => {
    return (dispatch) => {
        dispatch({
            type: SERVICE_MOVE_REGISTER_REQUEST
        });
        API.service.post
            .registerMove(attributes, token)
            .then((response) => {
                const service_move_register_data = response.data;
                pushNotification(service_move_register_data, null, notificationScopes.GLOBAL);
                dispatch({
                    type: SERVICE_MOVE_REGISTER_SUCCESS,
                    service_move_register_data
                });

                dispatch({
                    type: SERVICE_MOVE_APPROVE_SUCCESS
                });
                loadAppConfig({
                    context: 'react',
                    dependencies: {
                        navigate: router.navigate
                    }
                });
            })
            .catch((error) => {
                const service_move_register_error = getErrorFromFailResponse(error);
                pushNotification(service_move_register_error, null, notificationScopes.GLOBAL);

                if (error.response.data.data) {
                    dispatch({
                        type: SERVICE_MOVE_APPROVE_REQUEST_RESET
                    });
                    dispatch({
                        type: APP_MOUNTING,
                        app_mounting: true
                    });
                    loadAppConfig({
                        context: 'react',
                        dependencies: {
                            navigate: router.navigate
                        }
                    });
                } else {
                    dispatch({
                        type: SERVICE_MOVE_REGISTER_ERROR,
                        service_move_register_error
                    });
                }
            });
    };
};

export const cancelMoveService = (move_id, service_id) => {
    return (dispatch) => {
        dispatch({
            type: SERVICE_MOVE_CANCEL_REQUEST
        });
        API.service.post
            .cancelMove(move_id)
            .then((response) => {
                const service_move_cancel_data = getDataFromSuccessResponse(response);
                pushNotification(service_move_cancel_data);
                dispatch(
                    batchActions([
                        {
                            type: SERVICE_MOVE_CANCEL_SUCCESS,
                            service_move_cancel_data
                        },
                        moveStatus(service_id)
                    ])
                );
            })
            .catch((error) => {
                const service_move_cancel_error = getErrorFromFailResponse(error);
                pushNotification(service_move_cancel_error);
                dispatch(
                    batchActions([
                        {
                            type: SERVICE_MOVE_CANCEL_ERROR,
                            service_move_cancel_error
                        }
                    ])
                );
            });
    };
};

/**
 * SERVICE UNSUSPEND
 */

export const serviceUnsuspensionReason = (service_id) => {
    return (dispatch) => {
        dispatch({
            type: SERVICE_UNSUSPENSION_REASON_REQUEST
        });
        API.service.get
            .suspensionReason(service_id)
            .then((response) => {
                const service_unsuspension_reason_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: SERVICE_UNSUSPENSION_REASON_SUCCESS,
                    service_unsuspension_reason_data
                });
            })
            .catch((error) => {
                const service_unsuspension_reason_error = getErrorFromFailResponse(error);
                dispatch({
                    type: SERVICE_UNSUSPENSION_REASON_ERROR,
                    service_unsuspension_reason_error
                });
            });
    };
};

export function getServiceUpgradePaths(serviceId) {
    const { dispatch } = store;

    dispatch({
        type: SERVICE_CHANGE_PLAN_PATHS_REQUEST
    });

    API.service.get
        .upgradePaths(serviceId)
        .then((response) => {
            const service_change_plan_paths_data = getDataFromSuccessResponse(response);
            dispatch({
                type: SERVICE_CHANGE_PLAN_PATHS_SUCCESS,
                service_change_plan_paths_data
            });
        })
        .catch(() => {
            dispatch({
                type: SERVICE_CHANGE_PLAN_PATHS_ERROR
            });
        });
}

/**********************************************************************************************************
 *   DOMAIN LIST RESET
 **********************************************************************************************************/

export const resetDomainList = () => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_LIST_RESET
        });
    };
};

/**********************************************************************************************************
 *   DOMAIN LIST
 **********************************************************************************************************/

export const getDomainsList = (params, prevPage) => {
    return (dispatch) => {
        if (params) {
            dispatch({
                type: DOMAIN_LIST_MORE_REQUEST
            });
            API.domain
                .list(params)
                .then((response) => {
                    const domain_list_data = getDataFromSuccessResponse(response);
                    const domain_list_meta = getMetaFromSuccessResponse(response);
                    let new_domain_list_data = [];

                    if (prevPage && prevPage.length > 0) {
                        new_domain_list_data = prevPage.concat(domain_list_data);

                        dispatch({
                            type: DOMAIN_LIST_MORE_SUCCESS,
                            domain_list_data: Object.assign([], new_domain_list_data),
                            domain_list_meta
                        });
                    } else {
                        dispatch({
                            type: DOMAIN_LIST_MORE_SUCCESS,
                            domain_list_data,
                            domain_list_meta
                        });
                    }
                })
                .catch((error) => {
                    const domain_list_error = getErrorFromFailResponse(error);
                    dispatch({
                        type: DOMAIN_LIST_MORE_ERROR,
                        domain_list_error
                    });
                });
        } else {
            dispatch({
                type: DOMAIN_LIST_REQUEST
            });
            API.domain
                .list()
                .then((response) => {
                    const domain_list_data = getDataFromSuccessResponse(response);
                    const domain_list_meta = getMetaFromSuccessResponse(response);

                    dispatch({
                        type: DOMAIN_LIST_SUCCESS,
                        domain_list_data,
                        domain_list_meta
                    });
                })
                .catch((error) => {
                    const domain_list_error = getErrorFromFailResponse(error);
                    dispatch({
                        type: DOMAIN_LIST_ERROR,
                        domain_list_error
                    });
                });
        }
    };
};

/**********************************************************************************************************
 *   DOMAIN FUNCTIONS
 **********************************************************************************************************/
/**
 * @param {string} id
 * @param {{
 *   onSuccess?: () => void
 * }} [options]
 */
export const enableAutoRenewList = (id, options) => {
    const successNotification = (
        <span>
            Successfully enabled auto-renew.
            <br />
            <br />
            <Text bold>
                <span>Please note: </span>
            </Text>
            If this domain is due for renewal, please allow some time for the renewal invoice to be generated.
        </span>
    );
    return (dispatch) => {
        dispatch({
            type: DOMAIN_AUTO_RENEW_REQUEST
        });
        API.domain.post
            .enableRenew(id)
            .then((response) => {
                const domain_auto_renew_data = getDataFromSuccessResponse(response);
                pushNotification({ status: 200, details: successNotification });
                dispatch({
                    type: DOMAIN_AUTO_RENEW_SUCCESS,
                    domain_auto_renew_data
                });
                dispatch(getDomainsList());
                options?.onSuccess?.();
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(response);
                dispatch({
                    type: DOMAIN_AUTO_RENEW_FAIL,
                    domain_auto_renew_error: response
                });
            });
    };
};

export const disableAutoRenewList = (id) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_AUTO_RENEW_REQUEST
        });
        API.domain.post
            .disableRenew(id)
            .then((response) => {
                const domain_auto_renew_data = getDataFromSuccessResponse(response);
                pushNotification(domain_auto_renew_data);
                dispatch({
                    type: DOMAIN_AUTO_RENEW_SUCCESS,
                    domain_auto_renew_data
                });
                dispatch(getDomainsList());
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(response);
                dispatch({
                    type: DOMAIN_AUTO_RENEW_FAIL,
                    domain_auto_renew_error: response
                });
            });
    };
};

export const getTransferDetails = (id) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_FORM_REQUEST
        });
        API.domain.GET.transfer(id)
            .then((response) => {
                const domain_form_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: DOMAIN_FORM_SUCCESS,
                    domain_form_data
                });
                dispatch(getDomainsList());
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: DOMAIN_FORM_FAIL,
                    domain_form_error: response
                });
            });
    };
};

export const submitApproveTransfer = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_FORM_REQUEST
        });
        API.domain.post
            .approve(id, attributes)
            .then((response) => {
                const domain_form_data = getDataFromSuccessResponse(response);
                pushNotification(domain_form_data);
                dispatch(
                    batchActions([
                        {
                            type: DOMAIN_FORM_SUCCESS,
                            domain_form_data
                        },
                        getDomainsList()
                    ])
                );
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                dispatch({
                    type: DOMAIN_FORM_FAIL,
                    domain_form_error: response
                });
            });
    };
};

export const submitUpdateEpp = (id, attributes) => {
    const { dispatch } = store;

    dispatch({
        type: DOMAIN_FORM_REQUEST
    });
    API.domain.post
        .epp(id, attributes)
        .then((response) => {
            const domain_form_data = getDataFromSuccessResponse(response);
            dispatch({
                type: DOMAIN_FORM_SUCCESS,
                domain_form_data
            });
            dispatch(getDomainsList());
            pushNotification(domain_form_data);
        })
        .catch((error) => {
            const response = getErrorFromFailResponse(error);
            pushNotification(response);
            dispatch({
                type: DOMAIN_FORM_FAIL,
                domain_form_error: response
            });
        });
};

export const submitUpdateInfo = (id, attributes) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_FORM_REQUEST
        });
        API.domain.post
            .info(id, attributes)
            .then((response) => {
                const domain_form_data = getDataFromSuccessResponse(response);
                pushNotification({ status: 200, details: 'Details successfully updated.' });
                dispatch({
                    type: DOMAIN_FORM_SUCCESS,
                    domain_form_data
                });
                dispatch(getDomainsList());
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(response);
                dispatch({
                    type: DOMAIN_FORM_FAIL,
                    domain_form_error: response
                });
            });
    };
};

export const exportDomainInfo = (attributes) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_EXPORT_REQUEST
        });
        API.domain.post
            .export(attributes)
            .then((response) => {
                const domain_export_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: DOMAIN_EXPORT_SUCCESS,
                    domain_export_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(getErrorFromFailResponse(error));
                dispatch({
                    type: DOMAIN_EXPORT_ERROR,
                    domain_export_error: response
                });
            });
    };
};

export const resendTransferEmail = (id) => {
    const { dispatch } = store;

    dispatch({
        type: DOMAIN_FORM_REQUEST
    });
    API.domain.post
        .resend(id)
        .then((response) => {
            const domain_form_data = getDataFromSuccessResponse(response);
            pushNotification(domain_form_data);
            dispatch({
                type: DOMAIN_FORM_SUCCESS,
                domain_form_data
            });
        })
        .catch((error) => {
            const response = getErrorFromFailResponse(error);
            pushNotification(response);
            dispatch({
                type: DOMAIN_FORM_FAIL,
                domain_form_error: response
            });
        });
};

export const reprocessLockedDomain = (id) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_FORM_REQUEST
        });
        API.domain.post
            .reprocessTransfer(id)
            .then((response) => {
                const domain_form_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: DOMAIN_FORM_SUCCESS,
                    domain_form_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(getErrorFromFailResponse(error));
                dispatch({
                    type: DOMAIN_FORM_FAIL,
                    domain_form_error: response
                });
            });
    };
};

export const domainSearch = (keyword) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_SEARCH_REQUEST
        });

        API.domain
            .search(keyword)
            .then((response) => {
                const domain_search_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: DOMAIN_SEARCH_SUCCESS,
                    domain_search_data
                });
            })
            .catch((error) => {
                const domain_search_error = getErrorFromFailResponse(error);
                if (error.response) {
                    dispatch({
                        type: DOMAIN_SEARCH_ERROR,
                        domain_search_error
                    });
                }
            });
    };
};
export const resetDomainSearch = () => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_SEARCH_RESET
        });
    };
};

/**********************************************************************************************************
 *   HOSTING LIST RESET
 **********************************************************************************************************/

export const resetHostingList = () => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_LIST_RESET
        });
    };
};

export const serviceRestoreInvoice = (id) => {
    return (dispatch) => {
        dispatch({
            type: SERVICE_RESTORE_INVOICE_REQUEST
        });
        API.hosting
            .restore(id)
            .then((response) => {
                const service_restore_invoice_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: SERVICE_RESTORE_INVOICE_SUCCESS,
                    service_restore_invoice_data
                });
            })
            .catch((error) => {
                const service_restore_invoice_errors = getErrorFromFailResponse(error);
                pushNotification(service_restore_invoice_errors);
                dispatch({
                    type: SERVICE_RESTORE_INVOICE_ERROR,
                    service_restore_invoice_errors
                });
            });
    };
};

/**********************************************************************************************************
 *   DOMAIN LIST
 **********************************************************************************************************/

export const getHostingList = (params, prevPage) => {
    const { dispatch } = store;
    if (params) {
        dispatch({
            type: HOSTING_LIST_MORE_REQUEST
        });
        API.hosting
            .list(params)
            .then((response) => {
                const hosting_list_data = getDataFromSuccessResponse(response);
                const hosting_list_meta = getMetaFromSuccessResponse(response);
                let new_hosting_list_data = [];

                if (prevPage && prevPage.length > 0) {
                    new_hosting_list_data = prevPage.concat(hosting_list_data);

                    dispatch({
                        type: HOSTING_LIST_MORE_SUCCESS,
                        hosting_list_data: Object.assign([], new_hosting_list_data),
                        hosting_list_meta
                    });
                } else {
                    dispatch({
                        type: HOSTING_LIST_MORE_SUCCESS,
                        hosting_list_data,
                        hosting_list_meta
                    });
                }
            })
            .catch((error) => {
                const hosting_list_error = getErrorFromFailResponse(error);
                dispatch({
                    type: HOSTING_LIST_MORE_ERROR,
                    hosting_list_error
                });
            });
    } else {
        dispatch({
            type: HOSTING_LIST_REQUEST
        });
        API.hosting
            .list()
            .then((response) => {
                const hosting_list_data = getDataFromSuccessResponse(response);
                const hosting_list_meta = getMetaFromSuccessResponse(response);

                dispatch({
                    type: HOSTING_LIST_SUCCESS,
                    hosting_list_data,
                    hosting_list_meta
                });
            })
            .catch((error) => {
                const hosting_list_error = getErrorFromFailResponse(error);
                dispatch({
                    type: HOSTING_LIST_ERROR,
                    hosting_list_error
                });
            });
    }
};

export const getHostingByStatus = (query) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_LIST_REQUEST
        });
        API.hosting
            .status(query)
            .then((response) => {
                const hosting_list_data = getDataFromSuccessResponse(response);
                const hosting_list_meta = getMetaFromSuccessResponse(response);

                dispatch({
                    type: HOSTING_LIST_SUCCESS,
                    hosting_list_data,
                    hosting_list_meta
                });
            })
            .catch((error) => {
                const hosting_list_error = getErrorFromFailResponse(error);
                dispatch({
                    type: HOSTING_LIST_ERROR,
                    hosting_list_error
                });
            });
    };
};

export const hostingSearch = (keyword) => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_SEARCH_REQUEST
        });
        API.hosting
            .search(keyword)
            .then((response) => {
                const hosting_search_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: HOSTING_SEARCH_SUCCESS,
                    hosting_search_data
                });
            })
            .catch((error) => {
                const hosting_search_error = getErrorFromFailResponse(error);
                if (error.response) {
                    dispatch({
                        type: HOSTING_SEARCH_ERROR,
                        hosting_search_error
                    });
                }
            });
    };
};

export const resetHostingSearch = () => {
    return (dispatch) => {
        dispatch({
            type: HOSTING_SEARCH_RESET
        });
    };
};

/**********************************************************************************************************
 *   EMAIL LIST RESET
 **********************************************************************************************************/

export const resetEmailList = () => {
    return (dispatch) => {
        dispatch({
            type: EMAIL_LIST_RESET
        });
    };
};

/**********************************************************************************************************
 *   EMAIL LIST
 **********************************************************************************************************/

export const getEmailList = (params, prevPage) => {
    return (dispatch) => {
        if (params) {
            dispatch({
                type: EMAIL_LIST_MORE_REQUEST
            });
            API.email
                .list(params)
                .then((response) => {
                    const email_list_data = getDataFromSuccessResponse(response);
                    const email_list_meta = getMetaFromSuccessResponse(response);
                    let new_email_list_data = [];

                    if (prevPage && prevPage.length > 0) {
                        new_email_list_data = prevPage.concat(email_list_data);

                        dispatch({
                            type: EMAIL_LIST_MORE_SUCCESS,
                            email_list_data: Object.assign([], new_email_list_data),
                            email_list_meta
                        });
                    } else {
                        dispatch({
                            type: EMAIL_LIST_MORE_SUCCESS,
                            email_list_data,
                            email_list_meta
                        });
                    }
                })
                .catch((error) => {
                    const email_list_error = getErrorFromFailResponse(error);
                    dispatch({
                        type: EMAIL_LIST_MORE_ERROR,
                        email_list_error
                    });
                });
        } else {
            dispatch({
                type: EMAIL_LIST_REQUEST
            });
            API.email
                .list()
                .then((response) => {
                    const email_list_data = getDataFromSuccessResponse(response);
                    const email_list_meta = getMetaFromSuccessResponse(response);

                    dispatch({
                        type: EMAIL_LIST_SUCCESS,
                        email_list_data,
                        email_list_meta
                    });
                })
                .catch((error) => {
                    const email_list_error = getErrorFromFailResponse(error);
                    dispatch({
                        type: EMAIL_LIST_ERROR,
                        email_list_error
                    });
                });
        }
    };
};

export const emailSearch = (keyword) => {
    return (dispatch) => {
        dispatch({
            type: EMAIL_SEARCH_REQUEST
        });
        API.email
            .search(keyword)
            .then((response) => {
                const email_search_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: EMAIL_SEARCH_SUCCESS,
                    email_search_data
                });
            })
            .catch((error) => {
                const email_search_error = getErrorFromFailResponse(error);
                if (error.response) {
                    dispatch({
                        type: EMAIL_SEARCH_ERROR,
                        email_search_error
                    });
                }
            });
    };
};

export const filterEmail = (data, keyword) => {
    return (dispatch) => {
        dispatch({
            type: EMAIL_SEARCH_REQUEST
        });

        const email_search_data = data.emailHosting.filter((value) => value.attributes.domain.includes(keyword));

        dispatch({
            type: EMAIL_SEARCH_SUCCESS,
            email_search_data
        });
    };
};

export const resetEmailSearch = () => {
    return (dispatch) => {
        dispatch({
            type: EMAIL_SEARCH_RESET
        });
    };
};

/**********************************************************************************************************
 *   SSL LIST RESET
 **********************************************************************************************************/

export const resetSslList = () => {
    return (dispatch) => {
        dispatch({
            type: SSL_LIST_RESET
        });
    };
};

export const resetSearchInvoice = () => {
    return (dispatch) => {
        dispatch({
            type: SSL_SEARCH_RESET
        });
    };
};

/**********************************************************************************************************
 *   SSL LIST
 **********************************************************************************************************/
export const resetSslPurchase = () => {
    return (dispatch) => {
        dispatch({
            type: PURCHASE_SSL_PRODUCT_RESET
        });
    };
};

export const getSslList = (params, prevPage) => {
    return (dispatch) => {
        if (params) {
            dispatch({
                type: GET_SSL_LIST_MORE_REQUEST
            });
            API.ssl
                .list(params)
                .then((response) => {
                    const ssl_list_data = getDataFromSuccessResponse(response);
                    const ssl_list_meta = getMetaFromSuccessResponse(response);
                    let new_ssl_list_data = [];

                    if (prevPage && prevPage.length > 0) {
                        new_ssl_list_data = prevPage.concat(ssl_list_data);

                        dispatch({
                            type: GET_SSL_LIST_MORE_SUCCESS,
                            ssl_list_data: Object.assign([], new_ssl_list_data),
                            ssl_list_meta
                        });
                    } else {
                        dispatch({
                            type: GET_SSL_LIST_MORE_SUCCESS,
                            ssl_list_data,
                            ssl_list_meta
                        });
                    }
                })
                .catch((error) => {
                    if (error instanceof CanceledError) {
                        return;
                    }

                    const ssl_list_error = getErrorFromFailResponse(error);
                    dispatch({
                        type: GET_SSL_LIST_MORE_ERROR,
                        ssl_list_error
                    });
                });
        } else {
            dispatch({
                type: GET_SSL_LIST
            });
            API.ssl
                .list()
                .then((response) => {
                    const ssl_list_data = getDataFromSuccessResponse(response);
                    const ssl_list_meta = getMetaFromSuccessResponse(response);
                    dispatch({
                        type: GET_SSL_LIST_SUCCESS,
                        ssl_list_data,
                        ssl_list_meta
                    });
                })
                .catch((error) => {
                    const ssl_list_error = getErrorFromFailResponse(error);
                    dispatch({
                        type: GET_SSL_LIST_ERROR,
                        ssl_list_error
                    });
                });
        }
    };
};

export const getSslProducts = () => {
    return (dispatch) => {
        dispatch({
            type: GET_SSL_PRODUCTS
        });
        API.ssl
            .product()
            .then((response) => {
                const ssl_products_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: GET_SSL_PRODUCTS_SUCCESS,
                    ssl_products_data
                });
            })
            .catch((error) => {
                const ssl_products_error = getErrorFromFailResponse(error);
                dispatch({
                    type: GET_SSL_PRODUCTS_ERROR,
                    ssl_products_error
                });
            });
    };
};

export const purchaseSsl = (attributes) => {
    return (dispatch) => {
        dispatch({
            type: PURCHASE_SSL_PRODUCT
        });
        API.ssl
            .purchase(attributes)
            .then((response) => {
                const ssl_purchase_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: PURCHASE_SSL_PRODUCT_SUCCESS,
                    ssl_purchase_data
                });
            })
            .catch((error) => {
                const ssl_purchase_error = getErrorFromFailResponse(error);
                dispatch({
                    type: PURCHASE_SSL_PRODUCT_ERROR,
                    ssl_purchase_error
                });
            });
    };
};

export const sslSearch = (keyword) => {
    return (dispatch) => {
        dispatch({
            type: SSL_SEARCH_REQUEST
        });
        API.ssl
            .search(keyword)
            .then((response) => {
                const ssl_search_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: SSL_SEARCH_SUCCESS,
                    ssl_search_data
                });
            })
            .catch((error) => {
                const ssl_search_error = getErrorFromFailResponse(error);
                if (error.response) {
                    dispatch({
                        type: SSL_SEARCH_ERROR,
                        ssl_search_error
                    });
                }
            });
    };
};

export const resetSSLSearch = () => {
    return (dispatch) => {
        dispatch({
            type: SSL_SEARCH_RESET
        });
    };
};

//----------------------------VPS START --------
/**
 * @param {{
 *   page?: number | null,
 *   sort_by?: string | null,
 *   sort?: string | null,
 *   record_per_page?: number | null,
 * }} [params]
 */
export const getVPSList = (params) => {
    store.dispatch({
        type: VPS_LIST_REQUEST
    });
    API.vps
        .list(params)
        .then((response) => {
            const vps_list_data = getDataFromSuccessResponse(response);
            const vps_list_meta = getMetaFromSuccessResponse(response);
            store.dispatch({
                type: VPS_LIST_SUCCESS,
                vps_list_data,
                vps_list_meta
            });
        })
        .catch((error) => {
            if (error instanceof CanceledError) {
                return;
            }

            const vps_list_error = getDataFromSuccessResponse(error);
            store.dispatch({
                type: VPS_LIST_ERROR,
                vps_list_error
            });
        });
};

export const getVPSListParams = (params, prevPage, isForMigrationSection) => {
    return (dispatch) => {
        // this is needed so that services/modules/vps.js doesn't have to be refactored
        if (isForMigrationSection && params) {
            dispatch({
                type: VPS_LIST_MORE_REQUEST
            });
            API.vps
                .list(params)
                .then((response) => {
                    const vps_list_data = getDataFromSuccessResponse(response);
                    const vps_list_meta = getMetaFromSuccessResponse(response);
                    let new_vps_list_data = [];
                    if (prevPage && prevPage.length > 0) {
                        new_vps_list_data = prevPage.concat(vps_list_data);
                        dispatch({
                            type: VPS_LIST_MORE_SUCCESS,
                            vps_list_data: Object.assign([], new_vps_list_data),
                            vps_list_meta
                        });
                    } else {
                        dispatch({
                            type: VPS_LIST_MORE_SUCCESS,
                            vps_list_data,
                            new_vps_list_data,
                            vps_list_meta
                        });
                    }
                })
                .catch((error) => {
                    const vps_list_error = getErrorFromFailResponse(error);
                    dispatch({
                        type: VPS_LIST_MORE_ERROR,
                        vps_list_error
                    });
                });
        } else if (params) {
            dispatch({
                type: VPS_LIST_MORE_REQUEST
            });
            API.vps
                .list(params)
                .then((response) => {
                    const vps_list_data = getDataFromSuccessResponse(response);
                    const vps_list_meta = getMetaFromSuccessResponse(response);
                    let new_vps_list_data = [];
                    if (prevPage && prevPage.length > 0) {
                        new_vps_list_data = prevPage.concat(vps_list_data);
                        dispatch({
                            type: VPS_LIST_MORE_SUCCESS,
                            vps_list_data: Object.assign([], new_vps_list_data),
                            vps_list_meta
                        });
                    } else {
                        dispatch({
                            type: VPS_LIST_MORE_SUCCESS,
                            vps_list_data,
                            new_vps_list_data,
                            vps_list_meta
                        });
                    }
                })
                .catch((error) => {
                    const vps_list_error = getErrorFromFailResponse(error);
                    dispatch({
                        type: VPS_LIST_MORE_ERROR,
                        vps_list_error
                    });
                });
        } else {
            dispatch({
                type: VPS_LIST_REQUEST
            });
            API.vps
                .list()
                .then((response) => {
                    const vps_list_data = getDataFromSuccessResponse(response);
                    const vps_list_meta = getMetaFromSuccessResponse(response);
                    dispatch({
                        type: VPS_LIST_SUCCESS,
                        vps_list_data,
                        vps_list_meta
                    });
                })
                .catch((error) => {
                    const vps_list_error = getErrorFromFailResponse(error);
                    dispatch({
                        type: VPS_LIST_ERROR,
                        vps_list_error
                    });
                });
        }
    };
};

export const vpsSearch = (keyword) => {
    return (dispatch) => {
        dispatch({
            type: VPS_SEARCH_REQUEST
        });
        API.vps
            .search(keyword)
            .then((response) => {
                const vps_search_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: VPS_SEARCH_SUCCESS,
                    vps_search_data
                });
            })
            .catch((error) => {
                const vps_search_error = getErrorFromFailResponse(error);
                if (error.response) {
                    dispatch({
                        type: VPS_SEARCH_ERROR,
                        vps_search_error
                    });
                }
            });
    };
};

export const resetVPSSearch = () => {
    return (dispatch) => {
        dispatch({
            type: VPS_SEARCH_RESET
        });
    };
};

//----------------------------VPS END --------

/**********************************************************************************************************
 *   GSUITE LIST
 **********************************************************************************************************/

export const getGSuiteList = (params, prevPage) => {
    return (dispatch) => {
        if (params) {
            dispatch({
                type: GSUITE_LIST_MORE_REQUEST
            });
            API.gsuite
                .list(params)
                .then((response) => {
                    const gsuite_list_data = getDataFromSuccessResponse(response);
                    const gsuite_list_meta = getMetaFromSuccessResponse(response);
                    let new_gsuite_list_data = [];

                    if (prevPage && prevPage.length > 0) {
                        new_gsuite_list_data = prevPage.concat(gsuite_list_data);

                        dispatch({
                            type: GSUITE_LIST_MORE_SUCCESS,
                            gsuite_list_data: Object.assign([], new_gsuite_list_data),
                            gsuite_list_meta
                        });
                    } else {
                        dispatch({
                            type: GSUITE_LIST_MORE_SUCCESS,
                            gsuite_list_data,
                            gsuite_list_meta
                        });
                    }
                })
                .catch((error) => {
                    const gsuite_list_error = getErrorFromFailResponse(error);
                    dispatch({
                        type: GSUITE_LIST_MORE_ERROR,
                        gsuite_list_error
                    });
                });
        } else {
            dispatch({
                type: GSUITE_LIST_REQUEST
            });
            API.gsuite
                .list()
                .then((response) => {
                    const gsuite_list_data = getDataFromSuccessResponse(response);
                    const gsuite_list_meta = getMetaFromSuccessResponse(response);

                    dispatch({
                        type: GSUITE_LIST_SUCCESS,
                        gsuite_list_data,
                        gsuite_list_meta
                    });
                })
                .catch((error) => {
                    const gsuite_list_error = getErrorFromFailResponse(error);
                    dispatch({
                        type: GSUITE_LIST_ERROR,
                        gsuite_list_error
                    });
                });
        }
    };
};

export const gsuiteSearch = (keyword) => {
    return (dispatch) => {
        dispatch({
            type: GSUITE_SEARCH_REQUEST
        });
        API.gsuite
            .search(keyword)
            .then((response) => {
                const gsuite_search_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: GSUITE_SEARCH_SUCCESS,
                    gsuite_search_data
                });
            })
            .catch((error) => {
                const gsuite_search_error = getErrorFromFailResponse(error);
                if (error.response) {
                    dispatch({
                        type: GSUITE_SEARCH_ERROR,
                        gsuite_search_error
                    });
                }
            });
    };
};

export const resetGSuiteSearch = () => {
    return (dispatch) => {
        dispatch({
            type: GSUITE_SEARCH_RESET
        });
    };
};

/**********************************************************************************************************
 *   MS365 LIST
 **********************************************************************************************************/
export const getMs365List = (params, prevPage) => {
    return (dispatch) => {
        if (params) {
            dispatch({
                type: MS365_LIST_MORE_REQUEST
            });
            API.ms365
                .list(params)
                .then((response) => {
                    const ms365_list_data = getDataFromSuccessResponse(response);
                    const ms365_list_meta = getMetaFromSuccessResponse(response);
                    let new_ms365_list_data = [];

                    if (prevPage && prevPage.length > 0) {
                        new_ms365_list_data = prevPage.concat(ms365_list_data);

                        dispatch({
                            type: MS365_LIST_MORE_SUCCESS,
                            ms365e_list_data: Object.assign([], new_ms365_list_data),
                            ms365_list_meta
                        });
                    } else {
                        dispatch({
                            type: MS365_LIST_MORE_SUCCESS,
                            ms365_list_data,
                            ms365_list_meta
                        });
                    }
                })
                .catch((error) => {
                    if (error instanceof CanceledError) {
                        return;
                    }

                    const ms365_list_error = getErrorFromFailResponse(error);
                    dispatch({
                        type: MS365_LIST_MORE_ERROR,
                        ms365_list_error
                    });
                });
        } else {
            dispatch({
                type: MS365_LIST_REQUEST
            });
            API.ms365
                .list()
                .then((response) => {
                    const ms365_list_data = getDataFromSuccessResponse(response);
                    const ms365_list_meta = getMetaFromSuccessResponse(response);

                    dispatch({
                        type: MS365_LIST_SUCCESS,
                        ms365_list_data,
                        ms365_list_meta
                    });
                })
                .catch((error) => {
                    if (error instanceof CanceledError) {
                        return;
                    }

                    const ms365_list_error = getErrorFromFailResponse(error);
                    dispatch({
                        type: MS365_LIST_ERROR,
                        ms365_list_error
                    });
                });
        }
    };
};

export const ms365Search = (keyword) => {
    return (dispatch) => {
        dispatch({
            type: MS365_SEARCH_REQUEST
        });
        API.ms365
            .search(keyword)
            .then((response) => {
                const ms365_search_data = getDataFromSuccessResponse(response);
                dispatch({
                    type: MS365_SEARCH_SUCCESS,
                    ms365_search_data
                });
            })
            .catch((error) => {
                const ms365_search_error = getErrorFromFailResponse(error);
                if (error.response) {
                    dispatch({
                        type: MS365_SEARCH_ERROR,
                        ms365_search_error
                    });
                }
            });
    };
};

export const resetMs365Search = () => {
    return (dispatch) => {
        dispatch({
            type: MS365_SEARCH_RESET
        });
    };
};

/**
 * @param {"renew" | "contacts"} tool - the preselected tool
 * @param {number[]} preselectedList - Array of domain ID's
 * @param {string} promoCode - Promo/Discount code
 */
export function bulkManageDomainUpdate({ tool, preselectedList, promoCode, openManage }) {
    const { dispatch } = store;

    dispatch({
        type: DOMAIN_BULK_MANAGE_UPDATE,
        domain_bulk_manage_update: {
            bulkManageTool: tool,
            bulkManagePreselectedList: preselectedList,
            bulkManagePreappliedPromoCode: promoCode,
            openManage
        }
    });
}
