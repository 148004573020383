export const SUPER_USER_ACTION_ITEMS = {
    DVD_LOGO: 'DVD',
    HOOK_FORM_DATA_DEBUGGER: 'Hook Form Data',
    KATANA_FORM_TYPE_CHOICE_WRAPPER: 'Katana Form Type Choice Wrapper',
    KATANA_SECTION_MANAGER: 'VIPSites Section Manager',
    KATANA_VMAX: {
        title: 'VMAXXX - Katana',
        description: 'Available on /vmax route',
    },
    LOGIN_VIEW_MANIPULATION: { title: 'Login View Manipulation', description: 'Available on /login route' },
    NAVIGATION_ITEMS_MANAGER: 'Navigation Items Manager',
    RICH_TEXT_EDITOR_DEBUG: 'Rich Text Editor Debug',
    TOGGLE_MODULE_REASON: 'Toggle Module Reason',
    TOGGLE_ROUTER_DEVTOOLS: 'Toggle Router DevTools',
    KATANA_SOURCE_DOC_API_IFRAME: 'Katana - Source Doc API IFrame',
    KATANA_VIEWPORT_OLD_PHONE: 'Katana - Old Phone 3310 Viewport',
    FORCE_ORDERING_FORM_OPEN: 'Force Ordering Form Open',
    SHOW_VMAX_COMPONENT_BOOK: 'Show VMAX Component Book',
    KATANA_VMAX_NEW_SECTION_PREVIEW: {
        title: 'Katana - VMAX New Section Iframe Preview',
        description: 'Available on /vmax/dev/new-section route',
    },
} as const;
export const SUPER_USER_ACTION_ITEM_KEYS = Object.keys(SUPER_USER_ACTION_ITEMS) as Array<keyof typeof SUPER_USER_ACTION_ITEMS>;
