import { useMutation } from '@tanstack/react-query';
import { service } from 'containers/katana/queries/serviceID/service';
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions/handleDefaultErrorNotification';
import { handleDefaultSuccessNotification } from 'utilities/methods/commonActions/handleDefaultSuccessNotification';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Params = Parameters<typeof KATANA_API.katana.site.service_id.pages.page_id.reset.POST>[0];

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation() {
    async function handleMutation(params: Params) {
        return KATANA_API.katana.site.service_id.pages.page_id.reset.POST(params);
    }

    return useMutation({
        mutationFn: handleMutation,
        onSuccess: (data) => {
            handleDefaultSuccessNotification(data);
        },
        onError: handleDefaultErrorNotification,

        onSettled: (data, error, { serviceID }) => {
            service.invalidateQueries(serviceID);
        }
    });
}

export const resetPage = Object.freeze({
    useMutation: _useMutation
});
