/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import Axios from 'axios';
import store from 'store/store';
import { API, cancelBulkInfoRequest } from 'utilities/api/domain';

/*   ACTIONS
 *****************************************************/
import { pushNotification } from 'components/Toast/functions';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { getDataFromSuccessResponse, getErrorFromFailResponse, getMetaFromErrorResponse } from 'utilities/methods/commonActions';
import {
    DOMAIN_BULK_INITIAL_STATE,
    DOMAIN_BULK_LIST_REQUEST,
    DOMAIN_BULK_LIST_SUCCESS,
    DOMAIN_BULK_LIST_FAIL,
    DOMAIN_BULK_INFO_REQUEST,
    DOMAIN_BULK_INFO_FAIL,
    DOMAIN_BULK_INFO_SUCCESS,
    DOMAIN_BULK_INFO_RESET,
    DOMAIN_BULK_ACTION_REQUEST,
    DOMAIN_BULK_ACTION_SUCCESS,
    DOMAIN_BULK_ACTION_FAIL,
    DOMAIN_BULK_CONTACT_ACTION_REQUEST,
    DOMAIN_BULK_CONTACT_ACTION_SUCCESS,
    DOMAIN_BULK_CONTACT_ACTION_FAIL
} from './actionTypes';



/**********************************************************************************************************
 *   RESET FUNCTIONS
 **********************************************************************************************************/

export const resetDomainBulk = () => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_BULK_INITIAL_STATE
        });
    };
};

/**********************************************************************************************************
 *   BULK LIST
 **********************************************************************************************************/
export const bulkListDomains = () => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_BULK_LIST_REQUEST
        });
        API.domain.GET.bulk
            .list()
            .then((response) => {
                const domain_bulk_list_data = getDataFromSuccessResponse(response);

                dispatch({
                    type: DOMAIN_BULK_LIST_SUCCESS,
                    domain_bulk_list_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(getErrorFromFailResponse(error));
                dispatch({
                    type: DOMAIN_BULK_LIST_FAIL,
                    domain_bulk_list_error: response
                });
            });
    };
};

/**********************************************************************************************************
 *   BULK INFO
 **********************************************************************************************************/
export const bulkInfo = (data, promoCode) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_BULK_INFO_REQUEST,
            domain_bulk_info_status: 'loading'
        });

        if (cancelBulkInfoRequest) {
            cancelBulkInfoRequest();
        }

        API.domain.POST.bulk
            .info(data, promoCode)
            .then((response) => {
                const domain_bulk_info_data = getDataFromSuccessResponse(response);

                // Set status to error if one the return domains list length is different to the sent domains list
                if (data.length !== domain_bulk_info_data.domains?.length) {
                    const domain_bulk_info_error = { status: 500, details: 'Something went wrong.' };
                    const domain_bulk_info_meta = getMetaFromErrorResponse({});

                    pushNotification(domain_bulk_info_error);
                    dispatch({
                        type: DOMAIN_BULK_INFO_FAIL,
                        domain_bulk_info_error,
                        domain_bulk_info_meta
                    });
                }

                if (promoCode) domain_bulk_info_data.promo_code = promoCode;
                if (domain_bulk_info_data.errors?.length > 0)
                    pushNotification({ status: 500, details: domain_bulk_info_data.errors[0].message });

                dispatch({
                    type: DOMAIN_BULK_INFO_SUCCESS,
                    domain_bulk_info_data
                });
            })
            .catch((error) => {
                if (error instanceof Axios.Cancel) return;
                const domain_bulk_info_error = getErrorFromFailResponse(error);
                const domain_bulk_info_meta = getMetaFromErrorResponse(error);

                if (error.response) pushNotification(domain_bulk_info_error);

                dispatch({
                    type: DOMAIN_BULK_INFO_FAIL,
                    domain_bulk_info_error,
                    domain_bulk_info_meta
                });
            });
    };
};

export function bulkInfoReset() {
    const { dispatch } = store;

    dispatch({
        type: DOMAIN_BULK_INFO_RESET
    });
}

/**********************************************************************************************************
 *   BULK AUTO RENEW
 **********************************************************************************************************/
export const bulkEnableAuto = (data) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_BULK_ACTION_REQUEST,
            domain_bulk_action_status: 'loading'
        });
        API.domain.POST.bulk
            .enableAuto(data)
            .then((response) => {
                const domain_bulk_action_data = getDataFromSuccessResponse(response);
                pushNotification(getDataFromSuccessResponse(response));
                dispatch({
                    type: DOMAIN_BULK_ACTION_SUCCESS,
                    domain_bulk_action_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(getErrorFromFailResponse(error));
                dispatch({
                    type: DOMAIN_BULK_ACTION_FAIL,
                    domain_bulk_action_error: response
                });
            });
    };
};

export const bulkDisableAuto = (data) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_BULK_ACTION_REQUEST,
            domain_bulk_action_status: 'loading'
        });
        API.domain.POST.bulk
            .disableAuto(data)
            .then((response) => {
                const domain_bulk_action_data = getDataFromSuccessResponse(response);
                pushNotification(getDataFromSuccessResponse(response));
                dispatch({
                    type: DOMAIN_BULK_ACTION_SUCCESS,
                    domain_bulk_action_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(getErrorFromFailResponse(error));
                dispatch({
                    type: DOMAIN_BULK_ACTION_FAIL,
                    domain_bulk_action_error: response
                });
            });
    };
};

/**********************************************************************************************************
 *   BULK RENEW
 **********************************************************************************************************/
export const bulkRenew = (data, promoCode) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_BULK_ACTION_REQUEST
        });

        API.domain.POST.bulk
            .renew(data, promoCode)
            .then((response) => {
                const domain_bulk_action_data = getDataFromSuccessResponse(response);

                pushNotification(getDataFromSuccessResponse(response));
                dispatch({
                    type: DOMAIN_BULK_ACTION_SUCCESS,
                    domain_bulk_action_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(getErrorFromFailResponse(error));
                dispatch({
                    type: DOMAIN_BULK_ACTION_FAIL,
                    domain_bulk_action_error: response
                });
            });
    };
};

/**********************************************************************************************************
 *   BULK DNS
 **********************************************************************************************************/
export const bulkDNS = (data) => {
    return (dispatch) => {
        dispatch({
            type: DOMAIN_BULK_ACTION_REQUEST,
            domain_bulk_action_status: 'loading'
        });
        API.domain.PUT.bulk
            .dns(data)
            .then((response) => {
                const domain_bulk_action_data = getDataFromSuccessResponse(response);
                pushNotification(getDataFromSuccessResponse(response));
                dispatch({
                    type: DOMAIN_BULK_ACTION_SUCCESS,
                    domain_bulk_action_data
                });
            })
            .catch((error) => {
                const response = getErrorFromFailResponse(error);
                pushNotification(getErrorFromFailResponse(error));
                dispatch({
                    type: DOMAIN_BULK_ACTION_FAIL,
                    domain_bulk_action_error: response
                });
            });
    };
};

/**********************************************************************************************************
 *   BULK DNS
 **********************************************************************************************************/
export const bulkContact = (data) => {
    const { dispatch } = store;
    dispatch({
        type: DOMAIN_BULK_CONTACT_ACTION_REQUEST
    });

    API.domain.PUT.bulk
        .contact(data)
        .then((response) => {
            const domain_bulk_contact_action_data = getDataFromSuccessResponse(response);
            pushNotification(domain_bulk_contact_action_data);
            dispatch({
                type: DOMAIN_BULK_CONTACT_ACTION_SUCCESS,
                domain_bulk_contact_action_data
            });
        })
        .catch((error) => {
            const response = getErrorFromFailResponse(error);
            pushNotification(response);
            dispatch({
                type: DOMAIN_BULK_CONTACT_ACTION_FAIL
            });
        });
};
