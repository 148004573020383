import { OutlineButton } from 'components/Buttons/OutlineButton';
import { SolidButton } from 'components/Buttons/SolidButton';
import { Modal } from 'components/Lightboxes/Modal';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import React from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type DeleteSectionConfirmationLightbox = React.FC<{
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onConfirm: () => void;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

export const DeleteSectionConfirmationLightbox: DeleteSectionConfirmationLightbox = ({ isOpen, setIsOpen, onConfirm }) => {
    /***** FUNCTIONS *****/
    function close() {
        setIsOpen(false);
    }

    /***** RENDER *****/
    return (
        <Modal.Compact isOpen={isOpen} onClose={close} ariaLabel="Unsaved Changes Modal">
            <Text.Heading h2 align--center>
                Delete section
            </Text.Heading>
            <Padding y={4}>
                <Text size--m secondary align--center>
                    Are you sure you want to delete this section?
                </Text>
            </Padding>
            <Flex justify="center">
                <OutlineButton type="onClick" onClick={close}>
                    Cancel
                </OutlineButton>
                <SolidButton
                    color="warn"
                    onClick={() => {
                        onConfirm();
                        close();
                    }}
                >
                    Delete
                </SolidButton>
            </Flex>
        </Modal.Compact>
    );
};
