import { useMutation } from '@tanstack/react-query';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import type { KatanaNamespace } from 'containers/katana/types';
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultSuccessNotification } from 'utilities/methods/commonActions/handleDefaultSuccessNotification';

type Params = Parameters<typeof KATANA_API.katana.site.service_id.theme.POST>[0];

function createMutationKey(serviceID: KatanaNamespace.ServiceId) {
    return katanaQueryKeys.katana.service.ID.theme(serviceID);
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation(serviceID: KatanaNamespace.ServiceId) {
    /***** HOOKS *****/

    const { page } = useKatanaParams();

    /***** FUNCTIONS *****/
    function mutationFn(attributes: Params['attributes']) {
        return KATANA_API.katana.site.service_id.theme.POST({ serviceID, attributes });
    }

    /***** HOOK RESULTS *****/
    return useMutation({
        mutationFn,
        mutationKey: createMutationKey(serviceID),
        onSuccess: page === 'setup' ? undefined : handleDefaultSuccessNotification,
        onSettled: () => {
            katanaQuery.serviceID.service.invalidateQueries(serviceID);
            katanaQuery.serviceID.getSiteInfo.resetQueries(serviceID);
        },
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const createTheme = Object.freeze({
    useMutation: _useMutation,
});
