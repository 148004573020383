/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation } from '@tanstack/react-query';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { API } from 'utilities/api/domain';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions/handleDefaultErrorNotification';
import { handleDefaultSuccessNotification } from 'utilities/methods/commonActions/handleDefaultSuccessNotification';


/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useApplyDNSPresetMutation(data, options = {}) {
    /***** HOOK RESULTS *****/
    return useMutation({
        mutationFn: () => API.domain.POST.presetApply(data),
        onSuccess: handleDefaultSuccessNotification,
        onError: handleDefaultErrorNotification,
        ...options
    });
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
