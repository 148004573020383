import { Store } from '@tanstack/react-store';
import { CallToActionType } from 'containers/katana/containers/contentEditorModules/callToAction/consts';
import type { KatanaNamespace } from 'containers/katana/types';
import _ from 'lodash';
import { performValidations } from 'utilities/methods/commonActions/performValidation/performValidation';
import { requiredFieldErrorMessage } from 'utilities/methods/form';
import { validatorSocialMediaURLS } from 'utilities/methods/validators';
import { z } from 'zod';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/

export const navigationLabelsStore = new Store<KatanaNamespace.NavigationLabel[]>([]);
export const navigationLabelsFormStore = new Store<KatanaNamespace.NavigationLabel[]>([]);
export const socialFormatValidations = (social: string): ValidatorFunction[] => {
    const titleCaseSocial = _.upperFirst(social);
    function getURLFromSocial() {
        switch (social) {
            case 'twitter':
                return ['x', 'twitter'];

            default:
                return social;
        }
    }

    function validSocialDomainURLS(value: string) {
        const domain = getURLFromSocial();
        const domainValue = _.isArray(domain) ? domain : [domain];
        return domainValue.some((domain) => {
            return value.startsWith(`https://${domain}.com/`) || value.startsWith(`https://www.${domain}.com/`);
        });
    }

    return [
        (value: string) => {
            const validSocialLink = social === 'twitter' ? value.includes(`twitter`) || value.includes('x') : value.includes(social);
            return !value || validSocialLink ? undefined : `Please enter a valid ${titleCaseSocial} URL`;
        },
        (value: string) =>
            !value || value.toLowerCase().startsWith('https://') ? undefined : `Please include https:// in the ${titleCaseSocial} URL`,
        (value: string) => {
            const validationResult = validSocialDomainURLS(value);
            const hasValue = Boolean(value);

            return !hasValue || validationResult ? undefined : `Please enter a valid ${titleCaseSocial} URL`;
        },
        (value: string) => {
            const validationResult = validatorSocialMediaURLS(value);
            const hasValue = Boolean(value);

            return !hasValue || validationResult ? undefined : `Please enter a valid ${titleCaseSocial} URL`;
        },
    ];
};

const sitePageID: z.ZodType<KatanaNamespace.PageId> = z.number() as any;
const sectionID: z.ZodType<KatanaNamespace.SectionId> = z.number() as any;

const navigationTargetableSiteSchema = z.object({
    targetable_type: z.literal('site_page'),
    targetable_id: sitePageID,
    order: z.number(),
});

const navigationTargetableSectionSchema = z.object({
    targetable_type: z.literal('site_section'),
    targetable_id: sectionID,
    order: z.number(),
});

export const navigationZodSchema = z
    .object({
        style: z.object({
            color: z.string({ message: requiredFieldErrorMessage }),
            transparent: z.boolean().nullable().optional(),
            visible: z.boolean().nullable().optional(),
            header_style: z.string({ message: requiredFieldErrorMessage }),
        }),
        navigation_items: z.array(z.union([navigationTargetableSiteSchema, navigationTargetableSectionSchema]), {
            message: requiredFieldErrorMessage,
        }),
        call_to_action: z
            .object({
                enabled: z.boolean(),
                type: z.union([
                    z.literal(CallToActionType.LINK),
                    z.literal(CallToActionType.SECTION),
                    z.literal(CallToActionType.PHONE),
                    z.literal(CallToActionType.PAGE),
                ]),
                text: z.string().nullable(),
                value: z.string().max(255, { message: 'must contain at most 255 characters' }).nullable(),
                section_id: z.number().nullable(),
                page_id: z.number().nullable(),
            })
            .default({ enabled: false, type: CallToActionType.LINK, text: '', value: '', section_id: null, page_id: null })
            .superRefine(({ type, value, text, section_id, page_id, enabled }, context) => {
                if (!enabled) return;
                if (type === CallToActionType.PHONE && value !== null && value.length && !value?.match(/\d/)) {
                    context.addIssue({
                        code: 'custom',
                        message: 'Phone number should contain a number',
                        path: ['value'],
                    });
                }
                if (type === CallToActionType.LINK && !value) {
                    context.addIssue({
                        code: 'custom',
                        message: requiredFieldErrorMessage,
                        path: ['value'],
                    });
                }
                if (type === CallToActionType.SECTION && !section_id) {
                    context.addIssue({
                        code: 'custom',
                        message: requiredFieldErrorMessage,
                        path: ['section_id'],
                    });
                }
                if (type === CallToActionType.PAGE && !page_id) {
                    context.addIssue({
                        code: 'custom',
                        message: requiredFieldErrorMessage,
                        path: ['page_id'],
                    });
                }
                if ((type === CallToActionType.LINK || type === CallToActionType.SECTION) && !text) {
                    context.addIssue({
                        code: 'custom',
                        message: requiredFieldErrorMessage,
                        path: ['text'],
                    });
                }
            }),
        socials: z
            .array(
                z
                    .object({
                        type: z.string(),
                        url: z.string(),
                    })
                    .optional()
                    .superRefine((value, context) => {
                        if (!value) {
                            return;
                        }
                        const { type, url } = value;
                        const validations = socialFormatValidations(type);
                        const res = performValidations(validations, url);

                        if (res) {
                            context.addIssue({
                                code: 'custom',
                                message: res,
                            });
                        }
                    })
            )
            .optional(),
        show_socials_in_header: z.boolean().nullable(),
        show_socials_in_footer: z.boolean().nullable(),
    })
    .superRefine(({ show_socials_in_footer, show_socials_in_header, socials }, context) => {
        if (show_socials_in_footer || show_socials_in_header) {
            if (!socials) return;
            if (socials?.length < 1) {
                context.addIssue({
                    code: 'custom',
                    message: 'Please add at least one social media link',
                    path: ['socials'],
                });
            }
        }
    });
