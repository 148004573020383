/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { Select } from 'components/Form/Select';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useIsVPN } from 'utilities/hooks/redux/useIsVPN';
import { useStateEffect } from 'utilities/hooks/useStateEffect';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { useSuperUserItem } from 'components/StaffMenu/SuperUser/hooks/useSuperUserItem';
import './__DevFormTypeChoiceWrapper.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type ChildParams = {
    derivedValue: string;
};

type Props = {
    children: (params: ChildParams) => JSX.Element;
    value: string;
    options: string[];
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _DevFormTypeChoiceWrapper: React.FC<Props> = ({ children, value, options }) => {
    /***** STATE *****/
    const [derivedValue, setDerivedValue] = useStateEffect(value);

    /***** HOOKS *****/
    const isSuperActiveActive = useSuperUserItem('KATANA_FORM_TYPE_CHOICE_WRAPPER');
    const isVPN = useIsVPN();

    /***** RENDER *****/
    if (!isSuperActiveActive || !isVPN) {
        return children({ derivedValue: value });
    }

    return (
        <div className="DevFormTypeChoiceWrapper">
            <div className="DevFormTypeChoiceWrapper__content">
                <Select
                    onChange={setDerivedValue}
                    value={derivedValue}
                    options={options.map((currentValue) => ({ label: currentValue, value: currentValue }))}
                />
            </div>
            {children({ derivedValue })}
        </div>
    );
};
