import { useIsMutating, useMutation } from '@tanstack/react-query';
import { newSectionTrackerData } from 'containers/katana/modules/presetContent/components/sectionOrganiser/newSectionTrackerData';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import { pageSections } from 'containers/katana/queries/serviceID/pageSections';
import { sections } from 'containers/katana/queries/serviceID/sections';
import { queryClient } from 'store/queryClient';
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions/handleDefaultErrorNotification';
import { handleDefaultSuccessNotification } from 'utilities/methods/commonActions/handleDefaultSuccessNotification';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Params = Parameters<typeof KATANA_API.katana.site.service_id.pages.page_id.sections.section_id.duplicate.POST>[0];
type MutationParams = Omit<Params, 'sectionID'>;
type MainParams = Omit<Params, 'serviceID' | 'pageID'>;

function createMutationKey(params: MutationParams) {
    return katanaQueryKeys.katana.service.ID.pages.ID.sections.duplicate(params);
}

function _useIsMutating(params: MutationParams) {
    return useIsMutating({
        mutationKey: createMutationKey(params)
    });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation(mutationParams: MutationParams) {
    const { serviceID } = mutationParams;

    /***** FUNCTIONS *****/
    async function handleMutation(params: MainParams) {
        return KATANA_API.katana.site.service_id.pages.page_id.sections.section_id.duplicate.POST({ ...mutationParams, ...params });
    }

    /***** HOOK RESULTS *****/
    return useMutation({
        mutationFn: handleMutation,
        mutationKey: createMutationKey(mutationParams),
        onSuccess: (data) => {
            if (data.status === 200) {
                sections.optimistic.setWith(serviceID, 'data', sections.optimistic.predicates.newSection(data.data));
                pageSections.optimistic.setWith(mutationParams, 'data', sections.optimistic.predicates.newSection(data.data));

                newSectionTrackerData.addNewID(data.data.id);
            }
            handleDefaultSuccessNotification(data);
        },
        onError: handleDefaultErrorNotification,
        onSettled: (data, e, params) => {
            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID.preview(serviceID)
            });

            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID.sections(serviceID),
                exact: true
            });

            queryClient.invalidateQueries({
                queryKey: katanaQueryKeys.katana.service.ID.pages.ID.sections(mutationParams),
                exact: true
            });
        }
    });
}

export const duplicatePageSection = Object.freeze({
    useMutation: _useMutation,
    createMutationKey,
    useIsMutating: _useIsMutating
});
