import { ErrorBoundary } from '@sentry/react';
import FetchComponentError from 'components/Errors/FetchComponentError';
import RequestLoader from 'components/Loaders/Request';
import NXTableErrorMessage from 'components/NXTable/ErrorMessage';
import React, { Suspense } from 'react';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type NXTableBody = React.FC<{
    children: React.ReactNode;
    isLoading?: boolean;
    isError?: boolean | string;
    isEmpty?: boolean | React.ReactNode;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const Body: NXTableBody = ({ children, isLoading, isError, isEmpty }) => {
    /***** RENDER *****/
    return (
        <div className="NXTableBody">
            <ErrorBoundary fallback={<FetchComponentError />}>
                <Suspense fallback={<RequestLoader />}>
                    {(() => {
                        switch (true) {
                            case isLoading:
                                return <RequestLoader />;
                            case !!isError:
                                return <NXTableErrorMessage message={isError} />;
                            case !!isEmpty:
                                return <NXTableErrorMessage message={isEmpty} />;
                            default:
                                return children;
                        }
                    })()}
                </Suspense>
            </ErrorBoundary>
        </div>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default Body;
