import { navigationLabelsStore } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/navigationFormHandler/consts';
import type { NavigationFormHandlerNamespace } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/navigationFormHandler/types';
import { useNavigationDefaultFormValues } from 'containers/katana/containers/ContentEditorLightbox/formHandlers/navigationFormHandler/useNavigationDefaultFormValues';
import { useSiteDataPreviewValues } from 'containers/katana/containers/ContentEditorLightbox/hooks/useSiteDataPreviewValues';
import { SocialMediaLinksContentModule } from 'containers/katana/containers/contentEditorModules/socialMediaLinks';
import { useKatanaNextPage } from 'containers/katana/hooks/useKatanaNextPage';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { usePostNavigationMutation } from 'containers/katana/queries/usePostNavigationMutation';
import { useUpdateSocialLinksMutation } from 'containers/katana/queries/useUpdateSocialLinksMutation';
import type { KatanaNamespace } from 'containers/katana/types';
import _ from 'lodash';

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
export function useHandleNavigationSubmit(subpageRouteData: KatanaNamespace.SubPageRouteData) {
    /***** HOOKS *****/
    const { performRouting } = useKatanaNextPage(subpageRouteData);
    const { katanaServiceId } = useKatanaParams();
    const { submitSiteData } = useSiteDataPreviewValues();
    const defaultValuesNavigation = useNavigationDefaultFormValues(subpageRouteData);

    /***** QUERIES *****/
    const { data: get_katana_socials_data } = katanaQuery.serviceID.getSocials.useQuery(katanaServiceId);
    const { mutateAsync: mutateAsyncPostNavigation } = usePostNavigationMutation(katanaServiceId);
    const { mutateAsync: mutateAsyncUpdateSocialLinks } = useUpdateSocialLinksMutation(katanaServiceId);
    const { mutateAsync: mutateAsyncReplaceNavigationItems } = katanaQuery.serviceID.replaceNavigationItems.useMutation(katanaServiceId);
    const { mutateAsync: mutateAsyncDeleteSocialType } = katanaQuery.serviceID.deleteSocialType.useMutation(katanaServiceId);
    const { mutateAsync: mutateUpdateKatanaSiteSection } = katanaQuery.serviceID.updateSection.useMutation({ serviceID: katanaServiceId });

    /***** HOOK RESULTS *****/
    return async function handleSubmit(values: NavigationFormHandlerNamespace.NavigationFormValues) {
        const promises: Promise<any>[] = [];

        const clonedValues = _.cloneDeep(values);

        if (!_.has(clonedValues, 'style.logo_scale')) {
            _.set(clonedValues, 'style.logo_scale', defaultValuesNavigation?.style?.logo_scale);
            promises.push(mutateAsyncPostNavigation(clonedValues));
        }

        if (_.has(clonedValues, 'navigation_items')) {
            promises.push(mutateAsyncReplaceNavigationItems({ items: clonedValues.navigation_items }));
        }

        if (_.has(clonedValues, SocialMediaLinksContentModule.formFields.SOCIALS)) {
            const socials = clonedValues[SocialMediaLinksContentModule.formFields.SOCIALS] as KatanaNamespace.Social[];
            const filteredSocials = socials.filter(Boolean).filter(({ url }) => Boolean(url));
            /**
             * When the list of filtered socials is updated and it still has socials in it we can make a post request to update the list of socials
             * If the list is empty we can't make the request because the attributes key that CCP is expecting can't be an empty array
             *  Instead we need to fire a delete request for each social type that previously existed
             */
            if (filteredSocials.length) {
                promises.push(mutateAsyncUpdateSocialLinks(filteredSocials));
            } else {
                _.forEach(get_katana_socials_data, ({ type }) => {
                    promises.push(mutateAsyncDeleteSocialType(type));
                });
            }
        }

        if (navigationLabelsStore.state.length) {
            navigationLabelsStore.state.forEach((labelEntry) => {
                const { sectionID, name } = labelEntry;
                const mutation = mutateUpdateKatanaSiteSection({ sectionID, attributes: { name } });
                promises.push(mutation);
            });
        }

        submitSiteData(promises);

        return Promise.all(promises)
            .then(() => {
                performRouting();
                navigationLabelsStore.setState(() => []);
            })
            .catch((e) => {
                return e;
            });
    };
}
