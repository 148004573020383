import { useQuery } from '@tanstack/react-query';
import { queryClient } from 'store/queryClient';
import { API } from 'utilities/api/hosting';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';
/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/

const createQueryKey = (serviceId: string, ip_address?: string) => {
    return createBaseQueryKey(['webhosting', serviceId, 'check-firewall', ...(ip_address ? [ip_address] : [])]);

};
/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
const _useQuery = (serviceId: string, ip_address: string) => {
    return useQuery({
        queryKey: createQueryKey(serviceId, ip_address),
        queryFn: () => API.hosting.POST.checkFirewall(serviceId, { ip_address }),
        enabled: Boolean(ip_address),
        select: (response) => {
            if (response.status === 200) {
                return response.data;
            }
        }
    });
};

const invalidateQueries = (serviceId: string, ip_address?: string) => {
    queryClient.invalidateQueries({ queryKey: createQueryKey(serviceId, ip_address) });
};
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const checkFirewall = Object.freeze({
    useQuery: _useQuery,
    invalidateQueries
});
