/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useMutation } from '@tanstack/react-query';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';

/**********************************************************************************************************
 *   QUERIES
 **********************************************************************************************************/
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import type { KatanaNamespace } from 'containers/katana/types';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions/handleDefaultErrorNotification';
import { handleDefaultSuccessNotification } from 'utilities/methods/commonActions/handleDefaultSuccessNotification';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/

type Params = Parameters<typeof KATANA_API.katana.service_id.socials.POST>[0];

/**********************************************************************************************************
 *   Katana Update Social Links
 **********************************************************************************************************/
/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function useUpdateSocialLinksMutation(serviceID: KatanaNamespace.ServiceId, options = {}) {
    /***** HOOKS *****/
    const { page } = useKatanaParams();

    /***** RENDER *****/
    return useMutation({
        mutationFn: (attributes: Params['attributes']) => KATANA_API.katana.service_id.socials.POST({ serviceID, attributes }),
        onError: handleDefaultErrorNotification,
        onSuccess: page === 'setup' ? undefined : handleDefaultSuccessNotification,
        onSettled: () => {
            katanaQuery.serviceID.getSocials.invalidateQueries(serviceID);
            katanaQuery.serviceID.service.invalidateQueries(serviceID);
        },
        ...options,
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/
useUpdateSocialLinksMutation.FORM_FIELD_KEYS = {
    SOCIALS: 'socials',
} as const;

export { useUpdateSocialLinksMutation };
