import { queryOptions, useQuery } from '@tanstack/react-query';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import type { KatanaNamespace } from 'containers/katana/types';
import { queryClient } from 'store/queryClient';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { KATANA_API } from 'utilities/api/katana';
import { TruthKeys } from 'utilities/methods/commonActions/Truth';

type Params = Parameters<typeof KATANA_API.katana.unsplash.search.GET>[1];

function createQueryKey(id: KatanaNamespace.ServiceId, params: Params) {
    // I'm not using a base query key here because the results are not user specific
    return katanaQueryKeys.katana.unsplash.search(params);
}

function createQueryOptions(id: KatanaNamespace.ServiceId, params: Params) {
    return queryOptions({
        queryKey: createQueryKey(id, params),
        queryFn: () => KATANA_API.katana.unsplash.search.GET(id, params),
        retryOnMount: false,
        staleTime: Infinity,
        enabled: TruthKeys(params),
        select: (data) => {
            if (data?.status === 200) {
                return data.data;
            }
        },
    });
}

function prefetchQuery(id: KatanaNamespace.ServiceId, params: Params) {
    return queryClient.prefetchQuery(createQueryOptions(id, params));
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/

function _useQuery(id: KatanaNamespace.ServiceId, params: Params) {
    return useQuery({
        ...createQueryOptions(id, params),
    });
}

/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const searchUnsplash = Object.freeze({
    useQuery: _useQuery,
    prefetchQuery,
    createQueryKey,
    createQueryOptions,
});
