/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useContext } from 'react';
import { useTanstackStore } from 'utilities/hooks/tanstack-store/useTanstackStore';
import { useAppViewport } from 'utilities/hooks/useAppViewport/useAppViewport';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { PhosphorIcons } from 'components/Icons/Phosphor';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { PictureInPictureIconControlButton } from 'components/PictureInPicture/controls/ControlIconButton';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { PictureInPictureContext } from 'components/PictureInPicture/consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * @param {{
 *      text?:string,
 * }} props
 */
function _PictureInPictureMaximiseButton({ text = 'Maximise' }) {
    /***** HOOKS *****/
    const { stateStoreData } = useContext(PictureInPictureContext);
    const [pictureState, setPictureState] = useTanstackStore(stateStoreData.store);
    const isMobile = useAppViewport(['xs', 'sm']);

    if (pictureState === 'maximised') {
        return;
    }

    if (isMobile) {
        return;
    }
    /***** RENDER *****/
    return (
        <PictureInPictureIconControlButton
            text={text}
            Icon={PhosphorIcons.CornersOut}
            onClick={(e) => {
                e.stopPropagation();
                setPictureState('maximised');
            }}
        />
    );
}

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { _PictureInPictureMaximiseButton };
