import { AcknowledgementBox } from 'components/AcknowledgementBox';
import { SolidButton } from 'components/Buttons/SolidButton';
import { CheckBoxList } from 'components/Form/CheckBoxList';
import RequestLoader from 'components/Loaders/Request';
import { pushNotification } from 'components/Toast/functions';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import { useState } from 'react';
import { NXQuery } from 'utilities/query';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const DNSHostingChangeKatanaLaunch = () => {
    /***** STATE *****/
    const [acknowledgeDNSHostingChange, setAcknowledgeDNSHostingChange] = useState(false);
    const [acknowledgeDNSHostingChangeTime, setAcknowledgeDNSHostingChangeTime] = useState(false);

    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();

    /***** QUERIES *****/
    const { data: check_enable_site_data, isFetching: isCheckKatanaServiceDetailsFetching } =
        katanaQuery.serviceID.domainServiceDetails.useQuery(katanaServiceId);
    const { mutate: mutatePutDomainDNSType, isPending: isPutDomainDNSTypePending } = NXQuery.domain.domainID.configureDNSMode.useMutation();

    /***** FUNCTIONS *****/
    function mutateChangeDNSHosting() {
        if (!check_enable_site_data?.domain_id) {
            pushNotification({
                status: 400,
                details: 'No Domain ID found.'
            });
            return;
        }
        mutatePutDomainDNSType({
            id: check_enable_site_data?.domain_id,
            attributes: { dns_type: 'free_dns' }
        });
    }

    const multiLoaderData = RequestLoader.MultiLoader.useLoadersData([
        { condition: isCheckKatanaServiceDetailsFetching, message: 'Checking Service Details...' }
    ]);

    /***** RENDER *****/
    return (
        <RequestLoader.MultiLoader loaders={multiLoaderData}>
            <Padding y={4}>
                <Text secondary size--m align--center>
                    It looks like the DNS Mode for your domain <Text bold>{check_enable_site_data?.hostname}</Text>&nbsp;needs to be changed before we
                    can make your site live. Your DNS mode will be changed to DNS hosting.
                </Text>
            </Padding>
            <AcknowledgementBox.Compact title="Confirmation">
                <Flex direction="column" gap={2}>
                    <CheckBoxList.Item isChecked={acknowledgeDNSHostingChangeTime} onChange={setAcknowledgeDNSHostingChangeTime}>
                        <Text secondary size--s bold>
                            I understand that DNS Propagation may take up to 1 hour to complete before my changes are reflected.
                        </Text>
                    </CheckBoxList.Item>
                    <CheckBoxList.Item isChecked={acknowledgeDNSHostingChange} onChange={setAcknowledgeDNSHostingChange}>
                        <Text secondary size--s bold>
                            I understand that the DNS mode for my domain will be changed to DNS Hosting
                        </Text>
                    </CheckBoxList.Item>
                </Flex>
            </AcknowledgementBox.Compact>
            <SolidButton
                type="onClick"
                onClick={mutateChangeDNSHosting}
                disabled={!acknowledgeDNSHostingChange || !acknowledgeDNSHostingChangeTime}
                isLoading={isPutDomainDNSTypePending}
            >
                Change DNS Hosting
            </SolidButton>
        </RequestLoader.MultiLoader>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
