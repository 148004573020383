import Anchor from 'components/Anchor';
import { SolidButton } from 'components/Buttons/SolidButton';
import RequestLoader from 'components/Loaders/Request';
import { DialogNotification } from 'components/Notifications/DialogNotification';
import NXBox from 'components/NXBox';
import { Flex } from 'components/Utils/Flex';
import { Padding } from 'components/Utils/Padding';
import Text from 'components/Utils/Text';
import { useGetDynamicFormFieldValue } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useGetDynamicFormFieldValue';
import { _AccountConnected as AccountConnected } from 'containers/katana/formFields/caveats/InstagramConnectCaveat/_AccountConnected';
import { _ConnectAccount as ConnectAccount } from 'containers/katana/formFields/caveats/InstagramConnectCaveat/_ConnectAccount';
import { filterInstagramType } from 'containers/katana/formFields/caveats/InstagramConnectCaveat/filterInstagramType';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import type { KatanaNamespace } from 'containers/katana/types';
import React, { useEffect, useMemo, useState } from 'react';
import { useEventListener } from 'usehooks-ts';
import { SECOND } from 'utilities/consts';
import './_InstagramConnectCaveat.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type InstagramConnectCaveatProps = {
    property: KatanaNamespace.SectionDefinitions.PropertiesModified;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const InstagramConnectCaveat: React.FC<InstagramConnectCaveatProps> = ({ property }) => {
    /***** STATE *****/
    const [isAuthorising, setIsAuthorising] = useState(false);
    const [hasAttemptedAuthorisation, setHasAttemptedAuthorisation] = useState(false);
    const [refetchInterval, setRefetchInterval] = useState(SECOND * 10);
    const [isRequestTimedOut, setIsRequestTimedOut] = useState(false);
    const [totalInstagramAccounts, setTotalInstagramAccounts] = useState(0);

    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();
    const userSocialID = useGetDynamicFormFieldValue(property.key);

    useEventListener('focus', () => {
        if (isAuthorising) {
            setRefetchInterval(SECOND * 3);
        }
    });

    useEventListener('blur', () => {
        if (isAuthorising) {
            setRefetchInterval(SECOND * 10);
        }
    });

    /***** QUERIES *****/
    const {
        data: get_social_account_data,
        isLoading: isGetSocialAccountLoading,
        isSuccess: isGetSocialAccountSuccess
    } = katanaQuery.serviceID.getSocialAccount.useQuery(katanaServiceId, userSocialID);
    const { data: instagramAccounts, isLoading: isLoadingSocialAccounts } = katanaQuery.serviceID.listSocialAccounts.useQuery(katanaServiceId, {
        refetchInterval: isAuthorising ? refetchInterval : false,
        refetchOnWindowFocus: isAuthorising,
        select: filterInstagramType
    });

    /***** RENDER HELPERS *****/
    const hasConnectedSocialAccount = useMemo(() => {
        return instagramAccounts?.length || Boolean(userSocialID);
    }, [userSocialID, get_social_account_data, isGetSocialAccountSuccess]);

    /***** EFFECTS *****/
    useEffect(() => {
        if (!isAuthorising) return;

        //cancel the refetchInterval after 'x' amount of time
        const cancelRefetch = setTimeout(() => {
            setIsRequestTimedOut(true);
            setIsAuthorising(false);
        }, SECOND * 60);

        return () => {
            clearTimeout(cancelRefetch);
        };
    }, [instagramAccounts, isAuthorising]);

    useEffect(() => {
        if (instagramAccounts?.length !== totalInstagramAccounts) {
            setTotalInstagramAccounts(instagramAccounts?.length ?? 0);
            setIsRequestTimedOut(false);
            setIsAuthorising(false);
        }
    }, [instagramAccounts]);

    useEffect(() => {
        if (isAuthorising) {
            setHasAttemptedAuthorisation(true);
        }
    }, [isAuthorising]);

    /***** RENDER HELPERS *****/
    const connectionStatus: 'not_connected' | 'connected' | 'loading' | 'authorising' | undefined = useMemo(() => {
        if (isAuthorising) {
            return 'authorising';
        }

        if (isLoadingSocialAccounts || isGetSocialAccountLoading) {
            return 'loading';
        }

        if (hasConnectedSocialAccount) {
            return 'connected';
        }

        if (!hasConnectedSocialAccount) {
            return 'not_connected';
        }
    }, [hasConnectedSocialAccount, isLoadingSocialAccounts, isAuthorising, isGetSocialAccountLoading]);

    /***** RENDER *****/
    return (
        <Padding paddingOnly top={4} className="InstagramConnectCaveat">
            <NXBox.Title title="Instagram Details" />
            <NXBox.Description description="Add your instagram account to showcase your posts on your website." />

            <DialogNotification type="info" tail={{ pos: 'top' }}>
                PLEASE NOTE: In order to use this section your Instagram account must be setup as a Creator or Business account.{' '}
                <Anchor
                    to="https://ventraip.com.au/support-centre/convert-your-instagram-account-to-a-creator-or-business-account/"
                    color="white"
                    underline
                    inheritSize
                    inheritFontWeight
                >
                    Read our guide on how to convert your account from Personal to Creator or Business.
                </Anchor>
            </DialogNotification>

            <Flex direction="column" gap={2}>
                <Text>Instagram Account</Text>
                {(() => {
                    switch (connectionStatus) {
                        case 'not_connected':
                        case 'connected':
                            return (
                                <>
                                    <AccountConnected propertyKey={property.key} />
                                    <ConnectAccount
                                        propertyKey={property.key}
                                        setIsAuthorising={setIsAuthorising}
                                        isAuthorising={isAuthorising}
                                        hasAttemptedAuthorisation={hasAttemptedAuthorisation}
                                        isRequestTimedOut={isRequestTimedOut}
                                    />
                                </>
                            );

                        case 'loading':
                            return <RequestLoader message="Checking connected instagram accounts..." />;

                        case 'authorising':
                            return (
                                <Flex direction="column" gap={2}>
                                    <RequestLoader message="Waiting for instagram to authorise the connection..." />
                                    <SolidButton color="warn" onClick={() => setIsAuthorising(false)} width="full">
                                        Cancel
                                    </SolidButton>
                                </Flex>
                            );

                        default:
                            return null;
                    }
                })()}
            </Flex>
        </Padding>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
