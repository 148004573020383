import axios from 'axios';
import type { Item } from 'containers/orderingForm/types';
import { nxAxios } from 'store/nxAxios';

const CancelToken = axios.CancelToken;

export let cancelSearchDomain;
export let cancelSearchHosting;
export let cancelSearchEmail;
export let cancelSearchMailbox;
export let cancelSearchSSL;
export let cancelSearchVPS;
export let cancelSearchGSuite;
export let cancelSearchMs365;
export let cancelListSSL;
export let cancelListVPS;
export let cancelListMS365;

export let cancelCalculateChangePlanCost;

export namespace API {
    export namespace hosting {
        export namespace listNew {
            export type ReturnType = Artah.WebHosting.GET.NXReturnType;
            export type Filters = {
                page?: number;
                record_per_page?: number;
                sort?: string | null;
                sort_by?: string | null;
            };
        }

        export namespace searchNew {
            export type Params = {
                keyword: string;
                signal: AbortSignal;
            };
            export type ReturnType = Artah.WebHosting.Search.Keyword.GET.NXReturnType;
        }

        export namespace restore {
            export type Params = number | string;
            export type ReturnType = Artah.WebHosting.ID.Restore.POST.NXReturnType;
        }
    }

    export namespace service {
        export namespace get {
            export namespace suspensionReason {
                export type ReturnType = Promise<Artah.Service.ID.Unsuspend.Reason.GET._200['data']>;
                export type ErrorReturnType = Promise<Artah.Service.ID.Unsuspend.Reason.GET._403>;
                export type Params = number | string;
            }

            export namespace userExists {
                export type ReturnType = Artah.User.ServiceMove.UserExists.GET.NXReturnType;
                export type Params = string;
            }

            export namespace approveMove {
                export type ReturnType = Artah.User.ServiceMove.Approve.GET.NXReturnType;
                export type Params = string;
            }
        }
    }
    export namespace order {
        export namespace post {
            export namespace nxCreate {
                export type Params = {
                    paymentMethodId: string;
                    tokenId: string;
                    applyCredit: boolean;
                };
            }
        }
    }
}

export const API = {
    /**********************************************************************************************************
     *   SERVICE API
     **********************************************************************************************************/
    service: {
        get: {
            moveInvitation: (id) => {
                return axios.get(`/api/user/service-move/${id}`);
            },

            userExists: (token: API.service.get.userExists.Params): API.service.get.userExists.ReturnType => {
                return nxAxios.get(`/api/user/service-move/user-exists?token=${token}`);
            },

            approveMove: (token: API.service.get.approveMove.Params): API.service.get.approveMove.ReturnType => {
                return nxAxios.get(`/api/user/service-move/approve?token=${token}`);
            },

            addons: (serviceId) => {
                return axios.get(`/api/service/${serviceId}/addon`);
            },

            renewInfo: (id) => {
                return axios.get(`/api/service/${id}/renew`);
            },

            upgradePaths: (serviceId) => {
                return axios.get(`/api/service/${serviceId}/change-plan-paths`);
            },

            suspensionReason: (serviceId: API.service.get.suspensionReason.Params): API.service.get.suspensionReason.ReturnType => {
                return nxAxios.get(`/api/service/${serviceId}/unsuspend/reason`);
            }
        },

        post: {
            purchaseAddon: (serviceId, attributes) => {
                return axios.post(`/api/service/${serviceId}/addon/purchase`, {
                    attributes
                });
            },

            cancel: (id, attributes) => {
                const payload = {
                    type: 'cancellation',
                    attributes: attributes
                };
                return axios.post(`/api/service/${id}/cancellation`, payload);
            },

            cancelInfo: (id, attributes = {}) => {
                const payload = {
                    type: 'cancellation',
                    attributes: attributes
                };
                return axios.post(`/api/service/${id}/cancellation/check`, payload);
            },

            move: (attributes) => {
                return axios.post(`/api/user/service-move`, {
                    type: 'service-move',
                    attributes
                });
            },

            registerMove: (attributes, token) => {
                return axios.post(`/api/user/service-move/approve-signup?token=${token}`, {
                    type: 'service-move',
                    attributes
                });
            },

            cancelMove: (id) => {
                return axios.post(`/api/user/service-move/cancel/${id}`);
            },

            renew: (id, attributes) => {
                return axios.post(`/api/service/${id}/renew`, {
                    type: 'service',
                    id,
                    attributes
                });
            },
            /**
             *
             * @param {string} id
             * @param {{billing_cycle: "Monthly" | "Quarterly" | "Semi-Annually" | "Annually" | "Biennially" | "Triennially"}} attributes
             * @returns {Artah.Service.ID.Renew.Details.POST.All.Promise}
             */
            renewDetails: (id, attributes) => {
                return nxAxios.post(`/api/service/${id}/renew/details`, {
                    type: 'service',
                    id,
                    attributes
                });
            },

            unsuspend: (serviceId) => {
                return nxAxios.post(`/api/service/${serviceId}/unsuspend`);
            },

            changePlan: (id, attributes) => {
                return axios({
                    method: 'post',
                    url: `/api/service/${id}/change-plan`,
                    data: {
                        type: 'service',
                        attributes
                    }
                });
            },

            changePlanCalculate: (id, attributes) => {
                return axios({
                    method: 'post',
                    url: `/api/service/${id}/change-plan/calculate`,
                    data: {
                        type: 'service',
                        attributes
                    },
                    cancelToken: new CancelToken(function executor(c) {
                        cancelCalculateChangePlanCost = c;
                    })
                });
            }
        },

        service_id: {
            cancellation: {
                DELETE: (serviceID: number | string) => {
                    return nxAxios.delete(`/api/service/${serviceID}/cancellation`);
                }
            }
        }
    },

    /**********************************************************************************************************
     *   DOMAINS API
     **********************************************************************************************************/

    domain: {
        list: (params) => {
            if (params) {
                return axios.get(`/api/domain`, {
                    params
                });
            }

            return axios.get(`/api/domain`);
        },

        search: (keyword) => {
            return axios.get(`/api/domain/search/${keyword}`, {
                cancelToken: new CancelToken(function executor(c) {
                    cancelSearchDomain = c;
                })
            });
        },

        get: {
            transfer: (id) => {
                return axios.get(`/api/domain/${id}/transfer/details`);
            }
        },

        post: {
            export: (attributes) => {
                return axios.post(`/api/domain/export`, {
                    attributes
                });
            },

            enableRenew: (id) => {
                return axios.post(`/api/domain/${id}/auto-renew/enable`);
            },

            disableRenew: (id) => {
                return axios.post(`/api/domain/${id}/auto-renew/disable`);
            },

            approve: (id, attributes) => {
                return axios.post(`/api/domain/${id}/transfer/approve`, {
                    attributes
                });
            },

            info: (id, attributes) => {
                return axios.post(`/api/domain/${id}/registration-details/update`, {
                    attributes
                });
            },

            epp: (id, attributes) => {
                return axios.post(`/api/domain/${id}/epp/update`, {
                    attributes
                });
            },

            resend: (id) => {
                return axios.post(`/api/domain/${id}/transfer/resend`);
            },

            reprocessTransfer: (id) => {
                return axios.post(`/api/domain/${id}/transfer/reset`);
            }
        }
    },

    /**********************************************************************************************************
     *   EMAIL API
     **********************************************************************************************************/

    email: {
        list: (params) => {
            if (params) {
                return axios.get(`/api/email-hosting`, {
                    params
                });
            }

            return axios.get(`/api/email-hosting`);
        },

        search: (keyword) => {
            return axios.get(`/api/email-hosting/search/${keyword}`, {
                cancelToken: new CancelToken(function executor(c) {
                    cancelSearchEmail = c;
                })
            });
        },

        mailboxSearch: (id, keyword) => {
            return axios.get(`/api/email-hosting/mailbox/${id}/search/${keyword}`, {
                cancelToken: new CancelToken(function executor(c) {
                    cancelSearchMailbox = c;
                })
            });
        }
    },

    /**********************************************************************************************************
     *   HOSTING API
     **********************************************************************************************************/

    hosting: {
        list: (params) => {
            if (params) {
                return axios.get(`/api/web-hosting`, {
                    params
                });
            }

            return axios.get(`/api/web-hosting`);
        },

        listNew: (params: unknown): API.hosting.listNew.ReturnType =>
            nxAxios.get(`/api/web-hosting`, {
                params
            }),

        status: (query) => {
            return axios.get(`/api/web-hosting/?status=${query}`);
        },

        search: (keyword) => {
            return axios.get(`/api/web-hosting/search/${keyword}`, {
                cancelToken: new CancelToken(function executor(c) {
                    cancelSearchHosting = c;
                })
            });
        },

        searchNew: ({ keyword, signal }: API.hosting.searchNew.Params): API.hosting.searchNew.ReturnType => {
            return nxAxios.get(`/api/web-hosting/search/${keyword}`, {
                signal
            });
        },

        restore: (id: API.hosting.restore.Params) => {
            return axios.post(`/api/web-hosting/${id}/restore`, {});
        },

        /**
         * Restore function using nxAxios for use with tanstack mutations.
         * Once all instances of the redux action have been removed we can rename this
         */
        restoreNew: (id: API.hosting.restore.Params): API.hosting.restore.ReturnType => {
            return nxAxios.post(`/api/web-hosting/${id}/restore`, {});
        }
    },

    /*********************************************************************************************************
     *   SSL API
     **********************************************************************************************************/

    ssl: {
        /**
         * @type {import('./_services').Services.SSL.TList}
         */
        list: (params) => {
            if (params) {
                return axios.get(`/api/ssl`, {
                    params,
                    cancelToken: new CancelToken((c) => {
                        cancelListSSL = c;
                    })
                });
            }

            return axios.get(`/api/ssl`);
        },

        product: () => {
            return axios.get(`/api/ssl/product/list`);
        },

        /**
         * @type {import('./_services').Services.SSL.TSearch}
         */
        search: (keyword) => {
            return axios.get(`/api/ssl/search/${keyword}`, {
                cancelToken: new CancelToken(function executor(c) {
                    cancelSearchSSL = c;
                })
            });
        },

        purchase: (attributes) => {
            return axios.post(`/api/ssl/purchase`, {
                type: 'purchase-ssl',
                attributes
            });
        }
    },

    /**********************************************************************************************************
     *   VPS API
     **********************************************************************************************************/

    vps: {
        list: (params) => {
            if (params) {
                return axios.get(`/api/vps`, {
                    params,
                    cancelToken: new CancelToken(function executor(c) {
                        cancelListVPS = c;
                    })
                });
            }

            return axios.get(`/api/vps`);
        },

        search: (keyword) => {
            return axios.get(`/api/vps/search/${keyword}`, {
                cancelToken: new CancelToken(function executor(c) {
                    cancelSearchVPS = c;
                })
            });
        }
    },

    /**********************************************************************************************************
     *   GSUITE API
     **********************************************************************************************************/

    gsuite: {
        list: (params) => {
            if (params) {
                return axios.get(`/api/gsuite`, {
                    params
                });
            }

            return axios.get(`/api/gsuite`);
        },

        search: (keyword) => {
            return axios.get(`/api/gsuite/search/${keyword}`, {
                cancelToken: new CancelToken(function executor(c) {
                    cancelSearchGSuite = c;
                })
            });
        }
    },

    /**********************************************************************************************************
     *   MS365 API
     **********************************************************************************************************/

    ms365: {
        list: (params) => {
            if (params) {
                return axios.get(`/api/microsoft365`, {
                    params,
                    cancelToken: new CancelToken(function executor(c) {
                        cancelListMS365 = c;
                    })
                });
            }

            return axios.get(`/api/microsoft365`);
        },

        search: (keyword) => {
            return axios.get(`/api/microsoft365/search/${keyword}`, {
                cancelToken: new CancelToken(function executor(c) {
                    cancelSearchMs365 = c;
                })
            });
        }
    },

    cart: {
        // internal requires obfuscated product ids
        internal: {
            POST: {
                /**
                 * Depreciated, use nxCreate
                 */
                create: (items) => {
                    return axios.post(`/api/internal/cart/create`, {
                        items
                    });
                },

                nxCreate: (items) => {
                    return nxAxios.post(`/api/internal/cart/create`, {
                        items
                    });
                },

                promo: (token, code) => {
                    return axios.post(`/api/internal/cart/${token}/promo/add`, {
                        promo: code
                    });
                },

                addBestPromo: (cartId) => {
                    return axios.post(`/api/cart/${cartId}/promo/find-best`);
                }
            },
            PUT: {
                edit: (token, uuid, items) => {
                    return axios.put(`/api/internal/cart/${token}/item/${uuid}/edit`, {
                        ...items
                    });
                }
            }
        },

        GET: {
            items: (tokenId: string | null): Artah.Cart.TokenID.GET.All.Promise => {
                return nxAxios.get(`/api/cart/${tokenId}`);
            }
        },

        POST: {
            /**
             * Depreciated, use nxCreate
             */
            create: (items: Array<Item>): Artah.Cart.Create.POST.All.Promise => {
                return axios.post(`/api/cart/create`, {
                    items
                });
            },

            nxCreate: (items: Array<Partial<Item>>): Artah.Cart.Create.POST.All.Promise => {
                return nxAxios.post(`/api/cart/create`, {
                    items
                });
            },

            addItems: ({ cartToken, items }: { cartToken: string; items: Array<Partial<Item>> }) => {
                return nxAxios.post(`/api/cart/${cartToken}/item/add`, {
                    items
                });
            },

            editItem: (tokenId: string, items: Array<Item>): Artah.Cart.TokenID.items.process.POST.All.Promise => {
                return nxAxios.post(`/api/cart/${tokenId}/items/process`, {
                    items
                });
            },

            promo: (tokenId: string, code: string) => {
                return axios.post(`/api/cart/${tokenId}/promo/add`, {
                    promo: code
                });
            }
        },
        DELETE: {
            promo: (tokenId: string, code: string) => {
                return axios.delete(`/api/cart/${tokenId}/promo/${code}`);
            },

            removeItems: (tokenId: string, itemId: string) => {
                return nxAxios.delete(`/api/cart/${tokenId}/item/${itemId}`);
            }
        }
    },

    order: {
        POST: {
            /**
             * Depreciated, use nxAvailability
             */
            create: (tokenId: string, paymentMethodId: string): Artah.Cart.TokenID.order.create.POST.All.Promise => {
                return axios.post(`/api/cart/${tokenId}/order/create`, {
                    payment_method_id: paymentMethodId
                });
            },

            nxCreate: (params: API.order.post.nxCreate.Params): Promise<Artah.Cart.TokenID.order.create.NXReturnType> => {
                return nxAxios.post(`/api/cart/${params.tokenId}/order/create`, {
                    payment_method_id: params.paymentMethodId,
                    token_id: params.tokenId,
                    apply_credit: params.applyCredit
                });
            }
        }
    }
};
