/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useSelector } from 'react-redux';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { useNavigate } from '@tanstack/react-router';
import { OutlineButton } from 'components/Buttons/OutlineButton';
import Title from 'components/Title';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const GroupTitle = () => {
    /***** HOOKS *****/
    const navigate = useNavigate();
    /***** SELECTORS *****/
    const email_domain_group_details_data = useSelector((state: any) => state.email.email_domain_group_details_data);

    /***** RENDER *****/
    return (
        <Title
            serviceTitle={email_domain_group_details_data?.attributes?.domain || ''}
            serviceType={email_domain_group_details_data?.type}
            action={
                <OutlineButton
                    className="TitleShared__dropdown medium"
                    color="primary"
                    onClick={() =>
                        navigate({
                            to: '/support/support-centre',
                            search: {
                                categories: 'products, email-hosting-products'
                            }
                        })
                    }
                >
                    Email Hosting Help
                </OutlineButton>
            }
        />
    );
};
