import { _CheckBoxListItem as CheckBoxListItem } from 'components/Form/CheckBoxList/_CheckBoxListItem';
import type { HookFormCheckboxListItem } from 'components/Form/CheckBoxList/_CheckBoxListItem/wrappers/hookform/types';
import { HookForm } from 'components/Form/HookForm';
import { ValidationMessage } from 'components/Form/ValidationMessage';
import { useController } from 'react-hook-form';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const CheckBoxListItemReactHookFormDefault: HookFormCheckboxListItem.Component = ({
    children,
    name,
    inverse,
    className,
    disabled,
    intrinsic
}) => {
    /***** HOOKS *****/
    const { field, fieldState } = useController({ name });

    /***** FUNCTIONS *****/
    const handleChange = (value: boolean) => {
        if (disabled) {
            return;
        }
        const changeValueTransform = inverse ? !value : value;
        field.onChange(changeValueTransform);
        field.onBlur();
    };

    /***** RENDER HELPERS *****/
    const meta = HookForm.getMeta(fieldState);

    const valueTransform = inverse ? !field.value : field.value;

    /***** RENDER *****/
    return (
        <CheckBoxListItem intrinsic={intrinsic} isChecked={valueTransform} onChange={handleChange} className={className} disabled={disabled}>
            {children}
            <ValidationMessage.HookForm.Default {...meta} />
        </CheckBoxListItem>
    );
};
