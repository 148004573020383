/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import Text from 'components/Utils/Text';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Title component of the NXTable Header. This is the base styling for a header of an NXTable Column
 *
 * @type {NXTable.Header.TTitle}
 */
const Title = ({ children }) => {
    /***** RENDER *****/
    return (
        <Text bold uppercase size--xs color="C_NXTableColumnHeading_color_text">
            {children}
        </Text>
    );
};

/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default Title;
