import classNames from 'classnames';
import TextContext from 'components/Utils/Text/_Text/textContext';
import type { TextNamespace } from 'components/Utils/Text/_Text/types';
import { filterTextProps } from 'components/Utils/Text/_Text/utils';
import type { ColorTokens, ExcludePrimitiveToken } from 'config/tokens/base';
import React from 'react';
import { useStyle } from 'utilities/hooks/useStyle';
import { useTheme } from 'utilities/hooks/useTheme';
import type { NXUtils } from 'utilities/NXUtils';
import './_TextVMax.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
export namespace _TextVMax {
    export type BaseProps = {
        align?: 'left' | 'center' | 'right';
        color?: ExcludePrimitiveToken<ColorTokens> | NXUtils.Falsy;
        style?: React.CSSProperties;
        className?: string;
        children: React.ReactNode;
        /**
         * VMax Text props
         */
        fontWeight?: 'medium' | 'semibold';
    };

    export type Props = {
        size?: number;
    } & BaseProps &
        Pick<TextNamespace.Props, 'as'>;
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _TextVMax: React.FC<_TextVMax.Props> = ({ children, className, style, align, size, fontWeight = 'medium', as: component, ...props }) => {
    const { color } = props;

    /***** HOOKS *****/
    const { parentParagraph } = React.useContext(TextContext);
    const themeStyles = useTheme({
        '--Text-color': color,
    });

    const styles = useStyle({
        '--Text-size': size,
    });

    /***** RENDER HELPERS *****/
    // IMPROVE: Add automatic inference of span when inside a un-nestable component, like p can't be inside p or span but span can be inside p.
    const textClassNames = classNames('TextVMax', className, {
        [`TextVMax--align-[${align}`]: align,
        [`TextVMax--fontWeight-${fontWeight}`]: fontWeight,
    });

    const finalProps = {
        className: textClassNames,
        style: {
            ...style,
            ...styles,
            ...themeStyles,
        },
    };

    /***** RENDER *****/
    if (component) {
        return React.createElement(component, { ...filterTextProps(props), ...finalProps }, children);
    }

    if (parentParagraph) {
        return <span {...finalProps}>{children}</span>;
    }

    return <p {...finalProps}>{children}</p>;
};
