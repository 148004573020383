import { useIsMutating, useMutation } from '@tanstack/react-query';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { newSectionTrackerData } from 'containers/katana/modules/presetContent/components/sectionOrganiser/newSectionTrackerData';
import { katanaQueryKeys } from 'containers/katana/queries/katanaQueryKeys';
import { useHandleDynamicSectionMethods } from 'containers/katana/queries/methods/useHandleDynamicSectionMethods';
import { section } from 'containers/katana/queries/serviceID/section';
import { sections } from 'containers/katana/queries/serviceID/sections';
import { service } from 'containers/katana/queries/serviceID/service';
import type { KatanaNamespace } from 'containers/katana/types';
import _ from 'lodash';
import { KATANA_API } from 'utilities/api/katana';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions/handleDefaultErrorNotification';
import { handleDefaultSuccessNotification } from 'utilities/methods/commonActions/handleDefaultSuccessNotification';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/

type Params = Parameters<typeof KATANA_API.katana.site.service_id.sections.POST>[0];
type BaseParams = Pick<Params, 'serviceID' | 'sectionDefinitionID'>;

type MutationParams = Pick<Params, 'attributes' | 'sectionDefinitionID'>;

function createMutationKey(serviceID: KatanaNamespace.ServiceId) {
    return katanaQueryKeys.katana.service.ID.sections.add(serviceID);
}

function _useIsMutating(serviceID: KatanaNamespace.ServiceId) {
    return useIsMutating({
        mutationKey: createMutationKey(serviceID),
    });
}

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * Katana add section
 */
function _useMutation({ serviceID, sectionDefinitionID }: BaseParams, options = {}) {
    /***** HOOKS *****/
    const { page } = useKatanaParams();
    const { handleModifyEntireAttributeObject } = useHandleDynamicSectionMethods({ serviceID, sectionDefinitionID });

    function handleMutation({ sectionDefinitionID, attributes }: MutationParams) {
        const updatedAttributes = handleModifyEntireAttributeObject(attributes);

        return KATANA_API.katana.site.service_id.sections.POST({ serviceID, sectionDefinitionID, attributes: updatedAttributes });
    }

    return useMutation({
        mutationFn: handleMutation,
        mutationKey: createMutationKey(serviceID),
        onError: (response) => {
            handleDefaultErrorNotification(response);
            service.invalidateQueries(serviceID);
        },
        onSuccess: (response) => {
            if (page !== 'setup') {
                handleDefaultSuccessNotification(response);
            }

            service.invalidateQueries(serviceID);

            if (!_.has(response, 'data')) return;

            const correctResponse = section.createDataObject(response, {
                meta: {
                    media_attachments: [],
                },
            });

            newSectionTrackerData.addNewID(correctResponse.data.id);

            section.setQueryData({ serviceID, sectionID: correctResponse.data.id }, () => correctResponse);
            sections.optimistic.concat(serviceID, 'data', [{ ...correctResponse.data }]);
        },
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

export const addSection = Object.freeze({
    useMutation: _useMutation,
    useIsMutating: _useIsMutating,
});
