/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { DropzoneRef } from 'react-dropzone';
import { Field } from 'redux-form';
import _ from 'lodash';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { CheckBox } from 'components/Form/CheckBox';
import { Revealer } from 'components/Revealer';
import { Flex } from 'components/Utils/Flex';
import { Input } from 'components/Form/Input';
import { Fragment } from 'react';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { NXDropZoneWithKatanaImageUploadAndControl } from 'components/Form/NXDropZone/wrappers/reduxForm/WithKatanaImageUploadAndControl';
import { KatanaFileFormFieldChooseStockPhotoReduxForm } from '../inputTypes/dropzone_or_stock_photo/KatanaFileFormFieldChooseStockPhotoReduxForm';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { createAcceptMimeTypes } from 'containers/katana/formFields/file/methods';
import { useGetCaveat } from 'containers/katana/formFields/methods/useGetCaveat';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { transformToVideoEmbed } from 'containers/katana/queries/methods/attachmentData';
import { isAttachmentDataEmbed } from 'containers/katana/queries/methods/attachmentData';
import { useGetDynamicFormFieldValue } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useGetDynamicFormFieldValue';
import { videoEmbedValidation } from 'containers/katana/formFields/file/methods/videoEmbedValidation';
import { getMediaFeatureType } from 'containers/katana/formFields/file/methods/getMediaFeatureType';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { CaveatTypes } from 'containers/katana/formFields/caveatTypes';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
import type { KatanaNamespace } from 'containers/katana/types';
import type { MediaFeatures } from 'containers/katana/components/EntryCreationBehaviourHandler/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const KatanaFileFormFieldMediaReduxForm: KatanaNamespace.PropertyComponent = ({ property }) => {
    const { key, name, type, caveats, validationFunctions } = property;
    const formValue = useGetDynamicFormFieldValue(key);

    /***** STATE *****/
    const [activeFeature, setActiveFeature] = useState<MediaFeatures>(getMediaFeatureType(formValue));

    /***** HOOKS *****/
    const { katanaServiceId } = useKatanaParams();
    const zoneRef = useRef<DropzoneRef>();

    /***** EFFECTS *****/
    useEffect(() => {
        const currentActiveFeature = getMediaFeatureType(formValue);
        if (!currentActiveFeature) {
            return;
        }
        setActiveFeature(currentActiveFeature);
    }, [formValue]);

    /***** FUNCTIONS *****/
    const handleOnEqualIdentifierLogoClick = useCallback(() => {
        // Open Dropzone
        if (zoneRef.current) {
            zoneRef.current?.open?.();
        }
    }, []);

    function handleOnChangeVideoEmbed(value: string): KatanaNamespace.MediaDataEmbed {
        return transformToVideoEmbed(value);
    }

    function handleValueTransformEmbed(value: any) {
        if (isAttachmentDataEmbed(value)) {
            return value.url;
        } else {
            return '';
        }
    }

    const validationProvider = useMemo(() => {
        if (isAttachmentDataEmbed(formValue) && activeFeature === 'embed_video') {
            return videoEmbedValidation;
        }
        return validationFunctions;
    }, [formValue, activeFeature]);

    const handleOnZoneRef = useCallback((ref: DropzoneRef) => {
        zoneRef.current = ref;
    }, []);

    /***** RENDER HELPERS *****/
    const mimeTypeAccept = useGetCaveat(CaveatTypes.IMAGE_MIME_TYPE, caveats, []);
    const dropzoneMimeTypeAccept = createAcceptMimeTypes(_.isArray(mimeTypeAccept) ? mimeTypeAccept : undefined);
    const mediaFeatureRender = useGetCaveat(CaveatTypes.MEDIA_FEATURES, caveats, []);
    if (!_.isArray(mediaFeatureRender)) {
        return 'No Media Features Found';
    }

    /***** RENDER *****/
    return (
        <>
            <Flex direction="column" gap={2} className="KatanaFileFormFieldMedia">
                {mediaFeatureRender.map((element: { id: string }) => {
                    switch (element.id) {
                        case 'upload_image':
                            return (
                                <Fragment key={element.id}>
                                    <CheckBox.Panel
                                        type="circle"
                                        isChecked={activeFeature === 'upload_image'}
                                        onIsCheckedClick={handleOnEqualIdentifierLogoClick}
                                        onChange={(v) => {
                                            if (!v) {
                                                return;
                                            }
                                            setActiveFeature('upload_image');
                                        }}
                                    >
                                        Upload your own photo
                                    </CheckBox.Panel>
                                    <Revealer isOpen={activeFeature === 'upload_image'}>
                                        <Field
                                            name={key}
                                            label={name}
                                            component={NXDropZoneWithKatanaImageUploadAndControl}
                                            onZoneRef={handleOnZoneRef}
                                            serviceID={katanaServiceId}
                                            type={type}
                                            accept={dropzoneMimeTypeAccept}
                                            validate={validationProvider}
                                            singleFile
                                        />
                                    </Revealer>
                                </Fragment>
                            );
                        case 'stock_image':
                            return (
                                <Fragment key={element.id}>
                                    <CheckBox.Panel
                                        type="circle"
                                        isChecked={activeFeature === 'stock_image'}
                                        onChange={(v) => {
                                            if (!v) {
                                                return;
                                            }
                                            setActiveFeature('stock_image');
                                        }}
                                    >
                                        Pick a stock photo
                                    </CheckBox.Panel>
                                    <Revealer isOpen={activeFeature === 'stock_image'}>
                                        <Field
                                            name={key}
                                            type="file"
                                            component={KatanaFileFormFieldChooseStockPhotoReduxForm}
                                            validate={validationProvider}
                                        />
                                    </Revealer>
                                </Fragment>
                            );
                        case 'embed_video':
                            return (
                                <Fragment key={element.id}>
                                    <CheckBox.Panel
                                        type="circle"
                                        isChecked={activeFeature === 'embed_video'}
                                        onChange={(v) => {
                                            if (!v) {
                                                return;
                                            }
                                            setActiveFeature('embed_video');
                                        }}
                                    >
                                        Embed a YouTube video
                                    </CheckBox.Panel>
                                    <Revealer isOpen={activeFeature === 'embed_video'}>
                                        <Field
                                            component={Input.ReduxForm}
                                            label="YouTube Link"
                                            name={key}
                                            placeholder="E.g. https://www.youtube.com/watch?v=SFuqgn_Pm90"
                                            onChangeTransform={handleOnChangeVideoEmbed}
                                            valueTransform={handleValueTransformEmbed}
                                            validate={validationProvider}
                                        />
                                    </Revealer>
                                </Fragment>
                            );
                        default:
                            return null;
                    }
                })}
            </Flex>
        </>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/

export { KatanaFileFormFieldMediaReduxForm };
