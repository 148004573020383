/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import BackgroundColour from 'components/Utils/BackgroundColour';
import { Border } from 'components/Utils/Border';
import { Flex } from 'components/Utils/Flex';
import { NXSquare } from 'components/Utils/NXSquare';
import Text from 'components/Utils/Text';
import BigPlus from 'containers/services/modules/katanaServicesList/bigPlus';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useBooleanTanstackStore } from 'utilities/hooks/tanstack-store/useBooleanStore';
import { scrollToRef } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { katanaServiceBannerStateTanstackStore } from 'containers/services/modules/katanaServicesList/consts';
import './_katanaServiceListAddWebsiteButton.scss';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const AddWebsiteButton = () => {
    /***** STATE *****/
    const { setTrue: openGetKatana } = useBooleanTanstackStore(katanaServiceBannerStateTanstackStore);

    /***** FUNCTIONS *****/
    function handleOnClick() {
        openGetKatana();
        scrollToRef(document.querySelector('.KatanaServiceBanner'));
    }

    /***** RENDER *****/
    return (
        <Border all={2} dotted radius="C_BaseButton_border-radius_default" inject>
            <button type="button" className="KatanaServiceListAddWebsiteButton" onClick={handleOnClick}>
                <BackgroundColour secondary-alt opacity={0.1}>
                    <NXSquare ratio={0.7}>
                        <Flex justify="center" align="center" fullHeight fullWidth direction="column" gap={6}>
                            <BigPlus />
                            <Text align--center black size--xl semiBold>
                                Add a new Website
                            </Text>
                        </Flex>
                    </NXSquare>
                </BackgroundColour>
            </button>
        </Border>
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export default AddWebsiteButton;
