import { Accordion } from 'components/Accordion';
import { Padding } from 'components/Utils/Padding';
import { useGetDynamicFormFieldSyncErrors } from 'containers/katana/components/dynamicFormFieldRenderer/hooks/useGetDynamicFormFieldSyncErrors';
import useRecursivePropertiesRenderer from 'containers/katana/components/dynamicFormFieldRenderer/useRecursivePropertiesRenderer';
import ContentEditorModuleContent from 'containers/katana/containers/ContentEditorLightbox/contentEditorModuleContent';
import { ContentEditorRenderModulesContext } from 'containers/katana/containers/ContentEditorLightbox/contentEditorRenderModules/consts';
import type { ContentEditorRenderModulesNamespace } from 'containers/katana/containers/ContentEditorLightbox/contentEditorRenderModules/types';
import { useAccordionTitle } from 'containers/katana/formFields/caveats/useAccordionTitle';
import { OptionsIcon } from 'containers/katana/formFields/repeated/OptionsIcon';
import { useActionHandler } from 'containers/katana/formFields/repeated/OptionsIcon/useActionHandler';
import { SORTABLE_ID_KEY } from 'containers/katana/formFields/repeated/SortableRepeatedWrapper/consts';
import type { RepeatedFieldArrayRenderer } from 'containers/katana/formFields/repeated/types';
import { useModifiedFieldArrayProperties } from 'containers/katana/formFields/repeated/useModifiedFieldArrayProperties';
import _ from 'lodash';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useStoreEffect } from 'utilities/hooks/tanstack-store/useStoreEffect';
import { getValidationValue } from 'containers/katana/formFields/methods/getValidation';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _AccordionFieldArrayChildReduxForm: React.FC<RepeatedFieldArrayRenderer.Accordion.ReduxForm.Props> = (props) => {
    const { member, index, fields, properties, property, sortable } = props;
    /***** HOOKS *****/
    const { submitButtonClickEvent } = useContext(ContentEditorRenderModulesContext);
    const PropertiesRenderer = useRecursivePropertiesRenderer();
    const { syncErrors } = useGetDynamicFormFieldSyncErrors();
    const handleAction = useActionHandler({ index, fields, property, member });
    const accordionTitle = useAccordionTitle(property, fields, index);
    const fieldValues = fields.get(index);

    /***** RENDER HELPERS *****/
    const modifiedProperties = useModifiedFieldArrayProperties(member, properties, true);
    const maxLength = getValidationValue(property?.validation, 'max');
    const isDuplicateDisabled = fields.length >= maxLength;

    /***** FUNCTIONS *****/
    const accordionIsDefaultActive = useMemo(() => !_.keys(fieldValues).filter((key) => key !== SORTABLE_ID_KEY).length, [fieldValues]);
    const [isAccordionOpen, setIsAccordionOpen] = useState(accordionIsDefaultActive);

    const handleSubmitButtonEvent = useCallback(
        (e: ContentEditorRenderModulesNamespace.SubmitButtonClickEventValue) => {
            const errorInPath = _.has(syncErrors, member);
            if (errorInPath) {
                setIsAccordionOpen(true);
            }
        },
        [syncErrors, member]
    );

    /***** EFFECTS *****/
    useStoreEffect(submitButtonClickEvent, handleSubmitButtonEvent, false);

    const AccordionComponent = useMemo(() => (sortable ? Accordion.WithDragHandle : Accordion), [sortable]);

    const memoedContent = useMemo(
        () => (
            <Padding xy={4} paddingOnly>
                {/* <KatanaRepeatedFormFieldRemoveButton removeService={removeService} singularName={singularName} /> */}
                <ContentEditorModuleContent>
                    <PropertiesRenderer properties={modifiedProperties} />
                </ContentEditorModuleContent>
            </Padding>
        ),
        [modifiedProperties]
    );

    /***** RENDER *****/
    return (
        <AccordionComponent
            controlled={{
                active: isAccordionOpen,
                toggle: () => setIsAccordionOpen((currentState) => !currentState)
            }}
            id={fieldValues?.[SORTABLE_ID_KEY]}
            border
            title={accordionTitle}
            colour="primary"
            afterChevronContent={<OptionsIcon onAction={handleAction} isDuplicateDisabled={isDuplicateDisabled} maxLength={maxLength} />}
            content={memoedContent}
        />
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
