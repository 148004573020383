import { useMutation } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { getInvoiceActionUrl } from 'components/Lightboxes/OverlayLightbox/Components/invoice/invoiceUrlLightbox/methods';
import { queryClient } from 'store/queryClient';
import { API } from 'utilities/api/email';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions/handleDefaultErrorNotification';
import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Attributes = {
    billing_cycle: 'Monthly' | 'Quarterly' | 'Semi-Annually' | 'Annually';
    config: {
        config_id: number;
        option_id: number;
    }[];
};

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
function _useMutation(id: number) {
    const navigate = useNavigate();

    function mutationFn(attributes: Attributes) {
        return API.email.post.upgrade(id, attributes);
    }

    /***** HOOK RESULTS *****/
    return useMutation({
        mutationFn,
        onError: handleDefaultErrorNotification,
        onSuccess: (response) => {
            queryClient.invalidateQueries({ queryKey: createBaseQueryKey(['email']) });
            navigate({ to: getInvoiceActionUrl('pay', response.data.id) });
        }
    });
}

export const upgradeEmailService = Object.freeze({
    useMutation: _useMutation
});
