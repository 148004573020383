/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import classNames from 'classnames';
import React from 'react';

/**********************************************************************************************************
 *   SHARED
 **********************************************************************************************************/
import { FormItem } from 'components/Form/FormItem';
import { FormItemInner } from 'components/Form/FormItemInner';
import { FormLabel } from 'components/Form/FormLabel';

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { _CardSelectGrid as CardSelectGrid } from 'components/Form/CardSelectGrid/_CardSelectGrid';

/**********************************************************************************************************
 *   TYPE IMPORTS
 **********************************************************************************************************/
import type { CardSelectGridNamespace } from 'components/Form/CardSelectGrid/types';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _CardSelectGridReduxForm: React.FC<CardSelectGridNamespace.ReduxForm.Props> = ({
    /**
     * Redux Props
     */
    input,
    meta,
    description,
    label,

    /**
     * CardSelectGrid Props
     */
    ...props
}) => {
    const { name, value, onChange } = input;

    /***** RENDER *****/
    return (
        <FormItem
            name={name}
            className={classNames({
                'CardSelectGrid--hasDescription': description
            })}
        >
            <FormLabel htmlFor={name}>{label}</FormLabel>
            {description}
            <FormItemInner meta={meta} noBackground fullWidth>
                <CardSelectGrid {...props} onChange={onChange} value={value} />
            </FormItemInner>
        </FormItem>
    );
};
