/**********************************************************************************************************
 *   DECLARATIONS
 **********************************************************************************************************/

export const GSUITE_INITIAL_STATE = 'gsuite/GSUITE_INITIAL_STATE';
export const GSUITE_CURRENT_STEP = 'gsuite/GSUITE_CURRENT_STEP';

export const GSUITE_SERVICE_REQUEST = 'gsuite/GSUITE_SERVICE_REQUEST';
export const GSUITE_SERVICE_SUCCESS = 'gsuite/GSUITE_SERVICE_SUCCESS';
export const GSUITE_SERVICE_ERROR = 'gsuite/GSUITE_SERVICE_ERROR';

export const GSUITE_MAILBOX_REQUEST = 'gsuite/GSUITE_MAILBOX_REQUEST';
export const GSUITE_MAILBOX_SUCCESS = 'gsuite/GSUITE_MAILBOX_SUCCESS';
export const GSUITE_MAILBOX_ERROR = 'gsuite/GSUITE_MAILBOX_ERROR';

export const GSUITE_LOGIN_REQUEST = 'gsuite/GSUITE_LOGIN_REQUEST';
export const GSUITE_LOGIN_SUCCESS = 'gsuite/GSUITE_LOGIN_SUCCESS';
export const GSUITE_LOGIN_ERROR = 'gsuite/GSUITE_LOGIN_ERROR';

export const GSUITE_DELETE_MAILBOX_REQUEST = 'gsuite/GSUITE_DELETE_MAILBOX_REQUEST';
export const GSUITE_DELETE_MAILBOX_SUCCESS = 'gsuite/GSUITE_DELETE_MAILBOX_SUCCESS';
export const GSUITE_DELETE_MAILBOX_ERROR = 'gsuite/GSUITE_DELETE_MAILBOX_ERROR';

export const GSUITE_CUSTOMER_CHECK_REQUEST = 'gsuite/GSUITE_CUSTOMER_CHECK_REQUEST';
export const GSUITE_CUSTOMER_CHECK_SUCCESS = 'gsuite/GSUITE_CUSTOMER_CHECK_SUCCESS';
export const GSUITE_CUSTOMER_CHECK_ERROR = 'gsuite/GSUITE_CUSTOMER_CHECK_ERROR';

export const GSUITE_CUSTOMER_DATA_REQUEST = 'gsuite/GSUITE_CUSTOMER_DATA_REQUEST';
export const GSUITE_CUSTOMER_DATA_SUCCESS = 'gsuite/GSUITE_CUSTOMER_DATA_SUCCESS';
export const GSUITE_CUSTOMER_DATA_ERROR = 'gsuite/GSUITE_CUSTOMER_DATA_ERROR';

export const GSUITE_SEAT_PRICING_REQUEST = 'gsuite/GSUITE_SEAT_PRICING_REQUEST';
export const GSUITE_SEAT_PRICING_SUCCESS = 'gsuite/GSUITE_SEAT_PRICING_SUCCESS';
export const GSUITE_SEAT_PRICING_ERROR = 'gsuite/GSUITE_SEAT_PRICING_ERROR';

export const GSUITE_VERIFICATION_REQUEST = 'gsuite/GSUITE_VERIFICATION_REQUEST';
export const GSUITE_VERIFICATION_SUCCESS = 'gsuite/GSUITE_VERIFICATION_SUCCESS';
export const GSUITE_VERIFICATION_ERROR = 'gsuite/GSUITE_VERIFICATION_ERROR';

export const GSUITE_VERIFICATION_STATUS_REQUEST = 'gsuite/GSUITE_VERIFICATION_STATUS_REQUEST';
export const GSUITE_VERIFICATION_STATUS_SUCCESS = 'gsuite/GSUITE_VERIFICATION_STATUS_SUCCESS';
export const GSUITE_VERIFICATION_STATUS_ERROR = 'gsuite/GSUITE_VERIFICATION_STATUS_ERROR';

export const GSUITE_VERIFY_RECORD_REQUEST = 'gsuite/GSUITE_VERIFY_RECORD_REQUEST';
export const GSUITE_VERIFY_RECORD_SUCCESS = 'gsuite/GSUITE_VERIFY_RECORD_SUCCESS';
export const GSUITE_VERIFY_RECORD_ERROR = 'gsuite/GSUITE_VERIFY_RECORD_ERROR';

export const GSUITE_USER_ACCOUNTS_REQUEST = 'gsuite/GSUITE_USER_ACCOUNTS_REQUEST';
export const GSUITE_USER_ACCOUNTS_SUCCESS = 'gsuite/GSUITE_USER_ACCOUNTS_SUCCESS';
export const GSUITE_USER_ACCOUNTS_ERROR = 'gsuite/GSUITE_USER_ACCOUNTS_ERROR';

export const GSUITE_USER_DATA_REQUEST = 'gsuite/GSUITE_USER_DATA_REQUEST';
export const GSUITE_USER_DATA_SUCCESS = 'gsuite/GSUITE_USER_DATA_SUCCESS';
export const GSUITE_USER_DATA_ERROR = 'gsuite/GSUITE_USER_DATA_ERROR';

export const GSUITE_USER_CREATE_REQUEST = 'gsuite/GSUITE_USER_CREATE_REQUEST';
export const GSUITE_USER_CREATE_SUCCESS = 'gsuite/GSUITE_USER_CREATE_SUCCESS';
export const GSUITE_USER_CREATE_ERROR = 'gsuite/GSUITE_USER_CREATE_ERROR';

export const GSUITE_USER_DELETE_REQUEST = 'gsuite/GSUITE_USER_DELETE_REQUEST';
export const GSUITE_USER_DELETE_SUCCESS = 'gsuite/GSUITE_USER_DELETE_SUCCESS';
export const GSUITE_USER_DELETE_ERROR = 'gsuite/GSUITE_USER_DELETE_ERROR';

export const GSUITE_PRODUCT_PRICING_REQUEST = 'gsuite/GSUITE_PRODUCT_PRICING_REQUEST';
export const GSUITE_PRODUCT_PRICING_SUCCESS = 'gsuite/GSUITE_PRODUCT_PRICING_SUCCESS';
export const GSUITE_PRODUCT_PRICING_ERROR = 'gsuite/GSUITE_PRODUCT_PRICING_ERROR';

export const GSUITE_PURCHASE_REQUEST = 'gsuite/GSUITE_PURCHASE_REQUEST';
export const GSUITE_PURCHASE_SUCCESS = 'gsuite/GSUITE_PURCHASE_SUCCESS';
export const GSUITE_PURCHASE_ERROR = 'gsuite/GSUITE_PURCHASE_ERROR';

export const GSUITE_UPGRADE_REQUEST = 'gsuite/GSUITE_UPGRADE_REQUEST';
export const GSUITE_UPGRADE_SUCCESS = 'gsuite/GSUITE_UPGRADE_SUCCESS';
export const GSUITE_UPGRADE_ERROR = 'gsuite/GSUITE_UPGRADE_ERROR';

export const GSUITE_BILLING_CYCLE_REQUEST = 'gsuite/GSUITE_BILLING_CYCLE_REQUEST';
export const GSUITE_BILLING_CYCLE_SUCCESS = 'gsuite/GSUITE_BILLING_CYCLE_SUCCESS';
export const GSUITE_BILLING_CYCLE_ERROR = 'gsuite/GSUITE_BILLING_CYCLE_ERROR';

export const GSUITE_UPDATE_BILLING_CYCLE_REQUEST = 'gsuite/GSUITE_UPDATE_BILLING_CYCLE_REQUEST';
export const GSUITE_UPDATE_BILLING_CYCLE_SUCCESS = 'gsuite/GSUITE_UPDATE_BILLING_CYCLE_SUCCESS';
export const GSUITE_UPDATE_BILLING_CYCLE_ERROR = 'gsuite/GSUITE_UPDATE_BILLING_CYCLE_ERROR';
