import { createRoute, redirect } from "@tanstack/react-router";
import { loadAppConfig } from "App/actions/loadAppConfig";
import { CustomErrorFallback } from "components/Errors/ErrorBoundary";
import AppLoader from "components/Loaders/App";
import { pushNotification } from "components/Toast/functions";
import { brandStore } from 'config/hooks/useBrandStore';
import type { activateAccountSchema } from 'containers/login/modules/activate';
import { noop } from 'lodash';
import { RootRoute } from 'router/__root';
import { z } from 'zod';

const errorSchema = z
    .object({
        code: z.string(),
        errors: z.array(
            z.object({
                status: z.number(),
                details: z.string()
            })
        )
    })
    .transform(({ code, errors }) => ({ code, ...errors[0] }));

/**********************************************************************************************************
 *   ROUTE START
 **********************************************************************************************************/
export const ApproveUserRoute = createRoute({
    getParentRoute: () => RootRoute,
    path: '/approve-user/$token',
    pendingMs: 0, // ensure appLoader always shows
    errorComponent: CustomErrorFallback,
    pendingComponent: () => <AppLoader fullHeight />,
    staticData: {
        preventLoadAppConfig: true
    },
    async loader({ params: { token }, context: { NXQuery, queryClient } }) {
        // get necessary information from token for if we do fail to approve user
        await NXQuery.auth.login.checkToken.fetch().catch(noop);

        try {
            // The current account must exist before we can approve the user
            await NXQuery.account.subaccount.exists.fetch(token);

            // Attempt to approve the user
            await NXQuery.account.subaccount.approve.execute(token);

            // Handle success notification
            pushNotification({ details: 'Invitation to manage account accepted.', status: 200 }, null, 'global');
        } catch (e) {
            // Treat either endpoints errors the same
            const isIntaserve = brandStore.state.activeBrand === 'intaserve';
            const { data: error } = errorSchema.safeParse(e);
            const { is_security_detail_completed, is_user_detail_completed } = NXQuery.auth.login.checkToken.getData()?.data?.attributes ?? {};
            const isCheckTokenSuccess = queryClient.getQueryState(NXQuery.auth.login.checkToken.createQueryKey())?.status === 'success';

            /***** INTASERVE *****/
            // Intaserve handles additional users differently as they are only using Oauth, so redirect them here instead
            if (isIntaserve && error?.code === 'ERR_INVITATION_REQUIRES_SIGN_UP') {
                return handleIntaserveAdditionalUser(token);
            }

            /**** VENTRA *****/
            // before redirecting, show any toasts that the user may need to see
            if (error?.code === 'ERR_INVITATION_REQUIRES_SIGN_UP') {
                if (isCheckTokenSuccess) {
                    error.details = 'Please log out to accept this invitation.';
                } else {
                    error.details = 'Please create an account to accept this invitation.';
                }
            }
            if (error?.code === 'ERR_ACCESS_TOKEN') error.details = 'You must be logged in to accept this invitation.';

            pushNotification(error, null, 'global');

            // Take the user to the necessary activate route
            switch (error?.code) {
                // If the user is not authorized, they should just be taken through the standard login flow, and then can
                // accept the invitation after logging in
                case 'ERR_INVITATION_UNAUTHORISED':
                    return await loadAppConfig({ context: 'loader' });
                case 'ERR_ACCESS_TOKEN':
                    /**
                     * We need to send through the approve token under a different search param, since token is used in the login area to determine the initial state.
                     * Once we are done with login, this should be used to redirect back to this route to try again
                     */
                    throw redirect({ to: '/login', search: { 'approve-token': token } });
                case 'ERR_INVITATION_TOKEN_INVALID':
                    return handleTemplate('invalid', token);
                case 'ERR_INVITATION_REQUIRES_SIGN_UP': {
                    if (isCheckTokenSuccess) {
                        // Already logged in, but the token is for a user that doesn't have an account
                        await loadAppConfig({
                            context: 'loader',
                            onError: () => redirect({ to: '/login', throw: true })
                        });
                    }
                    return !is_security_detail_completed || !is_user_detail_completed
                        ? handleTemplate('additional-user', token)
                        : handleTemplate('invalid', token);
                }
                default:
                    return handleTemplate('expired-token', token);
            }
        }

        // If the user was successfully approved, get the necessary data to load the app and navigate to dashboard
        await loadAppConfig({
            context: 'loader',
            onError: () => redirect({ to: '/login', throw: true })
        });
    }
});

function handleTemplate(template: z.infer<typeof activateAccountSchema>['template'], token: string): never {
    throw redirect({
        to: '/activate',
        search: {
            token,
            template
        }
    });
}

function handleIntaserveAdditionalUser(token: string) {
    throw redirect({
        to: '/login',
        search: (search) => ({ ...search, token })
    });
}
