/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
export const phosphorIconPropKeys = [
    /**
     * Icon Colours
     */
    /** Colour Primary Purple */
    'primary',
    /** Colour Secondary Gray */
    'secondary',
    /** Colour Secondary Gray alternative */
    'secondary-alt',
    /** Colour Confirm Green */
    'confirm',
    /** Colour Warning Red */
    'warn',
    /** Colour Info Blue */
    'info',
    /** Colour Notice Orange */
    'notice',
    /** Colour White */
    'white',
    /** Colour Black === Dark Gray */
    'black',
    /** Colour #000 */
    'blackest',
    /** Colour Inactive, feint gray */
    'inactive',
    /** Colour Gray */
    'gray',
    /** Colour Light Gray */
    'light-gray',
    /** Colour Faded Light Gray */
    'faded-light-gray',
    'color',
    'size',
] as const;
