import {
    PROMOTION_CLAIM_FREE_DOMAIN_ERROR,
    PROMOTION_CLAIM_FREE_DOMAIN_REQUEST,
    PROMOTION_CLAIM_FREE_DOMAIN_SUCCESS,
    PROMOTION_INITIAL_STATE,
    PROMOTION_PROMO_CODE_STATUS_ERROR,
    PROMOTION_PROMO_CODE_STATUS_REQUEST,
    PROMOTION_PROMO_CODE_STATUS_RESET,
    PROMOTION_PROMO_CODE_STATUS_SUCCESS,
    PROMOTION_RADIX_RENEW_OFFERS_ERROR,
    PROMOTION_RADIX_RENEW_OFFERS_REQUEST,
    PROMOTION_RADIX_RENEW_OFFERS_SUCCESS
} from './actionTypes';
import { checkCodeCancelTokens } from './api';

const initialState = {
    promotion_all_services_status: null,
    promotion_all_services_data: null,
    promotion_claim_free_domain_status: null,
    promotion_claim_free_domain_data: null,
    promotion_promo_code_status_request_data: {},
    promotion_radix_renew_offers_status: null,
    promotion_radix_renew_offers_data: null
};

const promotionReducer = (state = initialState, action) => {
    switch (action.type) {
        /**********************************************************************************************************
         *   RESET
         **********************************************************************************************************/
        case PROMOTION_INITIAL_STATE:
            return initialState;

        /**********************************************************************************************************
         *  Free Domain Claim
         **********************************************************************************************************/
        case PROMOTION_CLAIM_FREE_DOMAIN_REQUEST:
            return {
                ...state,
                promotion_claim_free_domain_status: 'loading',
                promotion_claim_free_domain_data: null
            };

        case PROMOTION_CLAIM_FREE_DOMAIN_SUCCESS:
            return {
                ...state,
                promotion_claim_free_domain_status: 'success',
                promotion_claim_free_domain_data: action.promotion_claim_free_domain_data
            };

        case PROMOTION_CLAIM_FREE_DOMAIN_ERROR:
            return {
                ...state,
                promotion_claim_free_domain_status: 'error'
            };

        /**********************************************************************************************************
         *  Promo Code Check START
         **********************************************************************************************************/
        case PROMOTION_PROMO_CODE_STATUS_RESET:
            return {
                ...state,
                promotion_promo_code_status_request_data: {}
            };

        case PROMOTION_PROMO_CODE_STATUS_REQUEST: {
            const { promotion_promo_code_status_request_data } = action;
            const { promo_data } = promotion_promo_code_status_request_data;
            const { promo_code } = promo_data;

            const new_promotion_promo_code_status_request_data = {
                ...state.promotion_promo_code_status_request_data
            };

            if (Object.prototype.hasOwnProperty.call(new_promotion_promo_code_status_request_data, promo_code)) {
                new_promotion_promo_code_status_request_data[promo_code].status = 'loading';
            } else {
                new_promotion_promo_code_status_request_data[promo_code] = {
                    status: 'loading',
                    promo_data,
                    response_data: null
                };
            }

            return {
                ...state,
                promotion_promo_code_status_request_data: new_promotion_promo_code_status_request_data
            };
        }

        case PROMOTION_PROMO_CODE_STATUS_SUCCESS: {
            const { promotion_promo_code_status_request_data } = action;
            const { promo_data, response_data } = promotion_promo_code_status_request_data;
            const { promo_code } = promo_data;

            const new_promotion_promo_code_status_request_data = {
                ...state.promotion_promo_code_status_request_data
            };

            if (Object.prototype.hasOwnProperty.call(checkCodeCancelTokens, promo_code)) {
                delete checkCodeCancelTokens[promo_code];
            }

            new_promotion_promo_code_status_request_data[promo_code].status = 'success';
            new_promotion_promo_code_status_request_data[promo_code].response_data = response_data;

            return {
                ...state,
                promotion_promo_code_status_request_data: new_promotion_promo_code_status_request_data
            };
        }

        case PROMOTION_PROMO_CODE_STATUS_ERROR: {
            const { promotion_promo_code_status_request_data } = action;
            const { promo_data } = promotion_promo_code_status_request_data;
            const { promo_code } = promo_data;

            if (!Object.prototype.hasOwnProperty.call(state.promotion_promo_code_status_request_data, promo_code))
                return {
                    ...state
                };

            const new_promotion_promo_code_status_request_data = {
                ...state.promotion_promo_code_status_request_data
            };

            new_promotion_promo_code_status_request_data[promo_code].status = 'error';
            new_promotion_promo_code_status_request_data[promo_code].response_data = null;

            return {
                ...state,
                promotion_promo_code_status_request_data: new_promotion_promo_code_status_request_data
            };
        }
        /**********************************************************************************************************
         *  Promo Code Check END
         **********************************************************************************************************/

        /**********************************************************************************************************
         *   RADIX FREE DOMAINS
         **********************************************************************************************************/
        case PROMOTION_RADIX_RENEW_OFFERS_REQUEST:
            return {
                ...state,
                promotion_radix_renew_offers_status: 'loading',
                promotion_radix_renew_offers_data: null
            };

        case PROMOTION_RADIX_RENEW_OFFERS_SUCCESS:
            return {
                ...state,
                promotion_radix_renew_offers_status: 'success',
                promotion_radix_renew_offers_data: action.promotion_radix_renew_offers_data
            };

        case PROMOTION_RADIX_RENEW_OFFERS_ERROR:
            return {
                ...state,
                promotion_radix_renew_offers_status: 'error'
            };

        default:
            return state;
    }
};

export default promotionReducer;
