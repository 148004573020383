import classNames from 'classnames';
import { Input } from 'components/Form/Input';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import NXBox from 'components/NXBox';
import { Flex } from 'components/Utils/Flex';
import React, { useRef, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { useClickAway } from 'utilities/hooks/useClickAway';
import './_ColourPicker.scss';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    value: string;
    onChange: (value: string | null) => void;
    validations?: ((value: any) => string | undefined)[];
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/

export const _ColourPicker: React.FC<Props> = ({ value, onChange: _onChange, validations }) => {
    /***** STATES *****/
    const [openPalette, setOpenPalette] = useState(false);
    const [isValid, setIsValid] = useState(false);

    /***** HOOKS *****/
    const colourPickerRef = useRef(null);

    useClickAway([colourPickerRef], () => {
        setOpenPalette(false);
    });

    /***** FUNCTIONS *****/
    function validationResultHandler(validationResult: string | undefined) {
        setIsValid(!validationResult);
    }

    function onChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.value) {
            _onChange(e.target.value);
        } else {
            _onChange(null);
        }
    }

    /***** RENDER *****/
    return (
        <div ref={colourPickerRef} onPointerDown={() => setOpenPalette(true)} className="ColourPicker">
            <NXBox className={classNames({ active: openPalette, warn: !isValid })}>
                <Flex align="center" gap={0} alignChildren="stretch">
                    <PhosphorIcons.EyeDropperFill size={25} />
                    <Input.Decoupled
                        placeholder="Choose colour"
                        name="custom_color"
                        value={value}
                        intrinsicProps={{ onChange }}
                        fullWidth
                        validate={validations}
                        onEmitValidationResult={validationResultHandler}
                    />
                </Flex>
                <HexColorPicker className={classNames({ active: openPalette })} color={value} onChange={_onChange} />
            </NXBox>
        </div>
    );
};
