import {
    APP_ALERT_BANNER_REQUEST,
    APP_ALERT_BANNER_SUCCESS,
    APP_CHANGE_LIVECHAT_VISIBILITY,
    APP_COUNTRIES_ERROR,
    APP_COUNTRIES_REQUEST,
    APP_COUNTRIES_SUCCESS,
    APP_DISPLAY_STATES,
    APP_LOCAL_STATES_ERROR,
    APP_LOCAL_STATES_REQUEST,
    APP_LOCAL_STATES_SUCCESS,
    APP_MOUNTING
} from './actionTypes';

/**
 * @type {{
 *   app_mounting: boolean,
 *   app_livechat_visibility: string,
 *   app_viewport?: 'xs' | 'sm' | 'md' | 'lg' | 'xl',
 * }}
 */
const initialState = {
    app_mounting: false,
    app_livechat_visibility: 'minimized'
};

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        /**********************************************************************************************************
         *   APP USER STATES
         **********************************************************************************************************/
        case APP_MOUNTING:
            return {
                ...state,
                app_mounting: action.app_mounting
            };

        /**********************************************************************************************************
         *   APP DISPLAY STATES
         **********************************************************************************************************/
        case APP_DISPLAY_STATES:
            return {
                ...state,
                app_viewport: /** @type {'xs' | 'sm' | 'md' | 'lg' | 'xl'} */ (action.app_viewport)
            };
        /**********************************************************************************************************
         *   APP COUNTRIES DATA
         **********************************************************************************************************/
        case APP_COUNTRIES_REQUEST:
            return {
                ...state,
                app_countries_status: 'loading',
                app_countries_data: null
            };

        case APP_COUNTRIES_SUCCESS:
            return {
                ...state,
                app_countries_status: 'success',
                app_countries_data: action.app_countries_data
            };

        case APP_COUNTRIES_ERROR:
            return {
                ...state,
                app_countries_status: 'error'
            };
        /**********************************************************************************************************
         *   APP LOCAL STATES DATA
         **********************************************************************************************************/
        case APP_LOCAL_STATES_REQUEST:
            return {
                ...state,
                app_local_states_status: 'loading',
                app_local_states_data: null
            };

        case APP_LOCAL_STATES_SUCCESS:
            return {
                ...state,
                app_local_states_status: 'success',
                app_local_states_data: action.app_local_states_data
            };

        case APP_LOCAL_STATES_ERROR:
            return {
                ...state,
                app_local_states_status: 'error'
            };

        /**********************************************************************************************************
         *   APP ALERT BANNER
         **********************************************************************************************************/
        case APP_ALERT_BANNER_REQUEST:
            return {
                ...state,
                app_alert_banner_data: null
            };

        case APP_ALERT_BANNER_SUCCESS:
            return {
                ...state,
                app_alert_banner_data: action.app_alert_banner_data
            };

        /**********************************************************************************************************
         *   APP LIVECHAT VISIBILITY
         **********************************************************************************************************/
        case APP_CHANGE_LIVECHAT_VISIBILITY:
            return {
                ...state,
                app_livechat_visibility: action.app_livechat_visibility
            };

        default:
            return state;
    }
};

export default appReducer;
