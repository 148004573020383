import { ClickAwayListener } from '@mui/material';
import { PhosphorIcons } from 'components/Icons/Phosphor';
import { useState } from 'react';
import './_NXSelect.scss';
import { NXSelectContext } from 'components/Form/NXSelect/_NXSelect/consts';
import type { NXSelectNamespace } from 'components/Form/NXSelect/_NXSelect/types';
import classNames from 'classnames';
import { useStyle } from 'utilities/hooks/useStyle';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type Props = {
    children: React.ReactNode;
    onChange?: (option: NXSelectNamespace.OptionValue) => void;
    value?: NXSelectNamespace.OptionValue['value'];
    overflowLimit?: number;
};

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const _NXSelect: React.FC<Props> = ({ children, onChange, value, overflowLimit = 8 }) => {
    /***** STATE *****/
    const [isListOpen, setIsListOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<NXSelectNamespace.OptionValue | null>(null);

    /***** HOOKS *****/
    const style = useStyle({
        '--overflow-limit': String(overflowLimit)
    });

    /***** FUNCTIONS *****/
    function handleChange(option: NXSelectNamespace.OptionValue) {
        setSelectedOption(option);
        onChange?.(option);
    }

    /***** RENDER *****/
    return (
        <NXSelectContext.Provider value={{ isListOpen, setIsListOpen, selectedOption, setSelectedOption, value, onChange: handleChange }}>
            <ClickAwayListener onClickAway={() => setIsListOpen(false)}>
                <div className="NXSelect" style={style}>
                    <button type="button" onClick={() => setIsListOpen(!isListOpen)} className="NXSelect__listToggler">
                        {selectedOption?.label ?? <span className="NXSelect__listToggler__placeholder">Select an option</span>}
                        <PhosphorIcons.Chevron state={isListOpen} />
                    </button>

                    <div className={classNames('NXSelect__list', { hidden: !isListOpen })}>{children}</div>
                </div>
            </ClickAwayListener>
        </NXSelectContext.Provider>
    );
};
