import { NXPicturedIframeSourceDocPreview } from 'components/PicturedIframeSourceDocPreview';
import type { PictureInPictureNamespace } from 'components/PictureInPicture/types';
import { useRefContext } from 'components/Utils/RefProvider/useRefContext';
import { useSiteDataPreviewValues } from 'containers/katana/containers/ContentEditorLightbox/hooks/useSiteDataPreviewValues';
import { useKatanaParams } from 'containers/katana/hooks/useSetupEditorRouteParams';
import { katanaQuery } from 'containers/katana/queries/tanstackTree';
import type { KatanaNamespace } from 'containers/katana/types';
import React, { useEffect, useMemo } from 'react';
import { useTanstackStore } from 'utilities/hooks/tanstack-store/useTanstackStore';
import { useTruthyValue } from 'utilities/hooks/useTruthyValue';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type SitePresetPreviewBrowserBox = React.FC<{
    stateStoreData: PictureInPictureNamespace.CreateStateData;
    presetID?: KatanaNamespace.SitePresetId;
    selectedPresetID: KatanaNamespace.SitePresetId;
}>;

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
const SitePresetPreviewBrowserBox: SitePresetPreviewBrowserBox = ({ stateStoreData, presetID, selectedPresetID }) => {
    /***** HOOKS *****/
    const { siteData } = useSiteDataPreviewValues();
    const [stateStoreValue, setStateStoreValue] = useTanstackStore(stateStoreData.store);
    const isOpen = ['maximised', 'visible'].includes(stateStoreValue);
    const { katanaServiceId } = useKatanaParams();
    const leftAnchorElement = useRefContext('ContentEditorLightboxMainContent');

    /***** QUERIES *****/
    const {
        data: get_preset_preview_data,
        isFetching: isPresetPreviewFetching,
        isError,
    } = katanaQuery.serviceID.sitePresetPreview.useQuery({ serviceID: katanaServiceId, presetID, site: siteData, enabled: isOpen });
    const displayingPreview = useTruthyValue(get_preset_preview_data);

    /***** EFFECTS *****/
    useEffect(() => {
        if (stateStoreValue === 'visible' && selectedPresetID === 'katana.v1.custom') {
            setStateStoreValue('hidden');
        }
    }, [stateStoreValue, selectedPresetID]);

    /***** RENDER HELPERS *****/
    const siteEditorLoadingData = useMemo(
        () => [{ condition: isPresetPreviewFetching, message: 'Fetching template preview...' }],
        [isPresetPreviewFetching]
    );

    /***** RENDER *****/
    return (
        <NXPicturedIframeSourceDocPreview
            className="SitePresetPreviewBrowserBox"
            srcDoc={displayingPreview}
            stateStoreData={stateStoreData}
            loaderData={siteEditorLoadingData}
            isError={isError}
            leftAnchorElement={leftAnchorElement}
        />
    );
};
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
export { SitePresetPreviewBrowserBox };
