import axios from 'axios';
import { nxAxios } from 'store/nxAxios';
import type { MergeDeep } from 'type-fest';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
export namespace API {
    export namespace login {
        export namespace requestAlternate {
            export type ReturnType = Artah.TwoFactor.Create.GET.NXReturnType;
        }

        export namespace twoFactor {
            export namespace listMethods {
                export type Method = 'SMS' | 'EMAIL' | 'PUSH' | 'GAUTH';
                // prettier-ignore
                export type ReturnType = Promise<
                    MergeDeep<Artah.User.TwoFactor.GET._200, {
                        data: Array<
                            MergeDeep<Artah.User.TwoFactor.GET._200['data'][number], {
                                attributes: {
                                    method: Method
                                }
                            }>
                        >
                    }> 
                >
            }
            export namespace submit {
                export type Params = string;
            }
        }

        export namespace submit {
            export type Params = {
                email: string;
                password: string;
                g_recaptcha_response: string;
                remember: boolean;
            };
        }

        export namespace oAuthSubmit {
            export type params = {
                provider: string;
                token: string;
            };
        }

        export namespace additionalUser {
            export namespace oAuthSubmit {
                export type params = {
                    provider: string;
                    accessToken: string;
                    token: string;
                    redirectUrl: string;
                };
            }
            export type params = {
                provider: string;
                token: string;
            };
        }
    }

    export namespace token {
        export namespace check {
            export type ReturnType = Artah.CheckToken.GET.NXReturnType;
            export type Params = void;
        }
    }

    export namespace user {
        export namespace account {
            export namespace list {
                export type ReturnType = Artah.User.AccountList.GET.NXReturnType;
            }
        }
    }

    export namespace logout {
        export type ReturnType = Promise<void>;
    }

    export namespace user {
        export namespace get {
            export type ReturnType = Artah.User.GET.NXReturnType;
            export type Params = void;
        }
    }
}

export const API = {
    /**********************************************************************************************************
     *   TOKEN API
     **********************************************************************************************************/

    token: {
        check: (): API.token.check.ReturnType => {
            return nxAxios.get('/api/check-token');
        }
    },

    /**********************************************************************************************************
     *   LOGIN API
     **********************************************************************************************************/

    login: {
        submit: (attributes: API.login.submit.Params): Artah.Login.POST.NXReturnType => {
            return nxAxios.post('/api/login', {
                type: 'login',
                attributes
            });
        },

        oAuthSubmit: ({ provider, token }: API.login.oAuthSubmit.params): Artah.Auth.Google.Callback.POST.NXReturnType => {
            return nxAxios.post(`/api/auth/${provider}/callback`, {
                token
            });
        },

        additionalUser: {
            oAuthSubmit: ({
                provider,
                accessToken,
                token,
                redirectUrl
            }: API.login.additionalUser.oAuthSubmit.params): Artah.User.AdditionalUser.Google.Signup.POST.NXReturnType => {
                return nxAxios.post(`/api/user/additional-user/${provider}/signup`, {
                    auth_token: accessToken,
                    token,
                    redirect_url: redirectUrl
                });
            }
        },

        forgot: {
            password: (attributes) => {
                return axios.post('/api/forgot/password', {
                    type: 'forgot',
                    attributes
                });
            },

            email: (attributes) => {
                return axios.post('/api/forgot/email', {
                    type: 'forgot',
                    attributes
                });
            }
        },

        twoFactor: {
            requestDefault: () => {
                return nxAxios.get('/api/two-factor/create');
            },

            requestAlternate: (value: API.login.twoFactor.listMethods.Method): API.login.requestAlternate.ReturnType => {
                return nxAxios.get('/api/two-factor/create?method=' + value);
            },

            listMethods: (): API.login.twoFactor.listMethods.ReturnType => {
                return nxAxios.get('/api/user/two-factor');
            },

            submit: (value: API.login.twoFactor.submit.Params) => {
                return axios.post('/api/two-factor/', {
                    type: 'two-factor-authentication',
                    attributes: {
                        two_factor_token: value
                    }
                });
            }
        },

        recovery: {
            submit: (attributes) => {
                return axios.post('/api/user/recovery', {
                    type: 'recovery',
                    attributes
                });
            }
        }
    },

    logout: (): API.logout.ReturnType => {
        return nxAxios.delete('/api/logout');
    },

    /**********************************************************************************************************
     *   USER API
     **********************************************************************************************************/

    user: {
        get: (_: API.user.get.Params): API.user.get.ReturnType => {
            return nxAxios.get('/api/user');
        },

        updateEmail: (email) => {
            return axios.put('/api/user/email', {
                type: 'user',
                attributes: {
                    email
                }
            });
        },

        account: {
            list: (): API.user.account.list.ReturnType => {
                return nxAxios.get(`/api/user/account-list`);
            },

            set: (id: number): Artah.User.SelectAccount.ID.POST.NXReturnType => {
                return nxAxios.post(`/api/user/select-account/${id}`);
            }
        }
    }
};
